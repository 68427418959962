<template>
    <div>
      <div class="relative mt-4">
        <new-table-lite
          class="admin-table summary"
          :is-loading="table.isLoading"
          :is-static-mode="true"
          :is-slot-mode='true'
          :columns="selectColumnType()"
          :rows="table.rows"
          :total="table.totalRecordCount"
          :sortable="table.sortable"
          @is-finished="table.isLoading = false"
          :page-size='table.pagesize'
          :pageOptions='table.pageOptions'
          :has-checkbox="false"
          :is-hide-paging="false"
          :pageSizeChangeLabel="'pageSizeChangeLabel'"
          :messages="{
            pagingInfo: 'Total: {2}',
            pageSizeChangeLabel: 'Items per Page',
            gotoPageLabel: '',
            noDataAvailable: 'No records found!',
          }"
        >
        <template v-slot:location="data">
          <span v-if="data.value.productPricing?.locations?.name">{{data.value.productPricing.locations.name}}</span>
          <span v-else-if="data.value.inventorySnapshots?.[0]?.location?.name">{{data.value.inventorySnapshots?.[0]?.location?.name}}</span>
          <span v-else></span>
        </template>
        <template v-slot:Name="data">
          <span v-if="data.value.name">{{data.value.name}}</span>
          <span v-else></span>
        </template>
        <template v-slot:brand="data">
          <span v-if="data.value.brand?.name">{{data.value.brand?.name}}</span>
          <span v-else></span>
        </template>
        <template v-slot:productLot="data">
          <span v-if="data.value.batchUploadDetails?.[0]?.batch_lot">{{data.value.batchUploadDetails?.[0]?.batch_lot}}</span>
          <span v-else>--</span>
        </template>
        <template v-slot:stock="data">
          <span v-if="isCurrentDate(this.filter.date.value)">{{data.value.productPricing?.stock}}</span>
          <span v-else>{{ data.value.inventorySnapshots?.[0]?.closingInventory }}</span>
        </template>
        <template v-slot:batchStock="data">
          <span v-if="isCurrentDate(this.filter.date.value)">{{data.value.batchUploadDetails?.[0]?.current_nou}}</span>
          <span v-else>{{ data.value.batchUploadDetails?.[0]?.inventory_snapshot_batches?.[0]?.closing_inventory?data.value.batchUploadDetails?.[0]?.inventory_snapshot_batches?.[0]?.closing_inventory:0 }}</span>
        </template>
        <template v-slot:batchTotalCost="data">
          <span v-if="isCurrentDate(this.filter.date.value)">{{(data.value.batchUploadDetails?.[0]?.cost && data.value.batchUploadDetails?.[0]?.current_nou)?(data.value.batchUploadDetails[0].cost*data.value.batchUploadDetails[0].current_nou):0}}</span>
          <span v-else>{{ (data.value.batchUploadDetails?.[0]?.inventory_snapshot_batches?.[0]?.closing_inventory && data.value.batchUploadDetails?.[0]?.inventory_snapshot_batches?.[0]?.cost)?data.value.batchUploadDetails[0].inventory_snapshot_batches[0].closing_inventory*data.value.batchUploadDetails?.[0]?.inventory_snapshot_batches?.[0]?.cost:0 }}</span>
        </template>
        <template v-slot:totalCostInStock="data">
          <span>{{ data.value.productPricing?.totalStockCost ? data.value.productPricing.totalStockCost : (data.value.inventorySnapshots?.[0]?.totalStockCost ? data.value.inventorySnapshots[0].totalStockCost:0) }}</span>
        </template>
        <template v-slot:averageUnitCostInStock="data">
          <span>{{ data.value.productPricing?.cost ? data.value.productPricing.cost : ((data.value.inventorySnapshots?.[0]?.cost)?data.value.inventorySnapshots[0].cost:0) }}</span>
        </template>
        <template v-slot:retailPrice="data">
          <span>{{ data.value.productPricing?.sale_price ? data.value.productPricing?.sale_price : ((data.value.inventorySnapshots?.[0]?.sale_price)?data.value.inventorySnapshots[0].sale_price:0) }}</span>
        </template>
        <template v-slot:profitMargin="data">
          <span>{{ data.value.productPricing?.profitMargin ? data.value.productPricing.profitMargin : ((data.value.inventorySnapshots?.[0]?.profitMargin)?data.value.inventorySnapshots[0].profitMargin:0) }}</span>
        </template>
        <template v-slot:profitMarginPercentage="data">
          <span>{{ data.value.productPricing?.profit_margin_percentage ? data.value.productPricing?.profit_margin_percentage : ((data.value.inventorySnapshots?.[0]?.profit_margin_percentage)?data.value.inventorySnapshots[0].profit_margin_percentage:0) }}</span>
        </template>
        <template v-slot:markup="data">
          <span>{{ data.value.productPricing?.markup ? data.value.productPricing?.markup : ((data.value.inventorySnapshots?.[0]?.markup)?data.value.inventorySnapshots[0].markup:0) }}</span>
        </template>
        <template v-slot:firstReceivedOn="data">
          <span>{{ formatDate(data.value.batchUploadDetails?.[0]?.packaged_on) }}</span>
        </template>
        <template v-slot:lotReceivedOn="data">
          <span>{{ formatDate(data.value.batchUploadDetails?.[0]?.inventory_snapshot_batches?.[0]?.created_at) }}</span>
        </template>
        </new-table-lite>
      </div>
    </div>
  </template>
  
  <script>
  import { defineComponent , ref} from "vue";
  import Env from '@/env';
  import moment from 'moment'
  import { mapGetters } from 'vuex'
  import NewTableLite from '@/components/table/tableLite.vue'
  
  export default defineComponent({
    components: {
      NewTableLite
    },
    props: {
      filter: {
        type: Object,
        required: false
      },
      payload: {
        type: Array,
        required: false
      },
      includeLot: {
        type: String,
        required: false
      },
      loaded: {
        type: Boolean,
        required: false
      }
    },
    setup() {
      const columnsLot = ref([
        {
          label: "Location Name",
          field: "location",
          sortable: false,
       },
       {
          label: "Product Name",
          field: "Name",
          sortable: false,
       },
       {
          label: "Product variant name",
          field: "variantName",
          sortable: false,
        },
        {
          label: "Brand",
          field: "brand",
          sortable: false,
        },
        {
          isKey:true,
          label: "Product Lot",
          field: "productLot",
          sortable: false,
          headerClasses:'width'
        },
        {
          label: "Category",
          field: "categoryName",
          sortable: false,
          headerClasses:'width'
        },
        {
          label: "Sub Category",
          field: "subCategoryName",
          sortable: false,
        },
        {
          label: "SKU",
          field: "sku",
          sortable: false,
  
        },
        {
          label: "Compliance Type",
          field: "complianceType",
          sortable: false,
        },
        {
          label: "Unit of measurement",
          field: "concentration_unit",
          sortable: false,
        },
        {
          label: "Stock",
          field: "stock",
          sortable: false,
        },
        {
          label: "Batch Stock",
          field: "batchStock",
          sortable: false,
        },
        {
          label: "Batch Total Cost",
          field: "batchTotalCost",
          sortable: false,
        },
        {
          label: "Total Cost In Stock",
          field: "totalCostInStock",
          sortable: false,
        },
        {
          label: "Average Unit Cost in Stock",
          field: "averageUnitCostInStock",
          sortable: false,
        },
        {
          label: "Retail Price",
          field: "retailPrice",
          sortable: false,
        },
        {
          label: "Profit Margin",
          field: "profitMargin",
          sortable: false,
        },
        {
          label: "Profit Margin Percentage",
          field: "profitMarginPercentage",
          sortable: false,
        },
        {
          label: "Markup",
          field: "markup",
          sortable: false,
        },
        {
          label: "Lot Received On",
          field: "lotReceivedOn",
          sortable: false,
        }
      ]);
  
      const columnProduct = ref([
        {
          label: "Location Name",
          field: "location",
          sortable: false,
       },
       {
          label: "Product Name",
          field: "Name",
          sortable: false,
       },
       {
          label: "Product variant name",
          field: "variantName",
          sortable: false,
        },
        {
          label: "Brand",
          field: "brand",
          sortable: false,
        },
        {
          label: "Category",
          field: "categoryName",
          sortable: false,
          headerClasses:'width'
        },
        {
          label: "Sub Category",
          field: "subCategoryName",
          sortable: false,
        },
        {
          label: "SKU",
          field: "sku",
          sortable: false,
        },
        {
          label: "Compliance Type",
          field: "complianceType",
          sortable: false,
        },
        {
          label: "Unit of measurement",
          field: "concentration_unit",
          sortable: false,
        },
        {
          label: "Stock",
          field: "stock",
          sortable: false,
        },
        {
          label: "Total Cost In Stock",
          field: "totalCostInStock",
          sortable: false,
        },
        {
          label: "Average Unit Cost in Stock",
          field: "averageUnitCostInStock",
          sortable: false,
        },
        {
          label: "Retail Price",
          field: "retailPrice",
          sortable: false,
        },
        {
          label: "Profit Margin",
          field: "profitMargin",
          sortable: false,
        },
        {
          label: "Profit Margin Percentage",
          field: "profitMarginPercentage",
          sortable: false,
        },
        {
          label: "Markup",
          field: "markup",
          sortable: false,
        },
        {
          label: "First Received On",
          field: "firstReceivedOn",
          sortable: false,
        }
      ]);
  
      const table = ref({
        isLoading: false,
        rows: [],
        totalRecordCount: 0,
        sortable: {
          order: "name",
          sort: "asc",
        },
        pagesize: Env.defaultPageSize,
        pageOptions: Env.pageList,
        count: 0,
        page: 1,
        
      });
      
      return  {
        table,
        columnsLot,
        columnProduct
      };
    },
  
    async mounted() {
       this.load()
       this.emitter.on('reloadSalesList', this.reload)
    },
    beforeDestroy() {
     this.emitter.off('reloadSalesList', this.reload)
    },
    computed: {
          ...mapGetters({
              vg_selectedLocation: 'location/selected'
          })
    },
    watch: {
      loaded(){
        this.load()
      }
    },
    methods: {
      isCurrentDate(date){
        if(!date) return
        return moment(moment().format('YYYY-MM-DD')).isSame(date)
      },
      formatDate(date){
        if(!date) return ''
        return moment(date).format('YYYY-MM-DD hh:mm:ss A')
      },

      reload(){
        this.$nextTick(() => {
            this.load()
        })
      },
      selectColumnType(){
        if(this.includeLot){
          return this.columnsLot
        }else{
          return this.columnProduct
        }
      },
      async load(){
        this.table.isLoading = true
        this.table.rows = this.payload
        this.table.totalRecordCount = this.payload.length
        this.table.isLoading = false
      },
    }
  
  });
  </script>
  
  
<template>
    <div class="text-white flex-1 col-span-1 border-0 rounded-t-xl mt-1">
        <div class="pt-3 bg-[#2B2C32] px-[16px] py-[16px] rounded-xl">
            <div class="flex items-center  xl:flex-row flex-col gap-3 mt-3 mb-3"> 
                <search-terpene-aroma :terpene="terpeneList" :aroma="aromaList" :selectedTerpene="terpenes" :selectedAroma="aromas" @selectTerpene="addTerpene" @selectAroma="addAroma" />  
            </div>
            <div class="flex xl:flex-row flex-col gap-3 mt-7 mb-9">   
                <div class="w-full xl:w-1/2">
                    <block-loading v-if="terpeneLoading" />
                    <div v-else class="border-2 border-theme-green rounded-2xl">
                        <div class="py-2 px-3 border-b-2 border-theme-green text-grey font-bold">Terpenes</div>
                        <div v-if="terpenes.length>0">
                            <div v-for="(terpene,ind) in terpenes" :key="ind" class="flex flex-row justify-between">
                                <div class="py-2 px-3 font-bold">{{terpene.name}}</div>
                                <div class="mt-3 mr-4 cursor-pointer" @click.prevent="removeTerpene(terpene)">
                                    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.47578 13.8258L0.425781 12.7758L6.20078 7.00078L0.425781 1.22578L1.47578 0.175781L7.25078 5.95078L13.0258 0.175781L14.0758 1.22578L8.30078 7.00078L14.0758 12.7758L13.0258 13.8258L7.25078 8.05078L1.47578 13.8258Z" fill="#F53333"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div v-else class="py-2 px-3 text-grey">No Terpene Found</div>
                    </div>
                </div>
                <div class="w-full xl:w-1/2">
                    <block-loading v-if="aromaLoading" />
                    <div v-else class="border-2 border-theme-green rounded-2xl">
                        <div class="py-2 px-3 border-b-2 border-theme-green text-grey font-bold">Aromas</div>
                        <div v-if="aromas.length>0">
                            <div class="flex flex-row justify-between" v-for="(aroma,ind) in aromas" :key="'ar-'+ind">
                                <div class="py-2 px-3 font-bold flex flex-row">
                                    <div class="self-center mr-2">
                                        <svg width="17" height="6" viewBox="0 0 17 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.25 0H0.25V2H16.25V0ZM0.25 6H16.25V4H0.25V6Z" fill="#878686"/>
                                        </svg>
                                    </div>
                                    <div>{{aroma.name}}</div>
                                </div>
                                <div class="mt-3 mr-4 cursor-pointer" @click.prevent="removeAroma(aroma)">
                                    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.47578 13.8258L0.425781 12.7758L6.20078 7.00078L0.425781 1.22578L1.47578 0.175781L7.25078 5.95078L13.0258 0.175781L14.0758 1.22578L8.30078 7.00078L14.0758 12.7758L13.0258 13.8258L7.25078 8.05078L1.47578 13.8258Z" fill="#F53333"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div v-else class="py-2 px-3 text-grey">No Aroma Found</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Helpers from '@/helpers';
import { ref, defineComponent } from 'vue'
import SearchTerpeneAroma from './searchTerpeneAroma.vue'
import ProductApi from '@/controllers/BusinessProductApis'
import {mapMutations} from 'vuex'
import { useSnackbar } from "vue3-snackbar"

export default defineComponent({
    components: {
        SearchTerpeneAroma
    },
    props: ['editItem','aromas','terpenes'],
    setup() {
        const snackbar = useSnackbar();
        const terpeneLoading = ref(false)
        const terpeneList = ref([])

        const aromaLoading = ref(false)
        const aromaList = ref([])

        return {snackbar, terpeneLoading, terpeneList, aromaLoading, aromaList}
    },
    async mounted(){
        this.terpeneLoading = true
        this.aromaLoading = true
        await this.getTerpenes()
        this.terpeneLoading = false

        await this.getAromas()
        this.aromaLoading = false
    },
    watch: {
        async editItem(){
            this.terpeneLoading = true
            this.aromaLoading = true
            await this.getTerpenes()
            this.terpeneLoading = false

            await this.getAromas()
            this.aromaLoading = false
        }
    },
    methods: {
        async getTerpenes(){
            if(this.editItem.id){
                let res = await ProductApi.fetchAllTerpene()
                if(res?.data?.payload?.terpenes){
                    this.terpeneList = res.data.payload.terpenes
                }
            }
        },
        async getAromas(){
            if(this.editItem.id){
                let res = await ProductApi.fetchAllAromas()
                if(res?.data?.payload?.aromas){
                    this.aromaList = res.data.payload.aromas
                }
            }
        },
        async addTerpene(terpene){
            if(terpene.id){
                this.emitter.emit('updateMediaTerpeneAroma', {type: 'terpenes', add: {id:terpene.id, name: terpene.name}})
            }
        },
        async addAroma(aroma){
            if(aroma.id){
                this.emitter.emit('updateMediaTerpeneAroma', {type: 'aromas', add: {id:aroma.id, name: aroma.name}})
            }
        },
        async removeTerpene(terpene){
            if(terpene.id){
                this.emitter.emit('updateMediaTerpeneAroma', {type: 'terpenes', remove: {id:terpene.id, name: terpene.name}})
            }
        },
        async removeAroma(aroma){
            if(aroma.id){
                this.emitter.emit('updateMediaTerpeneAroma', {type: 'aromas', remove: {id:aroma.id, name: aroma.name}})
            }
        },
        ...mapMutations({
            vm_spinner: 'spinner/mutateSpinner'
        })
    }
})
</script>


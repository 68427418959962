<template>
    <div class="text-white flex-1 col-span-1 border-0 rounded-t-xl mt-1">
        <div class="pt-3 bg-[#2B2C32] px-[16px] py-[16px] rounded-xl">
            <div v-if="!productLoading">
                <div class="relative flex flex-col">
                    <div class="w-full w-full flex justify-between">
                        <div class="input-wrapper">
                            <span class="mb-0 text-sm font-bold text-grey">Lot Tracking</span>
                            <div class="flex mt-1">
                                <label class="flex items-center cursor-pointer">
                                    <input type="checkbox" v-model="lotTracking" class="hidden peer">
                                    <div class="relative toggle-bg  border-2  h-6 w-11 rounded-full" :class="lotTracking?'bg-theme-green border-theme-green':'bg-theme-red border-theme-red'"></div>
                                </label>
                            </div>
                        </div>
                        <div class=" mt-2">
                            <div class="text-xs text-grey">Total Count</div>
                            <div class="text-sm text-center">{{asn.nou}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex flex-row flex-wrap items-center gap-2 mt-3 mb-3 justify-center" > 
                <div class="w-[125px]">
                    <div class="input-wrapper">
                        <button @click="toggle('editVariant', editItem)" :class="(editVariant && editVariant.id==editItem.id)?'bg-theme-green':'bg-theme-grey'" class="flex justify-center items-center py-3 w-full rounded border border-theme-green text-white text-sm font-bold tracking-wider disabled:border-grey disabled:bg-grey">{{editItem.size}}</button>
                    </div>
                </div>  
            </div>
            <div class="bg-theme-borderblack h-1 w-full my-4"></div>
            <div>
                <component :updatedForm="updatedForm" :updatedLotInfo="updatedLotInfo" :asn="asn" :fulfillment="fulfillment" :is="type" :categories="categories" :brands="brands" :taxes="taxes" :editItem="editItem" :editVariant="editVariant" :type="type" :lotTracking="lotTracking" :media="media" :aromas="aromas" :terpenes="terpenes" @updateLotTracking="updateLotTracking" @updateField="updateField" @updatedLotForm="updatedLotForm" />
            </div>
        </div>
        
    </div> 
</template>
<script>
import { ref, defineComponent } from 'vue'
import defaultVariant from './regular/defaultVariant.vue'
import editVariant from './regular/editVariant.vue'
import {mapGetters, mapActions, mapMutations} from 'vuex'
import { useSnackbar } from 'vue3-snackbar'


export default defineComponent({
    props: ['productLoading', 'asn', 'fulfillment', 'categories', 'brands', 'taxes', 'editItem', 'media', 'aromas', 'terpenes'],
    components: {
        defaultVariant,
        editVariant,
    },
    setup() {
        const snackbar = useSnackbar();
        const type = ref('defaultVariant')
        const isParentLotTrackingEnabled = ref(false)
        const lotTracking = ref(false)
        const lotCount = ref(null)
        const editVariant = ref({})
        const productCbdThcType = ref('')
        const updatedForm = ref({
            name: '',
            brandId: '',
            categoryId: '',
            featuredCategoryIds: [],
            strain: '',
            licensedProducer: '',
            tax: [],
            sku: '',
            complianceType: '',
            concentration_unit: '',
            description: '',
            
            variantName: '',
            dryCannabisEquivalency: '',
            thc_cbd_value_type: 'single',
            cost: '',
            price: '',
            sale_price: '',
            barcode: '',
            fulfillmentTime: '',

            thc: '',
            thc_min: '',
            thc_max: '',
            cbd: '',
            cbd_min: '',
            cbd_max: '',
            totalTerp: '',
            caseSize: '',
        })
        const updatedLotInfo = ref(null)

        return {snackbar, type, isParentLotTrackingEnabled, editVariant, lotTracking, lotCount, productCbdThcType, updatedForm, updatedLotInfo}
    },
    mounted(){
        this.type = 'defaultVariant'
        this.editVariant = {}
        this.vm_currentTab({})

        this.emitter.on('changeThcCbdType', this.changeThcCbdType);
        this.emitter.on("changeLotCount", this.changeLotCount)
    },
    unmounted(){
        this.emitter.off("changeThcCbdType", this.changeThcCbdType)
        this.emitter.off("changeLotCount", this.changeLotCount)
    },
    beforeDestroy() {
        this.emitter.off("changeThcCbdType", this.changeThcCbdType)
        this.emitter.off("changeLotCount", this.changeLotCount)
    },
    watch: {
        'editItem.id'(){
            this.type = 'defaultVariant'
            this.editVariant = {}
            this.updatedForm = {
                name: '',
                brandId: '',
                categoryId: '',
                featuredCategoryIds: [],
                strain: '',
                licensedProducer: '',
                tax: [],
                sku: '',
                complianceType: '',
                concentration_unit: '',
                description: '',
                
                variantName: '',
                dryCannabisEquivalency: '',
                thc_cbd_value_type: 'single',
                cost: '',
                price: '',
                sale_price: '',
                barcode: '',
                fulfillmentTime: '',

                thc: '',
                thc_min: '',
                thc_max: '',
                cbd: '',
                cbd_min: '',
                cbd_max: '',
                totalTerp: '',
                caseSize: '',
            }
        },
    },
    computed: {
        ...mapGetters({
            vg_selectedBusiness: 'auth/activeBusiness',
            vg_selectedLocation: 'location/selected',
            vg_modified: 'modifiedForm/status'
        })  
    },
    methods: {
        updateField(field, data){
            this.updatedForm[field] = data
        },
        updatedLotForm(data){
            this.updatedLotInfo = data
        },
        updateLotTracking(val){
            this.lotTracking = val
        },
        changeThcCbdType(val){
            this.productCbdThcType = val
        },
        changeLotCount(val){
            this.lotCount = val
        },
        toggle(type, variant){
            if((this.type=='newVariant' && type=='newVariant') || (this.type==type && variant.id ==this.editVariant.id)){
                this.editVariant = {}
                this.type = 'defaultVariant'
                this.vm_currentTab({})
            }else{
                this.editVariant = variant
                this.type = type
                this.vm_currentTab(variant)
            }
        },
        ...mapMutations({
            vm_currentTab: 'product/currentTab',
            vm_modified: 'modifiedForm/mutateStatus'
        }),
        ...mapActions({
            va_help: 'help/setHelp'
        })
    }
})
</script>

<template>
    <div class="w-full">
        <div class="relative flex flex-col" tabindex="0" @focusout="closeIfEmpty()">
            <div class="input-wrapper">
                <label class="text-xs">Search</label>
                <div class="relative">
                    <div class="absolute mt-3 ml-2">
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.9 18.9746L10.325 12.3996C9.825 12.8329 9.24167 13.1704 8.575 13.4121C7.90833 13.6538 7.2 13.7746 6.45 13.7746C4.65 13.7746 3.125 13.1496 1.875 11.8996C0.625 10.6496 0 9.14128 0 7.37461C0 5.60794 0.625 4.09961 1.875 2.84961C3.125 1.59961 4.64167 0.974609 6.425 0.974609C8.19167 0.974609 9.69583 1.59961 10.9375 2.84961C12.1792 4.09961 12.8 5.60794 12.8 7.37461C12.8 8.09128 12.6833 8.78294 12.45 9.44961C12.2167 10.1163 11.8667 10.7413 11.4 11.3246L18 17.8746L16.9 18.9746ZM6.425 12.2746C7.775 12.2746 8.925 11.7954 9.875 10.8371C10.825 9.87878 11.3 8.72461 11.3 7.37461C11.3 6.02461 10.825 4.87044 9.875 3.91211C8.925 2.95378 7.775 2.47461 6.425 2.47461C5.05833 2.47461 3.89583 2.95378 2.9375 3.91211C1.97917 4.87044 1.5 6.02461 1.5 7.37461C1.5 8.72461 1.97917 9.87878 2.9375 10.8371C3.89583 11.7954 5.05833 12.2746 6.425 12.2746Z" fill="#878686"/>
                        </svg>
                    </div>
                    <input v-model="search" :placeholder="placeholder" class="block tracking-wider border-none rounded bg-gray-black w-full h-10 text-sm placeholder-theme-878686 pl-8" type="text">
                </div>
            </div>
            <div v-if="open" class="flex flex-col absolute top-16 w-full max-h-96 overflow-auto">
                <div @click.prevent="select(result)" class="relative grid grid-cols-6 p-2 border-t border-light-black drop-shadow transition duration-500 bg-theme-modalbg hover:bg-theme-grey cursor-pointer" v-for="result in results" :key="'brd-'+result.id" :value="result.id">
                    <div class="col-span-5 inline-block">{{result.name}}</div>
                </div>
                <div v-if="results.length==0" class="relative grid grid-cols-6 p-2 border-t border-light-black drop-shadow transition duration-500 bg-theme-modalbg hover:bg-theme-grey cursor-pointer">
                    <div class="col-span-5 inline-block">No item found</div>
                </div>
            </div>
        </div>           
    </div>
</template>
<script>
import {defineComponent} from 'vue'
import debounce from 'lodash/debounce'
import ProductApi from '@/controllers/BusinessProductApis'
export default defineComponent({
    props: {
        placeholder: String
    },
    data(){
        return {
            search: '',
            selected: false,
            open: false,
            results: []
        }
    },
    watch: {
        search: debounce(function(){
            if(this.search && !this.selected){
                this.searchByGtin()
            }
        }, 400)
    },
    methods: {
        async searchByGtin(){
            this.open = false
            let res = await ProductApi.getMasterProductByGtin(this.search)
            if(res?.data?.payload?.products){
                this.results = res.data.payload.products
            }
            this.open = true
        },
        select(item){
            this.selected = true
            this.search = item.name
            this.$emit('select', item)
            this.close()
        },
        close(){
            this.open = false
        },
        closeIfEmpty(){
            if(this.search==''){
                this.close()
            }
        }
    }
})
</script>
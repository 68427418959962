<template>
    <div class="relative flex flex-col" tabindex="0" @blur="close" >
        <div class="flex flex-row justify-between h-10 py-2 px-3 cursor-pointer" @click="toggleOpen">
            <div class="flex flex-row gap-2">
                <slot name="placeholderIcon" />
                <div :class="(selected && selected.length>0)?'text-white':'text-grey'">{{(selected && selected.length>0)?(selected.length+' selected'):placeholder}}</div>
            </div>
            <div class="mt-2">
                <svg v-if="open" width="11" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.83008 0.624219L0.830078 6.62422L1.90508 7.69922L6.83008 2.74922L11.7551 7.67422L12.8301 6.59922L6.83008 0.624219Z" fill="#878686"/>
                </svg>
                <svg v-else width="11" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.83325 7.37578L0.833252 1.37578L1.90825 0.300781L6.83325 5.25078L11.7583 0.325781L12.8333 1.40078L6.83325 7.37578Z" fill="#878686"/>
                </svg>
            </div>
        </div>
        <div v-if="open" class="flex flex-col absolute top-[40px] w-[260px] max-h-[300px] overflow-auto z-[50]">
            <div @click="select(option.id)" class="relative grid grid-cols-6 p-2 border-t border-light-black drop-shadow transition duration-500 bg-theme-modalbg hover:bg-theme-grey cursor-pointer" v-for="option in options" :key="'brd-'+option.id" :value="option.id">
                <div class="mr-2 self-center">
                    <svg v-if="isAdded(option.id)" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.45 17.55C0.75 17.85 1.1 18 1.5 18H16.5C16.9 18 17.25 17.85 17.55 17.55C17.85 17.25 18 16.9 18 16.5V1.5C18 1.1 17.85 0.75 17.55 0.45C17.25 0.15 16.9 0 16.5 0H1.5C1.1 0 0.75 0.15 0.45 0.45C0.15 0.75 0 1.1 0 1.5V16.5C0 16.9 0.15 17.25 0.45 17.55ZM16.5 16.5H1.5V1.5H16.5V16.5Z" fill="#2BA66B"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 16.5H16.5V1.5H1.5V16.5ZM14.7 5.75L7.475 12.975L3.425 8.925L4.5 7.85L7.475 10.825L13.625 4.675L14.7 5.75Z" fill="#2BA66B"/>
                        <path d="M7.475 12.975L14.7 5.75L13.625 4.675L7.475 10.825L4.5 7.85L3.425 8.925L7.475 12.975Z" fill="white"/>
                    </svg>
                    <svg v-else width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.5 18C1.1 18 0.75 17.85 0.45 17.55C0.15 17.25 0 16.9 0 16.5V1.5C0 1.1 0.15 0.75 0.45 0.45C0.75 0.15 1.1 0 1.5 0H16.5C16.9 0 17.25 0.15 17.55 0.45C17.85 0.75 18 1.1 18 1.5V16.5C18 16.9 17.85 17.25 17.55 17.55C17.25 17.85 16.9 18 16.5 18H1.5ZM1.5 16.5H16.5V1.5H1.5V16.5Z" fill="white"/>
                    </svg>
                </div>
                <div class="col-span-5 break-words inline-block text-[16px]">{{option.name}}</div>
            </div>
            <div class="relative flex items-center px-[8px] py-[12px] gap-[8px] bg-[#565150]">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.00016 11.3333C8.18905 11.3333 8.34739 11.2694 8.47516 11.1416C8.60294 11.0138 8.66683 10.8555 8.66683 10.6666C8.66683 10.4777 8.60294 10.3194 8.47516 10.1916C8.34739 10.0638 8.18905 9.99992 8.00016 9.99992C7.81127 9.99992 7.65294 10.0638 7.52516 10.1916C7.39739 10.3194 7.3335 10.4777 7.3335 10.6666C7.3335 10.8555 7.39739 11.0138 7.52516 11.1416C7.65294 11.2694 7.81127 11.3333 8.00016 11.3333ZM8.00016 8.66659C8.18905 8.66659 8.34739 8.6027 8.47516 8.47492C8.60294 8.34714 8.66683 8.18881 8.66683 7.99992V5.33325C8.66683 5.14436 8.60294 4.98603 8.47516 4.85825C8.34739 4.73047 8.18905 4.66659 8.00016 4.66659C7.81127 4.66659 7.65294 4.73047 7.52516 4.85825C7.39739 4.98603 7.3335 5.14436 7.3335 5.33325V7.99992C7.3335 8.18881 7.39739 8.34714 7.52516 8.47492C7.65294 8.6027 7.81127 8.66659 8.00016 8.66659ZM8.00016 14.6666C7.07794 14.6666 6.21127 14.4916 5.40016 14.1416C4.58905 13.7916 3.8835 13.3166 3.2835 12.7166C2.6835 12.1166 2.2085 11.411 1.8585 10.5999C1.5085 9.78881 1.3335 8.92214 1.3335 7.99992C1.3335 7.0777 1.5085 6.21103 1.8585 5.39992C2.2085 4.58881 2.6835 3.88325 3.2835 3.28325C3.8835 2.68325 4.58905 2.20825 5.40016 1.85825C6.21127 1.50825 7.07794 1.33325 8.00016 1.33325C8.92239 1.33325 9.78905 1.50825 10.6002 1.85825C11.4113 2.20825 12.1168 2.68325 12.7168 3.28325C13.3168 3.88325 13.7918 4.58881 14.1418 5.39992C14.4918 6.21103 14.6668 7.0777 14.6668 7.99992C14.6668 8.92214 14.4918 9.78881 14.1418 10.5999C13.7918 11.411 13.3168 12.1166 12.7168 12.7166C12.1168 13.3166 11.4113 13.7916 10.6002 14.1416C9.78905 14.4916 8.92239 14.6666 8.00016 14.6666Z" fill="#FCB852"/>
                </svg>
                <div class="relative text-[#FCB852] text-[12px]">Has to select at least one option.</div>
            </div>
        </div>
    </div>
    </template>
    <script>
    import {defineComponent} from 'vue'
    export default defineComponent({
        props: {
            modelValue: String,
            placeholder:String,
            options: Array
        },
        data(){
            return {
                open: false,
                selected: []
            }
        },
        mounted(){
            if(this.modelValue && this.modelValue.length>0){
                this.selected = this.modelValue
            }else{
                this.selected = []
            }
        },
        watch: {
            modelValue(){
                if(this.modelValue && this.modelValue.length>0){
                    this.selected = this.modelValue
                }else{
                    this.selected = []
                }
            }
        },
        methods: {
            toggleOpen(){
                this.open = !this.open
            },
            close(){
                this.open = false
            },
            isAdded(id){
                if(this.selected){
                    return this.selected.includes(id)
                }
            },
            select(id){
                if(this.selected && this.selected.length>0){
                    const ind = this.selected.indexOf(id)
                    if(ind>-1){
                        // don't remove if selected is only one
                        if(this.selected.length>1){
                            this.$emit('changed', 'removed', id)
                            this.selected.splice(ind, 1)
                        }
                    }else{
                        this.$emit('changed', 'added', id)
                        this.selected.push(id)
                    }
                }else{
                    this.$emit('changed', 'added', id)
                    this.selected.push(id)
                }
                this.$emit('update:modelValue', this.selected)
            }
        }
    })
    </script>
<template>
  <div class="grid grid-cols-1">
      <div class="text-white flex-1 col-span-1 border-0 rounded-t-xl overflow-hidden">
          <div class="bg-theme-green px-4 py-2 text-white">
              <div class="flex justify-between flex-col">
                  <div class=" tracking-wider leading-tight mt-0 flex justify-between">
                      <h2 class="font-bold tracking-wider leading-tight text-2xl mt-0 pb-6">{{ form.posStationName }}</h2>
                      <span @click.prevent="clearComponent" class=" cursor-pointer rounded-md  text-white py-2 hover:opacity-70">CLOSE</span>
                  </div>
                  <div class="flex flex-row justify-between items-center">
                      <h6 class="text-sm font-normal tracking-wider mr-2">{{ form.shiftDate }}</h6>
                      <div class="flex-row flex justify-between items-center">
                          <router-link  
                            :class="'h-auto xl:h-10 text-sm mr-2 flex justify-center items-center border-0 rounded-md cursor-pointer py-2 px-4 uppercase font-semibold ' + (form.isOpen ? 'bg-theme-modalbg text-grey px-3 py-2 rounded uppercase' : 'hover:bg-theme-darkgreen  hover:text-white  text-green-400 bg-white')"
                            :to="{
                                name: 'SalesMain',
                                query: {
                                    posStationId: form.posStationId,
                                }
                            }">
                                View sales  
                            </router-link>

                            <JsonCSV
                                v-if="form.isOpen == false"
                                :name="'EOD_'+form.posStationName + '_' + form.shiftDate+'.csv'"
                                :data="csvData">
                                <button :class="'h-auto xl:h-10 text-sm border-0 rounded-md cursor-pointer py-2 px-4 uppercase font-semibold hover:bg-theme-darkgreen  hover:text-white  text-green-400 bg-white'">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.3335 21.6673C1.80016 21.6673 1.3335 21.4673 0.933496 21.0673C0.533496 20.6673 0.333496 20.2007 0.333496 19.6673V14.9007H2.3335V19.6673H19.6668V14.9007H21.6668V19.6673C21.6668 20.2007 21.4668 20.6673 21.0668 21.0673C20.6668 21.4673 20.2002 21.6673 19.6668 21.6673H2.3335ZM11.0002 16.5673L4.56683 10.134L6.00016 8.70065L10.0002 12.7007V0.333984H12.0002V12.7007L16.0002 8.70065L17.4335 10.134L11.0002 16.5673Z" :fill="form.isOpen ? '#878686' : '#2BA66B'"/>
                                    </svg>
                                </button>
                            </JsonCSV>
                      
                            <button v-else :class="'h-auto xl:h-10 text-sm border-0 rounded-md cursor-pointer py-2 px-4 uppercase font-semibold bg-theme-modalbg text-grey px-3 py-2 rounded uppercase'">
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.3335 21.6673C1.80016 21.6673 1.3335 21.4673 0.933496 21.0673C0.533496 20.6673 0.333496 20.2007 0.333496 19.6673V14.9007H2.3335V19.6673H19.6668V14.9007H21.6668V19.6673C21.6668 20.2007 21.4668 20.6673 21.0668 21.0673C20.6668 21.4673 20.2002 21.6673 19.6668 21.6673H2.3335ZM11.0002 16.5673L4.56683 10.134L6.00016 8.70065L10.0002 12.7007V0.333984H12.0002V12.7007L16.0002 8.70065L17.4335 10.134L11.0002 16.5673Z" :fill="form.isOpen ? '#878686' : '#2BA66B'"/>
                                </svg>
                            </button>
                      </div>
                  </div>
                </div>
          </div>
          <div class="pt-3 bg-[#2B2C32] px-[16px] py-[16px] rounded-lg">
              <div class="flex gap-3 mb-4 xl:flex-row flex-col items-center">
                  <div class="xl:w-1/3 w-full">
                      <div class="input-wrapper">
                          <label class="text-xs mb-1">Started By</label>
                          <input disabled :value="form.startedBy" placeholder="John Crenwich" class="block border-0 rounded bg-[#1C1D21] w-full text-sm" type="text">
                      </div>
                  </div>
                  <div class="xl:w-1/3 w-full">
                      <div class="input-wrapper">
                          <label class="text-xs mb-1">Start Time</label>
                          <input disabled :value="readAbleDate(form.openedAt)" placeholder="24/04/2022 @ 9:30 AM" class="block border-0 rounded bg-[#1C1D21] w-full text-sm" type="text">
                      </div>
                  </div>
                  <div class="xl:w-1/3 w-full">
                      <div class="input-wrapper relative">
                          <label class="text-xs mb-1">Starting Float</label>
                          <input disabled :value="form.startingFloat ? displayPriceFormat(form.startingFloat) : 0.00" class="border-0 placeholder-theme-878686 pl-3 block border-none rounded bg-[#1C1D21] w-full text-sm text-theme-white" type="text">
                      </div>
                  </div>
              </div>
              <div class="flex gap-3 mb-4 xl:flex-row flex-col items-center">
                  <div class="xl:w-1/3 w-full">
                      <div class="input-wrapper">
                          <label class="text-xs mb-1">Ended By</label>
                          <input disabled :value="form.closedBy" placeholder="Blaze Johnson" class="border-0 block rounded bg-[#1C1D21] w-full text-sm" type="text">
                      </div>
                  </div>
                  <div class="xl:w-1/3 w-full">
                      <div class="input-wrapper">
                          <label class="text-xs mb-1">End Time</label>
                          <input disabled :value="readAbleDate(form.closedAt)" placeholder="24/04/2022 @ 9:30 AM" class="block border-0 rounded bg-[#1C1D21] w-full text-sm" type="text">
                      </div>
                  </div>
                  <div class="xl:w-1/3 w-full">
                      <div class="input-wrapper relative">
                          <label class="text-xs mb-1">End of Day Cash Drop</label>
                          <input disabled :value="displayPriceFormat(form.endingCashDrop)" class="placeholder-theme-878686 pl-3 block border-0 rounded bg-[#1C1D21] w-full text-sm text-theme-white" type="text">
                      </div>
                  </div>
              </div>

              <div class="bg-[#464851] h-[1px] w-full my-4"></div>
              <div>
                  <h2 class="text-base font-bold text-white my-2">Cash</h2>
                  <div class="flex gap-3 mb-4 xl:flex-row flex-col items-center">
                      <div class="xl:w-1/3 w-full">
                          <div class="input-wrapper relative">
                              <label class="text-xs mb-1">Actual</label>
                              <input disabled :value="displayPriceFormat(form.endingCash)" class="placeholder-theme-878686 pl-3 block border-0 rounded bg-[#1C1D21] w-full text-sm text-theme-white" type="text">
                              
                          </div>
                      </div>
                      <div class="xl:w-1/3 w-full">
                          <div class="input-wrapper relative">
                              <label class="text-xs mb-1">Expected</label>
                              <input disabled :value="displayPriceFormat(form.cashExpected)" class="placeholder-theme-878686 pl-3 block border-0 rounded bg-[#1C1D21] w-full text-sm text-theme-white" type="text">
                          </div>
                      </div>
                      <div class="xl:w-1/3 w-full">
                          <div class="input-wrapper relative">
                              <label class="text-xs mb-1">Difference</label>
                              <input :value="displayPriceFormat(form.cashDifference)" disabled :class="'pl-3 block border-0 rounded bg-[#1C1D21] w-full text-sm ' + (  getdifferenceClass(form.cashDifference) )" type="text">
                          </div>
                      </div>
                  </div>
              </div>

              <div class="bg-[#464851] h-[1px] w-full my-4"></div>
              <div v-if="toggler">
                  <div class="flex justify-between">
                    <h2 class="text-base font-bold text-white my-2">Card Count Total</h2>
                    <div @click="toggler=!toggler" class="text-base text-theme-green my-2 cursor-pointer underline">Expand to Detail Card Count</div>
                  </div>
                  <div class="flex gap-3 mb-4 xl:flex-row flex-col items-center">
                      <div class="xl:w-1/3 w-full">
                          <div class="input-wrapper relative">
                              <label class="text-xs mb-1">Actual</label>
                              <input disabled :value="displayPriceFormat(form.endingCard)" class="placeholder-theme-878686 pl-3 block border-0 rounded bg-[#1C1D21] w-full text-sm text-theme-white" type="text">
                              
                          </div>
                      </div>
                      <div class="xl:w-1/3 w-full">
                          <div class="input-wrapper relative">
                              <label class="text-xs mb-1">Expected</label>
                              <input disabled :value="displayPriceFormat(form.cardExpected)" class="placeholder-theme-878686 pl-3 block border-0 rounded bg-[#1C1D21] w-full text-sm text-theme-white" type="text">
                          </div>
                      </div>
                      <div class="xl:w-1/3 w-full">
                          <div class="input-wrapper relative">
                              <label class="text-xs mb-1">Difference</label>
                              <input :value="displayPriceFormat(form.cardDifference)" disabled :class="'pl-3 block border-0 rounded bg-[#1C1D21] w-full text-sm ' + (  getdifferenceClass(form.cardDifference) )" type="text">
                          </div>
                      </div>
                  </div>
              </div>
              <div v-if="!toggler">
                  <div class="flex justify-between">
                    <h2 class="text-base font-bold text-white my-2">Online Card Total</h2>
                    <div @click="toggler=!toggler" class="text-base text-theme-green my-2 cursor-pointer underline">Collapse to Combined Card Count</div>
                  </div>
                  <div class="flex gap-3 mb-4 xl:flex-row flex-col items-center">
                      <div class="xl:w-1/3 w-full">
                          <div class="input-wrapper relative">
                              <label class="text-xs mb-1">Actual</label>
                              <input disabled :value="displayPriceFormat(form.onlineCardActual)" class="placeholder-theme-878686 pl-3 block border-0 rounded bg-[#1C1D21] w-full text-sm text-theme-white" type="text">
                              
                          </div>
                      </div>
                      <div class="xl:w-1/3 w-full">
                          <div class="input-wrapper relative">
                              <label class="text-xs mb-1">Expected</label>
                              <input disabled :value="displayPriceFormat(form.onlineCardExpected)" class="placeholder-theme-878686 pl-3 block border-0 rounded bg-[#1C1D21] w-full text-sm text-theme-white" type="text">
                          </div>
                      </div>
                      <div class="xl:w-1/3 w-full">
                          <div class="input-wrapper relative">
                              <label class="text-xs mb-1">Difference</label>
                              <input :value="displayPriceFormat(form.onlineCardDifference)" disabled :class="'pl-3 block border-0 rounded bg-[#1C1D21] w-full text-sm ' + (  getdifferenceClass(form.onlineCardDifference) )" type="text">
                          </div>
                      </div>
                  </div>
              </div>
              <div v-if="!toggler">
                  <h2 class="text-base font-bold text-white my-2">Terminal Card Total</h2>
                  <div class="flex gap-3 mb-4 xl:flex-row flex-col items-center">
                      <div class="xl:w-1/3 w-full">
                          <div class="input-wrapper relative">
                              <label class="text-xs mb-1">Actual</label>
                              <input disabled :value="displayPriceFormat(form.instoreCardActual)" class="placeholder-theme-878686 pl-3 block border-0 rounded bg-[#1C1D21] w-full text-sm text-theme-white" type="text">
                              
                          </div>
                      </div>
                      <div class="xl:w-1/3 w-full">
                          <div class="input-wrapper relative">
                              <label class="text-xs mb-1">Expected</label>
                              <input disabled :value="displayPriceFormat(form.instoreCardExpected)" class="placeholder-theme-878686 pl-3 block border-0 rounded bg-[#1C1D21] w-full text-sm text-theme-white" type="text">
                          </div>
                      </div>
                      <div class="xl:w-1/3 w-full">
                          <div class="input-wrapper relative">
                              <label class="text-xs mb-1">Difference</label>
                              <input :value="displayPriceFormat(form.instoreCardDifference)" disabled :class="'pl-3 block border-0 rounded bg-[#1C1D21] w-full text-sm ' + (  getdifferenceClass(form.instoreCardDifference) )" type="text">
                          </div>
                      </div>
                  </div>
              </div>
              <div class="w-full" v-if="Object.keys(item.cardPaymentByType).length>0">
                    <div class="input-wrapper relative">
                        <h2 class="text-base font-bold text-white my-2">Payment Type</h2>
                        <div class="grid grid-cols-3 gap-2">
                            <div v-for="(value,key) in item.cardPaymentByType" :key="key">
                                <div class="input-wrapper relative">
                                    <label class="text-xs mb-1 capitalize">{{ key === 'master' ? 'MC' : key && key && key.replace('_', ' ') }}</label>
                                    <input :value="displayPriceFormat(value)" disabled class="pl-3 block border-0 rounded bg-[#1C1D21] w-full text-sm" type="text">
                                </div>
                            </div>
                        </div>
                    </div>
              </div>

              <div>
                  <div class="bg-[#464851] h-[1px] w-full my-4"></div>
                  <h2 class="text-base font-bold text-white">Non-Integrated Card Count Total</h2>
                  <div class="flex gap-3 mb-4 xl:flex-row flex-col items-center">
                    <div class="xl:w-2/6 w-full">
                          <div class="input-wrapper relative">
                              <label class="text-xs mb-1">Actual</label>
                              <input disabled :value="displayPriceFormat(form.nonIntegratedActual)" class="placeholder-theme-878686 pl-3 block border-0 rounded bg-[#1C1D21] w-full text-sm text-theme-white" type="text">
                              
                          </div>
                      </div>
                      <div class="xl:w-2/6 w-full">
                          <div class="input-wrapper relative">
                              <label class="text-xs mb-1">Expected</label>
                              <input disabled :value="displayPriceFormat(form.nonIntegratedExpected)" class="placeholder-theme-878686 pl-3 block border-0 rounded bg-[#1C1D21] w-full text-sm text-theme-white" type="text">
                          </div>
                      </div>
                      <div class="xl:w-2/6 w-full">
                          <div class="input-wrapper relative">
                              <label class="text-xs mb-1">Difference</label>
                              <input :value="displayPriceFormat(form.nonIntegratedDifference)" disabled :class="'pl-3 block rounded border-0 bg-[#1C1D21] w-full text-sm ' + (  getdifferenceClass(form.nonIntegratedDifference) )" type="text">
                          </div>
                      </div>
                    </div>
                    <div class="w-full" v-if="Object.keys(item.inStoreNonIntegratedPaymentByType).length>0">
                        <div class="input-wrapper relative">
                            <h2 class="text-base font-bold text-white my-2">Payment Type</h2>
                            <div class="grid grid-cols-3 gap-2">
                                <div v-for="(value,key) in item.inStoreNonIntegratedPaymentByType" :key="key">
                                    <div class="input-wrapper relative">
                                        <label class="text-xs mb-1 capitalize">{{ key === 'master' ? 'MC' : key && key && key.replace('_', ' ') }}</label>
                                        <input :value="displayPriceFormat(value)" disabled class="pl-3 block border-0 rounded bg-[#1C1D21] w-full text-sm" type="text">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
              </div>

              <div class="bg-[#464851] h-[1px] w-full my-4"></div>
              <div class="mt-4">
                  <h2 class="text-base font-bold text-white">Totals</h2>
                  <div class="flex gap-3 mb-4 xl:flex-row flex-col items-center">
                      <div class="xl:w-1/3 w-full">
                          <div class="input-wrapper relative">
                              <label class="text-xs mb-1">Actual</label>
                              <input disabled :value="displayPriceFormat(totalActual(form))" class="placeholder-theme-878686 pl-3 block border-none rounded bg-[#1C1D21] w-full text-sm text-theme-white" type="text">
                          </div>
                      </div>
                      <div class="xl:w-1/3 w-full">
                          <div class="input-wrapper relative">
                              <label class="text-xs mb-1">Expected</label>
                              <input disabled :value="displayPriceFormat(totalExpected(form))" class="placeholder-theme-878686 pl-3 block border-none rounded bg-[#1C1D21] w-full text-sm text-theme-white" type="text">
                          </div>
                      </div>
                      <div class="xl:w-1/3 w-full">
                          <div class="input-wrapper relative">
                              <label class="text-xs mb-1">Difference</label>
                              <input disabled :value="displayPriceFormat(totalDifference(form))" class="placeholder-theme-878686 pl-3 block border-none rounded bg-[#1C1D21] w-full text-sm text-theme-white" type="text">
                          </div>
                      </div>
                  </div>
                  <div class="grid gap-3 mb-4 xl:grid-cols-3  items-center">
                    <div class=" w-full">
                          <div class="input-wrapper relative">
                              <label class="text-xs mb-1">Tips</label>
                              <input disabled :value="form.totalTip?displayPriceFormat(form.totalTip):0" class="placeholder-theme-878686 pl-3 block border-none rounded bg-[#1C1D21] w-full text-sm text-theme-white" type="text">
                          </div>
                      </div>
                    </div>
              </div>

              <div class="bg-[#464851] h-[1px] w-full my-4"></div>
              <div class="mt-4">
                  <div class="flex gap-3 mb-4 xl:flex-row flex-col items-center">
                      <div class="xl:w-1/3 w-full">
                          <div class="input-wrapper relative">
                              <label class="text-xs mb-1">Tomorrow’s Float</label>
                              <input disabled :value="displayPriceFormat(form.tomorrowFloat)" class="placeholder-theme-878686 pl-3 block border-none rounded bg-[#1C1D21] w-full text-sm text-theme-white" type="text">
                          </div>
                      </div>
                      <div class="xl:w-1/3 w-full">
                          <div class="input-wrapper relative">
                              <label class="text-xs mb-1">End of Day Cash Drop</label>
                              <input disabled :value="displayPriceFormat(form.endingCashDrop)" class="placeholder-theme-878686 pl-3 block border-none rounded bg-[#1C1D21] w-full text-sm text-theme-white" type="text">
                          </div>
                      </div>
                      <div class="xl:w-1/3 w-full">
                          <div class="input-wrapper relative">
                              <label class="text-xs mb-1">Total Cash Drop</label>
                              <input disabled :value="displayPriceFormat(form.cashToDeposit)" class="placeholder-theme-878686 pl-3 block border-none rounded bg-[#1C1D21] w-full text-sm text-theme-white" type="text">
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
    
</template>


<script>
import { defineComponent, ref} from "vue"
import {mapGetters,mapActions,mapMutations} from "vuex";
import { useSnackbar} from "vue3-snackbar";
import Helpers from '@/helpers';
import moment from 'moment-timezone'
//import {  utils, writeFileXLSX } from 'xlsx';
import JsonCSV from 'vue-json-csv'


export default defineComponent({
  name: "EodUpdate",
  components: {
    JsonCSV,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    clearComponent: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const snackbar = useSnackbar();
    const form = ref({});
    const loading = ref(false);
    const csvData = ref([]);
    const toggler = ref(true)
    return {
      snackbar,
      form,
      loading,
      csvData,
      toggler
    };
  },

  watch:{
    'item.id'() {
        if(this.loading == false) { this.assignValues(); };
    },
  },

  computed: {
   
    ...mapGetters({
      vg_activeBusiness: 'auth/activeBusiness',
      vg_allLocations: 'location/list',
      vg_selectedLocation: 'location/selected'
    }),
  },
  async mounted() {
   this.assignValues();
  },
  methods: {
 
    getPaymentType(type){
        if(type){
            if(type.amex>0){
                return 'amex'
            }else if(type.debit>0){
                return 'debit'
            }else if(type.master>0){
                return 'master'
            }else if(type.visa>0){
                return 'visa'
            }else if(type.other>0){
                return 'other'
            }else{
                return ''
            }
        }else{
            return ''
        }
    },
    /**
     * Create xlsheet for records
     */
    // exportAsXlsheet() {
    //     const ws = utils.json_to_sheet([{
    //         Name: 'aa',
    //             Index: '1',
    //         },{
    //             Name: 'bb',
    //             Index: '2',
    //         }
    //     ]);
    //     const wb = utils.book_new();
    //     utils.book_append_sheet(wb, ws, "Data");
    //     writeFileXLSX(wb, "test.xlsx");
    // },

    totalActual(item) {
        let total = this.getAllSumOf(item.nonIntegratedActual, item.endingCash, item.endingCard);
        return total? total : 0
    },
    totalExpected(item) {
        let total = this.getAllSumOf(item.nonIntegratedExpected, item.cardExpected, item.cashExpected);
        return total? total : 0
    },
    totalDifference(item){
        let actual = this.getAllSumOf(item.nonIntegratedActual, item.endingCash, item.endingCard);
        let expected = this.getAllSumOf(item.nonIntegratedExpected, item.cardExpected, item.cashExpected);
        return actual-expected
    },
    
    /**
     * Assign values to form 
     */
    async assignValues() {
      this.form = this.item;
      let csvData2 = {}
          let csvData4 = {}
          let csvData1 = {
            "Started By": this.form.startedBy,
            "Start Time": this.readAbleDate(this.form.openedAt),
            "Starting Float": this.form.startingFloat ? this.displayPriceFormat(this.form.startingFloat) : 0.00,
            "Ended By": this.form.closedBy,
            "End Time": this.readAbleDate(this.form.closedAt),
            "End of Day Cash Drop": this.displayPriceFormat(this.form.endingCashDrop),
            "Cash (Actual)": this.displayPriceFormat(this.form.endingCash),
            "Cash (Expected)": this.displayPriceFormat(this.form.cashExpected),
            "Cash (Difference)": this.displayPriceFormat(this.form.cashDifference),
            "Card Count Total (Actual)": this.displayPriceFormat(this.form.endingCard),
            "Card Count Total (Expected)": this.displayPriceFormat(this.form.cardExpected),
            "Card Count Total (Difference)": this.displayPriceFormat(this.form.cardDifference),
            "Online Card Total (Actual)": this.displayPriceFormat(this.form.onlineCardActual),
            "Online Card Total (Expected)": this.displayPriceFormat(this.form.onlineCardExpected),
            "Online Card Total (Difference)": this.displayPriceFormat(this.form.onlineCardDifference),
            "Terminal Card Total (Actual)": this.displayPriceFormat(this.form.instoreCardActual),
            "Terminal Card Total (Expected)": this.displayPriceFormat(this.form.instoreCardExpected),
            "Terminal Card Total (Difference)": this.displayPriceFormat(this.form.instoreCardDifference),
          }
          if(Object.keys(this.form.cardPaymentByType).length>0){
              for(const key in this.form.cardPaymentByType){
                  let keyWord = key.replace('_', ' ')
                  let value = this.form.cardPaymentByType[key]
                  csvData2[`Payment Type (${keyWord})`] =  (value?this.displayPriceFormat(value):'0.00')
              }
          }

          let csvData3 = {
            "Non Integrated Card (Actual)": this.displayPriceFormat(this.form.nonIntegratedActual),
            "Non Integrated Card (Expected)": this.displayPriceFormat(this.form.nonIntegratedExpected),
            "Non Integrated Card (Difference)": this.displayPriceFormat(this.form.nonIntegratedDifference)
          }

          if(Object.keys(this.form.inStoreNonIntegratedPaymentByType).length>0){
              for(const key in this.form.inStoreNonIntegratedPaymentByType){ 
                  let keyWord = key.replace('_', ' ')
                  let value = this.form.inStoreNonIntegratedPaymentByType[key]
                  csvData4[`Non Integrated Card (${keyWord})`] =  (value?this.displayPriceFormat(value):'0.00')
              }
          }
          
          let csvData5 = {
              "Total (Actual)": this.displayPriceFormat(this.totalActual(this.form)),
              "Total (Expected)": this.displayPriceFormat(this.totalExpected(this.form)),
              "Total (Difference)": this.displayPriceFormat(this.totalDifference(this.form)),
              "Tips": this.form.totalTip ? this.displayPriceFormat(this.form.totalTip) : 0,
              "Tomorrow’s Float": this.displayPriceFormat(this.form.tomorrowFloat),
              "Total Cash Drop": this.displayPriceFormat(this.form.cashToDeposit),
          };
        this.csvData = [{...csvData1, ...csvData2, ...csvData3, ...csvData4, ...csvData5}]
    },

    /**
     * Validation captures
    */
    async validateFields(fields) {
      let res = await Helpers.validateFormFields(this.form, fields);
      // capture the fields
      this.form = res.data;
      return res.error ? false : true;
    },

    async update() {
      
    },

    /**
     * 
     * Get difference value
     */

    getdifferenceClass(val) {
        if(val == 0 || val == null) {
            return 'placeholder-theme-878686 border-theme-878686 text-white';
        } else if(val > 0) {
            return 'placeholder-theme-green border-theme-green text-theme-green border-1';
        } else {
            return 'placeholder-theme-red border-theme-red text-theme-red border-1';
        }
    },
    /**
     * 
     * @param val1 Get sum value
     * @param val2 
     */
    getSumOf(val1, val2) {
        val1 = val1 ? val1 : 0;
        val2 = val2 ? val2 : 0
        return val1+val2;
    },
    getAllSumOf(val1, val2, val3) {
        val1 = val1 ? val1 : 0;
        val2 = val2 ? val2 : 0
        val3 = val3 ? val3 : 0
        return val1+val2+val3;
    },

    /**
     * 
     * @param str make date readable
     */
    readAbleDate(str) {
      let defaultTimeZone = this.vg_selectedLocation.timezone?this.vg_selectedLocation.timezone:'America/Toronto';
      return str ? moment(str).tz(defaultTimeZone).format('YYYY-MM-DD hh:mmA'):'';
    },

    readAbleDateOnly(str){
      let defaultTimeZone = this.vg_selectedLocation.timezone?this.vg_selectedLocation.timezone:'America/Toronto';
      return str ? moment(str).tz(defaultTimeZone).format('YYYY-MM-DD') : '';
    },
    
    /**
     * 
     * @param price Manage price display format
     */
    displayPriceFormat(price) {
        if(price > 0) {
            return '$' + parseFloat(price).toFixed(2);
        } else if(price<0){
            return '- $' + Math.abs(parseFloat(price)).toFixed(2);
        }else{
          return '0.00';
        }
    },

    ...mapActions({
      va_spinner: 'spinner/setSpinner',
    })
  },
});
</script>

<template>
    <div>
      <div class="relative mt-4">
        <new-table-lite
          class="admin-table summary"
          :is-loading="table.isLoading"
          :is-static-mode="true"
          :is-slot-mode='true'
          :columns="columns"
          :rows="table.rows"
          :total="table.totalRecordCount"
          :sortable="table.sortable"
          @is-finished="table.isLoading = false"
          :page-size='table.pagesize'
          :pageOptions='table.pageOptions'
          :has-checkbox="false"
          :is-hide-paging="false"
          :pageSizeChangeLabel="'pageSizeChangeLabel'"
          :messages="{
            pagingInfo: 'Total: {2}',
            pageSizeChangeLabel: 'Items per Page',
            gotoPageLabel: '',
            noDataAvailable: 'No records found!',
          }"
        >
        <template v-slot:status="data">
            {{ data.value.status==='inprogress' ? 'In Progress' : data.value.status }}
        </template>
        <template v-slot:locationName="data">
            {{ data.value.location?.id ? data.value.location?.name : '' }}
        </template>
        <template v-slot:fullyReceived="data">
            {{ data.value.status==='completed'?'yes':'no' }}
        </template>
        <template v-slot:expectedTotal="data">
            {{ data.value.expectedTotal?`$${ data.value.expectedTotal.toFixed(2) }`:'' }}
        </template>
        <template v-slot:receivedTotal="data">
          {{ data.value.receivedTotal?`$${ data.value.receivedTotal.toFixed(2) }`:'' }}
        </template>
        </new-table-lite>
      </div>
    </div>
  </template>
  
  <script>
  import { defineComponent , ref} from "vue";
  import Env from '@/env';
  import moment from 'moment'
  import { mapGetters } from 'vuex'
  import NewTableLite from '@/components/table/tableLite.vue'
  
  export default defineComponent({
    components: {
      NewTableLite
    },
    props: {
      filter: {
        type: Object,
        required: false
      },
      payload: {
        type: Array,
        required: false
      },
      loaded: {
        type: Boolean,
        required: false
      }
    },
    setup() {
      const columns = ref([
        {
          label: "PO #",
          field: "shipmentId",
          sortable: false,
       },
       {
          label: "Status",
          field: "status",
          sortable: false,
       },
       {
          label: "Upload Date",
          field: "date",
          sortable: false,
        },
        // {
        //   label: "Supplier",
        //   field: "supplier",
        //   sortable: false,
        // },
        {
          isKey:true,
          label: "Location",
          field: "locationName",
          sortable: false,
          headerClasses:'width'
        },
        {
          label: "# of Products Ordered",
          field: "sumOfProductsQty",
          sortable: false,
          headerClasses:'width'
        },
        {
          label: "# of Products Received",
          field: "sumOfProductsQty",
          sortable: false,
        },
        {
          label: "Expected Cost",
          field: "expectedTotal",
          sortable: false,
  
        },
        {
          label: "Received Cost",
          field: "receivedTotal",
          sortable: false,
        },
        {
          label: "Fully Received",
          field: "fullyReceived",
          sortable: false,
        },
        {
          label: "Created By",
          field: "createdByName",
          sortable: false,
        },
      ]);
  
  
      const table = ref({
        isLoading: false,
        rows: [],
        totalRecordCount: 0,
        sortable: {
          order: "name",
          sort: "asc",
        },
        pagesize: Env.defaultPageSize,
        pageOptions: Env.pageList,
        count: 0,
        page: 1,
        
      });
      
      return  {
        table,
        columns
      };
    },
  
    async mounted() {
       this.load()
       this.emitter.on('reloadSalesList', this.reload)
    },
    beforeDestroy() {
     this.emitter.off('reloadSalesList', this.reload)
    },
    computed: {
          ...mapGetters({
              vg_selectedLocation: 'location/selected'
          })
    },
    watch: {
      loaded(){
        this.load()
      }
    },
    methods: {
      isCurrentDate(date){
        if(!date) return
        return moment(moment().format('YYYY-MM-DD')).isSame(date)
      },
      formatDate(date){
        if(!date) return ''
        return moment(date).format('YYYY-MM-DD hh:mm:ss A')
      },

      reload(){
        this.$nextTick(() => {
            this.load()
        })
      },
      async load(){
        this.table.isLoading = true
        this.table.rows = this.payload
        this.table.totalRecordCount = this.payload.length
        this.table.isLoading = false
      },
    }
  
  });
  </script>
  
  
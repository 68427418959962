<template>
  <div
    class="bg-[#23242A] text-white app-header custom"
    id="content_screen"
  >
    <div class="flex content-main gap-5">
      <div class="custom-full">
        <div class="bg-[#2B2C32] rounded-lg max-w-full py-[16px]">
          <div class="flex justify-between px-[16px]" :class="split?'flex-col gap-5 h-[80px] mb-6':'flex-row h-[40px]'">
            <div class="flex flex-row items-center gap-2">
              <svg
                @click="back"
                class="cursor-pointer"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z"
                  fill="#FF683C"
                />
              </svg>
              <div class="flex flex-col">
                <h2 class="text-[20px] font-bold tracking-wider text-white">
                  View Purchase Order
                </h2>
                <div class="flex flex-row items-center gap-[24px] mt-1">
                  <span class="text-[16px]">
                    Invoice # {{ payload.shipmentId }}
                  </span>
                  <span class="bg-theme-green bg-opacity-[30%] px-[4px] py-[2px] rounded">Status: Completed</span>
                </div>
              </div>
            </div>
            <div class="flex flex-row items-center gap-[24px]">
              <button @click="exportFile"
                class="flex cursor-pointer text-sm font-medium tracking-widest rounded text-white border border-theme-green py-2 px-3 disabled:bg-opacity-50 uppercase"
              >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19 9.5H15V3.5H9V9.5H5L12 16.5L19 9.5ZM11 11.5V5.5H13V11.5H14.17L12 13.67L9.83 11.5H11ZM19 20.5V18.5H5V20.5H19Z" fill="white"/>
              </svg>
                Export
              </button>
            </div>
          </div>

        </div>
        <div class="bg-[#2B2C32] rounded-lg max-w-full py-[16px] mt-[16px]">
          <div class="flex justify-between px-[16px] h-[40px]">
            <div class="flex flex-row items-center gap-2">
              <h2 class="text-xl font-bold tracking-wider text-white">
                Details
              </h2>
            </div>
          </div>

          <div class="grid mx-4 gap-4 p-[8px]" :class="split?'grid-cols-2':'grid-cols-4'">
            <div class="w-full input-wrapper">
              <label class="text-xs mb-1">Shipment ID *</label>
              <input
                disabled
                :value="payload.shipmentId"
                placeholder="Purchase Order # - 00998888"
                class="block border-0 rounded bg-theme-grey w-full text-sm"
                type="text"
              />
            </div>
            <div class="w-full input-wrapper">
              <label class="text-xs mb-1">Date</label>
              <input
                disabled
                :value="payload.date"
                placeholder="2023-03-24"
                class="block border-0 rounded bg-theme-grey w-full text-sm"
                type="text"
              />
            </div>
            <div class="w-full input-wrapper">
              <label class="text-xs mb-1">Location *</label>
              <input
                disabled
                :value="payload?.location?.name"
                placeholder="Tendy POS"
                class="block border-0 rounded bg-theme-grey w-full text-sm"
                type="text"
              />
            </div>
            <div class="w-full input-wrapper">
              <label class="text-xs mb-1">Employee</label>
              <input
                disabled
                v-model="payload.createdByName"
                placeholder="John Crenwich"
                class="block border-0 rounded bg-theme-grey w-full text-sm"
                type="text"
              />
            </div>
            <div class="w-full input-wrapper">
              <label class="text-xs mb-1">Shipping Cost</label>
              <input
                disabled
                :value="payload.shippingCost?'$'+payload.shippingCost:''"
                class="block border-0 rounded bg-theme-grey w-full text-sm"
                type="text"
              />
            </div>
            <div class="w-full input-wrapper">
              <label class="text-xs mb-1">Tax Cost</label>
              <input
                disabled
                :value="payload.tax?'$'+payload.tax:''"
                class="block border-0 rounded bg-theme-grey w-full text-sm"
                type="text"
              />
            </div>
            <div class="w-full input-wrapper">
              <label class="text-xs mb-1">Total Cost (inclusive of shipping and taxes)</label>
              <input
                disabled
                :value="payload.total?'$'+payload.total.toFixed(2):''"
                class="block border-0 rounded bg-theme-grey w-full text-sm"
                type="text"
              />
            </div>
            <div class="w-full input-wrapper">
              <label class="text-xs mb-1">Completed by</label>
              <input
                disabled
                v-model="payload.completedByName"
                class="block border-0 rounded bg-theme-grey w-full text-sm"
                type="text"
              />
            </div>
            <div class="w-full input-wrapper">
              <label class="text-xs mb-1">Fulfillment Time</label>
              <input
                disabled
                :value="payload.fullfillmentTime?payload.fullfillmentTime+' days':''"
                class="block border-0 rounded bg-theme-grey w-full text-sm"
                type="text"
              />
              <div class="flex items-start gap-[8px]">
                <div class="text-[12px] pt-1">Fulfilment time is used for your re-order report when calculating how long it will take items to restock</div>
                <div class="w-[16px] h-[16px] pt-1">
                  <svg  width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00016 1.33301C4.32016 1.33301 1.3335 4.31967 1.3335 7.99967C1.3335 11.6797 4.32016 14.6663 8.00016 14.6663C11.6802 14.6663 14.6668 11.6797 14.6668 7.99967C14.6668 4.31967 11.6802 1.33301 8.00016 1.33301ZM7.3335 4.66634V5.99967H8.66683V4.66634H7.3335ZM7.3335 7.33301V11.333H8.66683V7.33301H7.3335ZM2.66683 7.99967C2.66683 10.9397 5.06016 13.333 8.00016 13.333C10.9402 13.333 13.3335 10.9397 13.3335 7.99967C13.3335 5.05967 10.9402 2.66634 8.00016 2.66634C5.06016 2.66634 2.66683 5.05967 2.66683 7.99967Z" fill="white"/>
                  </svg>
              </div>
              </div>
            </div>
          </div>
        </div>

        <div class="bg-[#2B2C32] mt-5 pb-5 rounded-lg max-w-full">
            <div class="w-full ">
              <div class="font-[700] text-[20px] p-[16px]">Product List</div>

              <div class="flex gap-[15px] pr-5 pl-2">
                <div class="relative w-[204px]">
                    <div class="absolute ml-2 mt-3">
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.9 18.9746L10.325 12.3996C9.825 12.8329 9.24167 13.1704 8.575 13.4121C7.90833 13.6538 7.2 13.7746 6.45 13.7746C4.65 13.7746 3.125 13.1496 1.875 11.8996C0.625 10.6496 0 9.14128 0 7.37461C0 5.60794 0.625 4.09961 1.875 2.84961C3.125 1.59961 4.64167 0.974609 6.425 0.974609C8.19167 0.974609 9.69583 1.59961 10.9375 2.84961C12.1792 4.09961 12.8 5.60794 12.8 7.37461C12.8 8.09128 12.6833 8.78294 12.45 9.44961C12.2167 10.1163 11.8667 10.7413 11.4 11.3246L18 17.8746L16.9 18.9746ZM6.425 12.2746C7.775 12.2746 8.925 11.7954 9.875 10.8371C10.825 9.87878 11.3 8.72461 11.3 7.37461C11.3 6.02461 10.825 4.87044 9.875 3.91211C8.925 2.95378 7.775 2.47461 6.425 2.47461C5.05833 2.47461 3.89583 2.95378 2.9375 3.91211C1.97917 4.87044 1.5 6.02461 1.5 7.37461C1.5 8.72461 1.97917 9.87878 2.9375 10.8371C3.89583 11.7954 5.05833 12.2746 6.425 12.2746Z" fill="#878686"/>
                        </svg>
                    </div>
                    <input v-model="form.name" placeholder="Search" class="block pl-8 tracking-wider border-none rounded bg-gray-black h-10 text-sm placeholder-theme-878686" type="text">
                </div>
                <select v-model="form.categoryId" class="relative w-[204px] pt-1.5 h-10 rounded bg-gray-black focus:border-0 focus:ring-0 border-0 text-sm" :class="form.categoryId?'text-white':'text-theme-878686'">
                    <option value="">Categories</option>
                    <template v-for="category in categories" :key="'category-'+category.id">
                        <option v-if="!category.featured" :value="category.id">
                            {{category.parent?'&nbsp;&nbsp;&nbsp;':''}}{{category.name}}
                        </option>
                    </template>
                </select>
                <SelectSearchBrand v-model="form.brandId" :options="brands" placeholder="Brand" class="relative w-[204px] block rounded bg-gray-black border-none text-sm z-50" />
                <button @click="clearAll" class="text-theme-green px-2 text-[14px]">Clear All</button>
              </div>
            </div>
            
            <new-table-lite
                :is-static-mode="true"
                :is-slot-mode="true"
                :is-loading="table.loading"
                :class="'block w-full admin-purchase-order'"
                :rowClasses="row => 'id-'+row.id"
                :columns="table.columns"
                :rows="table.rows"
                :page="table.page"
                :total="table.totalRecords"
                :sortable="table.sortable"
                :page-size="table.pagesize"
                @do-search="changePage"
                :pageOptions='table.pageOptions'
                :messages="{
                    pagingInfo: 'Total: {2}',
                    pageSizeChangeLabel: 'Items per Page',
                    gotoPageLabel: 'Go to page',
                    noDataAvailable: 'No records found!',
                }"
            >
            <template v-slot:name="data">
              <div class="flex justify-start items-start">
                <div class=" w-[20px] h-[20px] rounded m-[5px]" :style="`background: ${data.value.product?.category?.posColour}`"></div>
                <div class="flex flex-col gap-[5px]">
                  <div>{{ data.value?.name }}</div>
                  <div v-if="data.value?.batchLot">{{ data.value?.batchLot }}</div>
                </div>
              </div>
            </template>
            <template v-slot:category="data">
              <div v-if="data.value?.product?.category?.name">{{ data.value?.product?.category?.name }}</div>
              <div v-else></div>
            </template>
            <template v-slot:weight="data">
              <div v-if="data.value?.product?.variantName">{{ data.value?.product?.variantName }}</div>
              <div v-else-if="data.value?.masterProduct?.size">{{ data.value?.masterProduct?.size }}</div>
              <div v-else>{{ data.value.name }}</div>
            </template>
            <template v-slot:caseSize="data">
              <div v-if="data.value?.product?.caseSize">{{ data.value?.product?.caseSize }}</div>
              <div v-else></div>
            </template>
            </new-table-lite>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref} from 'vue'
import Env from '@/env';
import ProductApi from '@/controllers/BusinessProductApis'
import NewTableLite from '@/components/table/tableLite.vue'
import SelectSearchBrand from '@/components/form/SelectSearchBrand'
import writeXlsxFile from 'write-excel-file';
import { useSnackbar } from "vue3-snackbar";
import { mapActions} from "vuex";
import LocationComplianceApis from '@/controllers/LocationComplianceApis';

export default defineComponent({
  props: ['split'],
  components: {
    NewTableLite,
    SelectSearchBrand
  },
  setup() {
    const snackbar = useSnackbar();
    const loading = false
    const table = ref({
        loading: false,
        columns: [],
        rows: [],
        totalRecords: 0,
        page: 1,
        pagesize: Env.defaultPageSize,
        sortable: {
            order: "productName",
            sort: "asc",
        },
        count: 0,
        clearChecked: false,
    })
    const form = ref({
      name: '',
      brandId: '',
      categoryId: '',
    })
    const mainColumn = [
      {
        label: "Name",
        field: "name",
        sortable: true,
      },
      {
        label: "Category",
        field: "category",
        sortable: true,
      },
      {
        label: "Weight",
        field: "weight",
        sortable: true,
      },
      {
        label: "Brand",
        field: "brand",
        sortable: true,
      },
      {
        label: "THC",
        field: "thc",
        sortable: false,
      },
      {
        label: "CBD",
        field: "cbd",
        sortable: false,
      },
      {
        label: "Terps",
        field: "totalTerp",
        sortable: false,
      },
      {
        label: "Unit",
        field: "concentrationUnit",
        sortable: false,
      },
      {
        label: "Case",
        field: "caseSize",
        sortable: true,
      },
      {
        label: "Qty",
        field: "nou",
        sortable: true,
      }
    ]
    const categories = ref([])
    const brands = ref([])
    const payload = ref({})
    const items = ref([])
    const aglcOcs = ref('')

    return {snackbar, loading, table, form, categories, brands, payload, items, mainColumn, aglcOcs}
  },
  async mounted(){
    this.va_spinner(true)
    await this.getComplianceProvider()
    await Promise.all([this.fetchList(), this.getBrand(), this.getCategory()])
    this.filterItems()
    this.va_spinner(false)
  },
  watch: {
    form:{
      handler(){
        this.filterItems()
      },
      deep: true
    }
  },
  methods: {
    changePage(offset, limit){
        this.table.page = (offset/limit)+1
    },
    async fetchList(){
      if(this.$route?.params?.id){
        let res = await ProductApi.getPurchaseOrderById(this.$route.params.id)
        if(res.status == 200){
          this.payload = res.data.payload
          this.table.columns = this.mainColumn
          this.items = res.data.payload.items
        }
      }
    },
    async getCategory(){
      let res = await ProductApi.fetchAllCategory()
      if(res.data?.payload?.category){
        for(let i=0; i<res.data.payload.category.length; i++){
          this.categories.push(res.data.payload.category[i])
          if(res.data.payload.category[i].subCategories && res.data.payload.category[i].subCategories.length>0){
              for(let j=0; j<res.data.payload.category[i].subCategories.length; j++){
                  this.categories.push(res.data.payload.category[i].subCategories[j])
              }
          }
        }
      }
    },
    async getBrand(){
        let res = await ProductApi.fetchAllBrands()
        if(res.data?.payload?.brands){
            this.brands = res.data.payload.brands
        }
    },
    filterItems(){
      this.table.loading = true
      let items = []
      if(this.form.name && this.form.brandId && this.form.categoryId){
        items = this.items.filter(each => each.name.toLowerCase().includes(this.form.name.toLowerCase()) && each.brandModel?.id==this.form.brandId && each.product?.category?.id==this.form.categoryId)
      }else if(this.form.name && this.form.brandId){
        items = this.items.filter(each => each.name.toLowerCase().includes(this.form.name.toLowerCase()) && each.brandModel?.id==this.form.brandId)
      }else if(this.form.name && this.form.categoryId){
        items = this.items.filter(each => each.name.toLowerCase().includes(this.form.name.toLowerCase()) && each.product?.category?.id==this.form.categoryId)
      }else if(this.form.brandId && this.form.categoryId){
        items = this.items.filter(each => each.brandModel?.id==this.form.brandId && each.product?.category?.id==this.form.categoryId)
      }else if(this.form.name){
        items = this.items.filter(each => each.name.toLowerCase().includes(this.form.name.toLowerCase()))
      }else if(this.form.brandId){
        items = this.items.filter(each => each.brandModel?.id==this.form.brandId)
      }else if(this.form.categoryId){
        console.log(this.form.categoryId)
        console.log(this.items)
        items = this.items.filter(each => each.product?.category?.id==this.form.categoryId)
      }else{
        items = this.items
      }
      this.table.rows = items
      this.table.totalRecords = items.length
      this.table.loading = false
      this.table.page = 1
    },
    clearAll(){
      this.form.name = ''
      this.form.brandId = ''
      this.form.categoryId = ''
    },
    back(){
      this.$router.push({ name: 'PurchaseOrdersV2' })
    },
    async getComplianceProvider(){
        let res = await LocationComplianceApis.fetch();
        if(res?.data?.payload?.providerName){
            this.aglcOcs = res.data.payload.providerName
        }else{
            this.aglcOcs = 'ocs'
        }
    },
    async exportFile() {
      try {
        let aglcSchema = [
          // {
          //   column: 'Order Number',
          //   type: String,
          //   wrap: true,
          //   alignVertical: 'center',
          //   align: 'center',
          //   value: (i) => i.ShipmentID?i.ShipmentID:'', 
          // },
          {
            column: 'SKU',
            type: String,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: (i) => {
              if(i.sku) {
                return i.sku;
              }else{
                ''
              }
            }, 
          },
          {
            column: 'Shipped Each Qty',
            type: Number,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: (i) => {
              if(i.nou) {
                return i.nou;
              }else{
                ''
              }
            }, 
          },
          {
            column: 'Case GTIN',
            type: String,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: (i) => {
              if(i.casegtin) {
                return i.casegtin;
              }else{
                ''
              }
            }, 
          },
          {
            column: 'Each GTIN',
            type: String,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: (i) => {
              if(i.productGtin) {
                return i.productGtin;
              }else{
                ''
              }
            }, 
          },
          {
            column: 'Lot Code',
            type: String,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: (i) => {
              if(i.batchLot) {
                return i.batchLot;
              }else{
                ''
              }
            }, 
          },
          {
            column: 'Price Per Unit',
            type: Number,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => i.cost ? i.cost :''
          },
          {
            column: 'SKU Description',
            type: String,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => i.description ? i.description :''
          },
          {
            column: 'Brand Name',
            type: String,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => {
              if(i.brand){
                return i.brand;
              } else {
                return '';
              }
            }
          },
          {
            column: 'Product Name',
            type: String,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => i.name ? i.name :''
          },
          {
            column: 'Strain',
            type: String,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => {
                if(i.product && i.product.strain){
                  return i.product.strain
                } else {
                  return ''
                }
            }
          },
          {
            column: 'THC Pre-Fix',
            type: Number,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => 0
          },
          {
            column: 'THC Min',
            type: Number,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => i.thcMin ? i.thcMin : 0
          },
          {
            column: 'THC Max',
            type: Number,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => i.thcMax ? i.thcMax : 0
          },
          {
            column: 'THC UOM',
            type: Number,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => 0
          },
          {
            column: 'CBD Pre-Fix',
            type: Number,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => 0
          },
          {
            column: 'CBD Min',
            type: Number,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => i.cbdMin ? i.cbdMin : 0
          },
          {
            column: 'CBD Max',
            type: Number,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => i.cbdMax ? i.cbdMax : 0
          },
          {
            column: 'CBD UOM',
            type: Number,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => 0
          },
          {
            column: 'Dominant Terpene',
            type: Number,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => 0
          },
          {
            column: 'Shipped Case Qty',
            type: Number,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => 0
          },
          {
            column: 'Master Case Each Qty',
            type: Number,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => 0
          }
        ]
        let ocsSchema = [
          // {
          //   column: 'ShipmentID',
          //   type: String,
          //   wrap: true,
          //   alignVertical: 'center',
          //   align: 'center',
          //   value: (i) => i.ShipmentID?i.ShipmentID:'', 
          // },
          {
            column: 'SKU',
            type: String,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: (i) => {
              if(i.sku) {
                return i.sku;
              }else{
                ''
              }
            }, 
          },
          {
            column: 'ItemName',
            type: String,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => i.name ? i.name :''
          },
          {
            column: 'UnitCost',
            type: Number,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => i.cost ? i.cost :''
          },
          {
            column: 'Vendor',
            type: String,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => i.licensedProducer ? i.licensedProducer :''
          },
          {
            column: 'Brand',
            type: String,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => {
              if(i.brand){
                return i.brand;
              } else {
                return '';
              }
            }
          },
          {
            column: 'CaseGTIN',
            type: String,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: (i) => {
              if(i.casegtin) {
                return i.casegtin;
              }else{
                ''
              }
            }, 
          },
          {
            column: 'PackagedOnDate',
            type: String,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: (i) => i.packagedOn?i.packagedOn:''
          },
          {
            column: 'BatchLot',
            type: String,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: (i) => {
              if(i.batchLot) {
                return i.batchLot;
              }else{
                ''
              }
            }, 
          },
          {
            column: 'EachGTIN',
            type: String,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: (i) => {
              if(i.productGtin) {
                return i.productGtin;
              }else{
                ''
              }
            }, 
          },
          {
            column: 'Shipped Qty',
            type: Number,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: (i) => {
              if(i.nou) {
                return i.nou;
              }else{
                ''
              }
            }, 
          },
          // {
          //   column: 'UOMCONVERSION',
          //   type: Number,
          //   wrap: true,
          //   alignVertical: 'center',
          //   align: 'center',
          //   value: i => i.packSize?i.packSize:0
          // },
          {
            column: 'THC',
            type: Number,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => i.thc ? i.thc : 0
          },
          {
            column: 'CBD',
            type: Number,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => i.cbd ? i.cbd : 0
          },
          {
            column: 'ConcentrationUnit',
            type: String,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => i.concentrationUnit ? i.concentrationUnit : ''
          },
          {
            column: 'TotalTerps',
            type: Number,
            wrap: true,
            alignVertical: 'center',
            align: 'center',
            value: i => i.totalTerp ? i.totalTerp : 0
          }
        ]
        let xlBlob = await writeXlsxFile(this.items, {
          schema: this.aglcOcs==='aglc'?aglcSchema:ocsSchema,
        });
        let fileName = `Purchase-Order-${this.payload.shipmentId}.xlsx`;
        let url = URL.createObjectURL(xlBlob);
        let link = document.createElement("a");
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        let self = this
        setTimeout(function(){
          self.snackbar.add({
            type: 'info',
            text: 'File downloaded',
            duration: 3000,
          })
        }, 1000)

      } catch(err) {
        console.log(err)
        return this.snackbar.add({
          type: 'info',
          text: 'Something went wrong. Please try again later.',
          duration: 3000,
        })
      }
    },
    ...mapActions({
      va_spinner: 'spinner/setSpinner',
    })
  }
})
</script>

<template>
    <div class="flex justify-between mb-5">
      <h2 class="text-[24px] font-semibold text-wite">End of Day Report List</h2>
      <JsonCSV
        v-if="csvData.length > 0"
        :name="'EOD_'+csvData.length + '.csv'"
        :data="csvData">
        <button class="h-auto xl:h-10 hover:bg-theme-darkgreen text-sm border-0 hover:text-white rounded-md cursor-pointer text-green-400 bg-white py-2 px-2 uppercase font-semibold">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.3335 21.6673C1.80016 21.6673 1.3335 21.4673 0.933496 21.0673C0.533496 20.6673 0.333496 20.2007 0.333496 19.6673V14.9007H2.3335V19.6673H19.6668V14.9007H21.6668V19.6673C21.6668 20.2007 21.4668 20.6673 21.0668 21.0673C20.6668 21.4673 20.2002 21.6673 19.6668 21.6673H2.3335ZM11.0002 16.5673L4.56683 10.134L6.00016 8.70065L10.0002 12.7007V0.333984H12.0002V12.7007L16.0002 8.70065L17.4335 10.134L11.0002 16.5673Z" fill="#2BA66B"/>
          </svg>
        </button>
      </JsonCSV>

      <button v-else class="h-auto xl:h-10 text-sm border-0 rounded-md cursor-default  bg-theme-modalbg text-grey py-2 px-2 uppercase font-semibold">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.3335 21.6673C1.80016 21.6673 1.3335 21.4673 0.933496 21.0673C0.533496 20.6673 0.333496 20.2007 0.333496 19.6673V14.9007H2.3335V19.6673H19.6668V14.9007H21.6668V19.6673C21.6668 20.2007 21.4668 20.6673 21.0668 21.0673C20.6668 21.4673 20.2002 21.6673 19.6668 21.6673H2.3335ZM11.0002 16.5673L4.56683 10.134L6.00016 8.70065L10.0002 12.7007V0.333984H12.0002V12.7007L16.0002 8.70065L17.4335 10.134L11.0002 16.5673Z" fill="#CCCCCC"/>
        </svg>
      </button>
    </div>

    <!-- <h2 class="text-[20px] text-[#878686] font-bold mb-3 flex justify-between"> End of Day Report List</h2> -->
    <block-loading v-if="loading" />
    <table-lite
      v-else
      :class="'admin-table'"
      :is-loading="table.isLoading"
      :is-slot-mode='true'
      :columns="columns"
      :rows="table.rows"
      :rowClasses="customRowClass"
      :total="table.totalRecordCount"
      :sortable="table.sortable"
      @is-finished="table.isLoading = false"
      :page-size='table.pagesize'
      @do-search="doSearch"
      :pageOptions='table.pageOptions'
      :has-checkbox="true"
       @row-clicked="setActive"
       @return-checked-rows="onCheckRows"
      :checked-return-type="'id'"
      :is-hide-paging="false"
      :pageSizeChangeLabel="'pageSizeChangeLabel'"
      :messages="{
        pagingInfo: 'Total: {2}',
        pageSizeChangeLabel: 'Items per Page',
        gotoPageLabel: '',
        noDataAvailable: 'No records found!',
      }"
    >
        <template v-slot:shiftDate="data">
            <span>{{data.value.shiftDate}}</span>
        </template>

        <template v-slot:isOpen="data">
            <span v-if="data.value.isOpen" class="text-theme-green font-semibold">In-Progress</span>
            <span v-else>Closed</span>
        </template>

        <template v-slot:totalDifference="data">
            <span :class="(data.value.totalDifference &&  data.value.totalDifference > 0) ? 'text-theme-green font-semibold' : ( (data.value.totalDifference &&  data.value.totalDifference < 0) ? 'text-theme-red font-semibold' : '') ">
              {{ readAblePrice(data.value.totalDifference) }}
            </span>
        </template>

        <!-- <template v-slot:cardDifference="data">
          <span :class="(data.value.cardDifference &&  data.value.cardDifference > 0) ? 'text-theme-green font-semibold' : ((data.value.cardDifference &&  data.value.cardDifference < 0) ? 'text-theme-red font-semibold' : '')">
            {{ readAblePrice(data.value.cardDifference) }}
          </span>
        </template> -->

        <template v-slot:closedBy="data">
           {{ data.value.closedBy ? data.value.closedBy : '-' }}
        </template>

    </table-lite>

</template>

<script>
import  { defineComponent , ref} from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { useSnackbar } from "vue3-snackbar";
import Helpers from '@/helpers';
// import LocationShiftApis from '@/controllers/LocationShiftApis';
import OrderShiftApis from '@/controllers/OrderShiftApis'
import Env from '@/env';
import JsonCSV from 'vue-json-csv'
import moment from 'moment-timezone'

export default defineComponent({
  name: 'ProductSalesList',
  components: {
    JsonCSV
  },
  props: {
    iscomponentactive: {
      type: Boolean,
      required: false,
    },
    selectedCallback: {
      type: Function,
      required: true,
    },
    clearComponent: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const snackbar = useSnackbar();
    const confirmStatusObj = ref({
      modal: false,
      obj: {
        status: Boolean,
        id: Number,
      },
    });
    const activeItem = ref({});
    const loading = ref(false);
    const columnsMain = ref([
      {
        label: "",
        field: "id",
        sortable: false,
        isKey: true,
        display: () => {
          return '';
        },
        columnClasses: ['hide'],
        headerClasses: ['hide'],
      },
      {
        label: "Date",
        field: "shiftDate",
        sortable: false,
      },
      {
        label: "Device",
        field: "posStationName",
        sortable: false,
      },
      {
        label: "Started By",
        field: "startedBy",
        sortable: false,
      },
       {
        label: "Closed By",
        field: "closedBy",
        sortable: false,
      },
       {
        label: "Total Difference",
        field: "totalDifference",
        sortable: false,
      },
      // {
      //   label: "Card Difference",
      //   field: "cardDifference",
      //   sortable: false,
      // },
      {
        label: "Status",
        field: "isOpen",
        sortable: false,
      },
   
    ]);

    const table = ref({
      checkedRows: [],
      isLoading: false,
      rows: [],
      totalRecordCount: 0,
      sortable: {
        order: "name",
        sort: "asc",
      },
      pagesize: Env.defaultPageSize,
      pageOptions: Env.pageList,
      count: 0,
      page: 1,
    });
    const csvData = ref([]);
    return  {
      table,
      columnsMain,
      activeItem,
      loading,
      confirmStatusObj,
      snackbar,
      csvData,
    };
  },

  async mounted() {
    this.loading = true;
    await this.fetchList(this.table.page);
    this.loading = false;
    this.emitter.on("refresh-product-sales-list", data => {
      this.fetchList(this.table.page);
    });

  },

  beforeDestroy() {
   this.emitter.off('refresh-product-sales-list', () => {})
  },

  watch: {
    'vg_selectedLocation'(val) {
      // if location selected is changed fetch the data
      this.fetchList(this.table.page);
    },
  },

  computed: {
    ...mapGetters({
      vg_selectedLocation: 'location/selected'
    }),

    /**
     * Compute columns
     */
    columns() {
      if(this.iscomponentactive){
        let removeFields= ['endingCash', 'endingCard', 'startedBy', 'closedBy', 'cashDifference', 'cardDifference'];
        if(this.columnsMain?.length>0){
          return this.columnsMain.filter(item => {
              if( removeFields.indexOf(item.field) == -1 ) {
                return item;
              }
          })
        }
      }
      return this.columnsMain;
    },
  },

  methods: {
    /**
     * Fetch list
     */
    async fetchList(page) {
      this.table.isLoading = true;
      //let res = await LocationShiftApis.fetchAllLocationShifts(this.vg_selectedLocation.id, this.table.pagesize, page, this.table.sortable.order, this.table.sortable.sort);
      let res = await OrderShiftApis.fetchAll(this.vg_selectedLocation.id, page, this.table.pagesize);
      this.table.isLoading = false;
      this.table.rows = (res.status == 200)?res.data.payload.shifts:[]
      this.table.totalRecordCount = (res.status == 200) ? res.data.payload.totalRecords : 0
      this.table.page = page;
    },

    /**
     * Select the item
     */
    setActive(item) {
      if(!item.isOpen){
        // Select unselect the item
        if(this.activeItem.id) {
          let oldactive = document.getElementsByClassName('thisid_'+this.activeItem.id);
          if(oldactive.length > 0){
            oldactive[0].classList.remove("active-row");
          }
        }
        this.activeItem = item;
        let newActive = document.getElementsByClassName('thisid_' + this.activeItem.id);
        if(newActive.length > 0){
          newActive[0].classList.add("active-row");
        }
        this.selectedCallback(item);
      }
    },

    /**
     * Create custom class on tr for active colors
     */
    customRowClass(item) {
      return 'thisid_' + item.id;
    },

    /**
     * Search now
     */
    doSearch(offset, limit, order, sort) {
      let page = (offset/limit)+1
      this.table.sortable.order = order
      this.table.sortable.sort = sort
      this.table.pagesize = limit;
      this.fetchList(page);
    },

    /**
     * 
     * Compute checked rows
     */
    onCheckRows(rows) {
      this.csvData = [];
      rows.forEach(row => {
        let item = this.table.rows.find((e) => e.id === row);
        if(item != undefined) {
          let csvData2 = {}
          let csvData4 = {}
          let csvData1 = {
            "Started By": item.startedBy,
            "Start Time": this.readAbleDate(item.openedAt),
            "Starting Float": item.startingFloat ? this.displayPriceFormat(item.startingFloat) : 0.00,
            "Ended By": item.closedBy,
            "End Time": this.readAbleDate(item.closedAt),
            "End of Day Cash Drop": this.displayPriceFormat(item.endingCashDrop),
            "Cash (Actual)": this.displayPriceFormat(item.endingCash),
            "Cash (Expected)": this.displayPriceFormat(item.cashExpected),
            "Cash (Difference)": this.displayPriceFormat(item.cashDifference),
            "Card Count Total (Actual)": this.displayPriceFormat(item.endingCard),
            "Card Count Total (Expected)": this.displayPriceFormat(item.cardExpected),
            "Card Count Total (Difference)": this.displayPriceFormat(item.cardDifference),
            "Online Card Total (Actual)": this.displayPriceFormat(item.onlineCardActual),
            "Online Card Total (Expected)": this.displayPriceFormat(item.onlineCardExpected),
            "Online Card Total (Difference)": this.displayPriceFormat(item.onlineCardDifference),
            "Terminal Card Total (Actual)": this.displayPriceFormat(item.instoreCardActual),
            "Terminal Card Total (Expected)": this.displayPriceFormat(item.instoreCardExpected),
            "Terminal Card Total (Difference)": this.displayPriceFormat(item.instoreCardDifference),
          }
          if(Object.keys(item.cardPaymentByType).length>0){
              for(const key in item.cardPaymentByType){
                  let keyWord = key.replace('_', ' ')
                  let value = item.cardPaymentByType[key]
                  csvData2[`Payment Type (${keyWord})`] =  (value?this.displayPriceFormat(value):'0.00')
              }
          }

          let csvData3 = {
            "Non Integrated Card (Actual)": this.displayPriceFormat(item.nonIntegratedActual),
            "Non Integrated Card (Expected)": this.displayPriceFormat(item.nonIntegratedExpected),
            "Non Integrated Card (Difference)": this.displayPriceFormat(item.nonIntegratedDifference)
          }

          if(Object.keys(item.inStoreNonIntegratedPaymentByType).length>0){
              for(const key in item.inStoreNonIntegratedPaymentByType){ 
                  let keyWord = key.replace('_', ' ')
                  let value = item.inStoreNonIntegratedPaymentByType[key]
                  csvData4[`Non Integrated Card (${keyWord})`] =  (value?this.displayPriceFormat(value):'0.00')
              }
          }
          
          let csvData5 = {
              "Total (Actual)": this.displayPriceFormat(this.totalActual(item)),
              "Total (Expected)": this.displayPriceFormat(this.totalExpected(item)),
              "Total (Difference)": this.displayPriceFormat(this.totalDifference(item)),
              "Tips": item.totalTip ? this.displayPriceFormat(item.totalTip) : 0,
              "Tomorrow’s Float": this.displayPriceFormat(item.tomorrowFloat),
              "Total Cash Drop": this.displayPriceFormat(item.cashToDeposit),
          };
         
          this.csvData.push({...csvData1, ...csvData2, ...csvData3, ...csvData4, ...csvData5})
        }
        
      })
    },

    totalActual(item) {
        let total = this.getAllSumOf(item.nonIntegratedActual, item.endingCash, item.endingCard);
        return total? total : 0
    },
    totalExpected(item) {
        let total = this.getAllSumOf(item.nonIntegratedExpected, item.cardExpected, item.cashExpected);
        return total? total : 0
    },
    totalDifference(item){
        let actual = this.getAllSumOf(item.nonIntegratedActual, item.endingCash, item.endingCard);
        let expected = this.getAllSumOf(item.nonIntegratedExpected, item.cardExpected, item.cashExpected);
        return actual-expected
    },

    /**
     * 
     *  Make price readable with signs
     */
    readAblePrice(val) {
      if(val == 0 || !val ) {
        return '-';
      } else if(val > 0) {
        return `$${ (Math.round(val * 100) / 100).toFixed(2) }`;
      } else {
        return `-$${ (Math.round(Math.abs(val) * 100) / 100).toFixed(2) }`;
      }
    },

    /**
     * 
     * @param val1 Get sum value
     * @param val2 
     */
    getSumOf(val1, val2) {
        val1 = val1 ? val1 : 0;
        val2 = val2 ? val2 : 0
        return val1+val2;
    },

    getAllSumOf(val1, val2, val3) {
        val1 = val1 ? val1 : 0;
        val2 = val2 ? val2 : 0
        val3 = val3 ? val3 : 0
        return val1+val2+val3;
    },

    /**
     * 
     * @param str make date readable
     */
    readAbleDate(str) {
      let defaultTimeZone = this.vg_selectedLocation.timezone?this.vg_selectedLocation.timezone:'America/Toronto';
      return str ? moment(str).tz(defaultTimeZone).format('YYYY-MM-DD hh:mmA') : '';
    },

    readAbleDateOnly(str){
      let defaultTimeZone = this.vg_selectedLocation.timezone?this.vg_selectedLocation.timezone:'America/Toronto';
      return str ? moment(str).tz(defaultTimeZone).format('YYYY-MM-DD') : '';
    },
    
    /**
     * 
     * @param price Manage price display format
     */
    displayPriceFormat(price) {
        if(price > 0) {
            return '$' + price.toFixed(2);
        } else if(price<0){
            return '- $' + Math.abs(price).toFixed(2);
        }else{
          return '0.00';
        }
    },

    ...mapActions({
      va_spinner: "spinner/setSpinner",
    })

  }
});
</script>


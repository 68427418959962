<template>
<div class="custom-modal-wrap overflow-y-auto  overflow-x-hidden fixed top-0 right-0 left-0 z-[100] md:inset-0 h-modal md:h-full justify-center items-center flex">
    <div class="absolute top-[30px] left-0 flex justify-center items-center  my-0 mx-auto right-0 w-full z-10 text-white flex-1 col-span-1 border-0 rounded-t-xl overflow-hidden">
        <div :class="expandError?'w-[850px]':'w-[550px]'" class="transition-width duration-500 mx-auto mt-10 pt-3 bg-[#464851] px-[16px] py-[16px] rounded-lg">
            <div class="flex justify-center relative pb-5">
                <span class="text-3xl">{{showTendyUpload?'Tendy template upload':'Purchase Order Upload'}}</span>
                <a  @click.prevent="close"  href="" class="right-0 top-2 absolute"><mdicon class="cursor-pointer hover:text-slate-200 " name="close"/></a>
            </div>
            <div class="flex">
                <div class="px-[16px] py-[16px] bg-[#2B2C32] rounded-xl">  
                    <div class="flex justify-center items-center  xl:flex-row flex-col gap-3">
                        <div v-if="showTendyUpload" class="w-full xl:w-1/2">
                            <div class="input-wrapper">
                                <button :class="showTendyUpload?'bg-theme-green':'bg-theme-black'" class="border border-theme-green w-full rounded-md px-4 py-4 uppercase tracking-widest text-sm font-medium hover:bg-[#2B5445]">Tendy Upload Template</button>
                            </div>
                        </div>

                        <div v-else class="w-full xl:w-1/2">
                            <div class="input-wrapper">
                                <button v-if="aglcOcs" :class="showTendyUpload?'bg-theme-black':'bg-theme-green'" class="border border-theme-green w-full rounded-md px-4 py-4 uppercase tracking-widest text-sm font-medium hover:bg-[#2B5445] h-[70px]">{{(aglcOcs==='aglc')?'AGLC PackList':'OCS Purchase Order ASN'}}</button>
                                <button v-else :class="showTendyUpload?'bg-theme-black':'bg-theme-green'" class="border border-theme-green w-full rounded-md px-4 py-4 uppercase tracking-widest text-sm font-medium hover:bg-[#2B5445] h-[70px]">Loading...</button>
                            </div>
                        </div>
                    </div> 
                    
                    <div class="flex flex-col justify-center">
                        <p class="text-sm text-center tracking-wider mt-4">Drag and Drop your order below or upload manually to continue. CSV or XLSX file accepted only.</p>
                        <div v-if="showTendyUpload || aglcOcs" class="text-sm text-center tracking-wider mt-4">
                            <a @click="createXlSheet()" href="#" class="underline text-theme-green">CLICK HERE</a> to download our template. Upload it below to continue
                        </div>
                            
                        <!-- <form> -->
                            <div @dragenter.prevent="dragging=true" @dragover.prevent="dragging=true"  @dragleave.prevent="dragging=false" @drop.prevent="addFile" draggable="false">
                                <label for="uploadFile" class="self-center cursor-pointer">
                                    <span class="flex justify-center py-9 hover:text-dark-gray" >
                                        <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M34.0013 75.3327V41.9993H0.667969V33.9993H34.0013V0.666016H42.0013V33.9993H75.3346V41.9993H42.0013V75.3327H34.0013Z" fill="#1C1D21"/>
                                        </svg>
                                    </span>
                                </label>
                                <input ref="uploadFile" id="uploadFile" class="hidden" type="file" @change="addFile" />
                                <div v-if="file.name" class="flex justify-center mt-4">
                                    <p class="text-sm text-center tracking-wider text-white underline">{{file.name}}</p>
                                    <a @click.prevent="clearFile" href="" class="ml-4"><mdicon class="cursor-pointer hover:text-white text-theme-red" name="close"/></a>
                                </div>
                                <p v-else class="text-sm text-center tracking-wider text-theme-878686">No file uploaded...</p>
                                <p v-if="error" class="flex justify-center mt-4 text-theme-red break-words">
                                    <svg class="fill-theme-red mr-2 min-h-[24px] min-w-[24px]" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 17C12.2833 17 12.5208 16.9042 12.7125 16.7125C12.9042 16.5208 13 16.2833 13 16C13 15.7167 12.9042 15.4792 12.7125 15.2875C12.5208 15.0958 12.2833 15 12 15C11.7167 15 11.4792 15.0958 11.2875 15.2875C11.0958 15.4792 11 15.7167 11 16C11 16.2833 11.0958 16.5208 11.2875 16.7125C11.4792 16.9042 11.7167 17 12 17ZM12 13C12.2833 13 12.5208 12.9042 12.7125 12.7125C12.9042 12.5208 13 12.2833 13 12V8C13 7.71667 12.9042 7.47917 12.7125 7.2875C12.5208 7.09583 12.2833 7 12 7C11.7167 7 11.4792 7.09583 11.2875 7.2875C11.0958 7.47917 11 7.71667 11 8V12C11 12.2833 11.0958 12.5208 11.2875 12.7125C11.4792 12.9042 11.7167 13 12 13ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"/>
                                    </svg>
                                    <div v-if="Array.isArray(errorMsg)">
                                        <span>You have {{errorMsg.length}} error messages, expand the right panel to view them. </span>
                                        <span @click="expandNow" class="text-theme-green underline cursor-pointer">Expand View</span>
                                    </div>
                                    <span v-else>{{errorMsg}}</span>
                                </p>
                            </div>
                            <div>
                                <div class="input-wrapper max-w-[120px] mt-6 mx-auto">
                                    <div class="text-xs mb-1 text-center">Fulfillment Time</div>
                                    <div class="flex flex-row border-none rounded bg-gray-black w-full">
                                        <input @paste.prevent :onkeypress="preventNonNumbersAndDecimal" v-model="fulfillTime" min="0" maxlength="4" placeholder="ie. 14" class="block mx-1 tracking-wider bg-gray-black border-0 w-[70px] focus:ring-0 text-sm placeholder-theme-878686 text-theme-white" type="number">
                                        <span class="self-center">Days</span>
                                    </div>
                                </div>
                            </div>
                        <!-- </form> -->
                        <div class="flex justify-center mt-4">
                            <button @click="uploadFile" :disabled="!file" class="rounded-lg bg-theme-green cursor-pointer disabled:bg-black disabled:cursor-default disabled:text-gray-black text-white px-[140px] py-[14px] text-sm tracking-wider font-medium">CONTINUE</button>
                        </div>
                    </div>
                </div>
                <div v-if="expandError" class="relative ml-2 px-[16px] py-[16px] bg-[#2B2C32] rounded-xl">
                    <div class="flex items-center justify-center">
                        <span class="text-white my-3">Error Message</span>

                        <svg @click="collapseNow" class="absolute right-3 cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.7049 16.59L11.1249 12L15.7049 7.41L14.2949 6L8.29492 12L14.2949 18L15.7049 16.59Z" fill="white"/>
                        </svg>
                    </div>
                    <p class="text-xs text-theme-orange my-2" v-for="error in errorMsg" :key="error">{{error}}</p>
                </div>
            </div>
        </div>
    </div> 
</div> 
</template>
<script>
import Helpers from '@/helpers';
import { ref, defineComponent } from 'vue'
import ProductApi from '@/controllers/BusinessProductApis'
import { useSnackbar } from "vue3-snackbar"
import {mapGetters, mapMutations} from 'vuex'
import downloadCsv from 'vue-json-csv'
import writeXlsxFile from 'write-excel-file';
import LocationComplianceApis from '@/controllers/LocationComplianceApis';

export default defineComponent({
    props: ['payload', 'showTendyUpload'], //  showTendyUpload true show tendy upload else ocs or aglc upload
    components: {
        downloadCsv
    },
    setup() {
        const snackbar = useSnackbar();
        const error = false
        const errorMsg = ''
        const expandError = ref(false)
        let file = ref("")
        const aglcOcs = ref('')
        let fulfillTime = ref("")
        const ocsFields = ['ShipmentID','ContainerID','SKU','ItemName','UnitPrice','Vendor','Brand','CaseGTIN','PackagedOnDate','BatchLot','GTINBarCode','EachGTIN','Shipped_Qty','UOM','UOMCONVERSIONQTY','THC','CBD','ConcentrationUnit','TotalTerps']
        const aglcFields = ['Order Number','SKU','Shipped Each Qty','Case GTIN','Each GTIN','Lot Code','Price Per Unit','SKU Description','Brand Name','Product Name','Strain','THC Pre-Fix','THC Min','THC Max','THC UOM','CBD Pre-Fix','CBD Min','CBD Max','CBD UOM','Dominant Terpene','Shipped Case Qty','Master Case Each Qty']
        const tendyFields = ['productId','Master Product Name','Variant Product Name','Category','Compliance Type','Cost','Price','Sale Price','Barcode/GTIN','Qty','Brand','SKU','Strain','Lot Tracking Enabled','Description','Image URL','Concentration Unit','Dry Cannabis Equivalency','THC','CBD','Lot THC','Lot CBD','Lot Cost','PackagedOnDate','BatchLot','Terp','Licensed Producer']
        const downloadableCsv = ref([])
        return {snackbar, error, errorMsg,expandError, file, aglcOcs, fulfillTime, ocsFields, aglcFields,tendyFields, downloadableCsv}
    },
    computed: {
        ...mapGetters({
            vg_selectedLocation: 'location/selected',
            vg_selectedBusiness: 'auth/activeBusiness',
        }),
    },
    mounted(){
        this.getComplianceProvider()
    },
    methods: {
        async getComplianceProvider(){
            let res = await LocationComplianceApis.fetch();
            if(res?.data?.payload?.providerName){
                this.aglcOcs = res.data.payload.providerName
            }else{
                this.aglcOcs = 'ocs'
            }
        },
        close(){
            this.$emit('close')
        },
        async addFile(e){
            this.error = false
            this.errorMsg = ''
            let fileList = e.target.files || e.dataTransfer.files
            if(fileList && fileList[0]){
                this.file = fileList[0]
            }
        },
        async uploadFile(){
            if(this.file){
                if(this.vg_selectedLocation.id){
                    this.vm_spinner(true)
                    let fileData = new FormData()
                    fileData.append('importFile', this.file)
                    if(this.showTendyUpload){
                        fileData.append('locationId', this.vg_selectedLocation.id)
                    }else{
                        fileData.append('locationId', this.vg_selectedLocation.id)
                    }
                    fileData.append('fullfillmentTime', this.fulfillTime)
                    if(this.aglcOcs==='aglc'){
                        fileData.append('fileType', 'aglc-purchase')
                    }
                    let res = null
                    if(!this.showTendyUpload){
                        res = await ProductApi.purchaseOrderUploadV1(fileData)
                    }else if(this.showTendyUpload){
                        res = await ProductApi.importUploadTendyUpload(fileData)
                    }
                    if(res.status==201){
                        this.snackbar.add({
                            type: 'info',
                            text: "Uploaded successfully.",
                            duration: 3000,
                        })
                    }else{
                        this.errorMsg = res.response?.data?.message
                        this.error =true
                    }
                    this.vm_spinner(false)
                    this.clearFile()
                    if(res.data?.payload){
                        if(this.showTendyUpload){
                            this.emitter.emit("reloadProductList")
                            this.$emit('close')
                        }else if(res.data.payload.length>0){
                            this.$emit('uploadedPayload', {data: res.data.payload, fulfillment: this.fulfillTime})
                        }
                    }
                }else{
                    this.snackbar.add({
                        type: 'info',
                        text: 'Please select location from top left corner',
                        duration: 3000,
                    })
                }
            }
        },
        async createXlSheet(){
            let schema = []
            let fields = this.showTendyUpload?this.tendyFields:(this.aglcOcs==='aglc'?this.aglcFields:this.ocsFields)
            for(let i=0; i<fields.length; i++){
                schema.push({
                    column: fields[i],
                    type: String,
                    wrap: true,
                    value: ''
                })
            }
            await writeXlsxFile([], {
                schema: schema,
                fileName: `${this.showTendyUpload?'tendy':this.aglcOcs}-template.xlsx`
            });
        },
        readFile(file, callback){
            let reader = new FileReader()
            reader.readAsText(file)
            reader.onload = e => {
                let result = reader.result
                callback(result)
            }
        },
        clearFile(){
            this.file = ""
            this.$refs.uploadFile.value = ''
            // this.downloadableCsv = []
        },
        preventNonNumbersAndDecimal(e) {
          var ASCIICode = (e.which) ? e.which : e.keyCode
          if(ASCIICode == 43 || ASCIICode == 45 || ASCIICode == 101 || e.which == 8 || ASCIICode !== 46 && ASCIICode != 0 && (ASCIICode < 48 || ASCIICode > 57)){
            return false
          }
          return true;
        },
        expandNow(){
            this.expandError = true
        },
        collapseNow(){
            this.expandError = false
        },
        ...mapMutations({
            vm_spinner: 'spinner/mutateSpinner'
        })
    }
})
</script>
<style>
.custom-modal-wrap:before {
  content: '';
  position: fixed;
  top: 0;
  width: 0;
  height: 100%;
  width: 100%;
  background: rgb(0 0 0 / 64%);
}
</style>
/**
 * @module AuthApi
 *  Apis must need auth details to trigger url - AuthApi
 */
/* eslint-disable */

import axios from 'axios';
import store from '@/store';
import Env from '@/env';

const productAxiosInstance = axios.create({
  baseURL: `${Env.productUrl}/api`,
  headers: {
    'api_token': Env.productApiToken ? Env.productApiToken : ''
  }
})

export default {
    /**
     * Create Custom Api
     * @function
     * @param {string} url - Url to get results
     * @returns {object} A promise that will return either a success object or an error object.
     */
    customApi: (method, url, headers) => {
        return productAxiosInstance({
            method,
            url,
            headers,
        });
    },

    /**
     * Call to an GET Authenticate api for response.
     * @function
     * @param {string} url - Url to get results
     * @returns {object} A promise that will return either a success object or an error object.
     */
    getApi: (url, header) => {
        return productAxiosInstance({
            method: 'GET',
            url,
            headers: header ? header : {
              'Authorization': 'Bearer '+ store.getters['auth/userToken']
            },
        });
    },

    /**
     * Call to an POST Authenticate api for response.
     * @function
     * @param {string} url - Url to get results
     * @param {string} data - accept objects
     * @returns {object} A promise that will return either a success object or an error object.
     */
    postApi: (url, header, data) => {
        return productAxiosInstance({
            method: 'POST',
            url,
            headers: header ? header : {
              'Authorization': 'Bearer '+ store.getters['auth/userToken']
            },
            data,
        });
    },

    postBlobApi: (url, header, data) => {
      return productAxiosInstance({
          method: 'POST',
          url,
          responseType: 'blob',
          headers: header ? header : {
            'Authorization': 'Bearer '+ store.getters['auth/userToken']
          },
          data,
      });
    },

    /**
     * Call to an PUT Authenticate api for response.
     * @function
     * @param {string} url - Url to get results
     * @param {string} data - accept objects
     * @returns {object} A promise that will return either a success object or an error object.
     */
    putApi: (url, header, data) => {
        return productAxiosInstance({
            method: 'PUT',
            url,
            headers: header ? header : {
              'Authorization': 'Bearer '+ store.getters['auth/userToken']
            },
            data,
        });
    },

    /**
     * Call to an PATCH Authenticate api for response.
     * @function
     * @param {string} url - Url to get results
     * @param {string} data - accept objects
     * @returns {object} A promise that will return either a success object or an error object.
     */
    patchApi: (url, header, data) => {
        return productAxiosInstance({
            method: 'PATCH',
            url,
            headers: header ? header : {
              'Authorization': 'Bearer '+ store.getters['auth/userToken']
            },
            data,
        });
    },
    /**
     * Call to an DELETE Authenticate api for response.
     * @function
     * @param {string} url - Url to get results
     * @returns {object} A promise that will return either a success object or an error object.
     */
    deleteApi: (url, header, data = {}) => {
        return productAxiosInstance({
            method: 'DELETE',
            url,
            headers: header ? header : {
              'Authorization': 'Bearer '+ store.getters['auth/userToken']
            },
            data
        });
    },

   
};
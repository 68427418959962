import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mdiVue from 'mdi-vue/v3'
import * as mdijs from '@mdi/js'
import '@/validations/vee-validate';
import "vue3-snackbar/dist/style.css";
import '@vuepic/vue-datepicker/dist/main.css'
import './index.css'
import { SnackbarService, Vue3Snackbar } from "vue3-snackbar";
import TableLite from "vue3-table-lite/ts";
import mitt from 'mitt';
const emitter = mitt();
//import  VueGoogleMaps from '@fawmi/vue-google-maps';
import Env from '@/env';
import { Loader } from "@googlemaps/js-api-loader";

// Global Components
// Loading components
import SiteLoading from './components/helpers/SiteLoading.vue';
import ApiLoading from './components/helpers/ApiLoading.vue';
import BlockLoading from './components/helpers/BlockLoading.vue';

// Dailogue
import CustomDailogue from '@/components/general/dialog/CustomDailogue.vue';
import CustomSelectDropdown from '@/components/helpers/CustomSelectDropdown.vue';
import ConfirmModal from "@/components/general/dialog/ConfirmModal.vue";
import ConfirmModalNewUI from "@/components/general/dialog/ConfirmModalNewUI.vue";
import RestrictionAlert from './components/helpers/RestrictionAlert.vue';

// Support package
// declare global {
//   interface Window { intercomSettings: any; }
// }

// window.intercomSettings = {
//   api_base: "https://api-iam.intercom.io",
//   app_id: "w7r0a0kg"
// } || {};

// (<any>window).intercomSettings = <any>{
//   api_base: "https://api-iam.intercom.io",
//   app_id: "w7r0a0kg"
// };

// (function() {
//   var w:any = window;
//   var ic:any = w.Intercom;
//   if (typeof ic === "function") {
//       ic('reattach_activator');
//       ic('update', w.intercomSettings);
//   } else {
//       var d:any = document;
//       var i:any = function() {
//           i.c(arguments);
//       };
//       i.q = [];
//       i.c = function(args) {
//           i.q.push(args);
//       };
//       w.Intercom = i;
//       var l:any = function() {
//           var s = d.createElement('script');
//           s.type = 'text/javascript';
//           s.async = true;
//           s.src = 'https://widget.intercom.io/widget/w7r0a0kg';
//           var x:any = d.getElementsByTagName('script')[0];
//           x.parentNode.insertBefore(s, x);
//       };
//       if (document.readyState === 'complete') {
//           l();
//       } else if (w.attachEvent) {
//           w.attachEvent('onload', l);
//       } else {
//           w.addEventListener('load', l, false);
//       }
//   }
// })();

router.beforeEach((to, from, next) => {
 
  // remove unnecessary query from route
  if (to.meta.middleware) {
      const middleware = Array.isArray(to.meta.middleware) ?
          to.meta.middleware : [to.meta.middleware];

      const context = {
          from,
          next,
          router,
          to,
      };

      const nextMiddleware = nextFactory(context, middleware, 1);
      return middleware[0]({ ...context, next: nextMiddleware });
  }
  
  return next();
  
});


/**
 * Impliment middleware
 */
// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
      // Run the default Vue Router `next()` callback first.
      context.next(...parameters);
      // Then run the subsequent Middleware with a new
      // `nextMiddleware()` callback.
      const nextMiddleware = nextFactory(context, middleware, index + 1);
      subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}


const app = createApp(App);
app.use(store)
app.use(router)
app.use(mdiVue, {
  icons: mdijs
})


app.use(SnackbarService);
// app.use(VueGoogleMaps, {
//   load: {
//     key: Env.googleApiKey,
//     libraries: "places"
//   }
// });
let apiKey = Env.googleApiKey as string;
const _gloader = new Loader({
  apiKey,
  libraries: ["drawing", "places"]
});
app.component("vue3-snackbar", Vue3Snackbar)
app.component("block-loading", BlockLoading);
app.component("api-loading", ApiLoading);
app.component("custom-modal", CustomDailogue);
app.component("table-lite", TableLite);
app.component("custom-select-dropdown", CustomSelectDropdown);
app.component("confirm-modal", ConfirmModal)
app.component("confirm-modal-new-ui", ConfirmModalNewUI)
app.component("restriction-alert", RestrictionAlert);

app.config.globalProperties.emitter = emitter;
app.config.globalProperties.confirm = async (msg:any, extraMsg:any) => {
  return store.dispatch('confirm/confirm', {msg:msg, extraMsg:extraMsg}) 
}
app.config.globalProperties.confirmNew = async (msg:any, extraMsg:any, confirmText:any, confirmClass:any, cancelText:any, cancelClass:any, confirmLeft: any) => {
  return store.dispatch('confirmNew/confirmNew', {msg:msg, extraMsg:extraMsg, confirmText:confirmText, confirmClass:confirmClass, cancelText:cancelText, cancelClass:cancelClass, confirmLeft:confirmLeft}) 
}
let cloverWindow:any;
app.config.globalProperties.cloverWindow = cloverWindow;

import isEmpty  from 'lodash/isEmpty'
import debounce  from 'lodash/debounce'
import find  from 'lodash/find'
import has  from 'lodash/has'
import clone  from 'lodash/clone'
import uniqBy  from 'lodash/uniqBy'
import pick  from 'lodash/pick'
import uniq  from 'lodash/uniq'
import indexOf  from 'lodash/indexOf'
import orderBy  from 'lodash/orderBy'
import findIndex  from 'lodash/findIndex'
import chunk  from 'lodash/chunk'
import filter  from 'lodash/filter'
import map  from 'lodash/map'
import startCase  from 'lodash/startCase'
import toLower  from 'lodash/toLower'
import upperFirst  from 'lodash/upperFirst'
import isEqual from 'lodash/isEqual'
const _lodash = {
  isEmpty,
  debounce,
  find,
  has,
  clone,
  uniqBy,
  pick,
  uniq,
  indexOf,
  orderBy,
  findIndex,
  chunk,
  filter,
  map,
  startCase,
  toLower,
  upperFirst,
  isEqual,
};
app.config.globalProperties._lodash = _lodash;
app.config.globalProperties._gloader = _gloader;

app.mount('#app');

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
     emitter: typeof emitter
  }
}

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    cloverWindow: typeof cloverWindow
  }
}


declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    _lodash: typeof _lodash
  }
}

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    _gloader: typeof _gloader
  }
}




<template>
  <div>
    <div class="relative mt-4">
      <block-loading v-if="loading" />
      <new-table-lite
        v-else
        class="admin-table summary Totals"
        :is-loading="table.isLoading"
        :is-static-mode="true"
        :is-slot-mode='true'
        :columns="selectColumnType()"
        :rows="table.rows"
        :rowClasses="row => row.orderNumber?'cursor-pointer id-'+row.orderNumber:null"
        :total="table.totalRecordCount"
        :sortable="table.sortable"
        @is-finished="table.isLoading = false"
        :page-size='table.pagesize'
        :pageOptions='table.pageOptions'
        :has-checkbox="false"
        @row-clicked="setActive"
        :is-hide-paging="false"
        :pageSizeChangeLabel="'pageSizeChangeLabel'"
        :messages="{
          pagingInfo: 'Total: {2}',
          pageSizeChangeLabel: 'Items per Page',
          gotoPageLabel: '',
          noDataAvailable: 'No records found!',
        }"
      >
        <!-- filter -->
        <template v-slot:filter>
          <tr v-if="selectedType==='product'" class="vtl-thead-tr z-20">
            <td class="vtl-tbody-td">
              <span>Totals</span>
            </td>
            <td class="vtl-tbody-td"></td>
            <td class="vtl-tbody-td"></td>
            <td class="vtl-tbody-td"></td>
            <td class="vtl-tbody-td"></td>
            <td class="vtl-tbody-td"></td>
            <td class="vtl-tbody-td"></td>
            <td class="vtl-tbody-td"></td>
            <td class="vtl-tbody-td">{{ sumPayload.itemsSold }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.itemsRefunded }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.netQty }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.grossSale }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.subTotal }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.totalDiscount }}</td>
            <td class="vtl-tbody-td"></td>
            <td class="vtl-tbody-td">{{ sumPayload.rewardRedemption }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.totalTax }}</td>
            <td class="vtl-tbody-td"></td>
            <td class="vtl-tbody-td">{{ sumPayload.grossProfit }}</td>
            <td class="vtl-tbody-td"></td>
            <td class="vtl-tbody-td">{{ sumPayload.grossMargin }}</td>
          </tr>
          <tr v-else-if="selectedType==='order'" class="vtl-thead-tr z-20">
            <td class="vtl-tbody-td">
              <span>Totals</span>
            </td>
            <td class="vtl-tbody-td"></td>
            <td class="vtl-tbody-td"></td>
            <td class="vtl-tbody-td"></td>
            <td class="vtl-tbody-td"></td>
            <td class="vtl-tbody-td"></td>
            <td class="vtl-tbody-td"></td>
            <td class="vtl-tbody-td"></td>
            <td class="vtl-tbody-td">{{ sumPayload.totalInvoice }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.grossSale }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.subTotal }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.totalDiscount }}</td>
            <td class="vtl-tbody-td"></td>
            <td class="vtl-tbody-td">{{ sumPayload.totalMembershipRedemptions }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.rewardRedemption }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.totalTax }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.totalFees }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.totalTips }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.totalCost }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.grossProfit }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.grossMargin }}</td>
            <td class="vtl-tbody-td"></td>
            <td class="vtl-tbody-td"></td>
            <td class="vtl-tbody-td"></td>
            <td class="vtl-tbody-td"></td>
          </tr>
          <tr v-else-if="selectedType==='location' && table.rows.length>1" class="vtl-thead-tr z-20">
            <td class="vtl-tbody-td">
              <span>Totals</span>
            </td>
            <td class="vtl-tbody-td">{{ sumPayload.numOfOrders }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.numOfRefOrders }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.netSoldOrders }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.totalInvoice }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.grossSales }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.netSales }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.totalDiscount }}</td>
            <td class="vtl-tbody-td"></td>
            <td class="vtl-tbody-td">{{ sumPayload.totalLoyalityRedemption }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.rewardRedemption }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.totalRefundAmount }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.totalTax }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.totalFees }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.totalTips }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.totalCost }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.grossProfit }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.grossMargin }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.totalProductSold }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.numOfLoyalityRedeem }}</td>
            <td class="vtl-tbody-td"></td>
            <td class="vtl-tbody-td"></td>
            <td class="vtl-tbody-td"></td>
            <td class="vtl-tbody-td">{{ sumPayload.cashPayment }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.amex }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.master }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.vis }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.debit }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.uber }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.gift_card }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.other }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.onlineSales }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.instoreSales }}</td>
            <td v-for="item in dynamicColumn" class="vtl-tbody-td" :key="item">{{sumPayload[item] }}</td>

          </tr>
          <tr v-else-if="selectedType==='day'" class="vtl-thead-tr z-20">
            <td class="vtl-tbody-td">
              <span>Totals</span>
            </td>
            <td class="vtl-tbody-td"></td>
            <td class="vtl-tbody-td">{{ sumPayload.numOfOrders }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.numOfRefOrders }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.netSoldOrders }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.totalInvoice }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.grossSales }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.netSales }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.totalDiscount }}</td>
            <td class="vtl-tbody-td"></td>
            <td class="vtl-tbody-td">{{ sumPayload.totalLoyalityRedemption }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.rewardRedemption }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.totalRefundAmount }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.totalTax }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.totalFees }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.totalTips }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.totalCost }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.grossProfit }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.grossMargin }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.totalProductSold }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.numOfLoyalityRedeem }}</td>
            <td class="vtl-tbody-td"></td>
            <td class="vtl-tbody-td"></td>
            <td class="vtl-tbody-td"></td>
            <td class="vtl-tbody-td">{{ sumPayload.onlineSales }}</td>
            <td class="vtl-tbody-td">{{ sumPayload.instoreSales }}</td>
          </tr>
        </template>

        <!-- data -->
        <template v-slot:date="data">
          <span v-if="data.value.date">{{readableDate(data.value.date)}}</span>
        </template>
        <template v-slot:completedDate="data">
            <span v-if="data.value.completedDate">{{canadaTime(data.value.completedDate)}}</span>
        </template>
        <template v-slot:visa="data">
            <span>{{paymentType(data.value)}}</span>
        </template>
        <template v-slot:cashPayment="data">
            <span>{{data.value.cashPayment}}</span>
        </template>
         <template v-slot:amex="data">
            <span>{{data.value.cardPayments.amex}}</span>
        </template>
        <template v-slot:master="data">
            <span>{{data.value.cardPayments.master}}</span>
        </template>
        <template v-slot:vis="data">
            <span>{{data.value.cardPayments.visa}}</span>
        </template>
        <template v-slot:debit="data">
            <span>{{data.value.cardPayments.debit}}</span>
        </template>
        <template v-slot:uber="data">
            <span>{{data.value.cardPayments.uber}}</span>
        </template>
        <template v-slot:gift_card="data">
            <span>{{data.value.cardPayments.gift_card}}</span>
        </template>
        <template v-slot:other="data">
            <span>{{data.value.cardPayments.other}}</span>
        </template>
      </new-table-lite>
    </div>
    <sales-detail v-if="detailPop" :closeClick="closeDetailModal" :item="item" />
  </div>
</template>

<script>
import { defineComponent , ref} from "vue";
import Env from '@/env';
import moment from 'moment-timezone'
import salesDetail from './Detail.vue'
import { mapGetters } from 'vuex'
import NewTableLite from '@/components/table/tableLite.vue'

export default defineComponent({
  components: {
    salesDetail,
    NewTableLite
  },
  props: {
    iscomponentactive: {
      type: Boolean,
      required: false,
    },
    selectedCallback: {
      type: Function,
      required: true,
    },
    clearComponent: {
      type: Function,
      required: true,
    },
    payload: {
      type: Array,
      required: false
    },
    sumPayload: {
      type: Object,
      required: false,
    },
    selectedType: {
      type: String,
      required: false
    }
  },
  setup() {
    const detailPop = ref(false)
    const item = ref({})
    const dynamicColumn = ref([])
    const columnsProduct = ref([
      {
        label: "Location",
        field: "location",
        sortable: false,
     },
     {
        label: "Category",
        field: "category",
        sortable: false,
     },
     {
        label: "Compliance Type",
        field: "complianceType",
        sortable: false,
      },
      {
        label: "Brand",
        field: "brand",
        sortable: false,
      },
      {
        label: 'Licensed Producer',
        field: 'licensedProducer',
        sortable: false,
      },
      {
        isKey:true,
        label: "Product",
        field: "product",
        sortable: false,
        headerClasses:'width'
      },
      {
        label: "Variant",
        field: "productVariationName",
        sortable: false,
        headerClasses:'width'
      },
      {
        label: "SKU",
        field: "sku",
        sortable: false,
      },
      {
        label: "Items Sold",
        field: "itemsSold",
        sortable: false,

      },
      {
        label: "Items Refunded",
        field: "itemsRefunded",
        sortable: false,
      },
      {
        label: "Net Qty Sold",
        field: "netQty",
        sortable: false,
      },
      {
        label: "Gross Sales",
        field: "grossSale",
        sortable: false,
      },
      {
        label: "Net Sales",
        field: "subTotal",
        sortable: false,
      },
      {
        label: "Total Discounts",
        field: "totalDiscount",
        sortable: false,
      },
      {
        label: "Markdown %",
        field: "markdownPercent",
        sortable: false,
      },
      {
        label: "Reward Tiers",
        field: "rewardRedemption",
        sortable: false,
      },
      {
        label: "Total Tax",
        field: "totalTax",
        sortable: false,
      },
      {
        label: "Cost of Goods Sold",
        field: "totalCost",
        sortable: false,
      },
      {
        label: "Gross Profit",
        field: "grossProfit",
        sortable: false,
      },
      {
        label: "Avg Retail Price",
        field: "averageRetailPrice",
        sortable: false,
      },
      {
        label: "Gross Margin",
        field: "grossMargin",
        sortable: false,
      }
    ]);

    const columnsOrder = ref([
      {
        label: "Location",
        field: "location",
        sortable: false,
      },
      {
        isKey:true,
        label: "Order ID",
        field: "orderNumber",
        isKey:true,
        sortable: false,
        headerClasses:'width'
      },
      {
        label: "Date & Time",
        field: "completedDate",
        sortable: false,
      },
      {
        label: "Order Status",
        field: "type",
        sortable: false,
      },
      {
        label: "POS Station",
        field: "posStationName",
        sortable: false,
      },
      {
        label: "Delivery",
        field: "delivery",
        sortable: false,
      },
      {
        label: "Online/In-Store",
        field: "orderReceiveMethod",
        sortable: false,
      },
      {
        label: "Platform",
        field: "platform",
        sortable: false,
      },
      {
        label: "Total Receipts",
        field: "totalInvoice",
        sortable: false,
      },
      {
        label: "Gross Sale",
        field: "grossSale",
        sortable: false,
      },
      {
        label: "Net Sales",
        field: "subTotal",
        sortable: false,
      },
      {
        label: "Total Discounts",
        field: "totalDiscount",
        sortable: false,
      },
      {
        label: "Discount Reason",
        field: "discountReason",
        sortable: false,
      },
      {
        label: "Total Membership Redemptions",
        field: "totalMembershipRedemptions",
        sortable: false,
      },
      {
        label: "Reward Tiers",
        field: "rewardRedemption",
        sortable: false,
      },
      {
        label: "Total Tax",
        field: "totalTax",
        sortable: false,
      },
      {
        label: "Total Fees",
        field: "totalFees",
        sortable: false,
      },
      {
        label: "Total Tips",
        field: "totalTips",
        sortable: false,
      },
      {
        label: "Cost of Goods Sold",
        field: "totalCost",
        sortable: false,
      },
      {
        label: "Gross Profit",
        field: "grossProfit",
        sortable: false,
      },
      {
        label: "Gross Margin",
        field: "grossMargin",
        sortable: false,
      },
      {
        label: "Tendered By",
        field: "tenderedBy",
        sortable: false,
      },
      {
        label: "Created By",
        field: "createdBy",
        sortable: false,
      },
      {
        label: "Customer",
        field: "customer",
        sortable: false,
      },
      {
        label: "Payment Type",
        field: "visa",
        sortable: false,
      }
    ]);
    const columnLocation = ([])
    const copyColumnLocation = ([
      {
        isKey:true,
        label: "Location",
        field: "locationName",
        sortable: false,
        headerClasses:'width'
      },
      {
        label: "Orders Sold",
        field: "numOfOrders",
        sortable: false,
      },
      {
        label: "Orders Refunded",
        field: "numOfRefOrders",
        sortable: false,
      },
      {
        label: "Net Orders Sold",
        field: "netSoldOrders",
        sortable: false,
      },
      {
        label: "Total Receipts",
        field: "totalInvoice",
        sortable: false,
      },
      {
        label: "Gross Sales",
        field: "grossSales",
        sortable: false,
      },
      {
        label: "Net Sales",
        field: "netSales",
        sortable: false,
      },
      {
        label: "Total Discount",
        field: "totalDiscount",
        sortable: false,
      },
      {
        label: "Discount Markdown %",
        field: "discountMarkdown",
        sortable: false,
      },
      {
        label: "Total Membership Redemptions",
        field: "totalLoyalityRedemption",
        sortable: false,
      },
      {
        label: "Reward Tiers",
        field: "rewardRedemption",
        sortable: false,
      },
      {
        label: "Refunds in $",
        field: "totalRefundAmount",
        sortable: false,
      },
      {
        label: "Total Tax",
        field: "totalTax",
        sortable: false,
      },
      {
        label: "Total Fees",
        field: "totalFees",
        sortable: false,
      },
      {
        label: "Total Tips",
        field: "totalTips",
        sortable: false,
      },
      {
        label: "Cost of Goods Sold",
        field: "totalCost",
        sortable: false,
      },
      {
        label: "Gross Profit",
        field: "grossProfit",
        sortable: false,
      },
      {
        label: "Gross Margin",
        field: "grossMargin",
        sortable: false,
      },
      {
        label: "Items Sold",
        field: "totalProductSold",
        sortable: false,
      },
      {
        label: "# of Membership Redemptions",
        field: "numOfLoyalityRedeem",
        sortable: false,
      },
      {
        label: "Avg Items Sold Per Order",
        field: "avgItemPerTrans",
        sortable: false,
      },
      {
        label: "Avg item qty per Order",
        field: "avgItemQtyPerTrans",
        sortable: false,
      },
      {
        label: "Avg Order $",
        field: "avgTransaction",
        sortable: false,
      },
      {
        label: 'Cash',
        field: "cashPayment",
        sortable: false,
      },
      {
        label: 'Amex',
        field: "amex",
        sortable: false,
      },
      {
        label: 'Mastercard',
        field: "master",
        sortable: false,
      },
      {
        label: 'Visa',
        field: "vis",
        sortable: false,
      },
      {
        label: 'Debit',
        field: "debit",
        sortable: false,
      },
      {
        label: 'Uber',
        field: "uber",
        sortable: false,
      },
      {
        label: 'Gift card',
        field: "gift_card",
        sortable: false,
      },
      {
        label: 'Other',
        field: "other",
        sortable: false,
      },
      {
        label: "Online sales $",
        field: "onlineSales",
        sortable: false,
      },
      {
        label: "In-store sales $",
        field: "instoreSales",
        sortable: false,
      },
    ])

    const columnDay = ([
      {
        isKey:true,
        label: "Date",
        field: "date",
        sortable: false,
        headerClasses:'width'
      },
      {
        label: "Location",
        field: "locationName",
        sortable: false,
        headerClasses:'width'
      },
      {
        label: "Orders Sold",
        field: "numOfOrders",
        sortable: false,
      },
      {
        label: "Orders Refunded",
        field: "numOfRefOrders",
        sortable: false,
      },
      {
        label: "Net Orders Sold",
        field: "netSoldOrders",
        sortable: false,
      },
      {
        label: "Total Receipts",
        field: "totalInvoice",
        sortable: false,
      },
      {
        label: "Gross Sales",
        field: "grossSales",
        sortable: false,
      },
      {
        label: "Net Sales",
        field: "netSales",
        sortable: false,
      },
      {
        label: "Total Discount",
        field: "totalDiscount",
        sortable: false,
      },
      {
        label: "Discount Markdown %",
        field: "discountMarkdown",
        sortable: false,
      },
      {
        label: "Total Membership Redemptions",
        field: "totalLoyalityRedemption",
        sortable: false,
      },
      {
        label: "Reward Tiers",
        field: "rewardRedemption",
        sortable: false,
      },
      {
        label: "Refunds in $",
        field: "totalRefundAmount",
        sortable: false,
      },
      {
        label: "Total Tax",
        field: "totalTax",
        sortable: false,
      },
      {
        label: "Total Fees",
        field: "totalFees",
        sortable: false,
      },
      {
        label: "Total Tips",
        field: "totalTips",
        sortable: false,
      },
      {
        label: "Cost of Goods Sold",
        field: "totalCost",
        sortable: false,
      },
      {
        label: "Gross Profit",
        field: "grossProfit",
        sortable: false,
      },
      {
        label: "Gross Margin",
        field: "grossMargin",
        sortable: false,
      },
      {
        label: "Items Sold",
        field: "totalProductSold",
        sortable: false,
      },
      {
        label: "# of Membership Redemptions",
        field: "numOfLoyalityRedeem",
        sortable: false,
      },
      {
        label: "Avg Items Sold Per Order",
        field: "avgItemPerTrans",
        sortable: false,
      },
      {
        label: "Avg item qty per Order",
        field: "avgItemQtyPerTrans",
        sortable: false,
      },
      {
        label: "Avg Order $",
        field: "avgTransaction",
        sortable: false,
      },
      {
        label: "Online sales $",
        field: "onlineSales",
        sortable: false,
      },
      {
        label: "In-store sales $",
        field: "instoreSales",
        sortable: false,
      },
    ])

    const table = ref({
      isLoading: false,
      rows: [],
      totalRecordCount: 0,
      sortable: {
        order: "name",
        sort: "asc",
      },
      pagesize: Env.defaultPageSize,
      pageOptions: Env.pageList,
      count: 0,
      page: 1,
      
    });
    
    return  {
      table,
      columnsProduct,
      columnsOrder,
      columnLocation,
      copyColumnLocation,
      columnDay,
      item,
      detailPop,
      dynamicColumn
    };
  },

  async mounted() {
     this.load()
     this.emitter.on('reloadSalesList', this.reload)
  },
  beforeDestroy() {
   this.emitter.off('reloadSalesList', this.reload)
  },
  computed: {
        ...mapGetters({
            vg_selectedLocation: 'location/selected'
        })
  },
  methods: {
    reload(){
      this.$nextTick(() => {
          this.load()
      })
    },
    selectColumnType(){
      if(this.selectedType==='product'){
        return this.columnsProduct
      }else if(this.selectedType==='order'){
        return this.columnsOrder
      }else if(this.selectedType==='location'){
        return this.columnLocation
      }else{
        return this.columnDay
      }
    },
    async load(){
      let tempRows = this.payload
      if(this.selectedType==='location'){
        this.columnLocation = [...this.copyColumnLocation]
        this.table.isLoading = true
        this.dynamicColumn = await this.getAllTaxes(this.payload)
        // add column in table column
        for(let taxCol of this.dynamicColumn){
          this.columnLocation.push({
            label: taxCol,
            field: taxCol,
            sortable: false,
          })
        }

        // add tax column for all records
        for(let i=0; i<this.payload.length; i++){
          for(let tax of this.dynamicColumn){
            tempRows[i][tax] = this.payload[i].totalTaxByCode[tax]?this.payload[i].totalTaxByCode[tax]:0
          }
        }
      }
      this.table.rows = tempRows
      this.table.totalRecordCount = tempRows.length
      this.table.isLoading = false
    },
    async getAllTaxes(payload){
      let dynamicColumn = []
      for(let i=0; i<payload.length; i++){
        for(let taxKey of Object.keys(payload[i].totalTaxByCode)){
          if(!dynamicColumn.includes(taxKey)){
            dynamicColumn.push(taxKey)
          }
        }
      }
      return dynamicColumn
    },
    column(){
      if(this.selectedType==='product'){
        return this.columnsProduct
      }else{
        return this.columnsOrder
      }
    },
    canadaTime(data){
        if(!data) return
        let defaultTimeZone = this.vg_selectedLocation.timezone?this.vg_selectedLocation.timezone:'America/Toronto';
        return moment(data).tz(defaultTimeZone).format('YYYY-MM-DD h:mm a');
    },
    readableDate(data){
      if(!data) return
      return moment(data).format('YYYY-MM-DD');
    },
    paymentType(data){
      if(data.amex){
        return 'Amex'
      }else if(data.visa){
        return 'Visa'
      }else if(data.mastercard){
        return 'Mastercard'
      }else if(data.debit){
        return 'Debit'
      }else if(data.cash){
        return 'Cash'
      }else if(data.otherCard){
        return 'Other'
      }else{
        return ''
      }
    },
    setActive(item){
      if(item.orderNumber){
        let activeRow = document.getElementsByClassName('id-'+item.orderNumber);
        if(activeRow[0] && activeRow[0].parentNode && activeRow[0].parentNode.children){
          for(let sibling of activeRow[0].parentNode.children){
              sibling.classList.remove('active-row')
          }
        }
        activeRow[0].classList.add("active-row")

        this.item = item
        this.openDetailModal()
      }
    },
    openDetailModal() {
      this.detailPop = true
    },
    closeDetailModal() {
      this.detailPop = false
    },
  }

});
</script>


import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "custom bg-[#23242A]" }
const _hoisted_2 = { class: "flex xl:flex-row flex-col px-4 pt-4 bg-[#23242A] text-white" }
const _hoisted_3 = {
  key: 0,
  class: "xl:w-[60%] w-full custom-right transition-all duration-100 ease-linear"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PurchaseOrderAddList = _resolveComponent("PurchaseOrderAddList")!
  const _component_edit_product = _resolveComponent("edit-product")!
  const _component_grey_product = _resolveComponent("grey-product")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["transition-all duration-100 ease-linear custom-left mr-2", (_ctx.add || _ctx.edit || _ctx.grey)?'xl:w-[50%] w-full':'w-full'])
      }, [
        _createVNode(_component_PurchaseOrderAddList, {
          onToggleAddEdit: _ctx.toggleAddEdit,
          split: _ctx.add || _ctx.edit || _ctx.grey
        }, null, 8, ["onToggleAddEdit", "split"])
      ], 2),
      (_ctx.add || _ctx.edit || _ctx.grey)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_ctx.edit)
              ? (_openBlock(), _createBlock(_component_edit_product, {
                  key: 0,
                  asn: _ctx.asn,
                  editItem: _ctx.editItem,
                  fulfillment: _ctx.fulfillment,
                  onAdded: _ctx.addedRow,
                  onClose: _ctx.closeEdit,
                  onToggleAddEdit: _ctx.toggleAddEdit
                }, null, 8, ["asn", "editItem", "fulfillment", "onAdded", "onClose", "onToggleAddEdit"]))
              : _createCommentVNode("", true),
            (_ctx.add || _ctx.grey)
              ? (_openBlock(), _createBlock(_component_grey_product, {
                  key: 1,
                  asn: _ctx.asn,
                  editItem: _ctx.editItem,
                  fulfillment: _ctx.fulfillment,
                  onAdded: _ctx.addedRow,
                  onClose: _ctx.closeGrey,
                  onToggleAddEdit: _ctx.toggleAddEdit
                }, null, 8, ["asn", "editItem", "fulfillment", "onAdded", "onClose", "onToggleAddEdit"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
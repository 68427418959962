<template>
    <tr class='h-[52px]' :class="showVariantName?' border-t border-[#464851]':''">
        <td></td>
        <td></td>
        <td></td>
        <td class="flex flex-row gap-2 font-normal h-full text-sm leading-[17.5px]">
            <div class="flex flex-grow h-[50px] p-1 items-center border-r border-[#464851]">
                <div>
                    <span>{{product.variantName}}</span>
                </div>
            </div>
        </td>
        <td> 
            <div v-if="batch" class="py-1 px-2 flex flex-row">
                <span class="font-normal text-sm leading-[17.5px]">{{batch.batchName}}</span>
            </div>
            <div v-else class="py-1 px-2 flex flex-row">
                <span class="text-[#9E9E9E]">Lot is disabled</span>
            </div>
        </td>
        <td class="font-normal text-sm leading-[17.5px] ">
            <div>{{batch?batch.transferQty:product.transferQty}}</div>
        </td>
        <!-- <td v-if="status!=='shipped transfer'" class="font-normal text-sm leading-[17.5px]"> -->
            <!-- :disabled="$route.query.status==='incomplete'" -->
            <!-- <input disabled maxlength="4" :onkeypress="preventNonNumbers" v-model="value" v-on:keyup.enter="updateProductBatchValue(product, batch, this.value)" @change="updateProductBatchValue(product, batch, this.value)" class="w-[60px] border border-[#9E9E9E] p-2 text-[#9E9E9E] bg-[#2B2C32] rounded focus:ring-0 focus:outline-0" />
        </td> -->
        <!-- <td v-if="status!=='shipped transfer'" :class="diff!=0?'text-red-500':''">{{diff>0?'+':''}}{{diff}}</td> -->
    </tr>
</template>
<script>
import { defineComponent, ref } from 'vue'
import debounce from 'lodash/debounce'

export default defineComponent({
    props: ['status', 'showVariantName', 'product', 'variant', 'batch', 'item'],
    setup() {
        const value =ref(0)
        const diff = ref(0)
        const error =ref(false)
        return {value,diff, error}

    },
    mounted(){
        this.value = this.batch?this.batch.transferQty:(this.product.transferQty?this.product.transferQty:0)
    },
    watch: {
        value: debounce(function(){
            let limit = this.batch?this.batch.transferQty:(this.product.transferQty?this.product.transferQty:0)
            this.error = parseInt(limit)<parseInt(this.value)
        }, 400)
    },
    methods: {
        preventNonNumbers(evt) {
            var ASCIICode = (evt.which) ? evt.which : evt.keyCode
            if((ASCIICode>=48 && ASCIICode<=57)){
                return true;
            }
            return false
        },
        updateProductBatchValue(product, batch, value){
            let ind = this.item.findIndex(item => item.transferProductId === product.id)
            if(ind>=0){
                let sumQty = 0
                if(batch){
                    let batchInd = this.item[ind].batches.findIndex(item => item.transferBatchId === batch.id)
                    this.item[ind].batches[batchInd].actualTransferQty = value?parseInt(value):''
                    this.value = value?parseInt(value):''
                    this.diff = this.value-this.item[ind].batches[batchInd].transferQty
                    for(let i=0; i<this.item[ind].batches.length; i++){
                        sumQty += this.item[ind].batches[i].actualTransferQty
                    }
                    this.item[ind].actualTransferQty = sumQty
                }else{
                    this.item[ind].actualTransferQty = value?parseInt(value):''
                    this.value = value?parseInt(value):''
                    this.diff = this.value-this.item[ind].transferQty
                }
            }
        },
    }
})
</script>

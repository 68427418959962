/**
 * @module business 
 *  Apis must need auth details to trigger url - AuthApi
 */

 import store from '@/store/index';
 import ProductApis from '@/controllers/ProductApis';
 import ApiHelper from '@/controllers/ApiHelpers';
 import router from '@/router';


 export default {
     /**
      * Fetch all products with location
      */
     fetchAllLocationProduct(params) {
        const location = store.getters['location/selected']
        const url = `/product/location/${location.id}${params?'?'+params:''}`;
        return ProductApis.getApi(url, null)
          .then(res => res)
          .catch(err => {
            ApiHelper.detectStatusAndRedirect(err);
            return err;
          });
     },

      /**
      * Fetch all products
      */
      fetchAllProduct(params) {
        const business = store.getters['auth/activeBusiness']
        const location = store.getters['location/selected']
        let url = ''
        if(location.id){
          url = `/product/admin/location/${location.id}${params?'?'+params:''}`;
        }else{
          url = `/product/forBusiness/${business.id}${params?'?'+params:''}`;
        }
        return ProductApis.getApi(url, null)
          .then(res => res)
          .catch(err => {
            ApiHelper.detectStatusAndRedirect(err);
            return err;
          });
     },

     /**
      * Fetch all masteer products
      */
     fetchAllMasterProduct(params) {
      let url = `/master-products${params?'?'+params:''}`
      return ProductApis.getApi(url, null)
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
        });
     },

     updateMasterProduct(productId, data) {
      let userToken = store.getters['auth/userToken'];
      let url = `/master-products/${productId}`
      return ProductApis.patchApi(url, {
        'Authorization': 'Bearer '+ userToken
      }, data)
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
        });
     },

     importOcsToMasterProduct(data){
      let userToken = store.getters['auth/userToken'];
      const url = `/master-products/importOcsProducts`;
      return ProductApis.postApi(url, {
        'Authorization': 'Bearer '+ userToken
      }, data)
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
        });
    },

    importAlbertaCatalogue(data){
      let userToken = store.getters['auth/userToken'];
      const url = `/master-products/albertaCatalogueImport`;
      return ProductApis.postApi(url, {
        'Authorization': 'Bearer '+ userToken
      }, data)
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
        });
    },

    fetchAllNoLocationProduct(params){
      const business = store.getters['auth/activeBusiness']
      let url = `/product/forBusiness/${business.id}${params?'?'+params:''}`;
      return ProductApis.getApi(url, null)
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
        });
    },


     /**
      * Fetch all products category
      */
      fetchAllCategory() {
        const business = store.getters['auth/activeBusiness']
        const url = `/category/forBusiness/${business.id}?page=1&perPage=100`;
        return ProductApis.getApi(url, null)
          .then(res => res)
          .catch(err => {
            ApiHelper.detectStatusAndRedirect(err);
            return err;
          });
     },

     /**
      * Fetch all products brands
      */
      fetchAllBrands(locationId) {
        const url = `/brand?isPagination=false${locationId?'&locationId='+locationId:''}`;
        return ProductApis.getApi(url, null)
          .then(res => res)
          .catch(err => {
            ApiHelper.detectStatusAndRedirect(err);
            return err;
          });
      },

    getMasterProductByGtin(gtin){
      const business = store.getters['auth/activeBusiness']
      const url = `/master-products?page1&perPage=1&search=${gtin}`;
      return ProductApis.getApi(url, null)
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
        });
    },

    getLicensedProducer(search){
      const business = store.getters['auth/activeBusiness']
      const url = `/product/licensedProducer/suggestion?&search=${search}`;
      return ProductApis.getApi(url, null)
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
        });
    },

      /**
      * Fetch products by id
      */
     getProductById(productId, locationId) {
      const business = store.getters['auth/activeBusiness']
      let url = `/product/${productId}?includeVariants=true`;
      if(locationId){
        url += `&locationId=${locationId}`
      }
      return ProductApis.getApi(url, null)
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
        });
    },

    /**
      * Get cannabis profile of specific product
      */
      fetchCannabisProfile(productId, locationId){
        const url = `product/${productId}/cannabisProfile?locationId=${locationId}`;
        return ProductApis.getApi(url, {})
          .then(res => res)
          .catch(err => {
            ApiHelper.detectStatusAndRedirect(err);
            return err;
          });
      },

      /**
      * Get pricing of specific product
      */
      fetchPricingByProductId(productAndLocationId){
        const url = `/products-pricing/getBy`;
        return ProductApis.postApi(url, {}, productAndLocationId)
          .then(res => res)
          .catch(err => {
            ApiHelper.detectStatusAndRedirect(err);
            return err;
          });
      },

      fetchLotByProductId(data){
        const url = `/batch-upload-detail/getBy?perPage=100&page=1`;
        return ProductApis.postApi(url, {}, data)
          .then(res => res)
          .catch(err => {
            ApiHelper.detectStatusAndRedirect(err);
            return err;
          });
      },

      /**
      * Fetch all taxes
      */
       fetchAllTaxes(businessId) {
        const url = `/location/tax/forBusiness/${businessId}`;
        return ProductApis.getApi(url, null)
          .then(res => res)
          .catch(err => {
            ApiHelper.detectStatusAndRedirect(err);
            return err;
          });
      },

      fetchTaxByProductId(data){
        const url = `/location/tax/getTaxProductLocation`;
        return ProductApis.postApi(url, null, data)
          .then(res => res)
          .catch(err => {
            ApiHelper.detectStatusAndRedirect(err);
            return err;
        });
      },

      fetchFeaturedCategory(productId){
        const location = store.getters['location/selected']
        if(!location.id) return 

        const url = `category/${productId}/featuredcategory/allCategories?locationId=${location.id}`;
        return ProductApis.getApi(url, null)
          .then(res => res)
          .catch(err => {
            ApiHelper.detectStatusAndRedirect(err);
            return err;
        });
      },

      /**
      * Create product
      */
      createProduct(data){
        let userToken = store.getters['auth/userToken'];
        const url = `/product`;
        return ProductApis.postApi(url, {
            'Authorization': 'Bearer '+ userToken
        }, data)
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
        });
      },

      addProductToCategoryAndFeaturedCategory(data){
        let userToken = store.getters['auth/userToken'];
        const url = `/product/updateCategory`;
        return ProductApis.postApi(url, {
          'Authorization': 'Bearer '+ userToken
        }, data)
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
        });
      },

      /**
       * Add Featured category
       */
      setFeaturedCategory(productId, data){
        let userToken = store.getters['auth/userToken'];
        const url = `/category/${productId}/featuredCategory/syncCategories`;
        return ProductApis.postApi(url, {
          'Authorization': 'Bearer '+ userToken
        }, data)
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
        });
      },

       /**
        * Create product profile
        */
        createCannabisProfile(productId, data){
          let userToken = store.getters['auth/userToken'];
          const url = `/product/${productId}/cannabisProfile`;
          return ProductApis.putApi(url, {
              'Authorization': 'Bearer '+ userToken
          }, data)
          .then(res => res)
          .catch(err => {
            ApiHelper.detectStatusAndRedirect(err);
            return err;
          });
        },

      /**
      * Create product pricing
      */
       createProductPricing(data){
        let userToken = store.getters['auth/userToken'];
        const url = `/products-pricing`;
        return ProductApis.postApi(url, {
            'Authorization': 'Bearer '+ userToken
        }, data)
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
        });
      },

      /**
      * Create product batch
      */
       createProductBatch(data){
        let userToken = store.getters['auth/userToken'];
        const url = `/batch-upload-detail`;
        return ProductApis.postApi(url, {
            'Authorization': 'Bearer '+ userToken
        }, data)
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
        });
      },

      /**
      * Create product batch
      */
      createBulkProductBatch(data){
        let userToken = store.getters['auth/userToken'];
        const url = `/batch-upload-detail/bulkCreate`;
        return ProductApis.postApi(url, {
            'Authorization': 'Bearer '+ userToken
        }, data)
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
        });
      },

      /**
      * Update product
      */
      editProduct(productId, data){
        let userToken = store.getters['auth/userToken'];
        const url = `/product/${productId}`;
        return ProductApis.putApi(url, {
            'Authorization': 'Bearer '+ userToken
        }, data)
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
        });
      },

     /**
      * Create new product from asn upload
      */
     editProductProfileCostBatch(data){
      let userToken = store.getters['auth/userToken'];
      const url = `/product/detailsUpsert`;
      return ProductApis.postApi(url, {
          'Authorization': 'Bearer '+ userToken
      }, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
    },

    /**
      * Create new product from asn upload
      */
    mergePurchaseOrder(itemId, data){
      let userToken = store.getters['auth/userToken'];
      const url = `/purchase-orders/merge/${itemId}`;
      return ProductApis.putApi(url, {
          'Authorization': 'Bearer '+ userToken
      }, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
    },

    /**
      * Update cannabis profile
      */
     updateCannabisProfile(productId, data){
      let userToken = store.getters['auth/userToken'];
          const url = `/product/${productId}/cannabisProfile`;
          return ProductApis.putApi(url, {
              'Authorization': 'Bearer '+ userToken
          }, data)
          .then(res => res)
          .catch(err => {
            ApiHelper.detectStatusAndRedirect(err);
            return err;
          });
    },

    /**
      * Update product pricing
      */
     updateProductPricing(pricingId, data){
      let userToken = store.getters['auth/userToken'];
      const url = `/products-pricing/${pricingId}`;
      return ProductApis.patchApi(url, {
          'Authorization': 'Bearer '+ userToken
      }, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
    },


    /**
      * Update product pricing
      */
     updateProductLot(lotId, data){
      let userToken = store.getters['auth/userToken'];
      const url = `/batch-upload-detail/${lotId}`;
      return ProductApis.patchApi(url, {
          'Authorization': 'Bearer '+ userToken
      }, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
    },

    addProductTax(data){
      let userToken = store.getters['auth/userToken'];
      const url = `/location/addProductLocationTax`;
      return ProductApis.postApi(url, {
          'Authorization': 'Bearer '+ userToken
      }, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
    },

    removeProductTax(data){
      let userToken = store.getters['auth/userToken'];
      const url = `/location/removeProductLocationTax`;
      return ProductApis.deleteApi(url, {
          'Authorization': 'Bearer '+ userToken
      }, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
    },


    addBulkProductTax(data){
      let userToken = store.getters['auth/userToken'];
      const url = `/location/multiTaxes/addProductLocationTax`;
      return ProductApis.postApi(url, {
          'Authorization': 'Bearer '+ userToken
      }, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
    },

    removeBulkProductTax(data){
      let userToken = store.getters['auth/userToken'];
      const url = `/location/multiTaxes/removeProductLocationTax`;
      return ProductApis.deleteApi(url, {
          'Authorization': 'Bearer '+ userToken
      }, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
    },

    deleteProduct(productId){
      let userToken = store.getters['auth/userToken'];
      const url = `/product/${productId}`;
      return ProductApis.deleteApi(url, {
          'Authorization': 'Bearer '+ userToken
      })
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
    },

    mergeProductAssigningVariant(productId, data){
      let userToken = store.getters['auth/userToken'];
      const url = `/product/assignVarient/${productId}`;
      return ProductApis.postApi(url, {
          'Authorization': 'Bearer '+ userToken
      }, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
    },

    // product image
    // get image
    getProductImage(productId){
      let userToken = store.getters['auth/userToken'];
      const url = `/product-images/ForProduct/${productId}`;
      return ProductApis.getApi(url, {
          'Authorization': 'Bearer '+ userToken
      })
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
    },

    // create image
    createProductImage(data){
      let userToken = store.getters['auth/userToken'];
      const url = `/product-images`;
      return ProductApis.postApi(url, {
          'Authorization': 'Bearer '+ userToken
      }, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
    },

    updateProductImage(imageId, data){
      let userToken = store.getters['auth/userToken'];
      const url = `/product-images/${imageId}`;
      return ProductApis.putApi(url, {
          'Authorization': 'Bearer '+ userToken
      }, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
    },

    removeProductImage(imageId){
      let userToken = store.getters['auth/userToken'];
      const url = `/product-images/${imageId}`;
      return ProductApis.deleteApi(url, {
          'Authorization': 'Bearer '+ userToken
      })
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
    },

    sortProductImage(data){
      let userToken = store.getters['auth/userToken'];
      const url = `/product-images/sort/updateSortOrder`;
      return ProductApis.putApi(url, {
          'Authorization': 'Bearer '+ userToken
      }, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
    },

    // Terpenes
    fetchAllTerpene(){
      const url = `/terpenes?page=1&perPage=100`;
      return ProductApis.getApi(url, {})
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
        });
    },

    fetchTerpeneByProductId(productId){
      const url = `/Product/${productId}/getTerpenes/all`;
      return ProductApis.getApi(url, {})
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
        });
    },

    addTerpene(productId, data){
      let userToken = store.getters['auth/userToken'];
      const url = `/Product/${productId}/syncTerpenes`;
      return ProductApis.postApi(url, {
          'Authorization': 'Bearer '+ userToken
      }, data)
      .then(res => res)
      .catch(err => {
        ApiHelper.detectStatusAndRedirect(err);
        return err;
      });
    },

    removeTerpene(productId, data){
      let userToken = store.getters['auth/userToken'];
      const url = `Product/${productId}/removeTerpenes`;
      return ProductApis.postApi(url, {
        'Authorization': 'Bearer '+ userToken
      }, data)
       .then(res => res)
       .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
       });
    },



    // Aromas
    fetchAllAromas(){
      const url = `/aromas?page=1&perPage=100`;
      return ProductApis.getApi(url, {})
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
        });
    },

    fetchAromasByProductId(productId){
      const url = `/Product/${productId}/getAromas/all`;
      return ProductApis.getApi(url, {})
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
        });
    },

    addAromas(productId, data){
      let userToken = store.getters['auth/userToken'];
      const url = `/Product/${productId}/syncAromas`;
      return ProductApis.postApi(url, {
          'Authorization': 'Bearer '+ userToken
      }, data)
      .then(res => res)
      .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
      });
    },

    removeAroma(productId, data){
      let userToken = store.getters['auth/userToken'];
      const url = `Product/${productId}/removeAromas`;
      return ProductApis.postApi(url, {
        'Authorization': 'Bearer '+ userToken
      }, data)
       .then(res => res)
       .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
         return err;
       });
    },


    // inventory
    listInventory(param, data){
      let userToken = store.getters['auth/userToken'];
      const url = `/inventory-adjustments/list?page=1&perPage=100${param}`;
      return ProductApis.postApi(url, {
          'Authorization': 'Bearer '+ userToken
      }, data)
      .then(res => res)
      .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
      });
    },

    addInventory(data){
      let userToken = store.getters['auth/userToken'];
      const url = `/inventory-adjustments`;
      return ProductApis.postApi(url, {
          'Authorization': 'Bearer '+ userToken
      }, data)
      .then(res => res)
      .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
      });
    },

    bulkAddInventory(data){
      let userToken = store.getters['auth/userToken'];
      const url = `/inventory-adjustments/bulkCreate`;
      return ProductApis.postApi(url, {
          'Authorization': 'Bearer '+ userToken
      }, data)
      .then(res => res)
      .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
      });
    },

    // update 
    updateInventory(inventoryId, data){
      let userToken = store.getters['auth/userToken'];
      const url = `/inventory-adjustments/${inventoryId}`;
      return ProductApis.patchApi(url, {
          'Authorization': 'Bearer '+ userToken
      }, data)
      .then(res => res)
      .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
      });
    },

    postInventory(locationId){
      let userToken = store.getters['auth/userToken'];
      const url = `/product/postInventoryPosition/${locationId}`;
      return ProductApis.postApi(url, {
          'Authorization': 'Bearer '+ userToken
      }, null)
      .then(res => res)
      .catch(err => {
          // ApiHelper.detectStatusAndRedirect(err);
          return err;
      });
    },

    // bulk upload
    purchaseOrderUploadV1(data){
      let userToken = store.getters['auth/userToken'];
      const url = `/product/bulkUpload`;
      return ProductApis.postApi(url, {
          "Content-Type": "multipart/form-data",
          'Authorization': 'Bearer '+ userToken
      }, data)
      .then(res => res)
      .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
      });
    },

    purchaseOrderUpload(data){
      let userToken = store.getters['auth/userToken'];
      const url = `/purchase-orders/upload`;
      return ProductApis.postApi(url, {
          "Content-Type": "multipart/form-data",
          'Authorization': 'Bearer '+ userToken
      }, data)
      .then(res => res)
      .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
      });
    },

    updatePurchaseOrderById(id, data){
      let userToken = store.getters['auth/userToken'];
      const url = `/purchase-orders/${id}`;
      return ProductApis.putApi(url, {
          'Authorization': 'Bearer '+ userToken
      }, data)
      .then(res => res)
      .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
      });
    },

    updateProductOrderItems(data){
      let userToken = store.getters['auth/userToken'];
      const url = `/purchase-orders/items/update`;
      return ProductApis.putApi(url, {
          'Authorization': 'Bearer '+ userToken
      }, data)
      .then(res => res)
      .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
      });
    },

    addOrUpdatePurchaseOrderItem(data){
      let userToken = store.getters['auth/userToken'];
      const url = `/purchase-orders/product/upsert`;
      return ProductApis.postApi(url, {
          'Authorization': 'Bearer '+ userToken
      }, data)
      .then(res => res)
      .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
      });
    },

    budlerUpload(data){
      let userToken = store.getters['auth/userToken'];
      const url = `/product/importTemplateProducts`;
      return ProductApis.postApi(url, {
          "Content-Type": "multipart/form-data",
          'Authorization': 'Bearer '+ userToken
      }, data)
      .then(res => res)
      .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
      });
    },

    importUploadTendyUpload(data){
      let userToken = store.getters['auth/userToken'];
      const url = `/product/import/updateTendyProducts`;
      return ProductApis.postApi(url, {
          "Content-Type": "multipart/form-data",
          'Authorization': 'Bearer '+ userToken
      }, data)
      .then(res => res)
      .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
      });
    },

    getPurchaseOrderById(orderId){
      let userToken = store.getters['auth/userToken'];
      const url = `/purchase-orders/${orderId}`;
      return ProductApis.getApi(url, {
        'Authorization': 'Bearer '+ userToken
      })
        .then(res => res)
        .catch(err => {
          console.log(err)
          // ApiHelper.detectStatusAndRedirect(err);
          return err;
        });
    },

    // fulfillment
    getFulfillmentByProductId(productId){
      let userToken = store.getters['auth/userToken'];
      const url = `/product-fullfillment-time/${productId}`;
      return ProductApis.getApi(url, {
        'Authorization': 'Bearer '+ userToken
      })
        .then(res => res)
        .catch(err => {
          console.log(err)
          // ApiHelper.detectStatusAndRedirect(err);
          return err;
        });
    },

    bulkUpdateFulfillment(data){
      let userToken = store.getters['auth/userToken'];
      const url = `/product-fullfillment-time/bulkUpsert`;
      return ProductApis.postApi(url, {
          'Authorization': 'Bearer '+ userToken
      }, data)
      .then(res => res)
      .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
      });
    },

    generateReport(locationId, data){
      let userToken = store.getters['auth/userToken'];
      const url = `/product/downloadComplianceReport/${locationId}`;
      return ProductApis.postBlobApi(url, {
          'Authorization': 'Bearer '+ userToken
      }, data)
      .then(res => res)
      .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
      });
    },

    templateExport(data){
      let userToken = store.getters['auth/userToken'];
      const url = `/product/export/templateImport`;
      return ProductApis.postBlobApi(url, {
          'Authorization': 'Bearer '+ userToken
      }, data)
      .then(res => res)
      .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
      });
    },

    archive(id) {
      let userToken = store.getters['auth/userToken'];
      let url = `/batch-upload-detail/archive/${id}`
      return ProductApis.patchApi(url, {
        'Authorization': 'Bearer '+ userToken
      }, null)
        .then(res => res)
        .catch(err => {
          ApiHelper.detectStatusAndRedirect(err);
          return err;
        });
     },

    purchaseOrderList(locationId){
      let userToken = store.getters['auth/userToken'];
      const url = `/batch-upload-detail/groupBy/shipment/${locationId}`;
      return ProductApis.getApi(url, {
        'Authorization': 'Bearer '+ userToken
      })
        .then(res => res)
        .catch(err => {
          console.log(err)
          // ApiHelper.detectStatusAndRedirect(err);
          return err;
        });
    },

    purchaseOrderListV2(locationId){
      let userToken = store.getters['auth/userToken'];
      const url = `/purchase-orders/list?locationId=${locationId}`;
      return ProductApis.getApi(url, {
        'Authorization': 'Bearer '+ userToken
      })
        .then(res => res)
        .catch(err => {
          console.log(err)
          // ApiHelper.detectStatusAndRedirect(err);
          return err;
        });
    },

    confirmPurchaseOrder(purchaseOrderId){
      let userToken = store.getters['auth/userToken'];
      const url = `/purchase-orders/confirmInventory/${purchaseOrderId}`;
      return ProductApis.putApi(url, {
        'Authorization': 'Bearer '+ userToken
      }, null)
        .then(res => res)
        .catch(err => {
          console.log(err)
          // ApiHelper.detectStatusAndRedirect(err);
          return err;
        });
    }
}
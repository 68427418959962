<template>
    <div class="flex">
        <div  v-if="redProduct==total || greyProduct==total || greenProduct==total || yellowProduct==total" style="width:calc(100% - 40px)" class="w-full min-w-[900px] overflow-hidden relative h-[80px]">
            <div class="flex items-center w-[100%] ml-4 py-4">
                <div :style="getTotalColor()" class="w-full pl-[20px] bg-opacity-60 min-w-[8%] w-[0%] font-[700] text-[20px]">{{ total }}</div>
            </div>
        </div>
        <div v-else style="width:calc(100% - 40px)" class="w-full min-w-[900px] overflow-hidden relative h-[80px]" >
            <div @mouseover="info=true" @mouseout="info=false" class="flex items-center w-[100%] ml-4 py-4">
                <div v-if="redProduct>0" :style="getRedStyle()"
                    :class="`relative bg-opacity-60 min-w-[8%] w-[0%] font-[700] text-[20px]`">{{ redProduct }}</div>
                <div v-if="greyProduct>0" :style="getGreyStyle()"
                    :class="`relative bg-opacity-60 min-w-[8%] w-[0%] font-[700] text-[20px]`">{{ greyProduct }}</div>
                <div v-if="yellowProduct>0" :style="getYellowStyle()"
                    :class="`relative bg-opacity-60 min-w-[8%] w-[0%] font-[700] text-[20px]`">{{ yellowProduct }}</div>
                <div v-if="greenProduct>0" :style="getGreenStyle()"
                    :class="`relative bg-opacity-60 min-w-[8%] w-[0%] font-[700] text-[20px]`">{{ greenProduct }}</div>
            </div>
            <div v-if="info" class="absolute top-10 left-[400px] w-[430px] h-[36px] px-[16px] py-[8px] text-[16px] font-[400] rounded-[4px] text-[#1C1D21] flex items-center justify-center bg-[#FCB852]">
                {{ greenProduct+ yellowProduct}} product(s) matched, {{ redProduct+greyProduct }} product(s) need attention.
            </div>
        </div>
        <div class="relative pl-[2px] py-[20px]">/{{ total }}</div>
    </div>
</template>
<script>
import { ref, defineComponent } from 'vue'

export default defineComponent({
    props: ['counts'],
    setup() {
        const info = ref(false)
        const redPercent = ref(null)
        const redProduct = ref(null)
        const greyPercent = ref(null)
        const greyProduct = ref(null)
        const yellowPercent = ref(null)
        const yellowProduct = ref(null)
        const greenPercent = ref(null)
        const greenProduct = ref(null)
        const total = ref(null)

        return {info, redPercent, redProduct, greyPercent, greyProduct, yellowPercent, yellowProduct, greenPercent, greenProduct, total}
    },
    mounted(){
        this.getData()
    },
    watch: {
        counts: {
            handler() {
                this.getData()
            },
            deep: true
        }
    },
    methods: {
        getTotalColor(){
            if(this.redProduct==this.total){
                return 'background-color: #DC3545CC';
            }else if(this.greyProduct==this.total){
                return 'background-color: #87868699';
            }else if(this.yellowProduct==this.total){
                return 'background-color: #FCB85299';
            }else if(this.greenProduct==this.total){
                return 'background-color: #2BA66B99';
            }
        },
        getRedStyle(){
            // red => 1st
            return `padding-left:20px; background:linear-gradient(135deg, #DC3545CC calc(100% - 20px), transparent calc(100% - 20px)); width:${this.redPercent}%`
        },
        getGreyStyle(){
            if(this.redPercent===0){
                // grey = 1st
                return `padding-left:20px; background:linear-gradient(135deg, #87868699 calc(100% - 20px), transparent calc(100% - 20px)); width:${this.greyPercent}%`
            }else if(this.yellowPercent===0 && this.greenPercent===0){
                // grey = last
                return `left:-16px;padding-left:50px;  background:linear-gradient(135deg, transparent 20px, #87868699 20px); width:${this.greyPercent}%;`
            }else{
                // grey = 2nd
                return `left:-16px; padding-left:30px; background:linear-gradient(135deg, transparent 20px, #87868699 20px calc(100% - 20px), transparent calc(100% - 20px)); width:${this.greyPercent}%;`
            }
        },
        getYellowStyle(){
            if(this.redPercent===0 && this.greyPercent===0){
                // yellow = 1st
                return `padding-left:20px; background:linear-gradient(135deg, #FCB85299 calc(100% - 20px), transparent calc(100% - 20px)); width:${this.yellowPercent}%`
            }else if((this.redPercent!==0 && this.greyPercent!==0) && this.greenPercent===0){
                // yellow = last out of 3
                return `left:-32px;padding-left:50px;  background:linear-gradient(135deg, transparent 20px, #FCB85299 20px); width:${this.yellowPercent}%;`
            }else if(this.greenPercent===0){
                // yellow = last out of 2
                return `left:-16px;padding-left:50px;  background:linear-gradient(135deg, transparent 20px, #FCB85299 20px); width:${this.yellowPercent}%;`
            }else if(this.redPercent==0 || this.greyPercent==0){
                // yellow => 3rd but not last
                return `left:-16px; padding-left:30px; background:linear-gradient(135deg, transparent 20px, #FCB85299 20px calc(100% - 20px), transparent calc(100% - 20px)); width:${this.yellowPercent}%;`
            }else{
                // yellow => 3rd
                return `left:-32px; padding-left:30px; background:linear-gradient(135deg, transparent 20px, #FCB85299 20px calc(100% - 20px), transparent calc(100% - 20px)); width:${this.yellowPercent}%;`
            }
        },
        getGreenStyle(){
            if(this.redPercent>0 && this.greyPercent>0 && this.yellowPercent>0){
                // green => last out of 4
                return `left:-48px; padding-left:50px; background:linear-gradient(135deg, transparent 20px, #2BA66B99 20px); width:${this.greenPercent}%;`
            }else if((Number(!!this.redPercent) + Number(!!this.greyPercent) + Number(!!this.yellowPercent)==2)){
                // green => last out of 3
                return `left:-32px; padding-left:50px; background:linear-gradient(135deg, transparent 20px, #2BA66B99 20px); width:${this.greenPercent}%;`
            }else{
                // green => last out of 2
                return `left:-16px; padding-left:50px; background:linear-gradient(135deg, transparent 20px, #2BA66B99 20px); width:${this.greenPercent}%;`
            }
        },
        getData(){
            this.redProduct = this.counts?.red??0
            this.greyProduct = this.counts?.grey??0
            this.yellowProduct = this.counts?.yellow??0
            this.greenProduct = this.counts?.green??0
            this.total = this.redProduct + this.greyProduct + this.yellowProduct + this.greenProduct
            this.redPercent = parseInt(this.redProduct/this.total*100)
            this.greyPercent = parseInt(this.greyProduct/this.total*100)
            this.yellowPercent = parseInt(this.yellowProduct/this.total*100)
            this.greenPercent = parseInt(this.greenProduct/this.total*100)
        }
    }
})
</script>

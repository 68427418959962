import { defineRule, configure } from 'vee-validate'
import { email, required, min, max } from '@vee-validate/rules'

configure({
  validateOnInput: true
})

defineRule('required', (value) => {
  if (!value && value?.length == 0) {
    return 'This field is required'
  }
  return true
})

defineRule('numberRequired', (value) => {
  if (value !== 0 || !value || value.length == 0) {
    return 'This field is required'
  }
  return true
})

defineRule('email', (value) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true
  }
  // Check if email
  if (
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    )
  ) {
    return 'This field must be a valid email'
  }
  return true
})

defineRule('pin', (value) => {
  if (value && (value.toString().length != 4 || isNaN(value))) {
    return 'Pin should be 4 digit number'
  }
  return true
})

defineRule('phone', (value) => {
  if (value && !/^\(\d{3}\)[-]\d{3}[-]\d{4}$/.test(value)) {
    return 'Phone number must be in (416)-124-1353 format'
  }
  return true
})

defineRule('number', (value) => {
  if (value === '') {
    return 'This field is required'
  } else if (value != undefined && isNaN(value)) {
    return 'This field must be number'
  }
  return true
})

defineRule('positiveInteger', (value) => {
  if (value !== null && value !== '' && !/^\d+$/.test(value)) {
    return 'This field must be positive number'
  }
  return true
})

defineRule('price', (value) => {
  // accept - null, '', 0, 3, 0.3, .3, undefined
  // reject - -3, -3.4
  if (
    value !== null &&
    value !== '' &&
    value !== '0' &&
    value !== 0 &&
    value !== undefined &&
    (isNaN(value) ||
      value < 0 ||
      !/^([0-9]*[1-9][0-9]*(\.[0-9]+)?|[0]*\.[0-9]*[1-9][0-9]*)$/.test(value))
  ) {
    return 'This field must be valid'
  }
  return true
})

defineRule('percentage', (value) => {
  if (
    value !== '' &&
    value !== undefined &&
    (isNaN(value) || value < 0 || value > 100)
  ) {
    return 'This field must be valid percentage'
  }
  return true
})

defineRule('min', (value, param) => {
  if (!value) {
    return 'This field is required'
  } else if (value.length < param) {
    return `This field must have minimum of ${param} characters`
  }
  return true
})

defineRule('max', (value, param) => {
  if (!value) {
    return 'This field is required'
  } else if (value.length > param) {
    return `This field must have maximum of ${param} characters`
  }
  return true
})

defineRule('requiredIf', (value, [target], ctx) => {
  if (!!ctx.form[target]) {
    if (value || value === 0) {
      return true
    } else {
      return 'This field is required'
    }
  } else {
    return true
  }
})

defineRule('requiredDateIf', (value, [target], ctx) => {
  if (ctx.form[target] && ctx.form[target].length > 0) {
    if (value || value === 0) {
      return true
    } else {
      return 'This field is required'
    }
  } else {
    return true
  }
})

defineRule('saleGreaterThanRegular', (value, [target], ctx) => {
  if (value === undefined || (ctx.form[target] && ctx.form[target] >= value)) {
    return true
  } else {
    return 'This field must not be greater than regular price'
  }
})


import Helpers from '@/helpers';
import { ref, defineComponent } from "vue";
import Base from "@/views/Login/Base.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { useStore } from "vuex";
import { useSnackbar } from "vue3-snackbar";
import moment from 'moment';
import ToggleBusinessStatusModal from "@/views/Business/ToggleBusinessStatusModal.vue";
import SelectLocation from '@/views/Business/SelectLocation.vue'

export default defineComponent({
  name: 'SelectBusiness',
 
  components: {
    Base,
    ToggleBusinessStatusModal,
    SelectLocation,
  },

 setup() {
  

  
    const toggleBusinessStatus = ref({
      modal: false,
      business: {}
    });
    const store = useStore()
    const loading = ref<Boolean>(false);
    var filteredBusinesses = ref<[]>([]);
    const searchtext = <any>ref('');
    const snackbar = useSnackbar();
    const selectedBusiness = <any>ref({})

    return {
      filteredBusinesses,
      loading,
      searchtext,
      store,
      snackbar,
      toggleBusinessStatus,
      selectedBusiness
    };


 },
  
  async mounted() {
    
    this.loading = true;
    await this.va_setUserBusinesses();
    // skip if only one business
    if(this.vg_userBusinesses.length==1){
      this.selectedBusiness = this.vg_userBusinesses[0]
      this.confirmBusiness()
    }
    this.loading = false
    this.filteredBusinesses = this.store.getters['auth/businesses'];
  },
  
  computed: {
    ...mapGetters({
      vg_loggedInUser: 'auth/user',
      vg_userBusinesses: 'auth/businesses',
    }),
  },
  watch: {
    'searchtext'(value) {
      this.filteredBusinesses = value ? this.store.getters['auth/businesses'].filter((item) => item.name.toLowerCase().includes(value.toLowerCase() ) ) : this.store.getters['auth/businesses'];
    },
    'vg_userBusinesses'(val) {
      this.filteredBusinesses = this.searchtext ? this.store.getters['auth/businesses'].filter((item) => item.name.toLowerCase().includes(this.searchtext.toLowerCase()) ) : val;
    },
    
  },
  

  methods: {
     
    currentDate() {
      const current = moment(new Date()).format("dddd, MMMM Do");
      return current;
    },
    timestamp (){
      const today = moment().format('h:mm A');
        return today;
    },
   
   
    /**
     * Show Toggle modal
     */
    showToggleBusinessModal(business) {
      this.toggleBusinessStatus = {
        modal: true,
        business: business
      };
    },

    /**
     * Close Toggle modal
     */
    closeToggleBusinessModal() {
      this.toggleBusinessStatus = {
        modal: false,
        business: {}
      };
    },

    selectBusiness(business){
      this.selectedBusiness = business
    },

    /**
     * Confirm business
     */
    async confirmBusiness() {
      let selectedBusiness = <any>this.selectedBusiness
      if(selectedBusiness.id){
        // as son as business selected get refresh token 
        this.va_spinner(true);
        let res = await this.va_setUserActiveBusiness(this.selectedBusiness);
        this.va_spinner(false);
        if(res.status == 200) {
          this.$router.push({
            name: 'SelectLocation'
          })
        } else if(res.response && res.response.status == 401) {
          this.$router.push({
            name: 'Login'
          })
          this.va_clearUserData();
        }

        this.snackbar.add({
          type: 'info',
          text: res.status == 200 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
          duration: 3000,
        })
      }

    },

    addBusiness(){
      // only super user can see and add business
      if(this.vg_loggedInUser.userType == 'super') {
        this.$router.push({
          name: 'AddBusiness'
        })
      }
    },

    /**
     * Logout
     */

    logout() {
      this.store.dispatch('auth/clearUserData')
      this.$router.push({
        name: 'Login'
      })
    },

    ...mapActions({
      va_spinner: "spinner/setSpinner",
      va_setUserBusinesses: "auth/setUserBusinesses",
      va_clearUserData: "auth/clearUserData",
      va_setUserActiveBusiness: "auth/setUserActiveBusiness",
    })
  }

});



import Helpers from '@/helpers';
import { ref, defineComponent } from "vue";
import Base from "@/views/Login/Base.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import BusinessApis from '@/controllers/BusinessApis';
import { useSnackbar } from "vue3-snackbar";

export default defineComponent({
  name: 'SelectBusiness',
  components: {
    Base,
  },

 setup() {
      const snackbar = useSnackbar();
      const businessForm = ref({
        name: {
          value: '',
          error_type: '',
          error_message: '',
          validations: ['required', 'min:3'],
        },
        slug: {
          value: '',
          error_type: '',
          error_message: '',
          validations: ['required', 'min:3'],
        },
      });

    return {
      businessForm,
      snackbar
    };
 },
 
  watch:{
    // Slug cannot have whitespaces
    'businessForm.slug.value'(val){
      if(val) {
        val = val.toLowerCase();
        val = val.replaceAll(/\s/g,'').trim();
        this.businessForm.slug.value = val;
      } else {
        this.businessForm.slug.value = '';
      }
    },
  },

  methods: {

    /**
     * Validation captures
     */
    async validateFields(fields) {
      let res = await Helpers.validateFormFields(this.businessForm, fields);
      // capture the fields
      this.businessForm = res.data;
      return res.error ? false : true;
    },

    // Create and set business
    async createBusiness() {
      let validate = await this.validateFields(['name', 'slug']);
      if(!validate) {
        return ;
      }
        
        this.va_spinner(true);
        // validate create new business
        let res = await BusinessApis.createBusiness({
          name: this.businessForm.name.value.trim(),
          slug: this.businessForm.slug.value,
        })
        this.va_spinner(false);
        this.snackbar.add({
          type: 'info',
          text: res.status == 201 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
          duration: 3000,
        })
        if(res.status == 201) {
          // set this business as active business
          await this.va_setUserActiveBusiness(res.data.payload);
          this.$router.push({
            name: 'Products'
          })
        }
    },


    ...mapActions({
      va_spinner: "spinner/setSpinner",
      va_setUserBusinesses: "auth/setUserBusinesses",
       va_setUserActiveBusiness: "auth/setUserActiveBusiness",
    })
  }

});


<template>
    <div :class="show?'opacity-100':'opacity-0'" class="text-white flex-1 col-span-1 border-0 rounded-t-xl transition-opacity duration-1000 ease-in-out">
            <div class="text-white flex-1 col-span-1 border-0 rounded-t-xl">
                <div class="bg-theme-green rounded-t-xl px-4 py-2 text-white">
                    <div class="flex justify-between items-center">
                        <div>
                            <h2 class="font-bold tracking-wider truncate text-single-line leading-tight text-2xl mt-0 mb-4">{{editItem.name}}</h2>
                            <h3 class="text-sm font-semibold truncate text-single-line leading-tight" v-if="editItem.brand">{{editItem.brand.name}}</h3>
                        </div>
                        <div class="flex flex-col">
                            <button @click="closeEdit" class="mb-3 flex justify-end">
                                CLOSE
                            </button>
                            <div class="mb-1 relative flex justify-end gap-2" tabindex="1"  @focusout="focusOutDropDown">
                                <button v-if="editPage=='regular'" @click="save" class="uppercase hover:bg-slate-100 px-[14px] py-[10px] bg-white rounded text-sm ml-3 tracking-widest text-theme-green">
                                    save
                                </button>
                                <!-- Drop down -->
                                <button v-if="editPage!='adjust-inventory'" @click="toggleDropDown" class="uppercase hover:bg-slate-100 px-[14px] py-[10px] bg-white rounded text-sm tracking-widest text-theme-green">...</button>
                                <div v-if="editDropDown" class="absolute top-14 right-0 mt-1 w-[220px] z-10 rounded-md shadow-lg">
                                    <div class="absolute right-0 -top-4 right-4 h-0 w-0 border-x-8 border-x-transparent border-b-[16px] border-b-gray-black"></div>
                                    <ul role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-item-3" class="bg-gray-black max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                        <li @click="dropDownConfirm(eachDropDown.page)" v-for="(eachDropDown, ind) in editDropDownList" :key="'drop-'+ind" class="cursor-pointer text-white last:text-theme-red hover:bg-light-black select-none relative py-2 pl-3 pr-9">
                                            <div class="flex items-center">
                                                <span class="ml-3 block font-normal uppercase" :class="eachDropDown.class">
                                                    {{eachDropDown.name}}
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div v-if="editPage!='adjust-inventory'">
                    <ul class="flex gap-[54px] bg-[#2B2C32] rounded-b-xl px-2 pt-3 pb-2">
                        <li @click="toggle('regular')" :class="editPage=='regular'?'active':''">
                            <a class="inline-block text-theme-white  tracking-widest text-sm font-semibold" href="#">GENERAL</a>
                        </li>
                        <li @click="toggle('media')" :class="editPage=='media'?'active':''">
                            <a class="text-theme-dimwhite tracking-widest text-sm font-semibold" href="#">MEDIA</a>
                        </li>
                        <li @click="toggle('terpene')" :class="editPage=='terpene'?'active':''">
                            <a class="text-theme-dimwhite tracking-widest text-sm font-semibold uppercase" href="#">Terpenes</a>
                        </li>
                        <li @click="toggle('inventoryLog')" :class="editPage=='inventoryLog'?'active':''">
                            <a class="text-theme-dimwhite tracking-widest text-sm font-semibold uppercase" href="#">Inventory Log</a>
                        </li>
                    </ul>
                </div>
                <component :productLoading="productLoading" :is="editPage" :asn="asn" :fulfillment="fulfillment" :categories="categories" :brands="brands" :taxes="taxes" :editItem="productDetail" :cannabisProfile="cannabisProfile" :pricing="pricing" :productTax="productTax" @reloadProductDetail="reloadProductDetail" />
            </div> 
    </div>

    <retire v-if="retirePop" :editItem="editItem" @close="closeRetire" />

    <custom-modal :size="'max-w-2xl'" v-if="productCard.modal">
      <template v-slot:maincontent>
        <SelectQrType :isSingle="true" :closeCallBack="closeCallBack" :products="[productCard.product]"/>
      </template>
    </custom-modal>

</template>
<script>
import { ref, defineComponent } from 'vue'
import Regular from './Regular.vue'
import Media from './Media.vue'
import InventoryLog from './InventoryLog.vue'
import Terpene from './terpene/Terpene.vue'
import AdjustInventory from './inventory/Inventory.vue'
import ProductApi from '@/controllers/BusinessProductApis'
import LocationCannabisSettingApi from '@/controllers/LocationCannabisSetting';
import SelectQrType from "@/views/Products/Product/QrCode/Index.vue";
import Retire from './retire/retire.vue'
import {mapGetters, mapMutations} from 'vuex'
import { useSnackbar } from "vue3-snackbar"
import ObjectHelper from '@/helpers/object'

export default defineComponent({
    props: ['asn', 'fulfillment', 'editItem'],
    components: {
        Regular,
        Media,
        InventoryLog,
        Terpene,
        AdjustInventory,
        SelectQrType,
        Retire
    },
    setup() {
        const productLoading = ref(false)
        const show = ref(true)
        const snackbar = useSnackbar();
        const editDropDown = ref(false)
        const productCard = ref({
          modal: false,
          product: {},
        });
        const editDropDownList = [
            {name: 'Adjust inventory', page: 'adjust-inventory', class: ''},
            // {name: 'Merge', page: 'merge', class: ''},
            {name: 'Print product cards', page: 'printProductCard', class: ''},
            // {name: 'Recommendations', page: 'recommendations', class: ''},
            {name: 'Archive / Delete', page: 'delete', class: 'text-theme-red'},
        ]
        const globalSetting = ref({})
        const categories = ref([])
        const brands = ref([])
        const taxes = ref([])
        const productDetail = ref({})
        const cannabisProfile = ref({})
        const pricing = ref({})
        const productTax = ref([])
        const editPage = ref('regular')
        const retirePop = ref(false)

        return {productLoading, show, snackbar, editDropDown, editDropDownList, globalSetting, categories, brands, taxes, productDetail, editPage, cannabisProfile, pricing, productTax, productCard, retirePop}
    },
    mounted(){
        let self = this
        this.vm_spinner(true)
        let promise0 = this.fetchLotSetting()
        let promise1 = this.getCategory()
        let promise2 = this.getBrand()
        let promise3 = this.getTaxes()
        let promise4 = this.getProductDetail(this.editItem.parentProductId?this.editItem.parentProductId:this.editItem.id)
        let promise5 = this.getCannabisProfile(this.editItem.parentProductId?this.editItem.parentProductId:this.editItem.id)
        let promise6 = this.getPricing(this.editItem.parentProductId?this.editItem.parentProductId:this.editItem.id)
        let promise7 = this.getProductTax(this.editItem.parentProductId?this.editItem.parentProductId:this.editItem.id)
        Promise.all([promise0, promise1, promise2, promise3, promise4, promise5, promise6, promise7]).then(() => {
            self.addCurrentCategoryLotTrackingAndGlobalLotTrackingToProductDetailObject()
            self.vm_spinner(false)
        })

        this.emitter.on("added", this.addedFromAsn);
        this.emitter.on("reloadProduct", this.reloadProduct);
        this.emitter.on("showDefaultVariant", this.showDefaultVariant)
    },
    beforeDestroy(){
        this.emitter.off("reloadProduct", this.reloadProduct);
        this.emitter.off("showDefaultVariant", this.showDefaultVariant)
    },
    unmounted(){
        this.emitter.off("reloadProduct", this.reloadProduct);
        this.emitter.off("showDefaultVariant", this.showDefaultVariant)
    },
    watch: {
        editItem(item){
            this.editPage = 'regular'
            let self = this
            this.show = false
            this.vm_spinner(true)
            let promise0 = this.fetchLotSetting()
            let promise1 = this.getCategory()
            let promise2 = this.getBrand()
            let promise3 = this.getTaxes()
            let promise4 = this.getProductDetail(this.editItem.parentProductId?this.editItem.parentProductId:this.editItem.id)
            let promise5 = this.getCannabisProfile(this.editItem.parentProductId?this.editItem.parentProductId:this.editItem.id)
            let promise6 = this.getPricing(this.editItem.parentProductId?this.editItem.parentProductId:this.editItem.id)
            let promise7 = this.getProductTax(this.editItem.parentProductId?this.editItem.parentProductId:this.editItem.id)
            Promise.all([promise0, promise1, promise2, promise3, promise4, promise5, promise6, promise7]).then(() => {
                self.addCurrentCategoryLotTrackingAndGlobalLotTrackingToProductDetailObject()
                self.vm_spinner(false)
                self.show = true
            })
            this.emitter.on("reloadProduct", this.reloadProduct);
        }
    },
    computed: {
        ...mapGetters({
            vg_selectedBusiness: 'auth/activeBusiness',
            vg_selectedLocation: 'location/selected',
            vg_modified: 'modifiedForm/status'
        })
    },
    methods: {
        addedFromAsn(row){
            this.$emit('added', row)
        },
        toggleDropDown(){
            this.editDropDown = !this.editDropDown
        },
        focusOutDropDown(el){
            if(!el.relatedTarget){
                this.editDropDown = false
            }
        },
        dropDownConfirm(page){
            // show confirm message in product list not in purchase order
            if(this.vg_modified && this.$route.name!=="AddPurchaseOrders"){
                console.log('confirm')
                this.confirmNew('Leave?', 'Are you sure you want to leave? Your changes have not been saved','Yes', 'bg-white text-theme-red', 'No', 'bg-theme-green text-white', true).then(async () => {
                    this.vm_modified(false)
                    this.dropDown(page)
                }).catch(err => {
                    console.log(err)
                })
            }else{
                this.dropDown(page)
            }
            this.editDropDown = false
        },
        dropDown(page){
            if(page=='delete'){
                this.retirePop = true
                // this.remove()
            } else if(page == 'adjust-inventory'){
                this.editPage = page
            } else if(page == 'printProductCard'){
                // open mdoal
                this.openProductCardModal()
            } else if(page == 'merge'){
                let obj = ObjectHelper.pickWithDefault(
                    {...this.editItem, ...this.cannabisProfile, ...this.pricing}, 
                    ['name','strain','complianceType','stock_threshold','current_nou','sku','concentration_unit','description','thc_cbd_value_type','thc','thc_min','thc_max','cbd','cbd_min','cbd_max','cost','sale_price','batch_lot','packaged_on'],
                    ['', '', '', '', null, '', '', '', 'single', null, null, null, null, null, null, null, null, null, null]
                )
                obj.productId = this.editItem.id
                obj.categoryId = this.editItem.categoryId
                obj.brand_id = this.editItem.brandId
                obj.gtin = this.editItem.barcode
                obj.size = this.editItem.variantName
                obj.weight_equivalent_dry_cannabis = this.editItem.dryCannabisEquivalency
                obj.image = this.editItem.image
                obj.aroma = this.editItem.aroma
                obj.terpene = this.editItem.terpene
                obj.variants = this.productDetail.variants
                let asnObj = {
                    EachGTIN: this.editItem.barcode,
                    ItemName: this.editItem.name,
                    sku: this.editItem.sku,
                    size: this.editItem.variantName,
                    cost: this.pricing.cost,
                    pmdProduct: obj
                }
                this.$emit('toggleAddEdit', {add: true, edit: false, editItem: asnObj, editMerge: true})
            }
        },
        closeEdit(){
            if(this.vg_modified){
                this.confirmNew('Leave?', 'Are you sure you want to leave? Your changes have not been saved','Yes', 'bg-white text-theme-red', 'No', 'bg-theme-green text-white', true).then(async () => {
                    this.vm_modified(false)
                    this.doCloseEdit()
                }).catch(err => {
                    console.log(err)
                })
            }else{
                this.doCloseEdit()
            }
        },
        doCloseEdit(){
            if(this.editPage=='adjust-inventory'){
                this.editPage = 'regular'
            }else{
                this.$emit('close')
            }
        },
        async toggle(name){
            // show confirm message in product list not in purchase order
            if(this.vg_modified && this.$route.name!=="AddPurchaseOrders"){
                this.confirmNew('Leave?', 'Are you sure you want to leave? Your changes have not been saved','Yes', 'bg-white text-theme-red', 'No', 'bg-theme-green text-white', true).then(async () => {
                    this.vm_modified(false)
                    this.editPage = name
                }).catch(err => {
                    console.log(err)
                })
            }else{
                this.editPage = name
            }
        },
        save(){
            this.emitter.emit('saveEditProduct', true)
        },
        closeRetire(reload){
            
            this.retirePop = false
            if(reload){
                this.$emit('close')
                this.emitter.emit('reloadProductList', true)
            }
        },
        async fetchLotSetting(){
            let res = await LocationCannabisSettingApi.fetchSetting();
            this.globalSetting =  res.status == 200 ? res.data.payload : {lotTracking: false};
        },
        async getCategory(){
            this.categories = []
            let res = await ProductApi.fetchAllCategory()
            if(res.data?.payload?.category){
                for(let i=0; i<res.data.payload.category.length; i++){
                    this.categories.push(res.data.payload.category[i])
                    if(res.data.payload.category[i].subCategories && res.data.payload.category[i].subCategories.length>0){
                        for(let j=0; j<res.data.payload.category[i].subCategories.length; j++){
                            this.categories.push(res.data.payload.category[i].subCategories[j])
                        }
                    }
                }
            }
        },
        async getBrand(){
            let res = await ProductApi.fetchAllBrands()
            if(res.data?.payload?.brands){
                this.brands = res.data.payload.brands
            }
        },
        async getTaxes(){
            if(this.vg_selectedBusiness.id){
                let res = await ProductApi.fetchAllTaxes(this.vg_selectedBusiness.id)
                if(res.data?.payload){
                    this.taxes = res.data.payload
                }
            }
        },
        async getProductDetail(productId){
            if(productId){
                let res = await ProductApi.getProductById(productId, this.vg_selectedLocation.id)
                if(res.data?.payload){
                    this.productDetail = res.data.payload
                    this.productDetail.ppiStock = this.editItem.ppiStock
                }
            }
        },
        async getCannabisProfile(productId){
            if(this.vg_selectedLocation.id){
                let res = await ProductApi.fetchCannabisProfile(productId, this.vg_selectedLocation.id)
                if(res.data?.payload){
                    this.cannabisProfile = res.data.payload
                }else{
                    this.cannabisProfile = {}
                }
            }
        },
        async getPricing(productId){
            if(this.vg_selectedLocation.id){
                let data = {locationId: this.vg_selectedLocation.id, productIds:[productId]}
                let res = await ProductApi.fetchPricingByProductId(data)
                if(res.data?.payload[0]){
                    this.pricing = res.data.payload[0]
                }else{
                    this.pricing = {}
                }
            }
        },
        async getProductTax(productId){
            if(this.vg_selectedLocation.id){
                let data = {locationId: this.vg_selectedLocation.id, ProductId:productId}
                let res = await ProductApi.fetchTaxByProductId(data)
                if(res.data?.payload){
                    this.productTax = res.data.payload
                }else{
                    this.productTax = []
                }
            }
        },
        addCurrentCategoryLotTrackingAndGlobalLotTrackingToProductDetailObject(){
            let result = this.categories.find(each => each.id==this.productDetail.categoryId)
            if(result){
                this.productDetail.categoryLotTracking = result.lotTracking
            }
            this.productDetail.globalLotTracking = this.globalSetting.lotTracking
        },
        async reloadProductDetail(productId){
            await this.getProductDetail(productId)
            this.addCurrentCategoryLotTrackingAndGlobalLotTrackingToProductDetailObject()
        },
        reloadProduct(productId){
            let self = this
            this.productLoading = true
            this.vm_spinner(true)
            let promise1 = this.getProductDetail(productId)
            let promise2 = this.getCannabisProfile(productId)
            let promise3 = this.getPricing(productId)
            Promise.all([promise1, promise2, promise3]).then(() => {
                self.addCurrentCategoryLotTrackingAndGlobalLotTrackingToProductDetailObject()
                setTimeout(function(){
                    self.vm_spinner(false)
                    self.productLoading = false
                }, 7000)
            })
        },
        showDefaultVariant(){
            this.editPage = 'regular'
        },
        /**
         * Open Qr Card Modal
         */
        openProductCardModal() {
          this.productCard = {
            modal: true,
            product: this.editItem,
          };
        },
        /**
         * Close Qr Card Modal
         */
        closeCallBack() {
          this.productCard = {
            modal: false,
            product: {},
          };
        },

        ...mapMutations({
            vm_spinner: 'spinner/mutateSpinner',
            vm_modified: 'modifiedForm/mutateStatus'
        })
    }
})
</script>
<style scoped>
    .active{
        /* position:relative; */
        border-bottom: solid 4px #2BA66B;
    }
    /* .active:before {
    content: '';
    position: absolute;
    background: #2BA66B;
    width: 100%;
    height: 4px;
    bottom: -12px;
    } */
  </style>

<template>
  
  <div class="mb-24">
      <div class="text-white flex-1 col-span-1 border-0 rounded-t-xl overflow-hidden">
        <div class="bg-theme-green px-4 py-2 text-white">
          <div class="flex justify-between items-center">
            <div class="flex items-center">
              <img v-if="form.icon_url.value" :src="form.icon_url.value" alt="" class="w-[40px] h-[40px]"/>
              <h2 class="font-semibold truncate text-single-line break-all leading-tight text-2xl mt-0 ml-2">{{ form.name.value ? form.name.value : 'New Badge'}}</h2>
            </div>
            
            <div class="flex flex-col">
              <div class="flex items-center content-center gap-2">
                <button @click="save()" class="text-sm cursor-pointer bg-white py-2.5 px-4 rounded text-theme-green hover:bg-theme-darkgreen hover:text-white ml-3">
                  <span>SAVE</span>
                </button>
                <span @click.prevent="clearComponent" class="cursor-pointer rounded-md  text-white py-2 pl-1  hover:opacity-70">CLOSE</span>
                <!-- <mdicon @click.prevent="clearComponent" size="32" name="close" class="cursor-pointer hover:opacity-60 text-4xl ml-2"/> -->
              </div>
            </div>
          </div>
        </div>


        <div class="bg-[#2B2C32] px-[16px] pt-[16px]  py-1 rounded-b-lg">
          <div>
            <ul class="flex bg-[#2B2C32] rounded-full ">
              <li class="mr-4">
                <a @click="activeTab = 'general'" :class="'text-theme-white tracking-widest font-bold text-sm' + (activeTab == 'general' ? ' active' : '')" href="#">General</a>
              </li>
              <li class="mr-4">
                <a @click="activeTab = 'icon'" :class="'text-theme-dimwhite tracking-widest font-bold text-sm' + (activeTab == 'icon' ? ' active' : '') " href="#">Icon</a>
              </li>
            </ul>
          </div>
        </div>


        <!-- first part -->
        <div class=" bg-[#2B2C32] px-[16px] py-[16px] mt-1 rounded-lg" v-show="activeTab == 'general'">
          <div class="flex items-center  xl:flex-row flex-col gap-3 mb-3">
            <div class="xl:w-1/2 w-full">
              <div :class="'input-wrapper ' + (form.action_type.error_type)">
                  <label class="mb-0 text-xs">Badge Threshold Type</label>
                  <select v-model="form.action_type.value" @change="validateFields(['action_type'])" :class="'block rounded bg-gray-black w-full border-none text-sm ' + (form.action_type.value ? 'text-white ' : 'text-theme-878686 ')">
                    <option value="" disabled> Select One</option>
                    <option v-bind:key="'actionkey_'+key " v-for="(actionType, key) in badgesActionTypes" :value="key">{{ actionType }}</option>
                  </select>
                   <p class="field-error label-text mt-1" v-if="form.action_type.error_message" v-text="form.action_type.error_message"></p>
              </div>
            </div>

            <div class="xl:w-1/2 w-full">
              <div :class="'input-wrapper ' + (form.date_restriction_text.error_type)">
                <label class="mb-0 text-xs">Date Range Restrictions</label>
                <select @change="validateFields(['date_restriction_text'])"  v-model="form.date_restriction_text.value" :class="'block rounded bg-gray-black w-full border-none text-sm ' + (form.date_restriction_text.value ? 'text-white ' : 'text-theme-878686 ')">
                    <option value="" disabled>Select One</option>
                    <option value="restriction">No Restriction</option>
                    <option value="limited">Limited Edition</option>
                </select>
                <p class="field-error label-text mt-1" v-if="form.date_restriction_text.error_message" v-text="form.date_restriction_text.error_message"></p>
              </div>
            </div>
          </div>

          <div class="flex items-center  xl:flex-row flex-col gap-3 mb-3">
            <div class="xl:w-1/2 w-full">
              <div :class="'input-wrapper ' + (form.action_threshold.error_type)">
                  <label class="mb-0 text-xs">Threshold</label>
                  <input placeholder="Threshold" @keyup="validateFields(['action_threshold'])" :onkeypress="preventNonNumbersAndDecimal" v-model="form.action_threshold.value" class="block tracking-wider border-none rounded placeholder-theme-878686 bg-gray-black w-full text-sm" type="number">
                  <p class="field-error label-text mt-1" v-if="form.action_threshold.error_message" v-text="form.action_threshold.error_message"></p>
              </div>
            </div>

            <div class="xl:w-1/2 w-full" >
              <div :class="'input-wrapper ' + (form.action.error_type)" v-if="form.action_type.value == 'action_completed'">
                  <label class="mb-0 text-xs">Action</label>
                  <select @change="validateFields(['action'])"  v-model="form.action.value" :class="'block rounded bg-gray-black w-full border-none text-sm ' + (form.date_restriction_text.value ? 'text-white ' : 'text-theme-878686 ')">
                      <option value="" disabled>Select One</option>
                      <option value="referral">Referral</option>
                      <option value="app_store_checkin_bonus">Checkin Bonus</option>
                      <option value="instore_order">Instore Order</option>
                  </select>
                  <p class="field-error label-text mt-1" v-if="form.action.error_message" v-text="form.action.error_message"></p>
              </div>

              
              <div class="input-wrapper" v-if="form.action_type.value == 'item_purchase'">
                <label class="text-xs mb-1">Products</label>
                <div class="flex justify-between items-center">
                    <span class="placeholder-white block bg-theme-modal_inner_bg pl-3 w-full focus:outline-0 text-sm text-theme-white border-none">{{ productSkus.length }} {{ productSkus.length > 1 ? 'Items' : 'Item' }} selected</span>
                    <div class="flex justify-between items-center">
                        <svg width="28" height="22" class="mr-4" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.58268 21.0993L7.18268 19.6993L9.31602 17.4993C6.87157 17.4993 4.77713 16.666 3.03268 14.9993C1.28824 13.3327 0.416016 11.2771 0.416016 8.83268C0.416016 6.4549 1.24379 4.41602 2.89935 2.71602C4.5549 1.01602 6.57157 0.166016 8.94935 0.166016H14.016V2.16602H8.94935C7.12713 2.16602 5.58268 2.81602 4.31602 4.11602C3.04935 5.41602 2.41602 6.98824 2.41602 8.83268C2.41602 10.7216 3.11046 12.3049 4.49935 13.5827C5.88824 14.8605 7.54935 15.4993 9.48268 15.4993L7.28268 13.2993L8.68268 11.8993L13.216 16.466L8.58268 21.0993ZM16.816 17.4993V15.4993H27.0827V17.4993H16.816ZM16.816 9.83268V7.83268H27.0827V9.83268H16.816ZM16.016 2.16602V0.166016H27.0827V2.16602H16.016Z" fill="#2BA66B"/>
                        </svg>
                        <a href="#" class="bg-white p-2 rounded hover:bg-slate-300" @click.prevent="openCategoriesWithProduct()">
                          <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M6.3 19.1508L2.75 15.6008L3.8 14.5508L6.3 17.0258L10.775 12.5508L11.825 13.6258L6.3 19.1508ZM6.3 11.1508L2.75 7.60078L3.8 6.55078L6.3 9.02578L10.775 4.55078L11.825 5.62578L6.3 11.1508ZM13.75 17.2508V15.7508H22.75V17.2508H13.75ZM13.75 9.25078V7.75078H22.75V9.25078H13.75Z" fill="#2BA66B"/>
                          </svg>
                        </a>
                    </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex items-center  xl:flex-row flex-col gap-3 mb-3 " v-if="form.date_restriction_text.value == 'limited'">
            <div class="xl:w-1/2 w-full">
             <div :class="'input-wrapper d-date clearable ' + (form.start_date.error_type)">
                <label class="mb-0 text-xs">Start Date</label>
                <Datepicker :clearable="true" :inputFormat='"yyyy-MM-dd"' :lowerLimit="new Date()" @keyup="validateFields(['start_date'])"  v-model="form.start_date.value" class="block w-full tracking-wider border-none rounded placeholder-theme-878686 bg-gray-black text-sm" placeholder="Select a Date" />
                <p class="field-error label-text mt-1" v-if="form.start_date.error_message" v-text="form.start_date.error_message"></p>
              </div>
            </div>

            <div class="xl:w-1/2 w-full">
              <div :class="'input-wrapper d-date clearable ' + (form.end_date.error_type)">
                <label class="mb-0 text-xs">End Date</label>
                <Datepicker :clearable="true" :inputFormat='"yyyy-MM-dd"' :lowerLimit="endDateLimit" @keyup="validateFields(['end_date'])" v-model="form.end_date.value" class="block w-full tracking-wider border-none rounded placeholder-theme-878686 bg-gray-black text-sm" placeholder="Select a Date" />
                <p class="field-error label-text mt-1" v-if="form.end_date.error_message" v-text="form.end_date.error_message"></p>
              </div>
            </div>
          </div>

          <div class="flex items-center  xl:flex-row flex-col gap-3 mb-3">
            <div class="w-full xl:w-1/2">
                <div class="input-wrapper h-[60px] relative">
                    <label class="text-xs tracking-[0.4px]">Badge Rewards</label>
                    <div class="flex items-center justify-between">
                      <div>
                        <div class="flex items-center">
                          <input id="pointreward" type="radio" :value="false" v-model="form.no_reward" name="no_reward" class="w-4 h-4  bg-transparent border-2 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                          <label for="pointreward" class="ml-2 text-sm font-normal tracking-wider text-white dark:text-gray-300">Points</label>
                        </div>
                      </div>
                      <div>
                        <div class="flex items-center">
                          <input id="noreward" type="radio" v-model="form.no_reward" :value="true" name="no_reward" class="w-4 h-4  bg-transparent border-2 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                          <label for="noreward" class="ml-2 text-sm font-normal tracking-wider text-white dark:text-gray-300">No Reward</label>
                        </div>
                       </div>
                    </div>
                </div>
            </div>

            <div class="w-full xl:w-1/2">
              <div v-if="form.no_reward == false" :class="'input-wrapper ' + (form.point_value.error_type)">
                <label class="text-xs tracking-[0.4px]">Point Value</label>
                <div class="relative">
                  <input v-model="form.point_value.value" :onkeypress="preventNonNumbers" @keyup="validateFields(['point_value'])" placeholder="1,500" class="block tracking-wider border-none rounded placeholder-theme-878686 bg-gray-black w-full text-sm" type="number">
                  <span class="absolute bottom-1 right-2 text-base font-bold tracking-wider border border-theme-green rounded text-theme-878686 py-[1px] px-[3px]">$ {{ pointToCash }}</span>
                </div>
                <p class="field-error label-text mt-1" v-if="form.point_value.error_message" v-text="form.point_value.error_message"></p>
              </div>
            </div>
          </div>

          <div class="flex items-center  xl:flex-row flex-col gap-3 mb-3">
            <div class="xl:w-1/2 w-full">
              <div :class="'input-wrapper ' + (form.no_of_times_repeatable.error_type)">
                  <label class="mb-0 text-xs">No of times earnable</label>
                  <input placeholder="No of times repeatable" @keyup="validateFields(['no_of_times_repeatable'])" :onkeypress="preventNonNumbersAndDecimal" v-model="form.no_of_times_repeatable.value" class="block tracking-wider border-none rounded placeholder-theme-878686 bg-gray-black w-full text-sm" type="number">
                  <p class="field-error label-text mt-1" v-if="form.no_of_times_repeatable.error_message" v-text="form.no_of_times_repeatable.error_message"></p>
              </div>
            </div>
          </div>

          <div class="bg-theme-borderblack h-1 w-full my-4"></div>

          <div class="flex items-center flex-col gap-2 my-2">   
            <div class="w-full">
              <div :class="'input-wrapper ' + (form.name.error_type)">
                <label class="text-xs tracking-[0.4px]">Name</label>
                <input placeholder="Big Smoker" @keyup="validateFields(['name'])" v-model="form.name.value" class="block tracking-wider border-none rounded placeholder-theme-878686 bg-gray-black w-full text-sm" type="text">
                <p class="field-error label-text mt-1" v-if="form.name.error_message" v-text="form.name.error_message"></p>
              </div>
            </div>
            <div class="w-full">
              <div :class="'input-wrapper relative ' + (form.desc.error_type)">
                <label class="text-xs tracking-[0.4px]">Description</label>
                <textarea  v-model="form.desc.value" @keyup="validateFields(['desc'])" placeholder="Make It Interesting..." class="block tracking-wider border-none rounded placeholder-theme-878686 bg-gray-black w-full text-sm"></textarea>
                <p class="field-error label-text mt-1" v-if="form.desc.error_message" v-text="form.desc.error_message"></p>
              </div>
            </div>
          </div>
        </div>

        <!-- second part -->
        <div class=" bg-[#2B2C32] px-[16px] py-[16px] mt-1 rounded-lg" v-show="activeTab == 'icon'">
          <span class="mb-0 text-xs">Icon</span>
          <div class="grid xl:grid-cols-2 grid-cols-1 gap-4">
            <div class="">
              <div class="input-wrapper">
                <div class="grid grid-cols-5">
                  <div class="hover:opacity-70 cursor-pointer" @click.prevent="setThisIcon(require(`@/assets/images/badge${item}.png`))" v-bind:key="'badge_icon'+ item" v-for="(item) in 18">
                    <img :src="require(`@/assets/images/badge${item}.png`)">
                  </div>
                </div>
              </div>
            </div>

            <div class="">
              <div class="input-wrapper">

                <div v-if="form.icon_url.value" class="relative border-2 border-theme-grey px-3 py-3 rounded-md flex text-center justify-center flex-col">
                  <mdicon @click.prevent="form.icon_url.value = ''" size="28" name="close" class="cursor-pointer absolute right-0 top-0 text-theme-red hover:opacity-60 text-4xl"/>
                  <div class="flex items-center justify-center">
                    <img class="w-full h-auto" :src="form.icon_url.value"/>
                  </div>
                </div>

                <div v-else class="border-2 border-theme-grey px-3 py-3 rounded-md flex text-center justify-center flex-col">
                  <p class="text-sm tracking-wider text-theme-878686">Min icon size: 150 x 150 px</p>
                  <span class="flex justify-center py-6">
                    <svg width="51" height="52" viewBox="0 0 51 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M23.1849 51.0827V28.6868H0.789062V23.3118H23.1849V0.916016H28.5599V23.3118H50.9557V28.6868H28.5599V51.0827H23.1849Z" fill="#1C1D21"/>
                    </svg>
                  </span>
                  <div class="text-sm tracking-wider text-theme-878686">

                    <file-manager v-model="form.icon_url.value" classes="bg-white text-[#2BA66B] px-4 py-2 border-0 rounded cursor-pointer text-sm font-medium tracking-widest" label="SELECT" >
                      <template v-slot:custom="{ onClick }">
                        <div class="flex flex-col items-center justify-between mt-1">
                          <div class="content-image w-[140px] h-[100px] my-auto" v-if="form.icon_url.value">
                            <img class="bg-white p-1 min-w-[50px] min-h-[50px]" :src="form.icon_url.value" />
                          </div>
                          <div v-else class="text-[#878686] text-sm tracking-wider mb-2">Select</div>
                          <div @click="onClick" class="h-9 bg-white text-[#2BA66B] px-4 py-2 border-0 rounded cursor-pointer text-sm font-medium tracking-widest">{{ form.icon_url.value ? '' : 'SELECT'}}</div>
                        </div>
                      </template>
                    </file-manager>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
  <CategoryWithProductModal v-if="categoryWithproductObj.active" :existingproducts="productSkus" :closeClick="closeCategoryWithProductModal" :loyaltyProgramId="loyaltyProgramId" :actionClick="setCategoriesWithProduct"/>
</template>


<script>
import {defineComponent, ref} from "vue"
import { mapGetters, mapActions, mapMutations } from "vuex";
import { useSnackbar } from "vue3-snackbar";
import Helpers from '@/helpers';
import Env from '@/env';
import Datepicker from 'vue3-datepicker'
import LoyaltyBadgesApis from '@/controllers/LoyaltyBadgesApis';
import LoyaltyBadgeRewardApis from '@/controllers/LoyaltyBadgeRewardApis';
import moment from 'moment';
import CategoryWithProductModal from '@/views/Membership/Program/Badges/CategoryWithProductModal.vue';
import FileManager from '@/components/manager/filemanager.vue'
import FileUploadApi from "@/components/manager/api";


export default defineComponent({
  name: "MembershipBadgesAdd",
  components: {
    Datepicker,
    CategoryWithProductModal,
    FileManager
  },
  props:{
    loyaltyProgramId: {
      type: String,
      required: false,
    },
    clearComponent: {
      type: Function,
      required: true,
    },
    earningSetting: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const snackbar = useSnackbar();
    const form = ref({
      action_type: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required'],
      },
       date_restriction_text: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required'],
      },
      date_restriction: false,
      action_threshold: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required', 'required_min_1'],
      },
      action:{
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required_if:action_type|action_completed'],
      },
      start_date: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required_if:date_restriction|true|string'],
      },
      end_date: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required_if:date_restriction|true|string'],
      },
      no_reward: true,
      reward_type: 'no_reward',
      point_value: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required_if:no_reward|false|string'],
      },
      name: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required', 'min:3'],
      },
      desc:  {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required'],
      },
      icon_url: {
        value: '',// https://d1k7e91iovemsu.cloudfront.net/images/dev/miss_jones/root/db2480b2-4eeb-4aca-8eb3-cb3d4d2a20f4.png
        error_type: '',
        error_message: '',
        validations: ['required'],
      },
      no_of_times_repeatable: {
        value: '',
        error_type: '',
        error_message: '',
        validations: ['required', 'required_min_1'],
      }
  
    });
    const activeTab = ref('general');
    const categoryWithproductObj = ref({
      active: false,
    });
    const productSkus = [];
    return {
      form,
      snackbar,
      activeTab,
      categoryWithproductObj,
      productSkus
    };
  },

  watch:{
    'form.date_restriction_text.value'(value) {
      this.form.date_restriction = (value == 'limited') ? true : false;
    },
    'form.no_reward'(value) {
      this.form.reward_type = value  ? 'no_reward' : 'points';
    },
    'form.start_date.value'() {
      this.validateFields(['start_date']);
      if(this.form.end_date.value != '') {
        // then need to check
        let endDate = moment(this.form.end_date.value);
        let startDate = moment(this.form.start_date.value);
        let dayGap = endDate.diff(startDate, 'day');
        if(dayGap < 0) {
          this.snackbar.add({
            type: 'info',
            text: 'End date must be equal or greater than start date.',
            duration: 3000,
          })
          this.form.end_date.value = '';
        }
      }
    },
    'form.end_date.value'() {
      this.validateFields(['end_date']);
    },
  },

  computed: {
    pointToCash() {
      if(this.form?.point_value?.value && this.earningSetting?.points_for_dollar) {
        return (Math.round(this.form.point_value.value / this.earningSetting.points_for_dollar * 100) / 100).toFixed(2)
      }
      return 0.00.toFixed(2);
    },

    endDateLimit() {
      if(this.form?.start_date?.value) {
        return this.form.start_date.value ?  moment(this.form.start_date.value).toDate() : new Date();
      }
      return new Date();
    },

    badgesActionTypes() {
      return Env.badgesActionTypes;
    },
  },

  mounted() {
     
  },
 
  methods: {

    /**
     * Validation captures
     */
    async validateFields(fields) {
      let res = await Helpers.validateFormFields(this.form, fields);
      // capture the fields
      this.form = res.data;
      return res.error ? false : true;
    },
    /**
     * Save
     */
    async save() {
      // check first step 
      let validate = await this.validateFields(['action_type', 'date_restriction_text',  'start_date', 'end_date', 'point_value', 'action', 'name', 'desc',  'action_threshold', 'no_of_times_repeatable']);
      if(!validate) {
        this.activeTab = 'general';
        return ;
      }
         
      // now if no 'icon_url' move to that tab
      if(this.form.icon_url.value == '') {
        if(this.activeTab == 'icon') {
          this.snackbar.add({
            type: 'info',
            text: 'Please add an icon.',
            duration: 3000,
          })
        }
        return this.activeTab = 'icon';
      }

   
      // else submit 
      this.va_spinner(true)
      let data = await Helpers.getFormKeyValue(this.form);

      if(!data.date_restriction) {
        delete data["end_date"];
        delete data["start_date"];
      } else {
        data.start_date = data.start_date ? moment(data.start_date).format('YYYY-MM-DD') : null;
        data.end_date = data.end_date ? moment(data.end_date).format('YYYY-MM-DD') : null;
      }


      let res = await LoyaltyBadgesApis.create(data);
      if(res.status == 201 ) {
        // we dont need to do anything
        let prmosie1;
        if(this.form.action_type.value == 'item_purchase') {
          prmosie1 = LoyaltyBadgesApis.assignProducts(res.data.id, this.productSkus);
        }
        let prmosie2 = LoyaltyBadgeRewardApis.create(res.data.id, {
          reward_type: "points",
          reward_points: this.form.point_value.value
        });
        // dont need to track the response
        Promise.all([prmosie1, prmosie2])
        this.clearComponent();
        this.emitter.emit("refresh-badges-list", true);
      }
      this.va_spinner(false);
      this.snackbar.add({
        type: 'info',
        text: res.status == 201 ? res.data.msg : Helpers.catchErrorMessage(res.response.data),
        duration: 3000,
      })
    },

    /**
     * Set this icon
     */
    async setThisIcon(imgName) {

      this.va_spinner(true)
 
      let presignedData = {
          content_type: 'image/png',
          name: 'file.png',
      }
      
      // get presigned url
      let presignedUrlData = await FileUploadApi.getPresignedUrl(presignedData)
      if(presignedUrlData?.data?.payload?.upload_url){
        let res = await fetch(imgName);
        let blob = await res.blob();
        let bb = new File([blob], 'file.png', { type: 'image/png' });
        let uploadFile =  await FileUploadApi.uploadResource(presignedUrlData.data.payload.upload_url, bb);
        if(uploadFile.status == 200 ) {
          this.form.icon_url.value = presignedUrlData.data.payload.full_url
        }         
      }
      this.va_spinner(false)
    },
    /**
     * openCategoriesWithProduct
     */
    openCategoriesWithProduct() {
      this.categoryWithproductObj.active = true;
    },
    /**
     * closeCategoryWithProductModal
     */
    closeCategoryWithProductModal() {
      this.categoryWithproductObj.active = false;
    },
    /**
     * setCategoriesWithProduct
     */
    setCategoriesWithProduct(data) {
      this.productSkus = data.skus;
      this.closeCategoryWithProductModal();
    },
    /**
     * Get readable name
     *  */ 
    iconName(iconUrl) {
      if(iconUrl) {
        return iconUrl.split('/').at(-1)
      }
      return '';

    },

    /**
     * 
     *  Do not allow to add +,-,e ,. 
     */
    preventNonNumbersAndDecimal(e) {
      var ASCIICode = (e.which) ? e.which : e.keyCode
      if (ASCIICode == 43 || ASCIICode == 45 || ASCIICode == 101 || e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
        return false;
      }
      return true;
    },
    /**
     * Do not allow to add +,-,e but allow  .
     */
    preventNonNumbers(e) {
      var ASCIICode = (e.which) ? e.which : e.keyCode
      if (ASCIICode == 43 || ASCIICode == 45 || ASCIICode == 101) {
        return false;
      }
      return true;
    },

    ...mapActions({
      va_spinner: 'spinner/setSpinner',
    })
  },
});

</script>
<style scoped>
  .active:before {
    content: '';
    position: absolute;
    background: #2BA66B;
    width: 100%;
    height: 4px;
    bottom: -10px;
  }
  .active{
    position:relative;
  }
</style>
<template>
    <div ref="productList" class="rounded-[10px] bg-[#2B2C32] w-full">
        <div class="text-[24px] text-white px-4 pt-4">
            <div class="flex justify-between">
                <span class="font-semibold">Product List</span>
               
                <div class="flex flex-row gap-2">
                    <div :class="countCheck?'bg-white text-theme-green ':'bg-theme-borderblack text-theme-modalbg'" class="font-[20px] flex justify-center align-center rounded px-1 min-w-[40px] h-[40px]">
                        {{countCheck}}
                    </div>
                    <div v-if="!resized" class="relative" tabIndex="0" @blur="closeDropDown">  
                        <div @click="toggleDropDown"  class="text-white bg-theme-green hover:bg-green-600 py-1 h-10 px-1 text-2xl border-0 rounded-md cursor-pointer">
                            <span>
                                <svg fill="currentColor" width="32" height="32" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"></path>
                                </svg>
                            </span>
                        </div>
                        <div v-if="toggleAddDropDown" class="absolute top-14 right-0 w-60   mt-1  z-10 rounded-md shadow-lg">
                            <div class="absolute right-0 -top-4 right-4 h-0 w-0 border-x-8 border-x-transparent border-b-[16px] border-b-gray-black"></div>
                            <ul role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-item-3" class="bg-gray-black  rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                <li  @click.prevent="openAdd" id="listbox-item-0" role="option" class="cursor-pointer text-gray-900 hover:bg-light-black select-none relative py-2 pl-3 pr-9">
                                    <div class="flex items-center">
                                        <span class="ml-3 block text-white font-normal truncate uppercase">
                                            Create new Product
                                        </span>
                                    </div>
                                </li>
                                <li @click="addItemsToCategories" id="listbox-item-1" role="option" class="text-gray-900 select-none relative py-2 pl-3 pr-9" :class="checkedProduct?.length>0?'cursor-pointer hover:bg-light-black':''">
                                    <div class="flex items-center">
                                        <span class="ml-3 block  font-normal truncate uppercase" :class="checkedProduct?.length>0?'text-white':'text-grey'">
                                            Add to Category
                                        </span>
                                    </div>
                                </li>
                                <li @click="mergeProducts" id="listbox-item-1" role="option" class="text-gray-900 select-none relative py-2 pl-3 pr-9" :class="checkedProduct?.length>0?'cursor-pointer hover:bg-light-black':''">
                                    <div class="flex items-center">
                                        <span class="ml-3 block  font-normal truncate uppercase" :class="checkedProduct?.length>0?'text-white':'text-grey'">
                                            Merge
                                        </span>
                                    </div>
                                </li>
                                <li @click="printProductCards" id="listbox-item-1" role="option" class="text-gray-900 select-none relative py-2 pl-3 pr-9" :class="checkedProduct?.length>0?'cursor-pointer hover:bg-light-black':''">
                                    <div class="flex items-center">
                                        <span class="ml-3 block font-normal truncate uppercase" :class="checkedProduct?.length>0?'text-white':'text-grey'">
                                            Print Product cards
                                        </span>
                                    </div>
                                </li>
                                <li @click="openPurchaseOrderUploadV1" id="listbox-item-1" role="option" class="cursor-pointer hover:bg-light-black text-gray-900 select-none relative py-2 pl-3 pr-9">
                                    <div class="flex items-center">
                                        <span class="ml-3 block text-white font-normal truncate uppercase">
                                            Purchase Order upload 
                                        </span>
                                    </div>
                                </li>
                                <li @click="openPurchaseOrderUpload" id="listbox-item-1" role="option" class="cursor-pointer hover:bg-light-black text-gray-900 select-none relative py-2 pl-3 pr-9">
                                    <div class="flex items-center">
                                        <span class="ml-3 block text-white font-normal truncate uppercase">
                                            Purchase Order upload (2.0)
                                        </span>
                                        <span class="w-[36px] h-[16px] rounded-md bg-[#FFFFFF] bg-opacity-[20%] py-[2px] px-[8px] text-[10px] text-[#FFF] leading-[12px]">New</span>
                                    </div>
                                </li>
                                <li @click="openTendyTemplateUpload" id="listbox-item-1" role="option" class="cursor-pointer hover:bg-light-black text-gray-900 select-none relative py-2 pl-3 pr-9">
                                    <div class="flex items-center">
                                        <span class="ml-3 block text-white font-normal truncate uppercase">
                                            Tendy template upload
                                        </span>
                                    </div>
                                </li>
                                <li @click="exportProductCatalogue" id="listbox-item-1" role="option" class="hover:bg-light-black text-gray-900 select-none relative py-2 pl-3 pr-9" :class="checkedProduct?.length>0?'cursor-pointer hover:bg-light-black':''">
                                    <div class="flex items-center">
                                        <span class="ml-3 block font-normal truncate uppercase" :class="checkedProduct?.length>0?'text-white':'text-grey'">
                                            Product Catalogue Export
                                        </span>
                                    </div>
                                </li>
                                <li @click="openPrintMenu" id="listbox-item-1" role="option" class="cursor-pointer hover:bg-light-black text-gray-900 select-none relative py-2 pl-3 pr-9">
                                    <div class="flex items-center">
                                        <span class="ml-3 block text-white font-normal truncate uppercase">
                                            Print Menu
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="table w-full mt-6 px-2 pb-4">
            <!-- @do-search="searchProduct" -->
            <new-table-lite
                :is-static-mode="true"
                :has-checkbox="true"
                :is-slot-mode="true"
                :is-loading="table.loading"
                :do-clear-checked="table.clearChecked"
                @cleared-checked="clearedChecked"
                :class="'block w-full min-h-[480px] admin-table admin-table-custom '"
                :rowClasses="row => row?'id-'+row.id:null"
                :columns="table.columns"
                :rows="table.rows"
                :total="table.totalRecords"
                :sortable="table.sortable"
                :page-size="table.pagesize"
                :page="table.page"
                @row-clicked="selectedRow"
                @return-checked-rows="checkedRow"
                @do-search="changePage"
                :pageOptions='table.pageOptions'
                :messages="{
                    pagingInfo: 'Total: {2}',
                    pageSizeChangeLabel: 'Items per Page',
                    gotoPageLabel: 'Go to page',
                    noDataAvailable: 'No records found!',
                }"
            >
                <!-- filter -->
                <template v-slot:filter>
                    <tr class="vtl-thead-tr z-20">
                        <td colspan="2">
                            <input placeholder="Name" name="search" v-model="search" class="w-full h-10 bg-theme-modalbg ring-0 outline-0 px-2 border-0 mb-1 text-white">
                        </td>
                        <td>
                            <select-category v-model="selectedCategory" class="w-full bg-theme-modalbg border-0 mb-1 text-white" placeholder="Categories" :options="categories"></select-category>
                        </td>
                        <td v-if="!resized">
                            <select-brand v-model="selectedBrand" classString="top-[90px]" class="w-full bg-theme-modalbg border-0 mb-1 text-white" placeholder="Brands" :options="brands"></select-brand>
                        </td>
                        <td v-if="!resized">
                            <SelectCheckboxMinOne :options="productStatus" v-model="selectedProductStatus" placeholder="Choose" class="w-full bg-theme-modalbg border-0 mb-1 text-white" />
                        </td>
                        <td v-if="vg_selectedLocation.id"></td>
                        <td>
                            <!-- <select-thc-cbd disabled class="w-full bg-theme-modalbg border-0 mb-1 text-white" placeholder="THC/CBD"></select-thc-cbd> -->
                        </td>
                        <!-- <td></td> -->
                        <td>
                            <!-- <select-update disabled v-model="selectUpdateOn" class="w-full bg-theme-modalbg border-0 mb-1 text-white" placeholder="Updated On" /> -->
                        </td>
                        
                    </tr>
                </template>
                <!-- content  -->
                <template v-slot:brandId="data">
                    <span>{{ data.value?.brand?.name }}</span>
                </template>
                <template  v-slot:categoryId="data">
                   <div class="flex items-center">
                    <span class="inline-block w-5 h-5 mr-2 rounded" :style="`background-color:${getCategoryColor(data.value.categoryId)} `"></span>
                    <span class="inline-block truncate text-single-line w-[200px] align-top">{{ getCategoryName(data.value.categoryId) }}</span>
                    </div>
                </template>
                <template v-if="vg_selectedLocation.id" v-slot:cbd_min="data">
                    <div v-if="data.value.thcCbdValueType=='range'">
                        <p><span class="text-white">THC:</span> {{ data.value.thcMin }} - {{ data.value.thcMax }}{{data.value.concentration_unit}}</p>
                        <p><span class="text-white">CBD:</span> {{ data.value.cbdMin }} - {{ data.value.cbdMax }}{{data.value.concentration_unit}}</p>
                    </div>
                    <div v-else>
                        <p><span class="text-white">THC:</span> {{ data.value.thc }}{{data.value.concentration_unit}}</p>
                        <p><span class="text-white">CBD:</span> {{ data.value.cbd }}{{data.value.concentration_unit}}</p>
                    </div>
                </template>
                <template v-if="vg_selectedLocation.id" v-slot:count="data">
                    <p v-if="data.value?.ppiStock">{{data.value.ppiStock.toLocaleString()}}</p>
                    <p class="text-white" v-if="data.value?.variants">{{(data.value.variants.length+1)}} variants</p>
                </template>
                <template v-slot:updatedAt="data">
                    <span>{{formatDate(data.value.updatedAt)}}</span>
                </template>
            </new-table-lite>

        </div>
    </div>
    
</template>
<script>
import debounce from 'lodash/debounce'
import Env from '@/env';
import { defineComponent, reactive, ref} from "vue";
import {mapActions, mapGetters, mapMutations} from 'vuex'
import BusinessProduct from '@/controllers/BusinessProductApis'
import NewTableLite from '@/components/table/tableLite.vue'
import SelectBrand from '@/components/form/SelectBrand.vue'
import SelectCategory from '@/components/form/SelectCategory.vue'
import SelectCheckboxMinOne from '@/components/form/SelectCheckboxMinOne.vue'
import SelectThcCbd from '@/components/form/SelectThcCbd.vue'
import SelectUpdate from '@/components/form/SelectUpdatedOn.vue'
import ProductHelper from '@/helpers/product'
import { useSnackbar } from "vue3-snackbar"
import moment from 'moment'

export default defineComponent({
    props:['resized'],
    components: {
        SelectBrand,
        SelectCategory,
        SelectThcCbd,
        SelectUpdate,
        NewTableLite,
        SelectCheckboxMinOne
    },
    setup(){
        // list
        const snackbar = useSnackbar();
        const toggleAddDropDown = ref(false)
        const search = ref('')
        const selectedBrand = ref([])
        const selectedCategory = ref('')
        const selectUpdateOn = ref({})
        const checkedProduct = ref([])
        const unCheckAll = ref(false)
        const countCheck = ref(null)
        const table = reactive({
            loading: false,
            columns: [
                {label: 'Id', field: 'id', sortable:false, isKey:true, columnClasses:'hidden', headerClasses:'hidden' },
                {label: 'Name', field:'name', sortable: false, isKey: true},
                {label: 'Category', field:'categoryId', sortable:false},
                {label: 'Brand', field:'brandId', sortable:false, headerClasses:'width'},
                {label: 'SKU', field: 'sku', sortable: false},
                {label: 'Count', field:'count', sortable: false},
                {label: 'THC/CBD', field:'cbd_min', sortable: false},
                {label: 'Updated On', field:'updatedAt', sortable: false, headerClasses:'hidden'},
            ],
            rows: [],
            totalRecords: 0,
            page: 1,
            sortable: {
                order: "",
                sort: "",
            },
            pagesize: Env.defaultPageSize,
            pageOptions: Env.pageList,
            count: 0,
            clearChecked: false,
        })
        const rows = ref([])
        const categories = ref([])
        const brands = ref([])
        const selectedProductStatus = ref(['active'])
        const productStatus = ([
            {id: 'active',name: 'Active'},
            {id: 'outofStock',name: 'Out of stock'},
        ])
        return {snackbar, toggleAddDropDown, search, selectedBrand, selectedCategory, selectUpdateOn, checkedProduct, unCheckAll, countCheck, table, rows, categories, brands, selectedProductStatus, productStatus}
    },
    mounted(){
        if(this.$route.query.search){
            this.search = this.$route.query.search
        }
        this.selectTableColumn()
        this.fetchProduct()
        this.getCategory()
        this.getBrand()
        this.emitter.on("reloadProductList", this.reloadProductList);
    },
    beforeDestroy() {
        this.emitter.off("reloadProductList", this.reloadProductList);
    },
    watch: {
        '$route.query.search'(val){
            this.search = val
            this.searchProduct()
        },
        search: debounce(async function(newVal, oldVal){
            if(newVal?.length != 2  &&  newVal?.length != 1) {
                this.searchProduct()
            }
        }, 900),
        'selectedBrand.length'(){
            this.searchProduct()
        },
        'selectedProductStatus.length'(){
            this.searchProduct()
        },
        selectedCategory(){
            this.searchProduct()
        },

        vg_selectedLocation(){
            this.selectTableColumn()
            this.fetchProduct()
            this.getCategory()
            this.getBrand()
            this.emitter.emit('closeAddEdit')
        },
        resized(val){
            if(val){
                this.selectTableColumn('addEdit')
            }else{
                this.selectTableColumn()
            }
        }
    },
    computed: {
        ...mapGetters({
            vg_selectedBusiness: 'auth/activeBusiness',
            vg_selectedLocation: 'location/selected',
            vg_modified: 'modifiedForm/status'
        })
    },
    methods: {
        // toggle clearChecked value to clear the checkbox
        clearChecked(){
            this.table.clearChecked = !this.table.clearChecked
        },
        // track recent clear boolean value
        clearedChecked(val){
            this.table.clearChecked = val
        },
        toggleDropDown(){
            this.toggleAddDropDown = !this.toggleAddDropDown
        },
        closeDropDown(){
            this.toggleAddDropDown = false
        },
        focusOutDropDown(el){
            if(!el.relatedTarget){
                this.toggleAddDropDown = false
            }
        },
        openPurchaseOrderUpload(){
            this.toggleAddDropDown = false
            this.$emit('togglePurchaseOrderUploadPop', true, false)
        },
        openPurchaseOrderUploadV1(){
            this.toggleAddDropDown = false
            this.$emit('togglePurchaseOrderUploadPopV1', true, false)
        },
        openTendyTemplateUpload(){
            this.toggleAddDropDown = false
            this.$emit('togglePurchaseOrderUploadPop', true, true)
        },
        openAdd(){
            this.toggleAddDropDown = false
            // remove all selection
            this.removeSelectRow(this.table.rows[0])
            this.$emit('toggleAddEdit', {add: true, edit: false, editItem: {}, printMenu: false, exportProductCatalogue: false})
        },
        openPrintMenu() {
          this.toggleAddDropDown = false
          // remove all selection
          this.removeSelectRow(this.table.rows[0])
          this.$emit('toggleAddEdit', {add: false, edit: false, editItem: {}, printMenu: true, exportProductCatalogue: false})
        },
        exportProductCatalogue(){
            if(this.checkedProduct?.length>0){
                this.toggleAddDropDown = false
                this.$emit('toggleAddEdit', {add: false, edit: false, editItem: {}, printMenu: false, exportProductCatalogue: true, selectedProducts: this.checkedProduct})
            }
        },
        selectedRow(item){
            if(this.vg_modified){
                this.confirmNew('Leave?', 'Are you sure you want to leave? Your changes have not been saved','Yes', 'bg-white text-theme-red', 'No', 'bg-theme-green text-white', true).then(async () => {
                    this.vm_modified(false)
                    this.doSelectRow(item)
                }).catch(err => {
                    console.log(err)
                })
            }else{
                this.doSelectRow(item)
            }
        },
        doSelectRow(item){
            // selection effect
            let activeRow = document.getElementsByClassName('id-'+item.id);
             if(activeRow[0] && activeRow[0].parentNode && activeRow[0].parentNode.children){
                for(let sibling of activeRow[0].parentNode.children){
                    sibling.classList.remove('active-row')
                }
             }
             activeRow[0].classList.add("active-row")

            // action
            this.$emit('toggleAddEdit', {add: false, edit: true, editItem: item})
        },
        checkedRow(items){
            this.countCheck = items.length
            this.checkedProduct = items
        },
        removeSelectRow(item){
            if(item?.id){
            let activeRow = document.getElementsByClassName('id-'+item.id);
             if(activeRow[0] && activeRow[0].parentNode && activeRow[0].parentNode.children){
                for(let sibling of activeRow[0].parentNode.children){
                    sibling.classList.remove('active-row')
                }
             }
            }
        },
        
        selectTableColumn(type=''){
            if(type=='addEdit'){
                this.table.columns = [
                    {label: 'Id', field: 'id', sortable:false, isKey:true, columnClasses:'hidden', headerClasses:'hidden' },
                    {label: 'Name', field:'name', sortable: false, isKey: false},
                    {label: 'Category', field:'categoryId', sortable:false},
                    // {label: 'Brand', field:'brandId', sortable:false}
                ]
            }else if(this.vg_selectedLocation.id){
                this.table.columns = [
                    {label: 'Id', field: 'id', sortable:false, isKey:true, columnClasses:'hidden', headerClasses:'hidden' },
                    {label: 'Name', field:'name', sortable: false},
                    {label: 'Category', field:'categoryId', sortable:false},
                    {label: 'Brand', field:'brandId', sortable:false, headerClasses:'width'},
                    {label: 'SKU', field: 'sku', sortable: false},
                    {label: 'Count', field:'count', sortable: false},
                    {label: 'THC/CBD', field:'cbd_min', sortable: false},
                    {label: 'Updated On', field:'updatedAt', sortable: false, headerClasses:'width'},
                    
                ]
            }else{
                this.table.columns = [
                    {label: 'Id', field: 'id', sortable:false, isKey:true, columnClasses:'hidden', headerClasses:'hidden' },
                    {label: 'Name', field:'name', sortable: false, isKey: false},
                    {label: 'Category', field:'categoryId', sortable:false},
                    {label: 'Brand', field:'brandId', sortable:false, headerClasses:'width'},
                    {label: 'SKU', field: 'sku', sortable: false},
                    {label: 'Updated On', field:'updatedAt', sortable: false, headerClasses:'width'},
                ]
            }
        },
        async getCategory(){
            this.categories = []
            let res = await BusinessProduct.fetchAllCategory()
            if(res.data?.payload?.category){
                //let rootCategory = res.data.payload.category.sort((a, b) => a.name.localeCompare(b.name))
                let rootCategory = res.data.payload.category
                for(let i=0; i<rootCategory.length; i++){
                    this.categories.push(rootCategory[i])
                    if(rootCategory[i].subCategories && rootCategory[i].subCategories.length>0){
                        for(let j=0; j<rootCategory[i].subCategories.length; j++){
                            this.categories.push(rootCategory[i].subCategories[j])
                        }
                    }
                }
            }
        },
        async getBrand(){
            let {data: {payload}} = await BusinessProduct.fetchAllBrands(this.vg_selectedLocation?.id)
            this.brands = payload.brands
        },
        getBrandName(brandId){
            if(this.brands?.length>0){
                let result = this.brands.find(each => each.id===brandId)
                if(result){
                    return result.name
                }
            }
            return ''
        },
        getCategoryName(categoryId){
            if(this.categories?.length>0){
                let result = this.categories.find(each => each.id===categoryId)
                if(result){
                    return result.name
                }
            }
            return ''
        },
        getCategoryColor(categoryId){
            if(this.categories?.length>0){
                let result = this.categories.find(each => each.id===categoryId)
                if(result){
                    return result.posColour
                }
            }
            return ''
        },
        changePage(offset, limit){
            this.table.page = (offset/limit)+1
        },
        async searchProduct(){
            this.table.loading = true
            this.table.rows = this.rows.filter(each => {
                let match1 = false
                let match2 = false
                let match3 = false
                let match4 = false
                // search
                if(this.search){
                    let search = this.search?.toString()?.toLowerCase()
                    match1 = each.name.toLowerCase().includes(search) || each.brand?.name?.toLowerCase().includes(search) || each.sku?.toLowerCase().includes(search) || each.variants?.findIndex(variant => variant.sku?.toLowerCase().includes(search))>=0 || each.barcode?.toString().includes(search) || each.categoryName?.toLowerCase().includes(search)
                }else{
                    match1 = true
                }
                // category
                if(this.selectedCategory){
                    if(this.selectedCategory=='noCategory'){
                        match2 = each.categoryId===null
                    }else if(each.categoryId){
                        match2 = this.selectedCategory==each.categoryId
                    }
                }else{
                    match2 = true
                }
                // brand
                if(this.selectedBrand.length>0){
                    match3 = this.selectedBrand.includes(each.brandId)
                }else{
                    match3 = true
                }
                if(!this.search && !this.selectedCategory && this.selectedBrand.length==0){
                    match1 = true
                    match2 = true
                    match3 = true
                }
                // stock archived
                if(this.selectedProductStatus.length<3){
                    let match41 = false
                    let match42 = false
                    if(this.selectedProductStatus.includes('active')){
                        match41 = each.ppiStock && each.ppiStock>0
                    }
                    if(this.selectedProductStatus.includes('outofStock')){
                        match42 = each.ppiStock===0 || !each.ppiStock
                    }
                    match4 = match41 || match42
                }else{
                    match4 = true
                }

                return match1 && match2 && match3 && match4
            }).sort(function(a, b) {
                return b.ppiStock-a.ppiStock
            })
            this.table.totalRecords = this.table.rows.length
            this.table.page = 1
            this.table.loading = false
        },
        async fetchProduct(){
            this.table.loading = true
            this.table.clearChecked = false
            let params = 'availableOnly=false&isPagination=false'
            let {data: {payload}} = await BusinessProduct.fetchAllProduct(params)
            this.rows = payload.products || payload
            this.table.rows = payload.products || payload
            this.table.totalRecords = payload.products?.length || payload?.length
            this.table.sortable.order = ''
            this.table.sortable.sort = ''
            this.table.clearChecked = true
            this.table.loading = false
            if(this.search || this.selectedCategory || this.selectedBrand.length>0 || this.selectedProductStatus.length>0){
                this.searchProduct()
            }
        },
        async reloadProductList(editItem){
            this.fetchProduct()
            if(editItem?.id){
                this.$emit('updatedEditItem', editItem)
            }
        },
        printProductCards() {
            if(!this.vg_selectedLocation.id){
                this.snackbar.add({
                    type: 'info',
                    text: "Please select a location from the top left corner.",
                    duration: 3000,
                })
                this.toggleAddDropDown = false;
                return
            }
            if(this.checkedProduct?.length>0){
                let selectedProduct = this.rows.filter(each => this.checkedProduct.includes(each.id.toString()))
                this.toggleAddDropDown = false;
                this.$emit('openQrCode', selectedProduct)
            }
        },
        mergeProducts(){
            if(this.checkedProduct?.length>0){
                this.toggleAddDropDown = false;
                if(!this.vg_selectedLocation.id){
                    this.snackbar.add({
                            type: 'info',
                            text: "Please select a location from the top left corner.",
                            duration: 3000,
                        })
                    return
                }
                let checkProductAndVariants = ProductHelper.getProductVariantIds(this.table.rows, this.checkedProduct)
                this.$emit('openMergeProducts', checkProductAndVariants)
            }
        },
        addItemsToCategories(){
            if(this.checkedProduct?.length>0){
                this.toggleAddDropDown = false;
                if(!this.vg_selectedLocation.id){
                    this.snackbar.add({
                            type: 'info',
                            text: "Please select a location from the top left corner.",
                            duration: 3000,
                        })
                    return
                }
                this.$emit('openCategorySelect', this.checkedProduct)
            }
        },
        formatDate(date){
            if(!date) return
            return moment(date).format('YYYY-MM-DD')
        },
        ...mapMutations({
            vm_spinner: 'spinner/mutateSpinner',
            vm_modified: 'modifiedForm/mutateStatus'
        }),
    }
})
</script>

export default {
    namespaced: true,
    state: {
      active: false,
    },
    getters: {
      status: state => state.active,
    },
    mutations: {
      mutateStatus(state, data) {
        state.active = data;
      },
    },
    actions: {
      setStatus(context, data) {
        context.commit('mutateStatus', data);
      },
    },
  };
  
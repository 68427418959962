<template>
    <div>
        <div class="text-[24px] text-white font-semibold mb-3 flex justify-between">
            Customer Migration
            <download-csv v-if="downloadableCsv.length>0" id="downloadPosMembership"  :data="downloadableCsv" name="pos.csv" class="invisible" />
            <button @click=" create()" :disabled="invalid" class="text-sm font-medium tracking-widest text-theme-green py-2 px-4 rounded bg-white disabled:bg-theme-grey disabled:text-grey  hover:text-white hover:bg-theme-darkgreen">IMPORT AND DOWNLOAD CSV</button>
        </div>

        <div v-if="error" class="relative border border-theme-red text-theme-red rounded w-full p-2 break-all pr-5">
            {{error}}
            <a  @click.prevent="close"  href="" class="right-1 top-1 absolute"><mdicon class="cursor-pointer text-theme-red " name="close"/></a>
        </div>

        <div class="py-2 grid gap-4 xl:grid-cols-4 grid-cols-1">
            <div class="flex flex-col gap-2">
                <div class="block text-white text-sm">POS</div>
                <Upload id="pos" @change="addPosFile" :reset="reset" />
            </div>
            <div class="flex flex-col gap-2">
                <div class="block text-white text-sm">Membership</div>
                <Upload id="membership" @change="addMembershipFile" :reset="reset" />
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent, ref} from "vue"
import { useSnackbar } from "vue3-snackbar";
import {mapMutations } from "vuex"
import Upload from './Upload'
import ToolsApis from '@/controllers/ToolsApis'
import Helpers from '@/helpers'
import downloadCsv from 'vue-json-csv'

export default defineComponent({
    components: {
        Upload,
        downloadCsv
    },
    setup() {
        const snackbar = useSnackbar();
        const invalid = ref(true)
        const form = ref({
            pos: null,
            membership: null
        })
        const error = ref('')
        const reset = ref(false)
        const downloadableCsv = ref([])

        return {snackbar, invalid, form, reset, error, downloadableCsv}
    },
    methods: {
        close(){
            this.error = ''
        },
        addPosFile(value){
            if(value.data) this.form.pos = value
            if(this.form.pos && this.form.membership){
                this.invalid = false
            }else{
                this.invalid = true
            }
        },
        addMembershipFile(value){
            if(value.data) this.form.membership = value
            if(this.form.pos && this.form.membership){
                this.invalid = false
            }else{
                this.invalid = true
            }
        },
        async create(){
            this.vm_spinner(true)
            let formData = new FormData();
            formData.append("pos", this.form.pos.data, this.form.pos.name)
            formData.append("membership", this.form.membership.data, this.form.membership.name)
            let res = await ToolsApis.migrateCustomer(formData)

            if(res.status!=201){
                this.error = Helpers.catchErrorMessage(res.response.data)
            }else{
                this.snackbar.add({
                    type: 'info',
                    text: res.data.message,
                    duration: 3000
                })
            }

            if(res.status===201){
                this.resetComponent()
                this.downloadCsv(res.data.payload)
            }
            this.vm_spinner(false)
        },
        downloadCsv(jsonData){
            let data = []
            let sample = {}
            for(let i=0; i<jsonData.length; i++){
                if(i==0){
                    sample["First Name"] = jsonData[i]["First Name"]
                    sample["Last Name"] = jsonData[i]["Last Name"]
                    sample["Date Of Birth"] = jsonData[i]["Date Of Birth"]
                    sample["Phone"] = jsonData[i]["Phone"]
                    sample["Email"] = jsonData[i]["Email"]
                    sample["Pricing Group"] = jsonData[i]["Pricing Group"]
                    sample["Notes"] = jsonData[i]["Notes"]
                    sample["Allow Marketing"] = jsonData[i]["Allow Marketing"]
                    sample["Medical Patient"] = jsonData[i]["Medical Patient"]
                    sample["Street Address 1"] = jsonData[i]["Street Address 1"]
                    sample["Street Address 2"] = jsonData[i]["Street Address 2"]
                    sample["Post Office Box Number"] = jsonData[i]["Post Office Box Number"]
                    sample["City"] = jsonData[i]["City"]
                    sample["Region"] = jsonData[i]["Region"]
                    sample["Postal Code"] = jsonData[i]["Postal Code"]
                    sample["Country"] = jsonData[i]["Country"]
                    sample["Caregiver"] = jsonData[i]["Caregiver"]
                    sample["Created Date"] = jsonData[i]["Created Date"]
                    sample["Disabled"] = jsonData[i]["Disabled"]
                    sample["Customer Type"] = jsonData[i]["Customer Type"]
                    sample["Last Modified Date Utc"] = jsonData[i]["Last Modified Date Utc"]
                    sample["Temporary Medical License"] = jsonData[i]["Temporary Medical License"]
                    sample["Gram Limit"] = jsonData[i]["Gram Limit"]
                    sample["Plant Count"] = jsonData[i]["Plant Count"]
                    sample["Allow Email"] = jsonData[i]["Allow Email"]
                    sample["Allow SMS"] = jsonData[i]["Allow SMS"]
                    sample["Membership Points"] = jsonData[i]["Membership Points"]
                    sample["Total Spend"] = jsonData[i]["Total Spend"]
                    sample["Loyalty Number"] = jsonData[i]["Loyalty Number"]
                    sample["Social Id"] = jsonData[i]["Social Id"]
                    sample["Social Type"] = jsonData[i]["Social Type"]
                    sample["Verified Email"] = jsonData[i]["Verified Email"]
                    sample["Verified Phone"] = jsonData[i]["Verified Phone"] 

                    data.push(sample)
                }else{
                    data.push(jsonData[i])
                }
            }
            this.downloadableCsv = data
            setTimeout(function(){
                document.getElementById('downloadPosMembership').click()
            }, 1000)
        },
        resetComponent(){
            this.reset = !this.reset
        },
        ...mapMutations({
            vm_spinner: 'spinner/mutateSpinner'
        })
    }
})
</script>
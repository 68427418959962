
import { defineComponent, ref } from 'vue'
import {mapGetters, mapMutations} from 'vuex'

export default defineComponent({
    setup() {
   
    },
    mounted(){
      this.vm_active(false)
    },
    computed: {
      ...mapGetters({
        vg_active: 'confirmNew/active',
        vg_msg: 'confirmNew/msg',
        vg_extraMsg: 'confirmNew/extraMsg',
        vg_confirmLeft: 'confirmNew/confirmLeft',
        vg_confirmText: 'confirmNew/confirmText',
        vg_confirmClass: 'confirmNew/confirmClass',
        vg_cancelText: 'confirmNew/cancelText',
        vg_cancelClass: 'confirmNew/cancelClass',
      })
    },
    methods: {
      reject(){
        this.vm_reject()
      },
      accept(){
        this.vm_accept()
      },
      ...mapMutations({
        vm_active: 'confirmNew/active',
        vm_accept: 'confirmNew/accept',
        vm_reject: 'confirmNew/reject'
      })
    }
})

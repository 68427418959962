<template>
  <section class="fixed z-[100] bg-theme-grey w-full" style="box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);">
    <div class="mx-auto sm:px-3 lg:px-5 h-[60px]">
      <div class="flex items-center justify-between w-full h-full">
        <div class="flex items-center justify-start gap-3 flex-row">
          
            <!-- logo -->
            <router-link :to="{name: 'ReportDashboard'}">
                  <!-- <link rel="icon" href="<%= BASE_URL %>favicon.ico"> -->
              <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.2771 10.1888L23.8023 19.7587C24.3641 20.7318 23.6618 21.9482 22.5381 21.9482L13.3069 21.9482L13.3069 10.1888L18.2771 10.1888Z" fill="white"/>
                <path d="M10.736 0.782108C11.2979 -0.191042 12.7025 -0.191041 13.2644 0.78211L17.0922 7.41213H6.9082L10.736 0.782108Z" fill="#B0EDAE"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.355 11.2836L1.14586 20.3061C1.0054 20.5493 1.18098 20.8534 1.4619 20.8534L9.59827 20.8534L9.59826 11.2836L6.355 11.2836ZM0.197744 19.7587L5.72292 10.1888L10.6931 10.1888L10.6931 21.9482L1.4619 21.9482C0.338206 21.9482 -0.364105 20.7318 0.197744 19.7587Z" fill="white"/>
              </svg>
            </router-link>

            <div class="w-[184px]">
              <location-selector @clickNewlocation="addnewlocationmodal = true" @selectTheLocation="selectTheLocation" />
            </div>


            <!-- breadcrumb -->
            <div class="flex flex-row gap-2 ml-5 mt-3">
              <h1 class="text-white text-sm" v-if="getCurrentBreadCrumb && getCurrentBreadCrumb[0]">
                {{getCurrentBreadCrumb[0]}}
              </h1>
              <span v-if="getCurrentBreadCrumb && (getCurrentBreadCrumb[1] || getCurrentBreadCrumb[2])" class="text-white">/</span>
              <h2 :class="(getCurrentBreadCrumb && getCurrentBreadCrumb[2])?'text-white text-sm':'text-theme-green text-sm'" v-if="getCurrentBreadCrumb && getCurrentBreadCrumb[1]">{{getCurrentBreadCrumb[1]}}</h2>
              <span class="text-white" v-if="getCurrentBreadCrumb && getCurrentBreadCrumb[2]">/</span>
              <h2 class="text-theme-green text-sm" v-if="getCurrentBreadCrumb && getCurrentBreadCrumb[2]">{{getCurrentBreadCrumb[2]}}</h2>
            </div>
        </div>

        <div class="text-white flex text-sm">
          <span class="pt-2">{{vg_selectedBusiness.name}}</span>
        </div>

        <div class="min-w-fit justify-center items-center flex flex-row large:gap-10 small:gap-2">
          <!-- Help  -->
          <div @click="openHelp" class="flex flex-row justify-center items-center gap-2 cursor-pointer text-white hover:text-white/[0.8]">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 10C0 4.48 4.48 0 10 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 10 20C4.48 20 0 15.52 0 10ZM11 14V16H9V14H11ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM6 8C6 5.79 7.79 4 10 4C12.21 4 14 5.79 14 8C14 9.28292 13.21 9.97332 12.4408 10.6455C11.711 11.2833 11 11.9046 11 13H9C9 11.1787 9.94212 10.4566 10.7704 9.82168C11.4202 9.32361 12 8.87922 12 8C12 6.9 11.1 6 10 6C8.9 6 8 6.9 8 8H6Z" fill="white"/>
            </svg>
            <span class=" text-sm">Help</span>
          </div>

          <div class="relative" tabindex="1" @blur="closeUserMenu">
            <div @click="toggleUserMenu"  class="flex flex-row justify-center items-center gap-2 max-w-[200px] cursor-pointer">
              <div>
                <img class="h-full w-9 rounded-full pl-1 pt-1" v-if="vg_user.imageUrl" :src="vg_user.imageUrl" alt="" />
                <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM5.07 16.28C5.5 15.38 8.12 14.5 10 14.5C11.88 14.5 14.51 15.38 14.93 16.28C13.57 17.36 11.86 18 10 18C8.14 18 6.43 17.36 5.07 16.28ZM10 12.5C11.46 12.5 14.93 13.09 16.36 14.83C17.38 13.49 18 11.82 18 10C18 5.59 14.41 2 10 2C5.59 2 2 5.59 2 10C2 11.82 2.62 13.49 3.64 14.83C5.07 13.09 8.54 12.5 10 12.5ZM10 4C8.06 4 6.5 5.56 6.5 7.5C6.5 9.44 8.06 11 10 11C11.94 11 13.5 9.44 13.5 7.5C13.5 5.56 11.94 4 10 4ZM8.5 7.5C8.5 8.33 9.17 9 10 9C10.83 9 11.5 8.33 11.5 7.5C11.5 6.67 10.83 6 10 6C9.17 6 8.5 6.67 8.5 7.5Z" fill="white"/>
                </svg>
              </div>
              <span class="text-white truncate">{{vg_user.email}}</span>
              <div >
                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.41 8.29492L12 12.8749L16.59 8.29492L18 9.70492L12 15.7049L6 9.70492L7.41 8.29492Z" fill="white"/>
                </svg>
              </div>
            </div>
            <transition v-show="showUserMenu" enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                <div class="absolute right-0 z-10 w-full origin-top-right rounded-md shadow-lg ring-1 bg-[#1C1D21] ring-black ring-opacity-5 focus:outline-none overflow-hidden">
                    <div class="bg-theme-modalbg cursor-pointer hover:bg-theme-green/[0.3]" v-for="item in userNavigation" :key="item.name">
                        <div class="px-4 py-4 text-sm text-gray-700 flex flex-row gap-3" @click.prevent="menuAction(item.name)">
                          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M2 0H16C17.1 0 18 0.9 18 2V16C18 17.1 17.1 18 16 18H2C0.89 18 0 17.1 0 16V12H2V16H16V2H2V6H0V2C0 0.9 0.89 0 2 0ZM8.5 14L7.09 12.59L9.67 10H0V8H9.67L7.09 5.41L8.5 4L13.5 9L8.5 14Z" fill="white"/>
                          </svg>
                          <span style="color:#FF9E9E">{{ item.name }}</span>
                        </div>
                    </div>
                </div>
            </transition>
          </div>
        </div>
      </div>
    </div>

    <!-- Create location -->
    <AddNewLocation v-if="addnewlocationmodal" :addnewlocationmodal="addnewlocationmodal" :callback="closeNewLocationModal"  />

    <!-- Help -->
    <custom-dialogue v-if="vg_help" size="max-w-[700px]">
      <template v-slot:maincontent>
        <div class="p-4 text-[14px] rounded-lg">
          <div class="relative">
              <h2 class="text-2xl text-white text-center pr-[40px]">Need Help?</h2>
              <span class="absolute right-1 top-1 pointer cursor-pointer hover:opacity-60"  @click.prevent="closeHelp()">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M19.3333 2.54602L17.4533 0.666016L10 8.11935L2.54667 0.666016L0.666672 2.54602L8.12001 9.99935L0.666672 17.4527L2.54667 19.3327L10 11.8793L17.4533 19.3327L19.3333 17.4527L11.88 9.99935L19.3333 2.54602Z" fill="white"/>
                  </svg>
              </span>
          </div>
          <div class="m-2 mt-4 tracking-wider grid-cols-4 bg-theme-modal_inner_bg rounded py-4 px-4 pb-4">
            <div class="max-w-[480px] mx-auto text-white text-center">
              Please reach out to our support team at <a class="underline" target="_blank" href="mailto:support@tendypos.com">support@tendypos.com</a> or <a href="callto: (437) 826-3754">(437) 826-3754</a> for help.
            </div>
          </div>
        </div>
      </template>
    </custom-dialogue>
  </section>
</template>

<script>
import {defineComponent, ref} from "vue"
import { mapActions, mapGetters } from "vuex"
import LocationSelector from '@/components/form/LocationSelector'
import AddNewLocation from '@/views/Location/AddNewLocation.vue';
import CustomDialogue from "@/components/general/dialog/CustomDailogue.vue";

export default defineComponent({
  components: {
    LocationSelector,
    AddNewLocation,
    CustomDialogue
  },
  setup(){
    const showUserMenu = ref(false)
    const addnewlocationmodal = ref(false);
    const userNavigation = [
      // {
      //   name: 'Profile',
      //   href:"/profile"
      // },
      {
        name: 'Logout',
        href:""
      }
    ]
    return {
      showUserMenu,
      addnewlocationmodal,
      userNavigation
    }
  },
  mounted(){
    
  },
  computed: {
    user() {
      let obj = {email: '', name: '', imageUrl: ''}
      obj.email = this.vg_user.email
      obj.name = this.vg_user.name
      return obj
    },
    getCurrentBreadCrumb(){
      let arr = []; 
      if(this.$route.matched.length>0){
        // first breadcrumb
        if(this.$route.matched[0]?.meta?.breadCrumbText){
          arr.push(this.$route.matched[0].meta.breadCrumbText)
        }
        // 2nd breadcrumb from navText
        if(this.$route.matched[1]?.meta?.navText){
          arr.push(this.$route.matched[1].meta.navText)
        }
        if(this.$route.matched[1]?.meta?.navText!==this.$route.matched[1]?.meta?.breadCrumbText){
          arr.push(this.$route.matched[1].meta.breadCrumbText)
        }
      }

      return arr;
    },
    ...mapGetters({
      vg_user: 'auth/user',
      vg_selectedLocation: 'location/selected',
      vg_selectedBusiness: 'auth/activeBusiness',
      vg_help: 'help/status'
    })
  },
  methods: {
    async selectTheLocation(location) {
      this.va_spinner(true);
      if(location.id==this.vg_selectedLocation.id){
        await this.va_selectTheLocation({});
      }else{
        await this.va_selectTheLocation(location);
      }
      this.va_spinner(false);
    },
    /**
     * Close modal 
     */
    closeNewLocationModal() {
      this.addnewlocationmodal = false;
    },
    openHelp(){
      this.va_help(true)
    },
    closeHelp(){
      this.va_help(false)
    },
    toggleUserMenu(){
      this.showUserMenu = !this.showUserMenu
    },
    closeUserMenu(e){
      if(!e.relativeTarget){
        this.showUserMenu = false
      }
    },
    menuAction(name){
      if(name=='Logout'){
        let self = this
        this.confirm('Are you sure to logout?').then(async () => {
          self.va_clearUserData()
          self.$router.push({
            name: 'Login',
            query: {
              reload: 1
            }
          })
        })
      }
    },
    ...mapActions({
      va_spinner: 'spinner/setSpinner',
      va_selectTheLocation: 'location/selectTheLocation',
      va_clearUserData: 'auth/clearUserData',
      va_help: 'help/setHelp'
    })
  }
})
</script>

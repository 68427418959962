<template>
  <div :class="wrapperClass">
    <vue3-snackbar top right :duration="4000"></vue3-snackbar>
    <api-loading/>
    <confirm-modal-new-ui />
    <confirm-modal />
    <main class="flex min-w-[740px] h-auto overflow-hidden">
      <!-- :style="styleVars" -->
      {{userLoggedinFully && !$route.name=='SelectLocation'}}
      <app-header v-if="userLoggedinFully && $route.name!='SelectLocation'"/>
      <div :class="(userLoggedinFully && $route.name!='SelectLocation')?(vg_leftNav?'ml-[216px] right-0 w-[calc(100%_-_236px)] top-[60px]':'ml-[68px] right-0 w-[calc(100%_-_68px)] top-[60px]'):'w-full top-0 '" class="fixed grow h-full">
        <left-nav v-if="userLoggedinFully && $route.name!='SelectLocation'"/>
        <router-view />
      </div>
    </main>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import AppHeader from '@/components/general/layouts/AppHeader.vue';
import LeftNav from '@/components/general/layouts/LeftNav.vue';
import Env from '@/env';
import LoginApis from '@/controllers/LoginApis';

export default defineComponent({
  components: {
    AppHeader,
    LeftNav
  },
  data() {
    return {
      wrapperClass: '',
    }
  },
  computed: {
    ...mapGetters({
      vg_userToken: 'auth/userToken',
      vg_activeBusiness: 'auth/activeBusiness',
      vg_selectedLocation: 'location/selected',
      vg_user: 'auth/user',
      vg_leftNav: 'nav/leftNav',
      vg_branding: 'auth/branding'
    }),
    // styleVars() {
    //   return {
    //     '--primary-color': this.vg_branding.primaryColor,
    //     '--secondary-color': this.vg_branding.secondaryColor
    //   }
    // },

    userLoggedinFully() {
      //if( (this.vg_userToken != '') && !this._lodash.isEmpty(this.vg_activeBusiness) && this.vg_activeBusiness.id && !this._lodash.isEmpty(this.vg_selectedLocation) && this.vg_selectedLocation.id) {
      if( (this.vg_userToken != '') && !this._lodash.isEmpty(this.vg_activeBusiness) && this.vg_activeBusiness.id) {
        return true;
      }
      return false;
    },
  },

  
  async mounted() {
      this.vm_modified(false)
      this.va_setSpinner(false);
      // On load change the title of page
      let pageTitle =this.$route.meta.nav;
      if(pageTitle && pageTitle.name){
        document.title = pageTitle.name;
        this.wrapperClass = this.$route.meta.className;
      }

      // On mounted
      if(this.userLoggedinFully) {
        this.initLoggedInterComConnection(true);

        let myDetailRes  = await LoginApis.getMyDetails('?includeRoles=1&includePermissions=1');
        this.vm_userPermissions((myDetailRes.status == 200 ? myDetailRes.data.payload.permissions : []))
        this.vm_userRoles( (myDetailRes.status == 200) ? myDetailRes.data.payload.roles : [])

      } else {
        // clear details
        this.initLoggedInterComConnection(false);
      }
  },

  watch: {
    // watch for route on change route change the title of page
    '$route': async function (to, from) {
      document.title = to.meta.navText ? to.meta.navText: '';
      this.wrapperClass = to.meta.className;
      // scroll to top of age when page changes
      window.scroll(0,0);
      if(to.path!=from.path){
        this.emitter.all.clear()
      }
    },
    //While watching
    userLoggedinFully (val) {
       // On mounted
       if(val) {
        this.initLoggedInterComConnection(true);
      } else {
        // clear details
        this.initLoggedInterComConnection(false);
      }
    },

  },
  methods: {
    /**
     * Set loggedin User Script
     */

    initLoggedInterComConnection(connect = true) {
      
      window.intercomSettings = connect ? {
        api_base: "https://api-iam.intercom.io",
        app_id: Env.intercomeID,
        name: this.vg_user.name, // Full name
        email: this.vg_user.email, // Email address
        created_at: this.vg_user.createdAt // Signup date as a Unix timestamp
      } : {
        api_base: "https://api-iam.intercom.io",
        app_id: Env.intercomeID,
      };

      if(connect) { 
        (function() {
            var w = window;
            var ic = w.Intercom;
            if (typeof ic === "function") {
                ic('reattach_activator');
                ic('update', w.intercomSettings);
            } else {
              var d = document;
              var i = function() {
                  i.c(arguments);
              };
              i.q = [];
              i.c = function(args) {
                  i.q.push(args);
              };
              w.Intercom = i;
              var l = function() {
                  var s = d.createElement('script');
                  s.type = 'text/javascript';
                  s.id = 'intercome-script';
                  s.async = true;
                  s.src = 'https://widget.intercom.io/widget/' + Env.intercomeID;
                  var x = d.getElementsByTagName('script')[0];
                  x.parentNode.insertBefore(s, x);
              };
              if (document.readyState === 'complete') {
                  l();
              } else if (w.attachEvent) {
                  w.attachEvent('onload', l);
              } else {
                  w.addEventListener('load', l, false);
              }
            }
        })();
      } else {
   
          
        // let intercomeScript = document.getElementById('intercome-script');
        // if(intercomeScript != null)
        // intercomeScript.remove();

        // let intercomeFrame = document.getElementById('intercom-frame');
        // if(intercomeFrame != null)
        // intercomeFrame.remove();

        // let intercomeFrameCss = document.getElementById('intercom-css-container');
        // if(intercomeFrameCss != null)
        // intercomeFrameCss.remove();

        // let intercomeContainer = document.getElementById('intercom-container');
        // if(intercomeContainer != null)
        // intercomeContainer.remove();

      }
    },

    ...mapMutations({
      vm_userPermissions: 'auth/mutateUserPermissions',
      vm_userRoles: 'auth/mutateUserRoles',
      vm_modified: 'modifiedForm/mutateStatus'
    }),

    ...mapActions({
      va_setSpinner: 'spinner/setSpinner',
      
    })
  }
})
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
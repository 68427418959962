import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import createMutationsSharer from "vuex-shared-mutations";
import SecureLS from "secure-ls";
import auth from './auth/index';
import spinner from './helpers/spinner'
import help from './helpers/help'
import confirm from './helpers/confirm'
import audit from './helpers/audit'
import purchaseOrderWarn from './helpers/purchaseOrderWarn'
import confirmNew from './helpers/confirmNew'
import nav from './helpers/nav'
import location from './location/index'
import clover from './clover/index'
import product from './product/index'
import modifiedForm from './helpers/modifiedForm'


const hostname = window.location.hostname
const localhost = hostname === 'localhost';
const env = (function () {
  if (localhost) return 'development'
  if (hostname.includes('dev')) return 'development'
  if (hostname.includes('.uat.')) return 'uat'
  if (hostname.includes('beta')) return 'beta'
  return 'production'
}())


// lets encrypt only if mode is production
var storage;
// if( env == 'production' ) {
//   const ls = new SecureLS({ 
//     encodingType: 'aes',
//     isCompression:  true ,
//     encryptionSecret: 'FSP_SHOPLEY_ENC_34!0BC',
//   });
//   storage = {
//     getItem: (key: string) => ls.get(key),
//     setItem: (key: string, value: any) => ls.set(key, value),
//     removeItem: (key: string) => ls.remove(key)
//   }
// }

export default createStore({
  modules: {
    auth: auth,
    help: help,
    audit: audit,
    purchaseOrderWarn: purchaseOrderWarn,
    spinner: spinner,
    confirm: confirm,
    confirmNew: confirmNew,
    modifiedForm: modifiedForm,
    nav: nav,
    location: location,
    clover: clover,
    product:product
  },
  plugins: [
    createPersistedState({
      storage
    }),
    createMutationsSharer({
      predicate: [
        'auth/mutateUser',
        'auth/mutateActiveBusiness',
        'location/mutateSelectedLocation',
        'clover/detailsMutate',
      ]
    }),
  ]
})

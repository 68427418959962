/**
 * @module login 
 *  Apis must need auth details to trigger url - AuthApi
 */

 import store from '@/store/index';
 import Env from '@/env';
 import NormalApis from '@/controllers/NormalApis';
 import AuthApis from '@/controllers/AuthApis';
 const apiVersion = 'api';
 
 export default {
     /**
      * Login
      */
     login(data:{}) {
         const url = `${Env.loginUrl}/${apiVersion}/auth/login`;
         return NormalApis.postApi(url, {
           'api-token': Env.loginApiToken
         }, data)
             .then(res => res)
             .catch(err => err);
     },

      /**
      * Gey My detail
      */
      getMyDetails(query:any = '') {
        const url = `${Env.loginUrl}/${apiVersion}/users/me${query}`;
        return AuthApis.getApi(url, null)
          .then(res => res)
          .catch(err => err);
      },


       /**
      * Get refresh token
      */
        getRefreshToken({
          businessId,
          locationId,
        }) {
          const url = `${Env.loginUrl}/${apiVersion}/auth/refresh-token`;
          let headers = {
            'Authorization': 'Bearer '+ store.getters['auth/userToken'],
            businessId,
          };
          // only pass location id if available
          if(locationId) {
            headers['locationId'] = locationId;
          }
          return AuthApis.getApi(url, headers)
            .then(res => res)
            .catch(err => err);
        },

      /**
      * Forget password
      */
       forgetPassword(data:{
         email: String
       }) {
        const url = `${Env.loginUrl}/${apiVersion}/auth/forget-password`;
        return NormalApis.postApi(url, {
          'api-token': Env.loginApiToken
        }, data)
        .then(res => res)
        .catch(err => err);
    },


    /**
      * Reset password
      */
     resetPassword(data:{
     }) {
      const url = `${Env.loginUrl}/${apiVersion}/auth/reset-password`;
      return NormalApis.postApi(url, {
        'api-token': Env.loginApiToken
      }, data)
          .then(res => res)
          .catch(err => err);
    }
 }
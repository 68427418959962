export default {
  namespaced: true,
  state: {
    currentTab: '',
  },
  getters: {
    currentTab: state => state.currentTab
  },
  mutations: {
    currentTab(state, data){
      state.currentTab = data
    }
  }
};

<template>
    <div>
        <div class="h-screen bg-[#23242A]">
            <div class="flex flex-col bg-[#23242A] text-white">
                <div class="w-full">
                    <div class="rounded-[10px] bg-[#2B2C32] w-full">
                        <div class=" text-white px-4 pt-4">
                            <div class="flex justify-between mb-3">
                                <span class="text-[25px]">Purchase Order Upload</span>
                                <div>
                                    <button @click="addAsnToProducts()" class="disabled:bg-field-black disabled:text-theme-modalbg text-white bg-theme-green rounded px-4 py-2 uppercase">Confirm all &amp; Continue</button>
                                    <button @click="closeBulkUpload()" class="ml-1 mt-1 disabled:bg-field-black disabled:text-theme-modalbg text-white bg-theme-green rounded px-4 py-2 uppercase">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-full">
                    <div class="rounded-[10px] bg-[#2B2C32] w-full">
                        <div class="text-[25px] text-[#878686] px-4 pt-4">
                            <div class="flex justify-between">
                                <span>Purchase Order Details</span>
                                <button @click="openAdd">
                                    <span class="text-white bg-theme-green hover:bg-green-600 px-3 text-3xl border-0 rounded-md cursor-pointer">+</span>
                                </button>
                            </div>
                        </div>
                        <div class="w-full mt-6 px-2 pb-4">
                            <new-table-lite
                                :is-static-mode="true"
                                :is-slot-mode="true"
                                :is-loading="table.loading"
                                :class="'w-full admin-table admin-table-custom'"
                                :rowClasses="row => [row?('id-'+row.SKU+'-'+row.BatchLot):null, (row.foundProduct && row.foundProduct?.productPricing)?'green':((!row.pmdProduct && !row.foundProduct)?'red':'')]"
                                :columns="table.columns"
                                :rows="table.rows"
                                :total="table.totalRecords"
                                :sortable="table.sortable"
                                :page-size="table.pagesize"
                                :page="table.page"
                                @row-clicked="selectedRow"
                                :pageOptions='table.pageOptions'
                                :messages="{
                                    pagingInfo: 'Total {2}',
                                    pageSizeChangeLabel: 'Items per Page',
                                    gotoPageLabel: 'Go to page',
                                    noDataAvailable: 'No records found!',
                                }"
                            >
                            <template v-slot:pmdProduct="data">
                                {{ data.value?.pmdProduct?.weight_equivalent_dry_cannabis }}
                            </template>
                            </new-table-lite>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent, ref} from 'vue'
import {mapActions, mapGetters, mapMutations} from 'vuex'
import BusinessProductApi from '@/controllers/BusinessProductApis'
import { useSnackbar } from "vue3-snackbar"
import NewTableLite from '@/components/table/tableLite.vue'
import Env from '@/env';
import Helpers from '@/helpers';
import moment from 'moment'

export default defineComponent({
    components: {
        NewTableLite
    },
    props: ['payload', 'addedFromBulk', 'addedData'],
    setup() {
        const snackbar = useSnackbar();
        const removedPayload = []
        const table = ref({
          loading: true,
          columns: [
            {label: 'Id', field: 'SKU', sortable:false, isKey:true, columnClasses:'hidden', headerClasses:'hidden' },
            {label: 'Name', field:'ItemName', sortable: false},
            {label: 'Brand', field:'Brand', sortable:false},
            {label: 'Lot#', field:'BatchLot', sortable:false},
            {label: 'Weight', field:'pmdProduct', sortable: false},
            {label: 'Count', field:'Shipped_Qty', sortable: false},
          ],
          rows: [],
          totalRecords: 0,
          page: 1,
          sortable: {
            order: "",
            sort: "",
          },
           pagesize: Env.defaultPageSize,
           pageOptions: Env.pageList,
           count: 0
        })

        return {snackbar, removedPayload, table}
    },
    mounted(){
        this.removedPayload = [...this.payload.data]
        this.fetchList()
        this.vm_purchaseOrderWarning(true)
    },
    watch: {
        async addedData(value){
            this.removedPayload = await this.removedPayload.filter(each => !(each.SKU==value.SKU && each.BatchLot==value.BatchLot))
            this.fetchList()
        }
    },
    computed: {
        ...mapGetters({
            vg_selectedLocation: 'location/selected',
            vg_locationSelected: "location/selected"
        })
    },
    methods: {
        openAdd(){
            this.$emit('toggleAddEdit', {add: true, edit: false, editItem: {}})
        },
        fetchList(){
            this.table.loading = true
            this.table.rows = this.removedPayload
            this.table.pagesize = Env.defaultPageSize
            this.table.totalRecords = this.removedPayload.length
            this.table.loading = false
        },
        async selectedRow(item){
            // selection effect
            let activeRow = document.getElementsByClassName('id-'+item.SKU+'-'+item.BatchLot);
            if(activeRow[0] && activeRow[0].parentNode && activeRow[0].parentNode.children){
                for(let sibling of activeRow[0].parentNode.children){
                    sibling.classList.remove('active-row')
                }
            }
            activeRow[0].classList.add("active-row")

            // check if product is available in no location 
            let noLocationProduct = await this.searchProductOnNoLocation(item)

            // foundProduct or pmdProduct
            if(item.foundProduct){
                this.$emit('toggleAddEdit', {add: false, edit: true, asn: item, fulfillment: this.payload.fulfillment, editItem: item.foundProduct, noLocationProduct: noLocationProduct})
            }else if(item.pmdProduct){
                this.$emit('toggleAddEdit', {add: true, edit: false, asn: item, fulfillment: this.payload.fulfillment, editItem: item, noLocationProduct: noLocationProduct})
            }else{
                this.$emit('toggleAddEdit', {add: true, edit: false, asn: item, fulfillment: this.payload.fulfillment, editItem: item, noLocationProduct: noLocationProduct})   
            }
        },
        async searchProductOnNoLocation(item){
            let res = await BusinessProductApi.fetchAllNoLocationProduct(`page=1&pageItem=1&includeCannabis=true&includePricing=true&locationId=${this.vg_locationSelected.id}&search=${item.ItemName}`)
            if(res?.data?.payload?.products){
                return res.data.payload.products
            }else{
                return []
            }
            
        },
        async addAsnToProducts(){
            if(this.table.rows && this.table.rows.length>0){
                let asnBatchArr = []
                let asnBatch = {}
                let fulfillmentArr = []
                let foundProducts = this.removedPayload.filter(each => each.foundProduct && each.foundProduct?.productPricing)
                for(let i=0; i<foundProducts.length; i++){
                    asnBatch = {
                        shipmentId: foundProducts[i].ShipmentID?foundProducts[i].ShipmentID.toString():'',
                        locationId: this.vg_selectedLocation.id,
                        productId: foundProducts[i].foundProduct.id,
                        badge_running: true, 
                        current_nou: foundProducts[i].Shipped_Qty?parseInt(foundProducts[i].Shipped_Qty):null, 
                        cost: foundProducts[i].UnitPrice?parseFloat(foundProducts[i].UnitPrice):null, 
                        batch_lot: foundProducts[i].BatchLot?foundProducts[i].BatchLot.toString():null, 
                        packaged_on: foundProducts[i].PackagedOnDate?moment(foundProducts[i].PackagedOnDate).toDate():null, 
                        on_hold: false,
                        reason: "Purchase order",
                        thc: foundProducts[i].pmdProduct?foundProducts[i].pmdProduct.thc:null,
                        cbd: foundProducts[i].pmdProduct?foundProducts[i].pmdProduct.cbd:null,
                        totalTerp: null,
                        thcMin: foundProducts[i].pmdProduct?foundProducts[i].pmdProduct.thc_min:null,
                        thcMax: foundProducts[i].pmdProduct?foundProducts[i].pmdProduct.thc_max:null,
                        cbdMin: foundProducts[i].pmdProduct?foundProducts[i].pmdProduct.cbd_min:null,
                        cbdMax: foundProducts[i].pmdProduct?foundProducts[i].pmdProduct.cbd_max:null,
                        caseSize: foundProducts[i].UOMCONVERSION?foundProducts[i].UOMCONVERSION:'',
                    }
                    // asn optional fields
                    if(foundProducts[i].THC) asnBatch.thc = foundProducts[i].THC
                    if(foundProducts[i].THC_MIN) asnBatch.thcMin = foundProducts[i].THC_MIN
                    if(foundProducts[i].THC_MAX) asnBatch.thcMax = foundProducts[i].THC_MAX
                    if(foundProducts[i].CBD) asnBatch.cbd = foundProducts[i].CBD
                    if(foundProducts[i].CBD_MIN) asnBatch.cbdMin = foundProducts[i].CBD_MIN
                    if(foundProducts[i].CBD_MAX) asnBatch.cbdMax = foundProducts[i].CBD_MAX
                    if(foundProducts[i].TotalTerps) asnBatch.totalTerp = foundProducts[i].TotalTerps
                    asnBatchArr.push(asnBatch)

                    if(this.payload.fulfillment){
                        fulfillmentArr.push({
                            locationId: this.vg_selectedLocation.id,
                            productId:foundProducts[i].foundProduct.id,
                            fullfillmentTime: this.payload.fulfillment
                        })
                    }

                }
                if(asnBatchArr.length>0){
                    this.vm_spinner(true)
                    let res = await BusinessProductApi.createBulkProductBatch({complianceType: foundProducts[0].foundProduct.complianceType, batches: asnBatchArr})
                    if(this.payload.fulfillment){
                        await BusinessProductApi.bulkUpdateFulfillment({times: fulfillmentArr})
                    }
                    if(res.status==201){
                        this.snackbar.add({
                            type: 'info',
                            text: res.status==201 ? `Multiple batches added to your catalogue!` : Helpers.catchErrorMessage(res.response.data),
                            duration: 3000,
                        })
                        
                        this.removedPayload = this.removedPayload.filter(each => {
                            let matched = true
                            for(let i=0; i<foundProducts.length; i++){
                                if(foundProducts[i].SKU==each.SKU && foundProducts[i].BatchLot==each.BatchLot){
                                    matched = false;
                                    break;
                                }
                            }
                            return matched
                        })
                    }else{
                        this.snackbar.add({
                            type: 'info',
                            text: Helpers.catchErrorMessage(res.response.data),
                            duration: 3000,
                        })
                    }
                    // this.doSearch(0, Env.defaultPageSize)
                    this.fetchList()
                    this.vm_spinner(false)
                }else{
                    this.snackbar.add({
                        type: 'info',
                        text: `No eligible product found`,
                        duration: 3000,
                    })
                }
            }
        },
        async closeBulkUpload(){
            await this.confirmNew("Exit", "Are you sure you want to leave purchase order without completing?", "Yes, Exit", "bg-theme-red hover:bg-opacity-70 text-white").then(() => {
                this.va_setPurchaseOrderWarning(false)
                this.$emit('closeBulkUpload')
            }).catch((err) => {

            })
        },
         ...mapMutations({
            vm_spinner: 'spinner/mutateSpinner',
            vm_purchaseOrderWarning: 'purchaseOrderWarn/mutatePurchaseOrderWarning'
        }),
        ...mapActions({
            va_setPurchaseOrderWarning: 'purchaseOrderWarn/setPurchaseOrderWarning'
        })
    }
})
</script>

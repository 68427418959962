
import { defineComponent, ref } from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import Helpers from '@/helpers';
import { useSnackbar } from "vue3-snackbar";
import BusinessApis from "@/controllers/BusinessApis";

export default defineComponent({
  name: "ToggleBusinessStatus",
  components: {
  },
  
  setup() {
    const snackbar = useSnackbar();
    return {
      snackbar,
    };
  },
  
  props: {
    business: {
      type: Object,
      required: true,
    },
    callback: {
      type: Function,
      required: true,
    },
  },

  methods: {
    /**
     * Toggle business status
     */
    async toggleBusinessStatus() {
      this.va_spinner(true);
      let res = await BusinessApis.updateBusiness(this.business.id, {
        status: !this.business.status,
      })
      if(res.status == 200) {
        // re fetch business and sync
        await this.va_setUserBusinesses();
        this.callback();
      }
      this.va_spinner(false);
      this.snackbar.add({
        type: 'info',
        text: res.status == 200 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
        duration: 3000,
      })
    },

    ...mapActions({
      va_spinner: "spinner/setSpinner",
      va_setUserBusinesses: "auth/setUserBusinesses",
    })

  },
});

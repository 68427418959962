
import { defineComponent, ref } from 'vue'
import { LocationMarkerIcon } from '@heroicons/vue/outline'
import { mapGetters, mapMutations} from "vuex";
import store from "@/store";

export default defineComponent({
    components: {
        LocationMarkerIcon
    },
    setup() {
        const locations = ref([])
        const toggle = ref(false)

        return {locations, toggle}
    },
    mounted(){
        if(this.vg_locations && this.vg_locations.length>0){
            this.locations = this.vg_locations?.sort((a, b) => a.name.localeCompare(b.name))
        }
    },
    watch: {
        vg_locations(){
            if(this.vg_locations && this.vg_locations.length>0){
                this.locations = this.vg_locations?.sort((a, b) => a.name.localeCompare(b.name))
            }else{
                this.locations = []
            }
        },
    },
    computed: {
        ...mapGetters({
            vg_locations: "location/list",
            vg_modified: 'modifiedForm/status',
            vg_isUserTypeSuper: "auth/isUserTypeSuper",
            vg_locationSelected: "location/selected",
        })
    },
    methods: {
        toggleMenu(){
            this.toggle = !this.toggle
        },
        closeMenu(){
            this.toggle = false
        },
        newLocation(){
            this.$emit('clickNewlocation')
            this.closeMenu()
        },
        async selectTheLocation(location){
            if(this.vg_modified){
                await store.dispatch("confirmNew/confirmNew", {
                    msg: "Leave?",
                    extraMsg:
                        "Are you sure you want to leave? Your changes have not been saved",
                    confirmText: "Yes",
                    confirmClass: "bg-white text-theme-red",
                    cancelText: "No",
                    cancelClass: "bg-theme-green text-white",
                    confirmLeft: true
                }).then(() => {
                    this.vm_modified(false)
                    this.closeMenu()
                    this.$emit('selectTheLocation', location)
                    
                }).catch((err) => {
                    console.log(err)
                })
            }else{
                this.closeMenu()
                this.$emit('selectTheLocation', location)
            }
        },
        ...mapMutations({
            vm_modified: 'modifiedForm/mutateStatus'
        }),
    }
})

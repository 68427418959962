<template>
    <div class="text-white flex-1 col-span-1 border-0 rounded-t-xl mt-1">
        <div class="pt-3 bg-[#2B2C32] px-[16px] py-[16px] rounded-xl">
            <div v-if="!productLoading">
                <div v-if="!lotTracking" class="relative flex flex-col">
                    <div class="w-full w-full flex justify-between">
                        <div class="input-wrapper">
                            <span class="mb-0 text-sm font-bold text-grey">Lot Tracking</span>
                            <div class="flex mt-1">
                                <label class="flex items-center cursor-pointer">
                                    <input :disabled="!isParentLotTrackingEnabled" type="checkbox" v-model="lotTracking" @click="saveLotTracking" class="hidden peer">
                                    <div class="relative toggle-bg  border-2  h-6 w-11 rounded-full" :class="lotTracking?'bg-theme-green border-theme-green':'bg-theme-red border-theme-red'"></div>
                                </label>
                            </div>
                        </div>
                        <div class=" mt-2">
                            <div class="text-xs text-grey">Total Count</div>
                            <div class="text-sm text-center">{{lotCount?lotCount:(pricing?.stock?pricing.stock:0)}}</div>
                        </div>
                    </div>
                    <div class="text-xs py-4">Ensure you enable lot tracking in the relevant <router-link :to="{name: 'ProductCategories'}" class="uppercase tracking-wider text-theme-green">Categories</router-link></div>
                    <div class="text-xs">Turning off lot tracking will impact the quality of your THC values being displayed to consumers. Before turning off this setting, ensure you have filled out THC % at a lot or variant level.</div>
                </div>
                <div v-else class="flex justify-between gap-2">
                    <div class="bg-[#E7A644] bg-opacity-10 p-2 rounded grow">
                        <div class="flex items-center">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 11.75C8.2125 11.75 8.39063 11.6781 8.53438 11.5344C8.67813 11.3906 8.75 11.2125 8.75 11V8C8.75 7.7875 8.67813 7.60938 8.53438 7.46563C8.39063 7.32188 8.2125 7.25 8 7.25C7.7875 7.25 7.60938 7.32188 7.46563 7.46563C7.32188 7.60938 7.25 7.7875 7.25 8V11C7.25 11.2125 7.32188 11.3906 7.46563 11.5344C7.60938 11.6781 7.7875 11.75 8 11.75ZM8 5.75C8.2125 5.75 8.39063 5.67813 8.53438 5.53438C8.67813 5.39062 8.75 5.2125 8.75 5C8.75 4.7875 8.67813 4.60938 8.53438 4.46563C8.39063 4.32188 8.2125 4.25 8 4.25C7.7875 4.25 7.60938 4.32188 7.46563 4.46563C7.32188 4.60938 7.25 4.7875 7.25 5C7.25 5.2125 7.32188 5.39062 7.46563 5.53438C7.60938 5.67813 7.7875 5.75 8 5.75ZM8 15.5C6.9625 15.5 5.9875 15.3031 5.075 14.9094C4.1625 14.5156 3.36875 13.9813 2.69375 13.3063C2.01875 12.6313 1.48438 11.8375 1.09063 10.925C0.696875 10.0125 0.5 9.0375 0.5 8C0.5 6.9625 0.696875 5.9875 1.09063 5.075C1.48438 4.1625 2.01875 3.36875 2.69375 2.69375C3.36875 2.01875 4.1625 1.48438 5.075 1.09063C5.9875 0.696875 6.9625 0.5 8 0.5C9.0375 0.5 10.0125 0.696875 10.925 1.09063C11.8375 1.48438 12.6313 2.01875 13.3063 2.69375C13.9813 3.36875 14.5156 4.1625 14.9094 5.075C15.3031 5.9875 15.5 6.9625 15.5 8C15.5 9.0375 15.3031 10.0125 14.9094 10.925C14.5156 11.8375 13.9813 12.6313 13.3063 13.3063C12.6313 13.9813 11.8375 14.5156 10.925 14.9094C10.0125 15.3031 9.0375 15.5 8 15.5ZM8 14C9.675 14 11.0938 13.4188 12.2563 12.2563C13.4188 11.0938 14 9.675 14 8C14 6.325 13.4188 4.90625 12.2563 3.74375C11.0938 2.58125 9.675 2 8 2C6.325 2 4.90625 2.58125 3.74375 3.74375C2.58125 4.90625 2 6.325 2 8C2 9.675 2.58125 11.0938 3.74375 12.2563C4.90625 13.4188 6.325 14 8 14Z" fill="#E7A644"/>
                            </svg>
                            <span class="pl-3 text-theme-orange text-sm">Lot tracking is enabled</span>
                        </div>
                        <div  class="text-sm">Lot tracking cannot be updated without going through our support, click <span @click="showHelp" class="cursor-pointer underline text-theme-orange text-sm">Help</span></div>
                    </div>
                    <div class=" mt-2">
                        <div class="text-xs text-grey">Total Count</div>
                        <div class="text-sm text-center">{{lotCount?lotCount:(pricing?.stock?pricing.stock:0)}}</div>
                    </div>
                </div>
            </div>

            <div class="flex flex-row flex-wrap items-center gap-2 mt-3 mb-3" :class="(editItem?.variants?.length>3)?'':'justify-center'"> 
                <div class="w-[125px]">
                    <div class="input-wrapper">
                        <button :disabled="!isAsnAndMatchVariant(editItem)" @click="toggle('editVariant', editItem)" :class="(editVariant && editVariant.id==editItem.id)?'bg-theme-green':'bg-theme-grey'" class="flex justify-center items-center py-3 w-full rounded border border-theme-green text-white text-sm font-bold tracking-wider disabled:border-grey disabled:bg-grey">{{editItem.variantName}}</button>
                    </div>
                </div>  
                <div class="w-[125px]" v-for="(variant, ind) in editItem.variants" :key="'vari-'+ind">
                    <div class="input-wrapper">
                        <button :disabled="!isAsnAndMatchVariant(variant)" @click="toggle('editVariant', variant)" :class="(editVariant && editVariant.id==variant.id)?'bg-theme-green':'bg-theme-grey'" class="flex justify-center items-center py-3 w-full rounded border border-theme-green text-white text-sm font-bold tracking-wider disabled:border-grey disabled:bg-grey">{{variant.variantName}}</button>
                    </div>
                </div>
            </div>
            <p class="text-xs tracking-wider text-theme-878686 text-center">Tap on a variant to see variant-specific information</p>
            <div class="flex justify-center mt-4">
                <button @click="toggle('newVariant')" :class="type=='newVariant'?'bg-theme-green text-white':'bg-black text-theme-green'" class="uppercase text-sm tracking-widest flex  px-4 py-4  rounded border border-theme-green">
                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2 text-white">
                        <path d="M11.3214 20V11.0714H20.25V8.92857H11.3214V0H9.17857V8.92857H0.25V11.0714H9.17857V20H11.3214Z" :fill="type=='newVariant'?'#fff':'#2BA66B'"/>
                    </svg>
                    new variant
                </button>
            </div>
            <div class="bg-theme-borderblack h-1 w-full my-4"></div>
            <div>
                <component :asn="asn" :fulfillment="fulfillment" :is="type" :categories="categories" :brands="brands" :taxes="taxes" :editItem="editItem" :editVariant="editVariant" :type="type" :lotTracking="lotTracking" :cannabisProfile="cannabisProfile" :pricing="pricing" :productTax="productTax" @updateAsnLotTracking="updateAsnLotTracking" />
            </div>
        </div>
        <lot v-if="isLotEnabled()" :editVariant="editVariant" :productCbdThcType="productCbdThcType"  />
        
    </div> 
</template>
<script>
import { ref, defineComponent } from 'vue'
import Helpers from '@/helpers';
import defaultVariant from './regular/defaultVariant.vue'
import newVariant from './regular/newVariant.vue'
import editVariant from './regular/editVariant.vue'
import lot from './lot/lot.vue'
import ProductApi from '@/controllers/BusinessProductApis'
import {mapGetters, mapActions, mapMutations} from 'vuex'
import { useSnackbar } from "vue3-snackbar"


export default defineComponent({
    props: ['productLoading', 'asn', 'fulfillment', 'categories', 'brands', 'taxes', 'editItem', 'cannabisProfile', 'pricing', 'productTax'],
    components: {
        defaultVariant,
        newVariant,
        editVariant,
        lot
    },
    setup() {
        const snackbar = useSnackbar();
        const type = ref('defaultVariant')
        const isParentLotTrackingEnabled = ref(false)
        const lotTracking = ref(false)
        const lotCount = ref(null)
        const editVariant = ref({})
        const productCbdThcType = ref('')

        return {snackbar, type, isParentLotTrackingEnabled, editVariant, lotTracking, lotCount, productCbdThcType}
    },
    mounted(){
        this.type = 'defaultVariant'
        this.editVariant = {}
        this.vm_currentTab({})
        this.updateLotTracking()

        this.emitter.on("changeThcCbdType", this.changeThcCbdType);
        this.emitter.on("changeLotCount", this.changeLotCount)
    },
    unmounted(){
        this.emitter.off("changeThcCbdType", this.changeThcCbdType)
        this.emitter.off("changeLotCount", this.changeLotCount)
    },
    beforeDestroy() {
        this.emitter.off("changeThcCbdType", this.changeThcCbdType)
        this.emitter.off("changeLotCount", this.changeLotCount)
    },
    watch: {
        'editItem.id'(){
            this.type = 'defaultVariant'
            this.editVariant = {}
            this.updateLotTracking()
        },
        'editItem.globalLotTracking'(){
            this.updateLotTracking()
        },
        'editVariant.id' (){
            this.updateLotTracking()
        }
    },
    computed: {
        ...mapGetters({
            vg_selectedBusiness: 'auth/activeBusiness',
            vg_selectedLocation: 'location/selected',
            vg_modified: 'modifiedForm/status'
        })  
    },
    methods: {
        isAsnAndMatchVariant(variant){
            if(this.asn?.sku){
                if(typeof this.asn.sku==='number'){
                    return this.asn.sku==variant.sku
                }else{
                    return this.asn.sku?.toLowerCase()?.trim()===variant.sku?.toLowerCase()?.trim()
                }
            }else{
                return true
            }
        },
        changeThcCbdType(val){
            this.productCbdThcType = val
        },
        changeLotCount(val){
            this.lotCount = val
        },
        updateLotTracking(){
            //this.isParentLotTrackingEnabled = this.editItem.globalLotTracking && this.editItem.categoryLotTracking
            this.isParentLotTrackingEnabled = this.editItem.globalLotTracking
            if(!this.isParentLotTrackingEnabled){
                this.lotTracking = false
            }else{
                if(this.editVariant?.id){
                    this.lotTracking = this.editVariant.lotTracking
                }else{
                    this.lotTracking = this.editItem.lotTracking
                }
            }
        },
        updateAsnLotTracking(val){
            this.lotTracking = val
        },
        toggle(type, variant){
            // show confirm message in product list not in purchase order
            if(this.vg_modified && this.$route.name!=="AddPurchaseOrders"){
                this.confirmNew('Leave?', 'Are you sure you want to leave? Your changes have not been saved','Yes', 'bg-white text-theme-red', 'No', 'bg-theme-green text-white', true).then(async () => {
                    this.vm_modified(false)
                    this.doToggle(type, variant)
                }).catch(err => {
                    console.log(err)
                })
            }else{
                this.doToggle(type, variant)
            }
            
        },
        doToggle(type, variant){
            if((this.type=='newVariant' && type=='newVariant') || (this.type==type && variant.id ==this.editVariant.id)){
                this.editVariant = {}
                this.type = 'defaultVariant'
                this.vm_currentTab({})
            }else{
                this.editVariant = variant
                this.type = type
                this.vm_currentTab(variant)
            }
        },
        isLotEnabled(){
            let variantLotEnabled = (this.editVariant && this.editVariant.id)?this.editVariant.lotTracking:this.editItem.lotTracking
            return this.type=='editVariant' && this.vg_selectedLocation.id && this.editItem.complianceType!='Accessory' && this.isParentLotTrackingEnabled && variantLotEnabled && !this.asn?.sku
        },
        showHelp(){
            this.va_help(true)
        },
        async saveLotTracking(){
            // clicking immediately cause previous data to show up
            let self = this
            setTimeout(async function(){
                let currentEditObj = self.editVariant.id?self.editVariant:self.editItem
                let form = currentEditObj
                form.lotTracking = self.lotTracking
                if(self.vg_selectedLocation.id){
                    form.locationId = self.vg_selectedLocation.id
                }
                let res = await ProductApi.editProduct(currentEditObj.id, form)
                self.snackbar.add({
                    type: 'info',
                    text: res.status == 200 ? "Lot tracking updated successfully." : Helpers.catchErrorMessage(res.response.data),
                    duration: 3000,
                })
                self.$emit('reloadProductDetail', self.editItem.id)
                if(form.lotTracking){
                    self.emitter.emit('reloadEditCannabis', self.editVariant)
                }
            }, 300)
        },
        ...mapMutations({
            vm_currentTab: 'product/currentTab',
            vm_modified: 'modifiedForm/mutateStatus'
        }),
        ...mapActions({
            va_help: 'help/setHelp'
        })
    }
})
</script>

export default {
    namespaced: true,
    state: {
      active: false,
      msg: '',
      extraMsg: "",
      confirmLeft: false,
      cancelText: 'Cancel',
      cancelClass: '',
      confirmText: 'Yes',
      confirmClass: '',
      resolve: null,
      reject: null
    },
    getters: {
        active: state => state.active,
        msg: state => state.msg,
        extraMsg: state => state.extraMsg,
        confirmLeft: state => state.confirmLeft,
        resolve: state => state.resolve,
        reject: state => state.reject,
        cancelText: state => state.cancelText,
        cancelClass: state => state.cancelClass,
        confirmText: state => state.confirmText,
        confirmClass: state => state.confirmClass
    },
    mutations: {
        active(state){
            state.active = false
        },
        accept(state){
            state.active = false
            state.resolve()
        },
        reject(state){
            state.active = false
            state.reject()
        }
    },
    actions: {
        confirmNew(context, content){
            context.state.active = true
            context.state.msg = content.msg?content.msg:'Delete'
            context.state.extraMsg = content.extraMsg?content.extraMsg:"Are you sure you want to delete this product?<br/> We wouldn't want you to do that by mistake!"
            context.state.confirmLeft = content.confirmLeft?content.confirmLeft:false
            context.state.cancelText = content.cancelText?content.cancelText:'Cancel'
            context.state.cancelClass = content.cancelClass?content.cancelClass:''
            context.state.confirmText = content.confirmText?content.confirmText:'Yes'
            context.state.confirmClass = content.confirmClass?content.confirmClass:''
            return new Promise((resolve, reject) => {
                context.state.resolve = resolve
                context.state.reject = reject
            })
        }
    }
  };
  
/**
 * @module Helpers
 */
 import { has, clone, indexOf , filter} from 'lodash';
 import moment from 'moment';
 import store from '@/store/index';

//  import moment from 'moment-timezone';
//  moment.tz.setDefault('America/Toronto')
 export default {
 
   /**
    * Catch error message
    * if obj has payload then get first item from array else fallbat to msg
    */
   catchErrorMessage(obj){
      if(obj && has(obj, 'payload')) {
        if(Array.isArray(obj.payload)) {
          return obj.payload[0];
        } else if ('msg' in obj) {
          return obj.msg ? obj.msg : (obj.message ? obj.message: 'Please try again later.') ;
        } else {
          return obj.payload[0];
        }
      } 
      return (obj && obj.msg) ? obj.msg : (obj.message ?  ( Array.isArray(obj.message) ? obj.message.join(', ') : obj.message) : 'Please try again later.') ;
   },

   /**
    * 
    * @param obj 
    * @param keys 
    * required_multiple_if => it accespts multiple value separated by , 
    * required_if => accepts single value
    * required_if, required_multiple_if, notrequired_multiple_if => [ key : comparingFormAnotherKey | comparingValue | comparingFormAnotherKeyType ]
    * 
    */
   validateFormFields(obj, keys) {
    let error = false;
    for (let key of keys) {
      let dataValue:any = obj[key]['value'];
      let validationKeys = obj[key]['validations'] ? obj[key]['validations'] : [];
      for (let el of validationKeys) {

        // For required
        if(el == 'required') {
          dataValue = typeof(dataValue) == 'string' ? dataValue.trim() : dataValue;
          if(dataValue!==0 && (!dataValue || dataValue == '')){
            obj[key]['error_type'] = 'blank';
            obj[key]['error_message'] = 'This field is required!';
            error = true;
            break;
          } else {
            obj[key]['error_type'] = 'success';
            obj[key]['error_message'] = '';
          }
        
        } else if(el == 'required_min_0') {

          dataValue = typeof(dataValue) == 'string' ? dataValue.trim() : dataValue;
          if(dataValue === '' || dataValue < 0){
            obj[key]['error_type'] = 'blank';
            obj[key]['error_message'] = 'Must be at least 0.';
            error = true;
            break;
          } else {
            obj[key]['error_type'] = 'success';
            obj[key]['error_message'] = '';
          }
        } else if(el == 'required_min_1') {

          dataValue = typeof(dataValue) == 'string' ? dataValue.trim() : dataValue;
          if(dataValue === '' || dataValue < 1){
            obj[key]['error_type'] = 'blank';
            obj[key]['error_message'] = 'Must be at least 1';
            error = true;
            break;
          } else {
            obj[key]['error_type'] = 'success';
            obj[key]['error_message'] = '';
          }

        }   else if ( el.includes("required_if:") ) {
          //required_if anotherkey|anothervalue|is that single string or object
          let elSplit = el.split(':');
          let compareKey = elSplit[1].split('|')[0] ? elSplit[1].split('|')[0].toString() : null;
          let compareValue =  elSplit[1].split('|')[1] ?  elSplit[1].split('|')[1].toString() : null;
          let compareKeyType =  elSplit[1].split('|')[2] ? elSplit[1].split('|')[2].toString() : null;
          let compareData = compareKeyType == 'string' ? obj[compareKey].toString() : obj[compareKey]['value'].toString();
          dataValue = typeof(dataValue) == 'string' ? dataValue.trim() : dataValue;
        
          // if compare data match then only validate
          if(compareData != compareValue) {
            obj[key]['error_type'] = '';
            obj[key]['error_message'] = '';
            // now if compare condition match detect for the field value
          } else if( (compareData == compareValue) && (!dataValue || dataValue == '')){
            obj[key]['error_type'] = 'blank';
            obj[key]['error_message'] = 'This field is required!';
            error = true;
            break;
          } else {
            obj[key]['error_type'] = 'success';
            obj[key]['error_message'] = '';
          }

          // For min:3

        } else if ( el.includes("notrequired_multiple_if:") ) {
          // after : first is compare key on the form
          // seoncd is data value to compare if , then multiple
          // last is compare key type whether we are comparing with sting or obj

          //required_if anotherkey|anothervalue|is that single string or object
          let elSplit = el.split(':');
          let compareKey = elSplit[1].split('|')[0] ? elSplit[1].split('|')[0].toString() : null;
          let compareValue =  elSplit[1].split('|')[1] ?  elSplit[1].split('|')[1].toString() : null;
          // if value has comma then multiple value , 
          let compareValues:any = compareValue.split(',');
          let compareKeyType =  elSplit[1].split('|')[2] ? elSplit[1].split('|')[2].toString() : null;
          let compareData = compareKeyType == 'string' ? obj[compareKey].toString() : obj[compareKey]['value'].toString();
          dataValue = (typeof(dataValue) == 'string') ? dataValue.trim() : dataValue;
           
          // console.log(compareValues)
          //console.log(compareValues.indexOf(compareData))
          let dataIndex = compareValues.indexOf(compareData);
          // if compare data match then only validate
          if(dataIndex > -1) {
          
            obj[key]['error_type'] = '';
            obj[key]['error_message'] = '';
            // now if compare condition match detect for the field value
          } else if( !dataValue || dataValue == ''){
            
            obj[key]['error_type'] = 'blank';
            obj[key]['error_message'] = 'This field is required!';
            error = true;
            break;
          } else {
            console.log('else')
            obj[key]['error_type'] = 'success';
            obj[key]['error_message'] = '';
          }

          // For min:3
      
        } else if ( el.includes("required_multiple_if:") ) {
          

          //required_if anotherkey|anothervalue|is that single string or object
          let elSplit = el.split(':');
          let compareKey = elSplit[1].split('|')[0] ? elSplit[1].split('|')[0].toString() : null;
          let compareValue =  elSplit[1].split('|')[1] ?  elSplit[1].split('|')[1].toString() : null;
          // if value has comma then multiple value , 
          let compareValues:any = compareValue.split(',');
          let compareKeyType =  elSplit[1].split('|')[2] ? elSplit[1].split('|')[2].toString() : null;
          let compareData = compareKeyType == 'string' ? obj[compareKey].toString() : obj[compareKey]['value'].toString();
          dataValue = (typeof(dataValue) == 'string') ? dataValue.trim() : dataValue;
           
          // if compare data match then only validate
          if(compareValues.indexOf(compareData) < -1 ) {
            obj[key]['error_type'] = '';
            obj[key]['error_message'] = '';
            // now if compare condition match detect for the field value
          } else if( (compareData == compareValue) && (!dataValue || dataValue == '')){
            obj[key]['error_type'] = 'blank';
            obj[key]['error_message'] = 'This field is required!';
            error = true;
            break;
          } else {
            obj[key]['error_type'] = 'success';
            obj[key]['error_message'] = '';
          }

          // For min:3
      
        } else if(el == 'min:2') {
          if(dataValue.length < 2){
            obj[key]['error_type'] = 'error';
            obj[key]['error_message'] = 'Must be at least 2 characters!';
            error = true;
            break;
          } else {
            obj[key]['error_type'] = 'success';
            obj[key]['error_message'] = '';
          }

        } else if(el == 'min:3') {
          if(dataValue.length < 3){
            obj[key]['error_type'] = 'error';
            obj[key]['error_message'] = 'Must be at least 3 characters!';
            error = true;
            break;
          } else {
            obj[key]['error_type'] = 'success';
            obj[key]['error_message'] = '';
          }
        } else if(el == 'max:15') {
          if(dataValue?.length > 15){
            obj[key]['error_type'] = 'error';
            obj[key]['error_message'] = 'Must be less than 16 characters!';
            error = true;
            break;
          } else {
            obj[key]['error_type'] = 'success';
            obj[key]['error_message'] = '';
          }  
        } else if(el == 'max:100') {
          if(dataValue?.length > 100){
            obj[key]['error_type'] = 'error';
            obj[key]['error_message'] = 'Must be less than 100 characters!';
            error = true;
            break;
          } else {
            obj[key]['error_type'] = 'success';
            obj[key]['error_message'] = '';
          }  
        }else if(el == 'max:30') {
          if(dataValue.length >= 30){
            obj[key]['error_type'] = 'error';
            obj[key]['error_message'] = 'Must be less than or equal to 30 characters!';
            error = true;
            break;
          } else {
            obj[key]['error_type'] = 'success';
            obj[key]['error_message'] = '';
          }  
        }// For min:9
         else if(el == 'positivenumber') {
           
          let dataValueStr = clone(dataValue).toString();
          if (dataValueStr.indexOf('.') > -1){
            obj[key]['error_type'] = 'error';
            obj[key]['error_message'] = 'Decimal values not allowed.';
            error = true;
            break;
          } else if(typeof(dataValue) != 'number'){
            obj[key]['error_type'] = 'error';
            obj[key]['error_message'] = 'Must be numeric.';
            error = true;
            break;
          } else if(dataValue < 0){
            obj[key]['error_type'] = 'error';
            obj[key]['error_message'] = 'Must be positive number.';
            error = true;
            break;
          } else {
            obj[key]['error_type'] = 'success';
            obj[key]['error_message'] = '';
          } 

        }else if(el == 'flat') {
           
            let dataValueStr = clone(dataValue).toString();
            if(typeof(dataValue) != 'number'){
              obj[key]['error_type'] = 'error';
              obj[key]['error_message'] = 'Must be numeric.';
              error = true;
              break;
            } else if(dataValue < 0){
              obj[key]['error_type'] = 'error';
              obj[key]['error_message'] = 'Must be positive number.';
              error = true;
              break;
            } else {
              obj[key]['error_type'] = 'success';
              obj[key]['error_message'] = '';
            } 
        
        } else if(el == 'numeric') {
           
          let dataValueStr = dataValue.toString();
          if (dataValueStr.indexOf('.') > -1){
            obj[key]['error_type'] = 'error';
            obj[key]['error_message'] = 'Decimal values not allowed.';
            error = true;
            break;
          } else if(typeof(dataValue) != 'number'){
            obj[key]['error_type'] = 'error';
            obj[key]['error_message'] = 'Must be numeric.';
            error = true;
            break;
          } else {
            obj[key]['error_type'] = 'success';
            obj[key]['error_message'] = '';
          }

          // For min:9
        } else if(el == 'min:9') {

          if(dataValue.length < 9){
            obj[key]['error_type'] = 'error';
            obj[key]['error_message'] = 'Must be at least 9 characters!';
            error = true;
            break;
          } else {
            obj[key]['error_type'] = 'success';
            obj[key]['error_message'] = '';
          }
          
          // For password
        } else if(el == 'mustbe:10') { 
            dataValue = dataValue && dataValue.toString();
          if(dataValue && dataValue.length != 10){
            obj[key]['error_type'] = 'error';
            obj[key]['error_message'] = 'Must be 10 digits!';
            error = true;
            break;
          } else {
            obj[key]['error_type'] = 'success';
            obj[key]['error_message'] = '';
          }
        
        } else if(el == 'mustbe:7') { 
            dataValue = dataValue.toString();
          if(dataValue.length != 7){
            obj[key]['error_type'] = 'error';
            obj[key]['error_message'] = 'Must be 7 characters!';
            error = true;
            break;
          } else {
            obj[key]['error_type'] = 'success';
            obj[key]['error_message'] = '';
          }
        } else if(el == 'mustbe:15') { 
          dataValue = dataValue.toString();
        if(dataValue.length != 15){
          obj[key]['error_type'] = 'error';
          obj[key]['error_message'] = 'Must be 15 characters!';
          error = true;
          break;
        } else {
          obj[key]['error_type'] = 'success';
          obj[key]['error_message'] = '';
        }

          // For phone
        } else if(el == 'phone') {
          if(/^\(\d{3}\)[-]\d{3}[-]\d{4}$/.test(dataValue) == false){
            obj[key]['error_type'] = 'error';
            obj[key]['error_message'] = 'Phone number must be in (416)-124-1353 format';
            error = true;
            break;
          } else {
            obj[key]['error_type'] = 'success';
            obj[key]['error_message'] = '';
          }
          
          // For password
        } else if(el == 'password') {
          var passRegix = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})")
          //var passRegix = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\_$%\^&\*])(?=.{8,})");
          if(passRegix.test(dataValue) == false){
            obj[key]['error_type'] = 'error';
            obj[key]['error_message'] = 'The password must have at least one capital letter, one number, one symbol, and be at least 9 characters in length';
            error = true;
            break;
          } else {
            obj[key]['error_type'] = 'success';
            obj[key]['error_message'] = '';
          }

          // For confirm password
        } else if(el == 'confirm_password') {
          if(dataValue !== obj['password']['value']){ // hard coded for now
            obj[key]['error_type'] = 'error';
            obj[key]['error_message'] = 'The password mismatch!';
            error = true;
            break;
          } else {
            obj[key]['error_type'] = 'success';
            obj[key]['error_message'] = '';
          }

        } else if(el==='url'){
          var urlRex = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
          if(dataValue.toString() && !urlRex.test(dataValue.toString())){
            obj[key]['error_type'] = 'error';
            obj[key]['error_message'] = 'Invalid url!';
            error = true;
            break;
          } else {
            obj[key]['error_type'] = 'success';
            obj[key]['error_message'] = '';
          }

        } else if(el==='urlOnly'){
          var urlRex = /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
          if(dataValue.toString() && !urlRex.test(dataValue.toString())){
            obj[key]['error_type'] = 'error';
            obj[key]['error_message'] = 'Invalid url!';
            error = true;
            break;
          } else {
            obj[key]['error_type'] = 'success';
            obj[key]['error_message'] = '';
          }

          // For email
        } else if(el == 'email') {
          var emailRex = /\S+@\S+\.\S+/;
          if(!emailRex.test(dataValue.toString())){
            obj[key]['error_type'] = 'error';
            obj[key]['error_message'] = 'Invalid email!';
            error = true;
            break;
          } else {
            obj[key]['error_type'] = 'success';
            obj[key]['error_message'] = '';
          }
        } else if(el.includes("maxval:")) {
          let elSplit = el.split('|');
          let maxValue = elSplit[0] ? elSplit[0].split(':')[1] : 0; // take the first array and split and get second item
          let condition = elSplit[1] ? elSplit[1] : '';
          let fieldsSplit = elSplit[2] ? elSplit[2].split(':') : [];
          let fieldToCompareKey = fieldsSplit[0];
          let fieldToCompareValue = fieldsSplit[1];
          dataValue = parseInt(dataValue);
          if(dataValue > maxValue){
            // if no condtion, normal max value validate
            if(!condition) {
              obj[key]['error_type'] = 'error';
              obj[key]['error_message'] = `Value can't be more than ${maxValue} `;
              error = true;
              break;
            } else{
              let compareTo = obj[fieldToCompareKey]['value'];
              if(typeof(obj[fieldToCompareKey]) === 'string' ) {
                compareTo = obj[fieldToCompareKey];
              } 
              if( compareTo  === fieldToCompareValue) {
                let fieldToCompareKeyReadable = fieldToCompareKey.toLowerCase();
                obj[key]['error_type'] = 'error';
                obj[key]['error_message'] = `Value cant be more than ${maxValue}, when ${fieldToCompareKeyReadable} is ${fieldToCompareValue}`;
                error = true;
                break;
              }
            }
          } else {
            obj[key]['error_type'] = 'success';
            obj[key]['error_message'] = '';
          }

        }
      }
    };
    return {
      error,
      data: obj
    };
   },
   

   /**
    * Get form key value pair
    */
    getFormKeyValue(obj) {
      
      let record = {};

      Object.keys(obj).forEach(key => {

      // for (let key in obj) {
        
        if( typeof(obj[key]) == 'object' && obj[key] != null ) {

          if(typeof(obj[key].value) == 'number') {

            record[key] = obj[key].value;// can be 0 

          } else if( typeof(obj[key].value) == 'boolean') {

            record[key] = obj[key].value ? true : false;

          } else if( typeof(obj[key].value) == 'string') {

            record[key] =  obj[key].value ? obj[key].value : null;

          } else {

            record[key] =  obj[key].value ? obj[key].value : ( obj[key].value != null ? '' : null);
          }
           
          
        } else if( typeof(obj[key]) == 'boolean') {
         
            record[key] = obj[key] ? true : false;
        
        } else if( typeof(obj[key]) == 'number') {
         
            record[key] = obj[key];

        } else {
          
          record[key] = (obj[key] == '') ? null : obj[key];
        }
      })
      
      return record;
    },


    /**
    * Assign api data to form
    */
    assignFormValues(form, payload) {
      let data = {};
      for (let key in form) {
        if(form[key] == null) {
          data[key] = payload[key] ? payload[key] : null;
        } else if( typeof(form[key]) == 'object') {
          data[key] = form[key];
          data[key]['value'] = payload[key] ? payload[key] : (form[key]['value'] ? form[key]['value'] : '');
          data[key]['error_type'] = '';
          data[key]['error_message'] = '';
        } else if ( typeof(form[key]) == 'string' ) {
          data[key] = payload[key] ? payload[key] : '';
        } else if ( typeof(form[key]) == 'boolean' ) {
          data[key] = payload[key] ? ( (payload[key] == true) ? true : false ) : false;
        } else if ( typeof(form[key]) == 'number' ) {
          data[key] = payload[key] ? payload[key] : null;
        } else {
          data[key] = payload[key] ? payload[key] : null;
        }
      }
      return data;
      
    },

    /**
     * Reset form values
     */
     resetFormValue(form) {
      let data = {};
      for (let key in form) {
        if( typeof(form[key]) == 'object') {
          data[key] = form[key];
          data[key]['value'] = '';
          data[key]['error_type'] = '';
          data[key]['error_message'] = '';
        } else if ( typeof(form[key]) == 'string' ) {
          data[key] = '';
        } else if ( typeof(form[key]) == 'boolean' ) {
          data[key] = form[key];
        } else if ( typeof(form[key]) == 'number' ) {
          data[key] = 0;
        }
      }
      return data;
     },

     /**
     * Remove validation and reset values
     */
     removeValidationOnly(form, arr) {
      let data = {};
      for (let key in form) {

        // if has key remove validation data to blank
        if(arr.indexOf(key) == -1 ) {
          data[key] = form[key];
           
        } else {
          if( typeof(form[key]) == 'object') {
            data[key] = form[key];
            data[key]['value'] = form[key].value;
            data[key]['error_type'] = '';
            data[key]['error_message'] = '';
          } else {
            data[key] = form[key];
          } 
        
        }

      }
      return data;
     },
    /**
     * Remove validation and reset values
     */
     removeValidationRestValue(form, arr) {
      let data = {};
      for (let key in form) {

        // if has key remove validation data to blank
        if(arr.indexOf(key) == -1 ) {
          data[key] = form[key];
           
        } else {
          if( typeof(form[key]) == 'object') {
            data[key] = form[key];
            data[key]['value'] = '';
            data[key]['error_type'] = '';
            data[key]['error_message'] = '';
          } else if ( typeof(form[key]) == 'string' ) {
            data[key] = '';
          } else if ( typeof(form[key]) == 'boolean' ) {
            data[key] = form[key];
          } else if ( typeof(form[key]) == 'number' ) {
            data[key] = 0;
          }
        
        }

      }
      return data;
     },
    /**
     * 
     * Convert location holiday hours to 24 format
     */
    convertLocation12hoursTo24hours(arr) {
      let data = <any>[];
      arr.forEach(item => {
        let openTimeData = `${item.openTime.HH}:${item.openTime.MM} ${ item.openTimeTypeAm ? 'AM' : 'PM'}`;
        let openTime = moment(openTimeData, ["h:mm A"]).format("HH:mm:ss");
        
        let closeTimeData = `${item.closeTime.HH}:${item.closeTime.MM} ${ item.closeTimeTypeAm ? 'AM' : 'PM'}`;
        let closeTime = moment(closeTimeData, ["h:mm A"]).format("HH:mm:ss");
        data.push({
          day: item.day,
          dayName: item.dayName,
          holidayDate: item.holidayDate,
          isOpen: item.isOpen,
          openTime,
          closeTime
        })
      })
      return data;
    },

    /**
     * 
     * Convert location holiday hours to 24 format
     */
     convertLocation12hoursTo24hoursLocationInfo(arr) {
      let data = <any>[];
      arr.forEach(item => {
        let openTimeData = `${item.openTime.HH}:${item.openTime.MM} ${ item.openTimeTypeAm ? 'AM' : 'PM'}`;
        let openTime = moment(openTimeData, ["h:mm A"]).format("HH:mm:ss");
        let closeTimeData = `${item.closeTime.HH}:${item.closeTime.MM} ${ item.closeTimeTypeAm ? 'AM' : 'PM'}`;
        let closeTime = moment(closeTimeData, ["h:mm A"]).format("HH:mm:ss");
        data.push({
          day: item.day,
          dayName: item.dayName,
          isOpen: item.isOpen,
          openTime,
          closeTime
        })
      })
      return data;
    },

    /**
     * Get data from google map obj
     */
    getDetailFromGmapAutoComplete(address_components, key, targetValue = '') {
      let returnValue = '';
      let searchdata = address_components.find(item => {
        if (indexOf(item.types, key) > -1) {
          return item;
        }
      })
      if (searchdata != undefined) {
        returnValue = targetValue ? searchdata[targetValue] : searchdata.long_name;
      }
      return returnValue;
    },

    /**
     * Validate start end time
     */
     validateStartEndTime(arr, index) {
      let obj = arr[index];
      let openTimeData = `${obj.openTime.HH}:${obj.openTime.MM} ${ obj.openTimeTypeAm ? 'AM' : 'PM'}`;
      let openTime = moment(openTimeData, ["h:mm A"]);
      let closeTimeData = `${obj.closeTime.HH}:${obj.closeTime.MM} ${ obj.closeTimeTypeAm ? 'AM' : 'PM'}`;
      let closeTime = moment(closeTimeData, ["h:mm A"]);
      let timeGap = closeTime.diff(openTime, 'minutes');
      arr[index].is_valid_openTime_closeTime = timeGap > 0 ? true : false;
      return arr;
    },


    /**
     * Collect all categories in an object
     */
     collectAllCategoriesInObj(bulkCategoryData) {
      if(bulkCategoryData.length > 0){
        let keyval = {};
        bulkCategoryData.forEach(item => {
          // this is for main
          keyval[item.id] = {
            name: item.name,
            id: item.id,
            thc_cbd_value_type: item.thc_cbd_value_type,
          }
          //this is for subcat
          if(item.subCategories && (item.subCategories.length > 0)){
            item.subCategories.forEach(subItem => {
              keyval[subItem.id] = {
                name: subItem.name,
                id: subItem.id,
                thc_cbd_value_type: subItem.thc_cbd_value_type,
              }
            })
          }
        })
        return keyval;
      } 
      return {};
    },

    /**
     * Remove blank categories
     */
    removeBlankCategories(categories) {
      let data:any = [];
      //  here are goig to show product and cat both
      categories.forEach(item => {
        if(item.products.length > 0 || item.subCategories.length > 0) {
          // for parent category with product
          if(item.products.length > 0 && item.subCategories.length == 0) {
            data.push(item)
          } else if(item.subCategories.length > 0 && this.checkCategoryHasAtleastaProduct(item.subCategories)) {
            // need to check whether the sub cat has product or not
            item.subCategories = item.subCategories.filter(i => i.products.length > 0)
            data.push(item)
          }
        }
      })
      return data;
    },

    
    /**
     * Remove blank categories and make variant a product
     */
    removeBlankCategoriesAndMakeVariantsProduct(categories) {
      let data:any = [];
      categories.forEach(item => {
        if(item.products.length > 0 || item.subCategories.length > 0) {
          // for parent category with product
          if(item.products.length > 0 && item.subCategories.length == 0) {
            
            let newProducts:any = [];
            item.products.forEach((p) => {
              newProducts.push(p) // push main product variant
                if(p.variants.length > 0) {
                  p.variants.map(v => { 
                    v.brand =  p.brand; 
                    // push brand on variant
                    newProducts.push(v) // push variant as product
                  })
                }
            })
            item.products = newProducts;
            data.push(item)
            // yo main product yeha product ko variant herna paro

          } else if(item.subCategories.length > 0 && this.checkCategoryHasAtleastaProduct(item.subCategories)) {
            // need to check whether the sub cat has product or not
            item.subCategories = item.subCategories.filter(i => i.products.length > 0)
            // yo sap cat ko product
            item.subCategories.map(subCat => {

              let newProducts:any = [];

              subCat.products.forEach((subcatProduct) => {

                newProducts.push(subcatProduct) // push main product variant
                  if(subcatProduct.variants.length > 0) {
                    subcatProduct.variants.map(v => { 
                      v.brand =  subcatProduct.brand; 
                      // push brand on variant
                      newProducts.push(v) // push variant as product
                    })
                  }
                 
              })
              subCat.products = newProducts;
              
            
            })

            data.push(item)
          }
        }
      })
      return data;
    },

    /**
     * Remove blank categories and make variant a product
     */
    cleanProductOnCategoryTree(categories){
      let data:any = [];
      categories.forEach(item => {
        if((item.products && item.products.length>0) || (item.subCategories && item.subCategories.length>0)) {
        
          // main product
          let newProducts:any = [];
          item.products.forEach((p) => {
            newProducts.push(p) // push main product variant
          })
          item.products = newProducts;
            
          // yo sap cat ko product
          let newSubcategories:any = []
          item.subCategories.map(subCat => {
            let newSubCategory:any = {}
            if(subCat.products && subCat.products.length>0){
              let newProducts:any = [];
              subCat.products.forEach((subcatProduct) => {
                newProducts.push(subcatProduct) // push main product variant
              })
              newSubCategory = subCat
              newSubCategory.products = newProducts;
              newSubcategories.push(newSubCategory)
            }
          })
          item.subCategories = newSubcategories
          

          data.push(item)
        }
        
      })
      return data;
    },

    /**
     * Remove blank categories and make variant a product and merge variants
     */
    makeVariantsProductOnCategoryTree(categories) {
      let data:any = [];
      categories.forEach(item => {
        if((item.products && item.products.length>0) || (item.subCategories && item.subCategories.length>0)) {
        
          // main product
          let newProducts:any = [];
          item.products.forEach((p) => {
            newProducts.push(p) // push main product variant
              if(p.variants?.length > 0) {
                p.variants.map(v => { 
                  v.brand = p.brand; 
                  // push brand on variant
                  newProducts.push(v) // push variant as product
                })
              }
          })
          item.products = newProducts;
            
          // yo sap cat ko product
          let newSubcategories:any = []
          item.subCategories.map(subCat => {
            let newSubCategory:any = {}
            if(subCat.products && subCat.products.length>0){
              let newProducts:any = [];
              subCat.products.forEach((subcatProduct) => {
                newProducts.push(subcatProduct) // push main product variant
                  if(subcatProduct.variants?.length > 0) {
                    subcatProduct.variants.map(v => { 
                      v.brand =  subcatProduct.brand; 
                      // push brand on variant
                      newProducts.push(v) // push variant as product
                    })
                  }
                  
              })
              newSubCategory = subCat
              newSubCategory.products = newProducts;
              newSubcategories.push(newSubCategory)
            }
          })
          item.subCategories = newSubcategories
          

          data.push(item)
        }
        
      })
      return data;
    },

    /**
     * Remove blank categories and add only main variant
     */
    makeProductOnCategoryTree(categories) {
      let data:any = [];
      categories.forEach(item => {
        
          // main product
          let newProducts:any = [];
          item.products.forEach((p) => {
            newProducts.push(p) // push main product variant
          })
          item.products = newProducts;
            
          // yo sap cat ko product
          item.subCategories.map(subCat => {
            let newProducts:any = [];
            subCat.products.forEach((subcatProduct) => {
              newProducts.push(subcatProduct) // push main product variant
            })
            subCat.products = newProducts;
          
          })
          

          data.push(item)
          
        
      })
      return data;
    },

    /**
     * Make product view to Products
     */
    makeProductsViewToProducts(categories) {

      // we dont need variant as  product for now
      categories.map(item => {
          item.products = item.productsView;
          item.productsView = [] // load decrease
          // yo sap cat ko product
          item.subCategories.map(subCat => {
            subCat.products = subCat.productsView
            subCat.productsView = []; // load decrease
          })
      })
      return categories;
    },

    /**
     * Check whether any sub cat product
     */
     checkCategoryHasAtleastaProduct(subCategories) {
      if(subCategories.length < 1) {
        return false;
      }
      for(let i = 0; i < subCategories.length ; i++) {
        if(subCategories[i].products.length > 0){
          return true;
        }
      }
    },

    /**
     * Set blank value of an obj to null
     */
    setEmptyValueToNull(obj) {

      for (let key in obj) {

        let defaultVal:any = null;

        if(typeof obj[key] == 'number') {
          defaultVal = obj[key] ? obj[key] : 0;

        } else if(typeof obj[key] == 'boolean') {

          defaultVal = obj[key] ? obj[key] : false;
        } else if(typeof obj[key] == 'string') {

          defaultVal = obj[key] ? obj[key] : null;
        } else {
          defaultVal = obj[key] == '' ? null : obj[key];
        }
        obj[key] = defaultVal;
      }

      return obj;
    },

    /**
     *  create query from string
     * @param obj 
     * @returns String
     */

    generateUrlParameter(obj) {
      let params = '';
      for (let key in obj) {
        if (Array.isArray(obj[key])) {
          // Push to apionly if has value
          if (obj[key].length > 0) {
            // if (key == 'thc') {
            //   params += `&${key}=${obj[key].join('|')}`;
            // } else {
              params += `&${key}=${obj[key].join()}`;
            // }
          }
        } else {
          params += obj[key] ? `&${key}=${obj[key]}` : '';
        }
      }
      return params;
    },

    /**
     * Get my permission
     */
    getMyPermission(key) {
      let user = store.getters['auth/user'];
      let userPermissions:any = store.getters['auth/userPermissions'];
      if(user && user.userType && user.userType == 'super') {
        return true;
      } else {
        return (userPermissions.indexOf(key) == -1 ) ? false : true;
      }
    },


    /**
     * Is checked all products and cats 
     */
    isAllMembershipProductAndCatsChecked(cat) {

      // if product length is 0 suspicious
      if(cat.products.length == 0){
        // check categories
        let subCatProductCount = 0;
        cat.sub_categories.forEach(i => {
          subCatProductCount += i.products.length
        })
        if(subCatProductCount == 0) {
          return false;
        }
      }

      for(let key in cat) {
        // lets says products all are checke 
        let productUnchecked = cat.products.filter(p => !p.selected);
        if(productUnchecked.length > 0) {
          return false;
        }
        for(let subKey in cat.sub_categories) {
           // lets says products all are checke 
          let subProductUnchecked = cat.sub_categories[subKey].products.filter(p => !p.selected);
          if(subProductUnchecked.length > 0) {
            return false;
          }
        }

      }

      return true

    },

    /**
     * is any product or cat checked at least one
     */
    atLeastMembershipAproductCheckedOnNormalCategory(cat) {

      // if product length is 0 suspicious
      let checked = false;
      if(cat.products.length > 0){
        checked = cat.products.some((i) => {
          return i.selected == true
        })
      }
      if(checked) {
        return true;
      }

      // Sub cat
      checked = false;
      if(cat.sub_categories) {
        for(let subKey in cat.sub_categories) {
          
          checked = cat.sub_categories[subKey].products.some((i) => {
            return i.selected == true
          })
        }
      }
      if(checked) {
        return true;
      }

      return checked;
    },

    /**
     * Is checked all products and cats for product cat as key different sub_cat and subCat
     */
    isAllProductAndNormalCatsChecked(cat) {

      // if product length is 0 suspicious
      if(cat.products.length == 0){
        // check categories
        let subCatProductCount = 0;
        // normal cat
        if(cat.subCategories) {
          cat.subCategories.forEach(i => {
            subCatProductCount += i.products.length
          })
        }
        // loyalty
        if(cat.sub_categories) {
          cat.sub_categories.forEach(i => {
            subCatProductCount += i.products.length
          })
        }

        if(subCatProductCount == 0) {
          return false;
        }
      }

      for(let key in cat) {
        // lets says products all are checke 
        let productUnchecked = cat.products.filter(p => !p.selected);
        if(productUnchecked.length > 0) {
          return false;
        }

        // this is regular catgeory
        if(cat.subCategories) {
          for(let subKey in cat.subCategories) {
            // lets says products all are checke 
            let subProductUnchecked = cat.subCategories[subKey].products.filter(p => !p.selected);
            if(subProductUnchecked.length > 0) {
              return false;
            }
          }
        }

        // sub cat belongs tp loyalty
        if(cat.sub_categories) {
          for(let subKey in cat.sub_categories) {
            // lets says products all are checke 
            let subProductUnchecked = cat.sub_categories[subKey].products.filter(p => !p.selected);
            if(subProductUnchecked.length > 0) {
              return false;
            }
          }
        }



      }
      return true
    },

    /**
     * is any product or cat checked at least one
     */
    atLeastAproductCheckedOnNormalCategory(cat) {

      // if product length is 0 suspicious
      let checked = false;
      if(cat.products.length > 0){
        checked = cat.products.some((i) => {
          return i.selected == true
        })
      
      }
      if(checked) {
        return true;
      }

      // Sub cat
      //checked = false;
      if(cat.subCategories) {
        for(let subKey in cat.subCategories) {
          
          checked = cat.subCategories[subKey].products.some((i) => {
            return i.selected == true
          })
          console.log(checked)
          if(checked) {
            
             break;
          }
        }
      }
      if(checked) {
        return true;
      }

      // Sub cat
      //checked = false;
      if(cat.sub_categories) {
        for(let subKey in cat.sub_categories) {
          checked = cat.sub_categories[subKey].products.some((i) => {
            return i.selected == true
          })
          console.log(checked)
          if(checked) {
           break;
          }
        }
      }
      return checked;
    },

    /**
     * Category select / unselect main category
     */
    toggleMainCategorySelect(catWithProducts, mainCatIndex) {
      catWithProducts.map((cat, catIndex) => {
        if(catIndex == mainCatIndex) {
          let setState = !cat.selected; // opposite of current
          if(cat.products.every(each => each.show) && cat.subCategories.every(each => each.show)){
            cat.selected = setState;
          }

          if(cat.products && cat.products.length>0){
            // if item doesnot have sub cat only products
            cat.products.map( (product, productIndex) => {
              if(product.show){
                product.selected = setState;
              }
            })
            cat.someSelected = setState;
          }

          // check for sub cat
          if(cat.subCategories && cat.subCategories.length > 0){
            cat.subCategories.map( (subCat ) => {
              if(subCat.show){
                // only check all if all product shown
                if(subCat.products.every(each => each.show)){
                  subCat.selected = setState;
                }
                subCat.someSelected = setState;
                // now sub cat products
                if(subCat.products && subCat.products.length > 0){
                  subCat.products.map( (subCatProduct) => {
                    if(subCatProduct.show){
                      subCatProduct.selected = setState;
                    }
                  });
                }
              }
            })
          }
          
        }
      })
      return catWithProducts;
    },
    /**
     * Main category Product  Select toggle
     */
    async toggleMainCategoryProductSelect(catWithProducts, mainCatIndex, mainCatproductIndex) {
       // Current product
       catWithProducts[mainCatIndex].products[mainCatproductIndex].selected = !catWithProducts[mainCatIndex].products[mainCatproductIndex].selected;
       let allChecked = await this.isAllProductAndNormalCatsChecked(catWithProducts[mainCatIndex]);
       catWithProducts[mainCatIndex].selected = allChecked ? true : false;
       let atLeastOneChecked = await this.atLeastAproductCheckedOnNormalCategory(catWithProducts[mainCatIndex]);
       catWithProducts[mainCatIndex].someSelected = atLeastOneChecked ? true : false;
       return catWithProducts;
    },

    async toggleMainCategoryProductSelectAndDeselectRest(catWithProducts, mainCatIndex, mainCatproductIndex) {
      // Current product
      for(let i=0; i<catWithProducts.length; i++){
        catWithProducts[i].selected = false
        catWithProducts[i].someSelected = false
        for(let j=0; j<catWithProducts[i].products.length; j++){
          catWithProducts[i].products[j].selected = false
        }
        for(let k=0; k<catWithProducts[i].subCategories.length; k++){
          catWithProducts[i].subCategories[k].selected = false
          catWithProducts[i].subCategories[k].someSelected = false
          for(let l=0; l<catWithProducts[i].subCategories[k].products.length; l++){
            catWithProducts[i].subCategories[k].products[l].selected = false
          }
        }
      }
      catWithProducts[mainCatIndex].products[mainCatproductIndex].selected = !catWithProducts[mainCatIndex].products[mainCatproductIndex].selected;
      let allChecked = await this.isAllProductAndNormalCatsChecked(catWithProducts[mainCatIndex]);
      catWithProducts[mainCatIndex].selected = allChecked ? true : false;
      let atLeastOneChecked = await this.atLeastAproductCheckedOnNormalCategory(catWithProducts[mainCatIndex]);
      catWithProducts[mainCatIndex].someSelected = atLeastOneChecked ? true : false;
      return catWithProducts;
   },

    /**
     * Toggle Sub category select
     */
    async toggleSubCategorySelect(catWithProducts, mainCatIndex, subcatIndex) {

      let setState = !catWithProducts[mainCatIndex].subCategories[subcatIndex].selected; // opposite of current
        if(catWithProducts[mainCatIndex].subCategories.every(each => each.show)){
          catWithProducts[mainCatIndex].subCategories[subcatIndex].selected = setState;
        }
        catWithProducts[mainCatIndex].subCategories[subcatIndex].someSelected = setState;
        // on this selected category make all products same
        let thisSubcatProducts = catWithProducts[mainCatIndex].subCategories[subcatIndex].products;
        if(thisSubcatProducts && thisSubcatProducts.length > 0) {
          thisSubcatProducts.map(subCatProduct => {
            if(subCatProduct.show){
              subCatProduct.selected = setState;
            }
          })
        }
        let allChecked = await this.isAllProductAndNormalCatsChecked(catWithProducts[mainCatIndex]);
        catWithProducts[mainCatIndex].selected = allChecked ? true : false;
        let atLeastOneChecked = await this.atLeastAproductCheckedOnNormalCategory(catWithProducts[mainCatIndex]);
        catWithProducts[mainCatIndex].someSelected = atLeastOneChecked ? true : false;
        return catWithProducts;
    },


    /**
     * Toggle Sub category product select
     */
    async toggleSubCategoryProductSelect(catWithProducts, mainCatIndex, subcatIndex, subcatProductIndex) {

      let setState = !catWithProducts[mainCatIndex].subCategories[subcatIndex].products[subcatProductIndex].selected; // opposite of current
        catWithProducts[mainCatIndex].subCategories[subcatIndex].products[subcatProductIndex].selected = setState;

        // now check if all products are checked if so check the sub cat
        let totalNoOfSubcatProduct = catWithProducts[mainCatIndex].subCategories[subcatIndex].products.length;
        let totalNoOfSubcatProductSelected = filter(catWithProducts[mainCatIndex].subCategories[subcatIndex].products, (item) => item.selected )
        catWithProducts[mainCatIndex].subCategories[subcatIndex].selected =  totalNoOfSubcatProductSelected.length == totalNoOfSubcatProduct ? true : false;

        let atLeastOneCheckedSubcat = await this.atLeastAproductCheckedOnNormalCategory(catWithProducts[mainCatIndex].subCategories[subcatIndex]);
        catWithProducts[mainCatIndex].subCategories[subcatIndex].someSelected = atLeastOneCheckedSubcat ? true : false;

          // Check if this cat sub cat and its all products and products are checked
        let allChecked = await this.isAllProductAndNormalCatsChecked(catWithProducts[mainCatIndex]);
        catWithProducts[mainCatIndex].selected = allChecked ? true :  false;

        let atLeastOneChecked = await this.atLeastAproductCheckedOnNormalCategory(catWithProducts[mainCatIndex]);
        catWithProducts[mainCatIndex].someSelected = atLeastOneChecked ? true : false;
        //console.log(catWithProducts[mainCatIndex])
        return catWithProducts;

    },

    async toggleSubCategoryProductSelectAndDeselectRest(catWithProducts, mainCatIndex, subcatIndex, subcatProductIndex) {
      for(let i=0; i<catWithProducts.length; i++){
        catWithProducts[i].selected = false
        catWithProducts[i].someSelected = false
        for(let j=0; j<catWithProducts[i].products.length; j++){
          catWithProducts[i].products[j].selected = false
        }
        for(let k=0; k<catWithProducts[i].subCategories.length; k++){
          catWithProducts[i].subCategories[k].selected = false
          catWithProducts[i].subCategories[k].someSelected = false
          for(let l=0; l<catWithProducts[i].subCategories[k].products.length; l++){
            catWithProducts[i].subCategories[k].products[l].selected = false
          }
        }
      }

      let setState = !catWithProducts[mainCatIndex].subCategories[subcatIndex].products[subcatProductIndex].selected; // opposite of current
        catWithProducts[mainCatIndex].subCategories[subcatIndex].products[subcatProductIndex].selected = setState;

        // now check if all products are checked if so check the sub cat
        let totalNoOfSubcatProduct = catWithProducts[mainCatIndex].subCategories[subcatIndex].products.length;
        let totalNoOfSubcatProductSelected = filter(catWithProducts[mainCatIndex].subCategories[subcatIndex].products, (item) => item.selected )
        catWithProducts[mainCatIndex].subCategories[subcatIndex].selected =  totalNoOfSubcatProductSelected.length == totalNoOfSubcatProduct ? true : false;

        let atLeastOneCheckedSubcat = await this.atLeastAproductCheckedOnNormalCategory(catWithProducts[mainCatIndex].subCategories[subcatIndex]);
        catWithProducts[mainCatIndex].subCategories[subcatIndex].someSelected = atLeastOneCheckedSubcat ? true : false;

          // Check if this cat sub cat and its all products and products are checked
        let allChecked = await this.isAllProductAndNormalCatsChecked(catWithProducts[mainCatIndex]);
        catWithProducts[mainCatIndex].selected = allChecked ? true :  false;

        let atLeastOneChecked = await this.atLeastAproductCheckedOnNormalCategory(catWithProducts[mainCatIndex]);
        catWithProducts[mainCatIndex].someSelected = atLeastOneChecked ? true : false;
        //console.log(catWithProducts[mainCatIndex])
        return catWithProducts;

    },

    /**
     * Loyalty
     */

    checkMainCategorySelectLoyalty(catWithProducts, mainCatIndex, type) {
      catWithProducts.map((cat, catIndex) => {
        if(catIndex == mainCatIndex) {
          let setState = type;
          cat.selected = setState;

          if(cat.products && cat.products.length>0){
            // if item doesnot have sub cat only products
            cat.products.map( (product, productIndex) => {
              product.selected = setState;
            })
            cat.someSelected = setState;
          }

          // check for sub cat
          if(cat.sub_categories && cat.sub_categories.length > 0){
            cat.sub_categories.map( (subCat ) => {
              subCat.selected = setState;
              subCat.someSelected = setState;
              // now sub cat products
              if(subCat.products && subCat.products.length > 0){
                subCat.products.map( (subCatProduct) => {
                  subCatProduct.selected = setState;
                });
              }
            })
          }
          
        }
      })
      return catWithProducts;
    },

    /**
     * Membership Category select / unselect main category
     */
    toggleMainCategorySelectLoyalty(catWithProducts, mainCatIndex) {
      catWithProducts.map((cat, catIndex) => {
        if(catIndex == mainCatIndex) {
          let setState = !cat.selected; // opposite of current
          cat.selected = setState;

          if(cat.products && cat.products.length>0){
            // if item doesnot have sub cat only products
            cat.products.map( (product, productIndex) => {
              product.selected = setState;
            })
            cat.someSelected = setState;
          }

          // check for sub cat
          if(cat.sub_categories && cat.sub_categories.length > 0){
            cat.sub_categories.map( (subCat ) => {
              subCat.selected = setState;
              subCat.someSelected = setState;
              // now sub cat products
              if(subCat.products && subCat.products.length > 0){
                subCat.products.map( (subCatProduct) => {
                  subCatProduct.selected = setState;
                });
              }
            })
          }
          
        }
      })
      return catWithProducts;
    },
    /**
     *  Membership Main category Product  Select toggle
     */
    async toggleMainCategoryProductSelectLoyalty(catWithProducts, mainCatIndex, mainCatproductIndex) {
       // Current product
       catWithProducts[mainCatIndex].products[mainCatproductIndex].selected = !catWithProducts[mainCatIndex].products[mainCatproductIndex].selected;
       let allChecked = await this.isAllProductAndNormalCatsChecked(catWithProducts[mainCatIndex]);
       catWithProducts[mainCatIndex].selected = allChecked ? true : false;
       let atLeastOneChecked = await this.atLeastAproductCheckedOnNormalCategory(catWithProducts[mainCatIndex]);
       catWithProducts[mainCatIndex].someSelected = atLeastOneChecked ? true : false;
       return catWithProducts;
    },

    /**
     * Membership Toggle Sub category select
     */
    async toggleSubCategorySelectLoyalty(catWithProducts, mainCatIndex, subcatIndex) {

      let setState = !catWithProducts[mainCatIndex].sub_categories[subcatIndex].selected; // opposite of current
        catWithProducts[mainCatIndex].sub_categories[subcatIndex].selected = setState;
        catWithProducts[mainCatIndex].sub_categories[subcatIndex].someSelected = setState;
        // on this selected category make all products same
        let thisSubcatProducts = catWithProducts[mainCatIndex].sub_categories[subcatIndex].products;
        if(thisSubcatProducts && thisSubcatProducts.length > 0) {
          thisSubcatProducts.map(subCatProduct => {
            subCatProduct.selected = setState;
          })
        }
        let allChecked = await this.isAllProductAndNormalCatsChecked(catWithProducts[mainCatIndex]);
        catWithProducts[mainCatIndex].selected = allChecked ? true : false;
        let atLeastOneChecked = await this.atLeastAproductCheckedOnNormalCategory(catWithProducts[mainCatIndex]);
        catWithProducts[mainCatIndex].someSelected = atLeastOneChecked ? true : false;
        return catWithProducts;
    },


    /**
     * Membership Toggle Sub category product select
     */
    async toggleSubCategoryProductSelectLoyalty(catWithProducts, mainCatIndex, subcatIndex, subcatProductIndex) {

      let setState = !catWithProducts[mainCatIndex].sub_categories[subcatIndex].products[subcatProductIndex].selected; // opposite of current
        catWithProducts[mainCatIndex].sub_categories[subcatIndex].products[subcatProductIndex].selected = setState;

        // now check if all products are checked if so check the sub cat
        let totalNoOfSubcatProduct = catWithProducts[mainCatIndex].sub_categories[subcatIndex].products.length;
        let totalNoOfSubcatProductSelected = filter(catWithProducts[mainCatIndex].sub_categories[subcatIndex].products, (item) => item.selected )
        catWithProducts[mainCatIndex].sub_categories[subcatIndex].selected =  totalNoOfSubcatProductSelected.length == totalNoOfSubcatProduct ? true : false;

        let atLeastOneCheckedSubcat = await this.atLeastAproductCheckedOnNormalCategory(catWithProducts[mainCatIndex].sub_categories[subcatIndex]);
        catWithProducts[mainCatIndex].sub_categories[subcatIndex].someSelected = atLeastOneCheckedSubcat ? true : false;

          // Check if this cat sub cat and its all products and products are checked
        let allChecked = await this.isAllProductAndNormalCatsChecked(catWithProducts[mainCatIndex]);
        catWithProducts[mainCatIndex].selected = allChecked ? true :  false;
        let atLeastOneChecked = await this.atLeastAproductCheckedOnNormalCategory(catWithProducts[mainCatIndex]);
        catWithProducts[mainCatIndex].someSelected = atLeastOneChecked ? true : false;
        return catWithProducts;

    },


 
 };
 
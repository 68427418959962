<template>
    <div class="text-white flex-1 col-span-1 border-0 rounded-t-xl mt-1">
        <div class="pt-3 bg-[#2B2C32] px-[16px] py-[16px] rounded-xl">
            <div class="flex flex-row flex-wrap items-center gap-2 mt-3 mb-3" :class="(editItem?.variants?.length>3)?'':'justify-center'"> 
                <div class="w-[125px]">
                    <div class="input-wrapper">
                        <button @click="toggle(editItem)" :class="(currentVariant && currentVariant.id==editItem.id)?'bg-theme-green':'bg-theme-grey'" class="flex justify-center items-center py-3 w-full rounded border border-theme-green text-white text-sm font-bold tracking-wider">{{editItem.variantName}}</button>
                    </div>
                </div> 
                <div class="w-[125px]" v-for="(variant, ind) in editItem.variants" :key="'vari-'+ind">
                    <div class="input-wrapper">
                        <button @click="toggle(variant)" :class="(currentVariant && currentVariant.id==variant.id)?'bg-theme-green':'bg-theme-grey'" class="flex justify-center items-center py-3 w-full rounded border border-theme-green text-white text-sm font-bold tracking-wider">{{variant.variantName}}</button>
                    </div>
                </div>
            </div>
            <div class="text-xs text-center pt-2">Tap on a variant to see variant-specific information</div>
            <new-table-lite 
                :class="'mt-5 block w-full min-h-[480px] admin-table '"
                :is-loading="table.loading"
                :is-slot-mode="true"
                :columns="table.columns"
                :rows="table.rows"
                :total="table.totalRecords"
                :sortable="table.sortable"
                :page-size="table.pagesize"
                :is-static-mode="true"
                :messages="{
                    pagingInfo: 'Total: {2}',
                    pageSizeChangeLabel: 'Items per Page',
                    gotoPageLabel: 'Go to page',
                    noDataAvailable: 'No records found!',
                }"
             >
                <template v-slot:createdAt="data">
                    <span>{{canadaTime(data.value.createdAt)}}</span>
                </template>
                <template v-slot:complianceReported="data">
                    <span>{{ data?.value?.complianceReported?'Yes':'No' }}</span>
                </template>
                <template v-slot:reason="data">
                    <span v-if="data?.value">{{ data.value?.reason=='Other'?'Inventory Audit':data.value?.reason }}</span>
                </template>
            </new-table-lite>
        </div>
    </div>
</template>

<script>
import { ref, reactive, defineComponent } from 'vue'
import NewTableLite from '@/components/table/tableLite.vue'
import ProductApi from '@/controllers/BusinessProductApis'
import moment from 'moment-timezone'
import {mapGetters} from 'vuex'

export default defineComponent({
    props: ['editItem'],
    components: {
        NewTableLite
    },
    setup() {
        const currentVariant = ref({})
        const table = reactive({
            loading: false,
            columns: [
                {label: 'Date & time', field:'createdAt', sortable: true, isKey: false},
                {label: 'Batch', field:'batchNo', sortable:false},
                {label: 'Action', field:'direction', sortable:false},
                {label: 'Adjustment', field: 'adjustment', sortable: false},
                {label: 'Compliance Report', field:'complianceReported', sortable: false},
                {label: 'Reason', field:'reason', sortable: false},
            ],
            rows: [],
            totalRecords: 0,
            page: 1,
            sortable: {
                order: "",
                sort: "",
            },
        })
        return {currentVariant, table}
    },
    mounted(){
        if(this.vg_currentTab?.id){
            this.currentVariant = this.vg_currentTab
            if(this.vg_currentTab.id){
                this.getData(this.vg_currentTab.id)
            }
        }else{
            this.currentVariant = this.editItem
            if(this.editItem.id){
                this.getData(this.editItem.id)
            }
        }
    },
    computed:{
        ...mapGetters({
            vg_selectedLocation: 'location/selected',
            vg_currentTab: 'product/currentTab'
        })
    },
    methods: {
        canadaTime(data){
            if(!data) return
            let defaultTimeZone = this.vg_selectedLocation.timezone?this.vg_selectedLocation.timezone:'America/Toronto';
            return moment(data).tz(defaultTimeZone).format('YYYY-MM-DD h:mm a');
        },
        toggle(variant){
            this.currentVariant = variant
            this.getData(variant.id)
        },
        async getData(id){
            this.table.loading = true
            let inventoryData = {productId: id}
            let params = ''
            if(this.vg_selectedLocation?.id){
                params='&locationId='+this.vg_selectedLocation.id
            }
            let res = await ProductApi.listInventory(params, inventoryData)
            if(res.status==201){
                if(this.vg_selectedLocation.id){
                    this.table.rows = res.data.payload.inventoryAdjustments.filter(each => each.locationId===this.vg_selectedLocation.id)
                }else{
                    this.table.rows = res.data.payload.inventoryAdjustments
                }
            }else{
                this.table.rows = []
            }
            this.table.totalRecords = this.table.rows.length
            this.table.loading = false
        }
    }
})
</script>
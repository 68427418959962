<template>
    <div :class="show?'opacity-100':'opacity-0'" class="text-white flex-1 col-span-1 border-0 rounded-t-xl transition-opacity duration-1000 ease-in-out">
            <div class="text-white flex-1 col-span-1 border-0 rounded-t-xl">
                <div class="bg-theme-green rounded-t-xl px-4 py-2 text-white">
                    <div class="flex justify-between items-center">
                        <div>
                            <h2 class="font-bold tracking-wider truncate text-single-line leading-tight text-2xl mt-0 mb-4">{{editItem.name}}</h2>
                            <h3 class="text-sm font-semibold truncate text-single-line leading-tight" v-if="editItem.brand">{{editItem.brand.name}}</h3>
                        </div>
                        <div class="flex flex-col">
                            <button @click="closeEdit" class="mb-3 flex justify-end">
                                CLOSE
                            </button>
                            <div class="mb-1 relative flex justify-end gap-2" tabindex="1"  @focusout="focusOutDropDown">
                                <button v-if="editPage=='regular'" @click="save" class="uppercase hover:bg-slate-100 px-[14px] py-[10px] bg-white rounded text-sm ml-3 tracking-widest text-theme-green">
                                    save
                                </button>
                                <!-- Drop down -->
                                <button v-if="editPage!='adjust-inventory'" @click="toggleDropDown" class="uppercase hover:bg-slate-100 px-[14px] py-[10px] bg-white rounded text-sm tracking-widest text-theme-green">...</button>
                                <div v-if="editDropDown" class="absolute top-14 right-0 mt-1 w-[220px] z-10 rounded-md shadow-lg">
                                    <div class="absolute right-0 -top-4 right-4 h-0 w-0 border-x-8 border-x-transparent border-b-[16px] border-b-gray-black"></div>
                                    <ul role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-item-3" class="bg-gray-black max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                        <li @click="dropDown(eachDropDown.page)" v-for="(eachDropDown, ind) in editDropDownList" :key="'drop-'+ind" class="cursor-pointer text-white hover:bg-light-black select-none relative py-2 pl-3 pr-9">
                                            <div class="flex items-center">
                                                <span class="ml-3 block font-normal uppercase" :class="eachDropDown.class">
                                                    {{eachDropDown.name}}
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div v-if="editPage!='adjust-inventory'">
                    <ul class="flex gap-[54px] bg-[#2B2C32] rounded-b-xl px-2 pt-3 pb-2">
                        <li @click="toggle('regular')" :class="editPage=='regular'?'active':''">
                            <a class="inline-block text-theme-white  tracking-widest text-sm font-semibold" href="#">GENERAL</a>
                        </li>
                        <li @click="toggle('media')" :class="editPage=='media'?'active':''">
                            <a class="text-theme-dimwhite tracking-widest text-sm font-semibold" href="#">MEDIA</a>
                        </li>
                        <li @click="toggle('terpene')" :class="editPage=='terpene'?'active':''">
                            <a class="text-theme-dimwhite tracking-widest text-sm font-semibold uppercase" href="#">Terpenes</a>
                        </li>
                    </ul>
                </div>
                <div v-if="showMerge" class="border border-theme-orange rounded-lg pt-4 pb-2 px-2 my-1">
                    <div>
                        <business-search-select placeholder="Search" @select="selectForceProductItem" />
                    </div>
                    <div class="text-sm py-2 ">Please search product you would like to merge</div>
                    <div class="grid grid-cols-1 gap-1 mt-2 mb-1">
                        <button :disabled="!mergeProduct.id" class="w-full border border-theme-green rounded uppercase py-2" :class="(mergeProduct.id)?'bg-theme-green hover:bg-theme-green-300 border-theme-green':'border-transparent bg-field-black text-theme-modalbg'" @click="confirmForceMerge">Confirm</button>
                    </div>
                </div>
                <component :productLoading="productLoading" :is="editPage" :asn="asn" :fulfillment="fulfillment" :categories="categories" :brands="brands" :taxes="taxes" :editItem="editItem" :media="media" :aromas="aromas" :terpenes="terpenes" />
            </div> 
    </div>
</template>
<script>
import { ref, defineComponent } from 'vue'
import Regular from './Regular.vue'
import Media from './Media.vue'
import Terpene from './terpene/Terpene.vue'
import ProductApi from '@/controllers/BusinessProductApis'
import LocationCannabisSettingApi from '@/controllers/LocationCannabisSetting';
import {mapGetters, mapMutations} from 'vuex'
import { useSnackbar } from "vue3-snackbar"
import BusinessSearchSelect from './businessSearchSelect'
import StringHelper from '@/helpers/string'

export default defineComponent({
    props: ['asn', 'fulfillment', 'editItem'],
    components: {
        Regular,
        Media,
        Terpene,
        BusinessSearchSelect
    },
    setup() {
        const productLoading = ref(false)
        const show = ref(true)
        const snackbar = useSnackbar();
        const editDropDown = ref(false)
        const editDropDownList = [
            {name: 'Merge', page: 'merge', class: ''},
        ]
        const globalSetting = ref({})
        const categories = ref([])
        const brands = ref([])
        const taxes = ref([])
        const editPage = ref('regular')
        const showMerge = ref(false)
        const mergeProduct = ref({})
        const media = ref([])
        const aromas = ref([])
        const terpenes = ref([])
        const form = ref({
            name: '',
            brandId: '',
            categoryId: '',
            featuredCategoryIds: [],
            strain: '',
            tax: '',
            complianceType: '',
            stock: 0,
            stock_threshold: '',
            sku: '',
            concentration_unit: '',
            description: '',
            thc: '',
            thc_min: '',
            thc_max: '',
            cbd: '',
            cbd_min: '',
            cbd_max: '',
            totalTerp: '',
            caseSize: '',
            licensedProducer: '',
            variantName: '',
            dryCannabisEquivalency: '',
            thc_cbd_value_type: 'single',
            cost: '',
            price: '',
            sale_price: '',
            barcode: '',
            reservedQty: 0,
            fulfillmentTime: '',
            activeStock: 0,
        })

        return {productLoading, show, snackbar, editDropDown, editDropDownList, globalSetting, categories, brands, taxes, editPage, showMerge, mergeProduct, media, aromas, terpenes, form}
    },
    mounted(){
        let self = this
        this.vm_spinner(true)
        let promise0 = this.fetchLotSetting()
        let promise1 = this.getCategory()
        let promise2 = this.getBrand()
        let promise3 = this.getTaxes()
        Promise.all([promise0, promise1, promise2, promise3]).then(() => {
            self.vm_spinner(false)
        })
        this.getImageAndTerpenes()
        this.emitter.on("added", this.addedFromAsn);
        this.emitter.on("showDefaultVariant", this.showDefaultVariant)
        this.emitter.on("updateMediaTerpeneAroma", this.updateMediaTerpeneAroma)
    },
    beforeDestroy(){
        this.emitter.off("showDefaultVariant", this.showDefaultVariant)
        this.emitter.off("updateMediaTerpeneAroma", this.updateMediaTerpeneAroma)
    },
    unmounted(){
        this.emitter.off("showDefaultVariant", this.showDefaultVariant)
        this.emitter.off("updateMediaTerpeneAroma", this.updateMediaTerpeneAroma)
    },
    watch: {
        editItem(item){
            this.editPage = 'regular'
            let self = this
            this.show = false
            this.vm_spinner(true)
            let promise0 = this.fetchLotSetting()
            let promise1 = this.getCategory()
            let promise2 = this.getBrand()
            let promise3 = this.getTaxes()
            Promise.all([promise0, promise1, promise2, promise3]).then(() => {
                self.vm_spinner(false)
                self.show = true
            })
            this.getImageAndTerpenes()
        }
    },
    computed: {
        ...mapGetters({
            vg_selectedBusiness: 'auth/activeBusiness',
            vg_selectedLocation: 'location/selected',
            vg_modified: 'modifiedForm/status'
        })
    },
    methods: {
        getImageAndTerpenes(){
            if(this.editItem.image){
                this.media =[{id: this.editItem.image}]
            }else{
                this.media = []
            }
            if(this.editItem.terpene?.length>0){
                this.terpenes = [...this.editItem.terpene]
            }else{
                this.terpenes = []
            }
            if(this.editItem.aroma?.length>0){
                this.aromas = [...this.editItem.aroma]
            }else{
                this.aromas = []
            }
        },
        addedFromAsn(row){
            this.$emit('added', row)
        },
        toggleDropDown(){
            this.editDropDown = !this.editDropDown
        },
        focusOutDropDown(el){
            if(!el.relatedTarget){
                this.editDropDown = false
            }
        },
        dropDown(page){
            if(page == 'merge'){
                this.showMerge = true
            }
            this.editDropDown = false
        },
        closeEdit(){
            if(this.editPage=='adjust-inventory'){
                this.editPage = 'regular'
            }else{
                this.$emit('close')
            }
        },
        async toggle(name){
            this.editPage = name
        },
        save(){
            this.emitter.emit('saveEditProduct', true)
        },
        updateMediaTerpeneAroma({type, add, remove}){
            if(type==='media'){
                remove?this.remove('media', remove):this.add('media', add)
            }else if(type==='terpenes'){
                remove?this.remove('terpenes', remove):this.add('terpenes', add)
            }else if(type==='aromas'){
                remove?this.remove('aromas', remove):this.add('aromas', add)
            }
        },
        add(type, add){
            let findInd = this[type].findIndex(each => each.id===add.id)
            if(findInd==-1){
                this[type].push(add)
            }
        },
        remove(type, remove){
            let findInd = this[type].findIndex(each => each.id===remove.id)
            if(findInd>=0){
                this[type].splice(findInd, 1)
            }
        },
        async fetchLotSetting(){
            let res = await LocationCannabisSettingApi.fetchSetting();
            this.globalSetting =  res.status == 200 ? res.data.payload : {lotTracking: false};
        },
        async getCategory(){
            this.categories = []
            let res = await ProductApi.fetchAllCategory()
            if(res.data?.payload?.category){
                for(let i=0; i<res.data.payload.category.length; i++){
                    this.categories.push(res.data.payload.category[i])
                    if(res.data.payload.category[i].subCategories && res.data.payload.category[i].subCategories.length>0){
                        for(let j=0; j<res.data.payload.category[i].subCategories.length; j++){
                            this.categories.push(res.data.payload.category[i].subCategories[j])
                        }
                    }
                }
            }
        },
        async getBrand(){
            let res = await ProductApi.fetchAllBrands()
            if(res.data?.payload?.brands){
                this.brands = res.data.payload.brands
            }
        },
        async getTaxes(){
            if(this.vg_selectedBusiness.id){
                let res = await ProductApi.fetchAllTaxes(this.vg_selectedBusiness.id)
                if(res.data?.payload){
                    this.taxes = res.data.payload
                }
            }
        },
        showDefaultVariant(){
            this.editPage = 'regular'
        },
        /**
         * Close Qr Card Modal
         */
        closeCallBack() {
          this.productCard = {
            modal: false,
            product: {},
          };
        },

        selectForceProductItem(productObj){
            this.mergeProduct = productObj
        },
        async confirmForceMerge(){
            this.vm_spinner(true)
            await ProductApi.mergePurchaseOrder(this.asn.id, {productId: this.mergeProduct.id})
            this.emitter.emit("reloadAddAsnList")
            this.emitter.emit('closeAddEditInPurchaseOrder')
            this.vm_spinner(false)
        },

        ...mapMutations({
            vm_spinner: 'spinner/mutateSpinner',
            vm_modified: 'modifiedForm/mutateStatus'
        })
    }
})
</script>
<style scoped>
    .active{
        /* position:relative; */
        border-bottom: solid 4px #2BA66B;
    }
    /* .active:before {
    content: '';
    position: absolute;
    background: #2BA66B;
    width: 100%;
    height: 4px;
    bottom: -12px;
    } */
  </style>

<template>
    <div class="custom bg-[#23242A]">
        <div class="flex xl:flex-row flex-col px-4 pt-4 bg-[#23242A] text-white">
            <div class="transition-all duration-100 ease-linear custom-left mr-2 w-full" :class="(add || edit)?'xl:w-[40%] w-full':'w-full'">
                <component :is="listType.component" :payload="listType.data" :addedFromBulk="listType.newAdded" :addedData="listType.newAddedData" :resized="add || edit" @closeBulkUpload="closeBulkUpload"
                    @togglePurchaseOrderUploadPop="togglePurchaseOrderUploadPop" @toggleAddEdit="toggleAddEdit"  @updatedEditItem="updatedEditItem" @openMergeProducts="openMergeProducts" />
            </div>
            <div v-if="add || edit" class="xl:w-[60%] w-full custom-right transition-all duration-100 ease-linear">
                <!-- asn is in item for add-product -->
                <add-product v-if="add" @added="addedRow" :item="editItem" :fulfillment="fulfillment" @toggleAddEdit="toggleAddEdit" @close="closeAdd" />
                <!-- addedRow - added stock from asn upload -->
                <edit-product v-if="edit" :asn="asn" :editItem="editItem" :fulfillment="fulfillment" @added="addedRow" @close="closeEdit" @toggleAddEdit="toggleAddEdit" />
            </div> 
            <PurchaseOrderUploadPop v-if="purchaseOrderUploadPop" @close="closePurchaseOrderUpload" @uploadedPayload="uploadedPayload" />
        </div>
    </div>
</template>
<script>
import { defineComponent, ref} from 'vue'
import PurchaseOrderList from '@/views/Products/PurchaseOrder/PurchaseOrder/PurchaseOrderListV2.vue'
import addProduct from '@/views/Products/Product/Add/Add.vue'
import editProduct from '@/views/Products/Product/Edit/Edit.vue'
import PurchaseOrderUploadPop from '@/views/Products/Product/List/OrderUploadPop.vue'
import PurchaseOrderAdd from '@/views/Products/PurchaseOrder/PurchaseOrderAdd/List.vue'

export default defineComponent({
    components: {
        PurchaseOrderList,
        addProduct,
        editProduct,
        PurchaseOrderUploadPop,
        PurchaseOrderAdd
    },
    setup() {
        // list 
        const listType = ref({
            component: 'PurchaseOrderList', 
            data: [],
            newAdded: false,
            newAddedData: {}
        })

        // poup
        const purchaseOrderUploadPop = ref(false)

         // add edit
        const add = ref(false)
        const edit = ref(false)
        const asn = ref({})
        const fulfillment = ref(null)
        const editItem = ref({})

        return {listType, purchaseOrderUploadPop, add, edit, asn, fulfillment, editItem}
    },
   mounted(){
        this.add = false
    },
    methods: {
        toggleAddEdit(status){
            this.editItem = status.editItem
            this.asn = status.asn
            this.fulfillment = status.fulfillment
            this.edit = status.edit
            this.add = status.add
        },
        closeAdd(){
            this.add = false
        },
        closeEdit(){
            this.edit = false
        },
        addedRow(data){
            if(this.listType.component=='PurchaseOrderAdd'){
                this.add = false
                this.edit = false
                this.listType.newAdded = true
                this.listType.newAddedData = data
            }else{
                this.add = false
                this.editItem = data
                this.edit = false
                this.listType.newAdded = true
                this.emitter.emit('reloadProductList')
            }
        },
        updatedEditItem(item){
            this.editItem = item
        },
        // purchase order upload
        togglePurchaseOrderUploadPop(status){
            this.purchaseOrderUploadPop = status
        },
        closePurchaseOrderUpload(){
            this.purchaseOrderUploadPop = false
        },
        uploadedPayload(payload){
            if(payload?.data?.payload?.id){
                this.closePurchaseOrderUpload()
                this.$router.push({name: 'AddPurchaseOrders', params: {id: payload.data.payload.id}})
            }
        },
        closeBulkUpload(){
            this.listType = {
                component: 'PurchaseOrderList',
                data: [],
                newAdded: false,
                newAddedData: {}
            }
            this.add = false
            this.edit = false
        }
    }
})
</script>

import { defineComponent, ref } from "vue";
import Base from "./Base.vue";
import { useSnackbar } from "vue3-snackbar";
import { EyeIcon, EyeOffIcon } from '@heroicons/vue/outline'
import LoginApis from "@/controllers/LoginApis";
import { mapGetters, mapActions } from "vuex";
import Helpers from '@/helpers';

export default defineComponent({
  name: "SetPassword",
  components: {
    Base,
    EyeIcon,
    EyeOffIcon
  },
  
  setup() {
    const snackbar = useSnackbar();
    const showpassword = ref(false);
    const shownewpassword = ref(false);
    const form = ref({
        password: {
          value: '',
          error_type: '',
          error_message: '',
          validations: ['required', 'password'],
        },
        confirm_password: {
          value: '',
          error_type: '',
          error_message: '',
          validations: ['required', 'confirm_password'],
        },
      });
    return {
      form,
      showpassword,
      shownewpassword,
      snackbar
    };
  },
  mounted() {
    if(!this.$route.query.token || this.$route.query.token == '') {
      this.$router.push({
        name: 'Login'
      })
    }
  },

  methods: {
    /**
     * Validate both field
     */
    validateBothField() {
       // console.log('validatie')
      if(this.form.confirm_password.value) {
        this.validateFields(['password','confirm_password']);
      } else {      
        this.validateFields(['password']) 
      }
      
    },
    /**
     * Validation captures
     */
    async validateFields(fields) {
      
      let res = await Helpers.validateFormFields(this.form, fields);
      // capture the fields
      this.form = res.data;
      return res.error ? false : true;
    },

    /**
     * Submit reset form
     */
    async onSubmit() {

      let validate = await this.validateFields(['password', 'confirm_password']);
      if(!validate) {
        return ;
      }

        this.va_spinner(true);
        let res = await LoginApis.resetPassword({
          token: this.$route.query.token,
          password:  this.form.password.value
        })
        this.va_spinner(false);
        this.snackbar.add({
          type: 'info',
          text: res.status == 201 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
          duration: 3000,
        })
        if(res.status == 201) {
          this.$router.push({
            name: 'Login'
          })
        }
    },

    /**
     * Sometimes user add confirm password then password , at that time
     */
    validateConfirmField() {
      // console.log('validatie')
      // if(this.form.confirm_password.value) {
      //   this.validateFields(['confirm_password']);
      // }
    },

    ...mapActions({
      va_spinner: "spinner/setSpinner",
    })

  },
  
});


import { defineComponent, ref } from "vue";

// import PasswordSetup from "./PasswordSetup.vue";
export default defineComponent({
  name: "LoginBase",
  components: {
  },
  setup() {
  },
});

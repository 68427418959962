<template>
    <Form ref="defaultForm" :validation-schema="getSchema(form.complianceType)">
        <!-- v-slot="{ errors }" {{errors}} -->
        <div class="flex items-center  xl:flex-row flex-col gap-3 mb-3">   
            <div class="xl:w-2/3 w-full">
                <div class="input-wrapper">
                    <Field v-slot="{field, errors, errorMessage}" name="name" v-model="form.name">
                        <span class="text-xs mb-0" :class="labelClass(field.value, errorMessage)">Name</span>
                        <input @change="updateField('name', form.name)" v-bind="field" class="block tracking-wider border-none rounded bg-gray-black w-full text-sm text-theme-white" :class="inputClass(field.value, errorMessage)" placeholder="OG Kush" type="text">
                        <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        <div v-else class="text-xs">&nbsp;</div>
                    </Field>
                </div>
            </div>
            <div class="xl:w-1/3 w-full">
                <div class="input-wrapper">
                    <Field v-slot="{field, errors, errorMessage}" name="brandId" v-model="form.brandId">
                        <span class="mb-0 text-xs" :class="labelClass(field.value, errorMessage)">Brand</span>
                        <SelectSearchBrand @change="(val) => updateField('brandId', val)" v-model="form.brandId" :options="brands" placeholder="Select One" class="relative block rounded bg-gray-black w-full border-none text-sm " />
                        <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        <div v-else class="text-xs">&nbsp;</div>
                    </Field>
                </div>
            </div>
        </div>
        <div class="flex gap-3 xl:flex-row flex-col items-start mb-3">
            <div class="w-full xl:w-1/3">
                <div class="input-wrapper">
                    <Field v-slot="{field, errors, errorMessage}" name="categoryId" v-model="form.categoryId">
                        <label class="text-xs mb-1" :class="labelClass(field.value, errorMessage)">Category</label>
                        <div class="block rounded bg-gray-black w-full text-sm px-1 overflow-hidden">
                        <div class="flex-1 justify-between">
                            <div class="flex flex-row">
                                <span class="mt-2 w-5 h-5 rounded" :style="'background:'+categoryColor+';'" ></span>
                                <select @change="updateField('categoryId', form.categoryId)" v-bind="field" class="relative left-2 pt-1.5 w-full h-9 bg-gray-black focus:border-0 focus:ring-0 border-0" :class="form.categoryId?'text-white':'text-theme-878686'">
                                    <option value="" disabled>Select One</option>
                                    <template v-for="category in categories" :key="'category-'+category.id">
                                        <option v-if="!category.featured" :value="category.id">
                                            {{category.parent?'&nbsp;&nbsp;&nbsp;':''}}{{category.name}}
                                        </option>
                                    </template>
                                </select>
                            </div>
                        </div>
                        </div>
                        <div v-if="errors.length>0" class="text-sm"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                    </Field>
                </div>
            </div>
            <div class="w-full xl:w-1/3">
                <div class="input-wrapper">
                    <span class="mb-0 text-xs">Featured Category</span>
                    <select-checkbox @change="updateField('featuredCategoryIds', form.featuredCategoryIds)" v-model="form.featuredCategoryIds" :options="categories.filter(each => each.featured && each.parent==null)" placeholder="Select One" class="block rounded bg-gray-black border-none w-full text-sm " :class="form.featuredCategoryIds?'text-white':'text-theme-878686'" /> 
                </div>
            </div>
            <div class="w-full xl:w-1/3">
                <div class="input-wrapper">
                    <Field v-slot="{field, errors, errorMessage}" name="strain" v-model="form.strain" >
                        <span class="mb-0 text-xs" :class="labelClass(field.value, errorMessage)">Plant Type</span>
                        <select @change="updateField('strain', form.strain)" v-if="form.complianceType.toLowerCase()!=='accessory'" v-bind="field" class="capitalize block rounded border-none bg-gray-black w-full text-sm tracking-wider"  :class="form.strain?'text-white':'text-theme-878686'">
                            <option value="">Select One</option>
                            <option v-for="(plantType, ind) in plantTypes" :key="'plant-'+ind" :value="plantType">{{plantType}}</option>
                        </select>
                        <div v-else class="flex items-center rounded bg-gray-black px-2 h-[36px] block tracking-wider border-none focus:ring-0 rounded bg-gray-black w-full text-[#9E9E9E] text-sm placeholder-theme-878686">N/A</div>
                        <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        <div v-else class="text-xs">&nbsp;</div>
                    </Field>
                </div>
            </div>
        </div>
        <div class="flex gap-3 xl:flex-row flex-col items-start mb-3">
            <div class="w-full xl:w-2/3">
                <div class="input-wrapper">
                    <Field v-slot="{field, errors, errorMessage}" name="licensedProducer" v-model="form.licensedProducer" >
                        <LicenseProducer @change="updateField('licensedProducer', form.licensedProducer)" placeholder="Enter LP here" v-model="form.licensedProducer" iconClass=" mt-3" />
                        <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        <div v-else class="text-xs">&nbsp;</div>
                    </Field>
                </div>
            </div>
            <div class="w-full xl:w-1/3" v-if="vg_selectedLocation.id">
                <div class="input-wrapper">
                    <Field name="tax" v-model="form.tax" >
                        <span class="mb-0 text-xs">Tax</span>
                        <select-checkbox @change="updateField('tax', form.tax)" v-model="form.tax" :options="taxes.map(each => {return {id: each.id, name:each.name}})" placeholder="Tax" class="block rounded bg-gray-black w-full border-none text-sm tracking-wider" :class="form.tax?'text-white':'text-theme-878686'" /> 
                    </Field>
                </div>
            </div>
        </div>
        <div class="flex gap-3 xl:flex-row flex-col items-start mb-3">
            <div class="w-full xl:w-1/3">
                <div class="input-wrapper">
                    <Field v-model="form.sku" v-slot="{field, errors, errorMessage}" name="sku" >
                        <label class="text-xs" :class="labelClass(field.value, errorMessage)">SKU</label>
                        <input @change="updateField('sku', form.sku)" v-bind="field" placeholder="GJFKD2342" class="block tracking-wider border-none rounded bg-gray-black w-full text-sm placeholder-theme-878686" type="text">
                        <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        <div v-else class="text-xs">&nbsp;</div>
                    </Field>
                </div>
            </div>
            <div class="w-full xl:w-1/3">
                <div class="input-wrapper">
                    <Field v-slot="{field, errors, errorMessage}" name="complianceType" v-model="form.complianceType" >
                        <span class="mb-0 text-xs" :class="labelClass(field.value, errorMessage)">Compliance Type</span>
                        <select @change="updateField('complianceType', form.complianceType)" v-model="form.complianceType" class="block rounded bg-gray-black border-none w-full text-sm " :class="form.complianceType?'text-white':'text-theme-878686'">
                            <option value="" disabled>Select One</option>
                            <option v-for="(compliance, ind) in complianceList" :value="compliance" :key="'compliance-'+ind">{{compliance}}</option>
                        </select>
                        <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        <div v-else class="text-xs">&nbsp;</div>
                    </Field>
                </div>
            </div>
            <div class="w-full xl:w-1/3">
                <div class="input-wrapper">
                    <Field v-slot="{field, errors, errorMessage}" name="concentration_unit" v-model="form.concentration_unit" >
                        <span class="mb-0 text-xs" :class="labelClass(field.value, errorMessage)">THC/CBD Unit</span>
                        <select @change="updateField('concentration_unit', form.concentration_unit)" v-if="form.complianceType.toLowerCase()!=='accessory'" v-bind="field" class="block rounded bg-gray-black w-full border-none text-sm tracking-wider" :class="form.concentration_unit?'text-white':'text-theme-878686'">
                            <option value="">Select One</option>
                            <option value="%">%</option>
                            <option value="mg/g">Mg/g</option>
                            <option value="mg/ml">Mg/Ml</option>
                            <option value="mg">Mg</option>
                            <option value="ml">Ml</option>
                            <option value="mg/bottle">Mg/bottle</option>
                            <option value="mg/pack">Mg/pack</option>
                            <option value="mg/unit">Mg/unit</option>
                        </select>
                        <div v-else class="flex items-center rounded bg-gray-black px-2 h-[36px] block tracking-wider border-none focus:ring-0 rounded bg-gray-black w-full text-[#9E9E9E] text-sm placeholder-theme-878686">N/A</div>
                        <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        <div v-else class="text-xs">&nbsp;</div>
                    </Field>
                </div>
            </div>
        </div>
        <div class="flex gap-3 xl:flex-row flex-col items-start mb-3" v-if="vg_selectedLocation.id">
            <!-- single thc or range -->
            <div class="w-full xl:w-1/2" :class="(form.thc_cbd_value_type=='single')?'':'hidden'">
                <div class="input-wrapper relative">
                    <Field v-slot="{field, errors, errorMessage}" name="thc" v-model.number="form.thc">
                        <label class="text-xs" :class="labelClass(field.value, errorMessage)">THC</label>
                        <div v-if="form.complianceType.toLowerCase()!=='accessory'" class="flex flex-row rounded bg-gray-black ">
                            <span class="mt-1 ml-2">{{form.concentration_unit}}</span>
                            <input @change="updateField('thc', form.thc)" v-model.number="form.thc" placeholder="20.5" class="block tracking-wider border-0 focus:ring-0 bg-gray-black  w-full text-sm placeholder-theme-878686" type="number">
                        </div>
                        <div v-else class="flex items-center rounded bg-gray-black px-2 h-[36px] block tracking-wider border-none focus:ring-0 rounded bg-gray-black w-full text-[#9E9E9E] text-sm placeholder-theme-878686">N/A</div>
                        <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        <div v-else class="text-xs">&nbsp;</div>
                    </Field>
                </div>
            </div>
            <!-- cbd is not set or cdb is range -->
            <div class="w-full xl:w-2/3" :class="(!form.thc_cbd_value_type || form.thc_cbd_value_type=='range')?'':'hidden'">
                <div class="input-wrapper">
                    <label class="text-xs">THC</label>
                    <div class="flex">
                        <div class="relative w-full">
                            <Field v-slot="{field, errors, errorMessage}" name="thc_min" v-model.number="form.thc_min">
                                <div v-if="form.complianceType.toLowerCase()!=='accessory'" class="flex flex-row rounded bg-gray-black ">
                                    <span class="mt-1 ml-1">{{form.concentration_unit}}</span>
                                    <input @change="updateField('thc_min', form.thc_min)" v-model.number="form.thc_min" placeholder="20.5" class="px-1 block tracking-wider border-0 focus:ring-0 bg-gray-black  w-full text-sm placeholder-theme-878686" type="number">
                                </div>
                                <div v-else class="flex items-center rounded bg-gray-black px-2 h-[36px] block tracking-wider border-none focus:ring-0 rounded bg-gray-black w-full text-[#9E9E9E] text-sm placeholder-theme-878686">N/A</div>
                                <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                                <div v-else class="text-xs">&nbsp;</div>
                            </Field>
                        </div>
                        <span class="flex items-center mx-1">
                            <svg width="5" height="3" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.61719 0.955078V2.5H0.754883V0.955078H4.61719Z" fill="white"/>
                            </svg>
                        </span>
                        <div class="relative w-full">
                            <Field v-slot="{field, errors, errorMessage}" name="thc_max" v-model.number="form.thc_max">
                                <div v-if="form.complianceType.toLowerCase()!=='accessory'" class="flex flex-row rounded bg-gray-black ">
                                    <span class="mt-1 ml-1">{{form.concentration_unit}}</span>
                                    <input @change="updateField('thc_max', form.thc_max)" v-model.number="form.thc_max" placeholder="20.5" class="px-1 block tracking-wider border-0 focus:ring-0 bg-gray-black  w-full text-sm placeholder-theme-878686" type="number">
                                </div>
                                <div v-else class="flex items-center rounded bg-gray-black px-2 h-[36px] block tracking-wider border-none focus:ring-0 rounded bg-gray-black w-full text-[#9E9E9E] text-sm placeholder-theme-878686">N/A</div>
                                <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                                <div v-else class="text-xs">&nbsp;</div>
                            </Field>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Single CBD or range -->
            <div  class="w-full xl:w-1/2" :class="(form.thc_cbd_value_type=='single')?'':'hidden'">
                <div class="input-wrapper relative">
                    <Field v-slot="{field, errors, errorMessage}" name="cbd" v-model.number="form.cbd">
                        <label class="text-xs" :class="labelClass(field.value, errorMessage)">CBD</label>
                        <div v-if="form.complianceType.toLowerCase()!=='accessory'" class="flex flex-row rounded bg-gray-black ">
                            <span class="mt-1 ml-2">{{form.concentration_unit}}</span>
                            <input @change="updateField('cbd', form.cbd)" v-model.number="form.cbd" placeholder="20.5" class="block tracking-wider border-0 focus:ring-0 bg-gray-black  w-full text-sm placeholder-theme-878686" type="number">
                        </div>
                        <div v-else class="flex items-center rounded bg-gray-black px-2 h-[36px] block tracking-wider border-none focus:ring-0 rounded bg-gray-black w-full text-[#9E9E9E] text-sm placeholder-theme-878686">N/A</div>
                        <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        <div v-else class="text-xs">&nbsp;</div>
                    </Field>
                </div>
            </div>
            <!-- thc_cbd_value_type is not set or thc_cbd_value_type is range -->
            <div class="w-full xl:w-2/3" :class="(!form.thc_cbd_value_type || form.thc_cbd_value_type=='range')?'':'hidden'">
                <div class="input-wrapper">
                    <label class="text-xs">CBD</label>
                    <div class="flex">
                        <div class="relative w-full">
                            <Field v-slot="{field, errors, errorMessage}" name="cbd_min" v-model.number="form.cbd_min">
                                <div v-if="form.complianceType.toLowerCase()!=='accessory'" class="flex flex-row rounded bg-gray-black ">
                                    <span class="mt-1 ml-1">{{form.concentration_unit}}</span>
                                    <input @change="updateField('cbd_min', form.cbd_min)" v-model.number="form.cbd_min" placeholder="20.5" class="px-1 block tracking-wider border-0 focus:ring-0 bg-gray-black  w-full text-sm placeholder-theme-878686" type="number">
                                </div>
                                <div v-else class="flex items-center rounded bg-gray-black px-2 h-[36px] block tracking-wider border-none focus:ring-0 rounded bg-gray-black w-full text-[#9E9E9E] text-sm placeholder-theme-878686">N/A</div>
                                <div v-if="errors.length>0" class="text-xs" :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                                <div v-else class="text-xs">&nbsp;</div>
                            </Field>
                        </div>
                        <span class="flex items-center mx-1">
                            <svg width="5" height="3" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.61719 0.955078V2.5H0.754883V0.955078H4.61719Z" fill="white"/>
                            </svg>
                        </span>
                        <div class="relative w-full">
                            <Field v-slot="{field, errors, errorMessage}" name="cbd_max" v-model.number="form.cbd_max">
                                <div v-if="form.complianceType.toLowerCase()!=='accessory'" class="flex flex-row rounded bg-gray-black ">
                                    <span class="mt-1 ml-1">{{form.concentration_unit}}</span>
                                    <input @change="updateField('cbd_max', form.cbd_max)" v-model.number="form.cbd_max" placeholder="20.5" class="px-1 block tracking-wider border-0 focus:ring-0 bg-gray-black  w-full text-sm placeholder-theme-878686" type="number">
                                </div>
                                <div v-else class="flex items-center rounded bg-gray-black px-2 h-[36px] block tracking-wider border-none focus:ring-0 rounded bg-gray-black w-full text-[#9E9E9E] text-sm placeholder-theme-878686">N/A</div>
                                <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                                <div v-else class="text-xs">&nbsp;</div>
                            </Field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full xl:w-1/3">
                <div class="input-wrapper">
                    <Field v-slot="{field, errors, errorMessage}" name="totalTerp" v-model="form.totalTerp" >
                        <label class="text-xs" :class="labelClass(field.value, errorMessage)">Terps</label>
                        <div class="flex flex-row rounded bg-gray-black " v-if="form.complianceType.toLowerCase()!=='accessory'">
                            <span class="mt-1 ml-2">%</span>
                            <input @change="updateField('totalTerp', form.totalTerp)" v-model="form.totalTerp" placeholder="4.0" min="0" class="block tracking-wider border-none focus:ring-0 rounded bg-gray-black w-full text-sm placeholder-theme-878686" type="number">
                        </div>
                        <div v-else class="flex items-center rounded bg-gray-black px-2 h-[36px] block tracking-wider border-none focus:ring-0 rounded bg-gray-black w-full text-[#9E9E9E] text-sm placeholder-theme-878686">N/A</div>
                        <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        <div v-else class="text-xs">&nbsp;</div>
                    </Field>
                </div>
            </div>
        </div>
        <div class="flex items-start mb-3">
            <div class="w-full">
                <div class="input-wrapper">
                    <Field v-slot="{field, errors, errorMessage}" name="description" v-model="form.description" >
                        <label class="text-xs" :class="labelClass(field.value, errorMessage)">Description</label>
                        <textarea @change="updateField('description', form.description)" v-bind="field" id="message" rows="4" class="block rounded border-none p-2.5 w-full text-sm bg-gray-black placeholder-theme-878686" placeholder=""></textarea>
                        <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        <div v-else class="text-xs">&nbsp;</div>
                    </Field>
                </div>
            </div>
        </div>
    </Form>
    <div class="flex justify-end flex-reverse">
        <button @click="emitter.emit('closeAddEdit')" class="border rounded px-5 py-2 text-grey border-grey">Cancel</button>
        <button @click="emitter.emit('saveEditProduct')" class="uppercase hover:bg-slate-100 px-8 py-2 bg-white rounded ml-3 text-theme-green">save</button>
    </div>
</template>
<script>
import _ from 'lodash'
import Helpers from '@/helpers';
import {mapGetters, mapMutations, mapActions} from 'vuex'
import {Form, Field} from 'vee-validate'
import SelectCheckbox from '@/components/form/SelectCheckbox.vue'
import SelectSearchBrand from '@/components/form/SelectSearchBrand'
import { ref, defineComponent } from 'vue'
import ProductApi from '@/controllers/BusinessProductApis'
import BusinessCategoryApis from "@/controllers/BusinessCategoryApis";
import { useSnackbar } from "vue3-snackbar"
import StringHelper from '@/helpers/string'
import BusinessTaxApis from "@/controllers/BusinessTaxApis";
import isEmpty from 'lodash/isEmpty'
import PriceMarkupSettingApis from "@/controllers/LocationMarkupPrice";
import lowerCase from 'lodash/lowerCase'
import LicenseProducer from '@/components/form/LicenseProducer'
import ObjectHelper from '@/helpers/object'

export default defineComponent({
    props: [ 'updatedForm', 'updatedLotInfo', 'asn', 'fulfillment', 'editItem', 'type', 'categories', 'brands', 'taxes','lotTracking', 'media', 'aromas', 'terpenes'],
    components: {
        Form,
        Field,
        SelectCheckbox,
        SelectSearchBrand,
        LicenseProducer
    },
    setup() {
        const snackbar = useSnackbar();
        const form = ref({
            name: '',
            brandId: '',
            categoryId: '',
            featuredCategoryIds: [],
            strain: '',
            tax: [],
            complianceType: '',
            stock: '',
            stock_threshold: '',
            sku: '',
            concentration_unit: '',
            description: '',
            thc_cbd_value_type: '',
            thc: '',
            thc_min: '',
            thc_max: '',
            cbd: '',
            cbd_min: '',
            cbd_max: '',
            totalTerp: '',
            caseSize: '',
            licensedProducer: ''
        })
        const redSchema = {
            name: 'required',
            strain: 'required',
            categoryId: 'required',
            complianceType: 'required',
            sku: 'required|max:100',
            concentration_unit: 'required',
            thc: 'required|price',
            thc_min: 'price',
            thc_max: 'price',
            cbd: 'required|price',
            cbd_min: 'price',
            cbd_max: 'price',
            totalTerp: 'percentage',
            licensedProducer: 'required'
        }
        const redAccessorySchema= {
            name: 'required',
            categoryId: 'required',
            complianceType: 'required',
            sku: 'required|max:100',
            thc: 'price',
            thc_min: 'price',
            thc_max: 'price',
            cbd: 'price',
            cbd_min: 'price',
            cbd_max: 'price',
            licensedProducer: 'required'
        }
        const greySchema = {
            name: 'required',
            strain: 'required',
            categoryId: 'required',
            complianceType: 'required',
            sku: 'required|max:100',
            concentration_unit: 'required',
            thc: 'price',
            thc_min: 'price',
            thc_max: 'price',
            cbd: 'price',
            cbd_min: 'price',
            cbd_max: 'price',
            totalTerp: 'percentage',
            licensedProducer: 'required'
        }
        const greyAccessorySchema = {
            name: 'required',
            categoryId: 'required',
            complianceType: 'required',
            sku: 'required|max:100',
            thc: 'price',
            thc_min: 'price',
            thc_max: 'price',
            cbd: 'price',
            cbd_min: 'price',
            cbd_max: 'price',
            licensedProducer: 'required'
        }

        let complianceList = ['Dried flower','Pre rolled','Extracts ingested','Extracts inhaled','Edibles solids','Edibles non-solids','Topicals','Accessory']
        let plantTypes = ['sativa', 'indica', 'hybrid', 'blend']
        const markupPrices = ref([])
        const markupPercent = ref('')
        const regularPriceModified = ref(false)

        return {snackbar, form, redSchema, redAccessorySchema, greySchema, greyAccessorySchema, complianceList, plantTypes, markupPrices, markupPercent, regularPriceModified}
    },
    async mounted() {
        await this.load()
        await this.getMarkupPrices()
        this.emitter.on("saveEditProduct", this.submit);
    },
    unmounted(){
        this.emitter.off("saveEditProduct", this.submit)
    },
    beforeDestroy() {
        this.emitter.off("saveEditProduct", this.submit)
    },
    watch: {
        async editItem(){
            await this.load()
        }
    },
    computed: {
        categoryColor: function() {
            if(this.form.categoryId){
                let currentCategory = this.categories.filter(each => each.id == this.form.categoryId)
                if(currentCategory[0]?.posColour)
                    return currentCategory[0].posColour
                else
                    return 'black'
            }else{
                return 'black'
            }
        },
        ...mapGetters({
            vg_selectedBusiness: 'auth/activeBusiness',
            vg_selectedLocation: 'location/selected'
        })
    },
    methods: {
        load(){
            // select lot and tax if category id is selected
            if(this.form.categoryId){
                this.getLotTrackingForSelectedCategory(this.form.categoryId)
                this.getTaxForSelectedCategory(this.form.categoryId)
            }
            
            this.form.name = this.updatedForm?.name ? this.updatedForm?.name : this.asn.name
            this.form.brandId = this.updatedForm?.brandId ? this.updatedForm?.brandId : (this.asn.brandModel?.id ? this.asn.brandModel?.id : null)
            this.form.categoryId = this.updatedForm?.categoryId ? this.updatedForm?.categoryId : null
            this.form.featuredCategoryIds = this.updatedForm?.featuredCategoryIds ? this.updatedForm?.featuredCategoryIds : []
            this.form.strain = this.updatedForm?.strain ? this.updatedForm?.strain : this.editItem.strain
            this.form.tax = this.updatedForm?.tax ? this.updatedForm?.tax : []
            this.form.complianceType = this.updatedForm?.complianceType ? this.updatedForm?.complianceType : StringHelper.capitalize(this.editItem.complianceType)
            this.form.sku = this.updatedForm?.sku ? this.updatedForm?.sku : this.asn.sku
            this.form.concentration_unit = this.updatedForm?.concentration_unit ? this.updatedForm?.concentration_unit : this.asn.concentrationUnit
            this.form.description = this.updatedForm?.description ? this.updatedForm?.description : (this.asn?.description ? this.asn?.description : '')
            this.form.thc_cbd_value_type = this.updatedForm?.thc_cbd_value_type ? this.updatedForm?.thc_cbd_value_type : (!this.form.thc_cbd_value_type ? 'single': !this.form.thc_cbd_value_type)
            this.form.licensedProducer = this.updatedForm?.licensedProducer ? this.updatedForm?.licensedProducer : (this.asn.licensedProducer ? this.asn.licensedProducer : null)

            // get from recently edited or get from asn file, 0 is valid data
            this.form.thc = this.updatedForm?.thc ? this.updatedForm?.thc : (this.asn.thc || this.asn.thc===0?this.asn.thc:'')
            this.form.thc_min = this.updatedForm?.thc_min ? this.updatedForm?.thc_min : (this.asn.thcMin || this.asn.thcMin===0?this.asn.thcMin:'')
            this.form.thc_max = this.updatedForm?.thc_max ? this.updatedForm?.thc_max : (this.asn.thcMax || this.asn.thcMax===0?this.asn.thcMax:'')
            this.form.cbd = this.updatedForm?.cbd ? this.updatedForm?.cbd : (this.asn.cbd || this.asn.cbd===0?this.asn.cbd:'')
            this.form.cbd_min = this.updatedForm?.cbd_min ? this.updatedForm?.cbd_min : (this.asn.cbdMin || this.asn.cbdMin===0?this.asn.cbdMin:'')
            this.form.cbd_max = this.updatedForm?.cbd_max ? this.updatedForm?.cbd_max : (this.asn.cbdMax || this.asn.cbdMax===0?this.asn.cbdMax:'')
            this.form.totalTerp = this.updatedForm?.totalTerp ? this.updatedForm?.totalTerp : (this.asn.totalTerp || this.asn.totalTerp===0?this.asn.totalTerp:'')
        },
        updateField(field, value){
            if(field==='categoryId'){
                this.getLotTrackingForSelectedCategory(value)
                this.getTaxForSelectedCategory(value)
            }
            this.$emit('updateField', field, value)
        },
        getMarkupPercent(){
            if(this.regularPriceModified){
                this.markupPercent = ((this.form.price-this.form.cost)/(this.form.cost*0.01)).toFixed(2)
            }
        },
        async getMarkupPrices(){
            let res = await PriceMarkupSettingApis.fetchByLocationId(this.vg_selectedLocation.id)
            this.markupPrices = ObjectHelper.getDefaultMarkup(res?.data?.payload)
            
        },
        getSchema(type){
            if(this.asn.masterProduct){
                if(type=='Accessory'){
                    return this.greyAccessorySchema
                }else{
                    return this.greySchema
                }
            }else{
                if(type=='Accessory'){
                    return this.redAccessorySchema
                }else{
                    return this.redSchema
                }
            }
        },
        labelClass(value, error){
            if(value && error){
                return 'text-theme-red'
            }else if(error){
                return 'text-theme-orange'
            }else if(value){
                return 'text-theme-green'
            }
        },
        inputClass(value, error){
            if(value && error){
                return 'border-theme-red'
            }else if(error){
                return 'border-theme-orange'
            }else if(value){
                return 'border-theme-green'
            }
        },
        errorClass(value, error){
            if(value && error){
                return 'text-theme-red'
            }else if(error){
                return 'text-theme-orange'
            }
        },
        async getTaxForSelectedCategory(catId){
            if(this.vg_selectedLocation.id){
                let categoryTax = await BusinessTaxApis.getCategoryTaxes(catId, this.vg_selectedLocation.id)
                if(categoryTax.status==200 && categoryTax.data.payload){
                    this.form.tax = categoryTax.data.payload.map(each => each.id)
                }else{
                    this.form.tax = []
                }
                this.$emit('updateField', 'tax', this.form.tax)
            }
        },
        async getLotTrackingForSelectedCategory(catId){
            let category = await BusinessCategoryApis.getCategoryById(catId)
            if(category.status==200 && category.data.payload){
                this.$emit('updateLotTracking', category.data.payload.lotTracking)
            }else{
                this.$emit('updateLotTracking', false)
            }
        },
        calculatePrice(val){
            if(!this.form.complianceType) return
            let currentMarkupPrice = this.markupPrices.find(each => each.complianceType.toLowerCase()===lowerCase(this.form.complianceType.replace(/[\s-]/g, '')))
            if(currentMarkupPrice && currentMarkupPrice.status && currentMarkupPrice.markupPercentage){
                // this.markupPercent = currentMarkupPrice.markupPercentage
                let value = parseFloat(val)
                this.currentMarkupPrice = parseInt(currentMarkupPrice.markupPercentage)

                this.markupType = currentMarkupPrice.markupType
                let result = 0
                let decimal = 0
                if(currentMarkupPrice.markupType==='margin'){
                    let marginVal = 1-this.currentMarkupPrice/100
                    result = parseFloat(value)/marginVal
                    return result?result.toFixed(2):''
                }else{
                    result = parseFloat(value)+(val*this.currentMarkupPrice/100)
                    decimal = (result%1).toFixed(2)
                    if(decimal>0){
                        return result?+parseInt(result)+0.99:''
                    }else{
                        return result?+result.toFixed(2):''
                    }
                }
            }else if(this.asn){
                return this.asn.cost
            }else{
                return 0
            }
        },
        async submit(){
            let {valid} = await this.$refs.defaultForm?.validate()
            let hasPrice = true
            let hasCartEquivalency = true
            let hasVariant = true
            let price

            price = this.updatedForm?.price ? this.updatedForm?.price : this.calculatePrice(this.asn.cost)
            if(price===0){
                this.snackbar.add({
                    type: 'info',
                    text: "Price is required",
                    duration: 3000,
                })
                hasPrice = false
            }

            if(this.editItem.size==='N/A' && !this.updatedForm.variantName){
                this.snackbar.add({
                    type: 'info',
                    text: "Variant Name is required",
                    duration: 3000,
                })
                hasVariant = false
            }

            if(!this.asn.masterProduct?.weight_equivalent_dry_cannabis && !this.updatedForm.dryCannabisEquivalency){
                this.snackbar.add({
                    type: 'info',
                    text: "Cart Equivalency is required",
                    duration: 3000,
                })
                hasCartEquivalency = false
            }

            if(valid && hasPrice && hasCartEquivalency && hasVariant){
                if(this.form.complianceType=='Accessory'){
                    this.editItem.productFamily = 'retail'
                }
                let productData = {...this.editItem, ...this.form}
                if(productData.barcode===''){
                    productData.barcode = null
                }

                let cannabisProfileData = {
                    locationId: this.vg_selectedLocation.id,
                    thc_cbd_value_type: this.form.thc_cbd_value_type?this.form.thc_cbd_value_type:'range',
                    concentration_unit: this.form.concentration_unit,
                    thc: parseFloat(this.form.thc),
                    cbd: parseFloat(this.form.cbd),
                    thc_min: parseFloat(this.form.thc_min),
                    thc_max: parseFloat(this.form.thc_max),
                    cbd_min: parseFloat(this.form.cbd_min),
                    cbd_max: parseFloat(this.form.cbd_max),
                    totalTerp: parseFloat(this.form.totalTerp)
                }

                let productPricingData = {
                    locationId: this.vg_selectedLocation.id,
                    cost: this.asn.cost,
                    price: price,
                    sale_price: price,
                    stock: parseInt(this.form.stock) || 0,
                }
                this.saveProduct(this.editItem, productData, cannabisProfileData, productPricingData)
            }
        },
        async saveProduct(variant, productData,cannabisProfileData,productPricingData){
            this.vm_spinner(true)
            let data = {
                product:{
                    name:this.form.name,
                    locationId:this.vg_selectedLocation.id,
                    categoryId:this.form.categoryId,
                    brandId:this.form.brandId,
                    productFamily:'cannabis',
                    complianceType:this.form.complianceType,
                    strain:this.form.strain,
                    concentration_unit:this.form.concentration_unit,
                    supplierId:null,
                    parentProductId:null,
                    sku:this.form.sku,
                    barcode:this.updatedForm?.barcode ? this.updatedForm?.barcode : this.asn.productGtin,
                    variantName:this.updatedForm.variantName?this.updatedForm.variantName:productData.size,
                    dryCannabisEquivalency:this.updatedForm.dryCannabisEquivalency?this.updatedForm.dryCannabisEquivalency:this.asn.masterProduct?.weight_equivalent_dry_cannabis,
                    retired:false,
                    lotTracking:this.lotTracking,
                    caseSize:this.updatedForm?.caseSize ? this.updatedForm?.caseSize : this.asn.packSize,
                    licensedProducer: this.form.licensedProducer,
                    description:this.form.description,
                    purchaseOrderItemId:this.asn.id
                },
                pricing: {
                    locationId:this.vg_selectedLocation.id,
                    price:this.updatedForm?.price ? this.updatedForm?.price : productPricingData.price,
                    sale_price:this.updatedForm?.sale_price ? this.updatedForm?.sale_price : productPricingData.sale_price,
                    cost:this.updatedForm?.cost ? this.updatedForm?.cost : productPricingData.cost,
                    stock:0,
                    activeStock:0
                }, 
                cannabisProfile: {
                },
                mediaTerpen:{}
            }
            if(this.form.complianceType.toLowerCase()==='accessory'){
                data.noCannabisProfile = true
                data.cannabisProfile = {
                    locationId:this.vg_selectedLocation.id,
                    thc:'',
                    thc_min:'',
                    thc_max:'',
                    cbd:'',
                    cbd_min:'',
                    cbd_max:'',
                    totalTerp:'',
                    thc_cbd_value_type: '',
                    fullfillmentTime:this.fulfillment
                }
            }else{
                data.cannabisProfile = {
                    locationId:this.vg_selectedLocation.id,
                    thc:this.updatedForm?.thc ? this.updatedForm?.thc : cannabisProfileData.thc,
                    thc_min:this.updatedForm?.thc_min ? this.updatedForm?.thc_min : cannabisProfileData.thc_min,
                    thc_max:this.updatedForm?.thc_max ? this.updatedForm?.thc_max : cannabisProfileData.thc_max,
                    cbd:this.updatedForm?.cbd ? this.updatedForm?.cbd : cannabisProfileData.cbd,
                    cbd_min:this.updatedForm?.cbd_min ? this.updatedForm?.cbd_min : cannabisProfileData.cbd_min,
                    cbd_max:this.updatedForm?.cbd_max ? this.updatedForm?.cbd_max : cannabisProfileData.cbd_max,
                    totalTerp:this.updatedForm?.totalTerp ? this.updatedForm?.totalTerp : cannabisProfileData.totalTerp,
                    thc_cbd_value_type: this.updatedForm?.thc_cbd_value_type ? this.updatedForm?.thc_cbd_value_type : (!this.form.thc_cbd_value_type ? 'single': !this.form.thc_cbd_value_type),
                    fullfillmentTime:this.updatedForm?.fulfillmentTime ? this.updatedForm?.fulfillmentTime : this.fulfillment
                }
            }
            if(this.media.length>0){
                data.mediaTerpen.images = this.media.map(item => item.id)                
            }
            if(this.aromas.length>0){
                data.mediaTerpen.aromaIds = this.aromas.map(item => item.id)
            }
            if(this.terpenes.length>0){
                data.mediaTerpen.terpenids = this.terpenes.map(item => item.id)
            }

            if(this.lotTracking){
                    let asnData = {...this.asn, ...this.updatedLotInfo}
                    let lotInfo = _.pick(asnData, ['cost','packagedOn','cbd','cbdMax','cbdMin','thc','thcMax','thcMin','totalTerp'])
                    lotInfo.name = this.updatedForm?.name ? this.updatedForm?.name : this.asn.name
                    lotInfo.locationId = this.vg_selectedLocation.id
                    lotInfo.shipmentId = this.asn.id
                    lotInfo.sku = this.updatedForm?.sku ? this.updatedForm?.sku : this.asn.sku
                    lotInfo.batch_lot = asnData.batchLot
                    data.batch = lotInfo
                }

            let res = await ProductApi.addOrUpdatePurchaseOrderItem(data)

            if(res.status===201){
                // add and remove taxes
                let variantIds = [res.data.payload.product.id]
                await this.addOrRemoveProductTax(variantIds, [], this.form.tax)

                // add featured category
                if(this.form.featuredCategoryIds && this.form.featuredCategoryIds.length>0){
                    let featuredData = {
                        locationId: this.vg_selectedLocation.id,
                        categoryIds: this.form.featuredCategoryIds
                    }
                    await ProductApi.setFeaturedCategory(res.data.payload.product.id, featuredData)
                }
            }
            
            this.vm_spinner(false)
            this.snackbar.add({
                type: 'info',
                text: (res.status == 200 || res.status==201) ? "Product updated successfully." : Helpers.catchErrorMessage(res.response.data),
                duration: 3000,
            })
            if(res.status==200 || res.status==201){
                this.emitter.emit("reloadAddAsnList")
                this.emitter.emit('closeAddEditInPurchaseOrder')
            }
        },
        async addOrRemoveProductTax(productIds, productTax, formTax){
            // add tax
            let preUpdatedTaxes = productTax.map(each => {return each.id})
            let removed = _.difference(preUpdatedTaxes, formTax)
            let added = _.difference(formTax, preUpdatedTaxes)

            for(let i=0; i<removed.length; i++){
                await ProductApi.removeProductTax({locationId: this.vg_selectedLocation.id, productIds: productIds, taxId: removed[i]})
            }

            for(let j=0; j<added.length; j++){
                await ProductApi.addProductTax({locationId: this.vg_selectedLocation.id, productIds: productIds, taxId: added[j]})
            }
            
        },
        ...mapMutations({
            vm_spinner: 'spinner/mutateSpinner',
        })
     }
})
</script>

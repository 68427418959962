<template>
    <Base>
      <template v-slot:basecontent>
        <div class="m-auto">
            <div class="rounded py-[12px] px-[24px] w-full bg-theme-grey">
              <!-- <div class="mb-4">
                <a @click.prevent="logout()" class="text-theme-green hover:text-white text-sm float-right" href="#">LOGOUT</a>
              </div> -->
              <div class="flex justify-between py-3">
                <p class="font-normal text-base text-[#9E9E9E]">{{currentDate()}}</p>
                <p class="font-normal text-base text-[#9E9E9E]">{{timestamp()}}</p>
              </div>
              <div class="font-bold my-6 text-center text-white text-xl leading-6 tracking-[0.15px]">{{vg_activeBusiness.name}}</div>
              <div class=" mb-6 text-center font-normal text-white text-base leading-5">Please select a location</div>
                <block-loading  v-if="loading" />
                <template v-else>
                  
                <div class="overflow-y-auto select-business-block overflow-hidden">
                  <div class="grid gap-4 large:grid-cols-4 xl:grid-cols-5  sm:grid-cols-3 grid-cols-1">
                    <template v-if="filteredLocation.length>0">
                      <div @click="selectLocation(location)" v-for="location in filteredLocation" :key="location.id" :class="selectedLocation.id==location.id ? 'border border-theme-green' : '' "  class="bg-[#464851] flex items-center justify-center mx-2 mb-4 p-2  hover:bg-theme-darkgreen text-center text-white cursor-pointer select-none rounded-lg h-[56px] w-[180px]">
                        <span class="break-all font-bold flex items-center justify-center line-clamp-2 text-base leading-5 w-[164px] h-[40px] tracking-[0.15px]">{{location.name}}</span>
                      </div>
                    </template>
                  </div>
                </div>
             </template>
             <div class="flex justify-center py-6">
              <button @click="confirmLocation()" :disabled="!selectedLocation.id" class="disabled:bg-theme-modalbg flex justify-center items-center hover:opacity-70 w-[323px] h-[44px] px-4 py-2 rounded bg-[#2BA66B] font-bold text-base text-white leading-5 tracking-[0.15px]">CONFIRM</button>
             </div>

          </div>
        </div>
         <ToggleBusinessStatusModal :callback="closeToggleBusinessModal" :business="toggleBusinessStatus.business" v-if="toggleBusinessStatus.modal"/>
      </template>
    </Base>
</template>

<script>
import Helpers from '@/helpers';
import { ref, defineComponent } from "vue";
import Base from "@/views/Login/Base.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { useStore } from "vuex";
import { useSnackbar } from "vue3-snackbar";
import moment from 'moment';
import ToggleBusinessStatusModal from "@/views/Business/ToggleBusinessStatusModal.vue";
import SelectLocation from '@/views/Business/SelectLocation.vue'
import LocationsApis from '@/controllers/LocationsApis';

export default defineComponent({
  name: 'SelectLocation',
 
  components: {
    Base,
    ToggleBusinessStatusModal,
    SelectLocation,
  },

 setup() {
  
  
 
    const toggleBusinessStatus = ref({
      modal: false,
      business: {}
    });
    const store = useStore()
    const loading = ref(false);
    const filteredLocation = ref([]);
    const searchtext = ref('');
    const snackbar = useSnackbar();
    const selectedLocation = ref({})

    return {
      filteredLocation,
      loading,
      searchtext,
      store,
      snackbar,
      toggleBusinessStatus,
      selectedLocation
    };


 },
  
  async mounted() {
    this.$nextTick(async () => {
      this.loading = true
      let {data} = await LocationsApis.fetchAllLocations();
      this.filteredLocation = data.payload
      if(this.filteredLocation?.length==1){
        this.selectedLocation = this.filteredLocation[0]
        this.confirmLocation()
      }
      this.loading = false
    })
  },
  computed: {
    ...mapGetters({
      vg_loggedInUser: 'auth/user',
      vg_userLocation: 'location/list',
      vg_activeBusiness: 'auth/activeBusiness',
    }),
  },
  watch: {
    'searchtext'(value) {
      this.filteredLocation = value ? this.store.getters['location/list'].filter((item) => item.name.toLowerCase().includes(value.toLowerCase() ) ) : this.store.getters['location/list'];
    },
    'vg_userLocation'(val) {
      this.filteredLocation = this.searchtext ? this.store.getters['location/list'].filter((item) => item.name.toLowerCase().includes(this.searchtext.toLowerCase()) ) : val;
    },
  },

  methods: {
 
    currentDate() {
      const current = moment(new Date()).format("dddd, MMMM Do");
      return current;
    },
    timestamp() {
      const today = moment().format('h:mm:ss a');
      return today;
    },
   
    /**
     * Show Toggle modal
     */
    showToggleBusinessModal(business) {
      this.toggleBusinessStatus = {
        modal: true,
        business: business
      };
    },

    /**
     * Close Toggle modal
     */
    closeToggleBusinessModal() {
      this.toggleBusinessStatus = {
        modal: false,
        business: {}
      };
    },

    selectLocation(location){
      this.selectedLocation = location
    },

    /**
     * Confirm location
     */
    async confirmLocation() {
      // as son as business selected get refresh token 
      if(this.selectedLocation.id){
        this.va_spinner(true);
        let res = await this.va_setSelectTheLocation(this.selectedLocation);
        this.va_spinner(false);
        if(res.status == 200) {
          this.$router.push({
            name: 'ReportDashboard'
          })
        } else if(res.response && res.response.status == 401) {
          this.$router.push({
            name: 'Login'
          })
          this.va_clearUserData();
        }

        this.snackbar.add({
          type: 'info',
          text: res.status == 200 ? res.data.message : Helpers.catchErrorMessage(res.response.data),
          duration: 3000,
        })
      }

    },

    /**
     * Logout
     */

    logout() {
      this.store.dispatch('auth/clearUserData')
      this.$router.push({
        name: 'Login'
      })
    },

    ...mapActions({
      va_spinner: "spinner/setSpinner",
      va_setSelectTheLocation: "location/selectTheLocation",
      va_clearUserData: "auth/clearUserData",
    })
  }

});

</script>

<style scoped>
.select-business-block {
  max-height: 35vh;
}

</style>

import { defineComponent, reactive, ref} from "vue";
import PurchaseOrderAddList from '@/views/Products/PurchaseOrder/PurchaseOrderAdd/List.vue'
import addProduct from '@/views/Products/Product/Add/Add.vue'
import editProduct from '@/views/Products/Product/Edit/Edit.vue'
import GreyProduct from '@/views/Products/PurchaseOrder/GreyProduct/Edit.vue'

export default defineComponent({
    components: {
      PurchaseOrderAddList,
      addProduct,
      editProduct,
      GreyProduct
    },
    setup() {
      const add = ref<boolean>(false)
      const edit = ref(false)
      const grey = ref(false)
      const asn = <any>ref({})
      const fulfillment = <any>ref(null)
      const editItem = <any>ref({})

      return {add, edit, grey, asn, fulfillment, editItem}
    },
    mounted(){
      this.emitter.on("closeAddEditInPurchaseOrder", this.closeAddEdit);
    },
    beforeDestroy() {
      this.emitter.off("closeAddEditInPurchaseOrder", this.closeAddEdit);
    },
    methods: {
      toggleAddEdit(status){
        this.editItem = status.editItem
        this.asn = status.asn
        this.fulfillment = status.fulfillment
        this.edit = status.edit
        this.add = status.add
        this.grey = status.grey
      },
      closeAdd(){
          this.add = false
      },
      closeEdit(){
          this.edit = false
      },
      closeGrey(){
        this.grey = false
        this.add = false
      },
      closeAddEdit(){
          this.add = false
          this.edit = false
          this.grey = false
      },
    }
  })

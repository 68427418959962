<template>

<div class="flex flex-col z-[99]" tabindex="0" @blur="close">
    <div class="flex flex-row justify-between h-10 p-2 cursor-pointer" :class="selected?'text-white':'text-theme-878686'" @click="toggleOpen">
        <div class="truncate" :class="selected?'text-white':'text-grey'">{{selected?getName(selected):placeholder}}</div>
        <div class="mt-2">
            <svg v-if="open" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.83008 0.624219L0.830078 6.62422L1.90508 7.69922L6.83008 2.74922L11.7551 7.67422L12.8301 6.59922L6.83008 0.624219Z" fill="white"/>
            </svg>
            <svg v-else width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.83325 7.37578L0.833252 1.37578L1.90825 0.300781L6.83325 5.25078L11.7583 0.325781L12.8333 1.40078L6.83325 7.37578Z" fill="white"/>
            </svg>
        </div>
    </div>
    <div v-if="open" class="flex flex-col  z-[9999] absolute top-[40px] w-[200px]" :class="classString">
        <input v-model="search" @blur="close" type="text" placeholder="Search" class="text-white w-[195px] border-0 border-b border-black focus:border-black  bg-theme-modalbg text-sm SelectSearchBrandInput" />
        <div class="max-h-56 overflow-auto">
            <div @click="select(option.id)" class="relative grid grid-cols-6 p-2 border-t border-light-black drop-shadow transition duration-500 bg-theme-modalbg hover:bg-theme-grey cursor-pointer" v-for="option in filteredOptions" :key="'brd-'+option.id" :value="option.id">
                <div class="col-span-5 inline-block">{{option.name}}</div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import {defineComponent} from 'vue'
export default defineComponent({
    name: 'SelectSearchBrand',
    props: {
        value: String,
        classString: String,
        modelValue: String,
        placeholder:String,
        options: Array
    },
    data(){
        return {
            open: false,
            selected: null,
            search: '',
            filteredOptions: []
        }
    },
    mounted(){
        this.selected = this.modelValue
        this.filteredOptions =  this.options?.sort((a, b) => a.name.localeCompare(b.name))
        this.filteredOptions.unshift({id: null, name: 'Select One'})
    },
    watch: {
        options() {
            this.filteredOptions =  this.options?.sort((a, b) => a.name.localeCompare(b.name))
            this.filteredOptions.unshift({id: null, name: 'Select One'})
        },
        value(val){
            this.selected = val
        },
        modelValue(){
            this.selected = this.modelValue?this.modelValue:''
        },
        search(){
            this.filteredOptions = this.options.filter(each => each.name.toLowerCase().includes(this.search.toLowerCase()))?.sort((a, b) => a.name.localeCompare(b.name))
        }
    },
    methods: {
        toggleOpen(){
            this.open = !this.open
        },
        close(e){
            if(!e.relatedTarget){
                this.open = false
            }
        },
        isAdded(id){
            return this.selected.includes(id)
        },
        getName(id){
            let obj = this.options.find(each => each.id==id)
            return obj?obj.name:''
        },
        select(id){
            this.selected = id
            this.search = ''
            this.open = false
            this.$emit('change', this.selected)
            this.$emit('update:modelValue', this.selected)
        }
    }
})
</script>
<template>
    <Form ref="defaultForm" :validation-schema="getSchema(form.complianceType)">
        <!-- v-slot="{ errors }" {{errors}} -->
        <div class="flex items-center  xl:flex-row flex-col gap-3 mb-3">   
            <div class="xl:w-2/3 w-full">
                <div class="input-wrapper">
                    <Field v-slot="{field, errors, errorMessage}" name="name" v-model="form.name">
                        <span class="text-xs mb-0" :class="labelClass(field.value, errorMessage)">Name</span>
                        <input @focus="vm_modified(true)" v-bind="field" class="block tracking-wider border-none rounded bg-gray-black w-full text-sm text-theme-white" :class="inputClass(field.value, errorMessage)" placeholder="OG Kush" type="text">
                        <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        <div v-else class="text-xs">&nbsp;</div>
                    </Field>
                </div>
            </div>
            <div class="xl:w-1/3 w-full">
                <div class="input-wrapper">
                    <Field v-slot="{field, errors, errorMessage}" name="brandId" v-model="form.brandId">
                        <span class="mb-0 text-xs" :class="labelClass(field.value, errorMessage)">Brand</span>
                        <SelectSearchBrand @focus="vm_modified(true)" v-bind="field" :value="field.value" :options="brands" placeholder="Select One" class="relative block rounded bg-gray-black w-full border-none text-sm " />
                        <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        <div v-else class="text-xs">&nbsp;</div>
                    </Field>
                </div>
            </div>
        </div>
        <div class="flex gap-3 xl:flex-row flex-col items-start mb-3">
            <div class="w-full xl:w-1/3">
                <div class="input-wrapper">
                    <Field v-slot="{field, errors, errorMessage}" name="categoryId" v-model="form.categoryId">
                        <label class="text-xs mb-1" :class="labelClass(field.value, errorMessage)">Category</label>
                        <div class="block rounded bg-gray-black w-full text-sm px-1 overflow-hidden">
                        <div class="flex-1 justify-between">
                            <div class="flex flex-row">
                                <span class="mt-2 w-5 h-5 rounded" :style="'background:'+categoryColor+';'" ></span>
                                <select @focus="vm_modified(true)" v-bind="field" class="relative left-2 pt-1.5 w-full h-9 bg-gray-black focus:border-0 focus:ring-0 border-0" :class="form.categoryId?'text-white':'text-theme-878686'">
                                    <option value="" disabled>Select One</option>
                                    <template v-for="category in categories" :key="'category-'+category.id">
                                        <option v-if="!category.featured" :value="category.id">
                                            {{category.parent?'&nbsp;&nbsp;&nbsp;':''}}{{category.name}}
                                        </option>
                                    </template>
                                </select>
                            </div>
                        </div>
                        </div>
                        <div v-if="errors.length>0" class="text-sm"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                    </Field>
                </div>
            </div>
            <div class="w-full xl:w-1/3">
                <div class="input-wrapper">
                    <span class="mb-0 text-xs">Featured Category</span>
                    <select-checkbox @focus="vm_modified(true)" v-model="form.featuredCategoryIds" :options="categories.filter(each => each.featured && each.parent==null)" placeholder="Select One" class="block rounded bg-gray-black border-none w-full text-sm " :class="form.featuredCategoryIds?'text-white':'text-theme-878686'" /> 
                </div>
            </div>
            <div class="w-full xl:w-1/3">
                <div class="input-wrapper">
                    <Field v-slot="{field, errors, errorMessage}" name="strain" v-model="form.strain" >
                        <span class="mb-0 text-xs" :class="labelClass(field.value, errorMessage)">Plant Type</span>
                        <select @focus="vm_modified(true)" v-bind="field" class="capitalize block rounded border-none bg-gray-black w-full text-sm tracking-wider"  :class="form.strain?'text-white':'text-theme-878686'">
                            <option value="">Select One</option>
                            <option v-for="(plantType, ind) in plantTypes" :key="'plant-'+ind" :value="plantType">{{plantType}}</option>
                        </select>
                        <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        <div v-else class="text-xs">&nbsp;</div>
                    </Field>
                </div>
            </div>
        </div>
        <div class="flex gap-3 xl:flex-row flex-col items-start mb-3">
            <div class="w-full xl:w-2/3">
                <div class="input-wrapper">
                    <Field v-slot="{field, errors, errorMessage}" name="licensedProducer" v-model="form.licensedProducer" >
                        <LicenseProducer @focus="vm_modified(true)" placeholder="Enter LP here" v-model="form.licensedProducer" iconClass=" mt-3" />
                        <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        <div v-else class="text-xs">&nbsp;</div>
                    </Field>
                </div>
            </div>
            <div class="w-full xl:w-1/3" v-if="vg_selectedLocation.id">
                <div class="input-wrapper">
                    <Field name="tax" v-model="form.tax" >
                        <span class="mb-0 text-xs">Tax</span>
                        <select-checkbox @focus="vm_modified(true)" v-model="form.tax" :options="taxOptions" placeholder="Tax" class="block rounded bg-gray-black w-full border-none text-sm tracking-wider" :class="form.tax?'text-white':'text-theme-878686'" /> 
                    </Field>
                </div>
            </div>
        </div>
        <div class="flex gap-3 xl:flex-row flex-col items-start mb-3">
            <div class="w-full xl:w-1/3">
                <div class="input-wrapper">
                    <Field v-model="form.sku" v-slot="{field, errors, errorMessage}" name="sku" >
                        <label class="text-xs" :class="labelClass(field.value, errorMessage)">SKU</label>
                        <input @focus="vm_modified(true)" v-bind="field" placeholder="GJFKD2342" class="block tracking-wider border-none rounded bg-gray-black w-full text-sm placeholder-theme-878686" type="text">
                        <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        <div v-else class="text-xs">&nbsp;</div>
                    </Field>
                </div>
            </div>
            <div class="w-full xl:w-1/3">
                <div class="input-wrapper">
                    <Field v-slot="{field, errors, errorMessage}" name="complianceType" v-model="form.complianceType" >
                        <span class="mb-0 text-xs" :class="labelClass(field.value, errorMessage)">Compliance Type</span>
                        <select @focus="vm_modified(true)" v-model="form.complianceType" class="block rounded bg-gray-black border-none w-full text-sm " :class="form.complianceType?'text-white':'text-theme-878686'">
                            <option value="" disabled>Select One</option>
                            <option v-for="(compliance, ind) in complianceList" :value="compliance" :key="'compliance-'+ind">{{compliance}}</option>
                        </select>
                        <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        <div v-else class="text-xs">&nbsp;</div>
                    </Field>
                </div>
            </div>
            <div class="w-full xl:w-1/3">
                <div class="input-wrapper">
                    <Field v-slot="{field, errors, errorMessage}" name="concentration_unit" v-model="form.concentration_unit" >
                        <span class="mb-0 text-xs" :class="labelClass(field.value, errorMessage)">THC/CBD Unit</span>
                        <select @focus="vm_modified(true)" v-bind="field" class="block rounded bg-gray-black w-full border-none text-sm tracking-wider" :class="form.concentration_unit?'text-white':'text-theme-878686'">
                            <option value="">Select One</option>
                            <option value="%">%</option>
                            <option value="mg/g">Mg/g</option>
                            <option value="mg/ml">Mg/Ml</option>
                            <option value="mg">Mg</option>
                            <option value="ml">Ml</option>
                            <option value="mg/bottle">Mg/bottle</option>
                            <option value="mg/pack">Mg/pack</option>
                            <option value="mg/unit">Mg/unit</option>
                        </select>
                        <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        <div v-else class="text-xs">&nbsp;</div>
                    </Field>
                </div>
            </div>
        </div>
        <div class="flex gap-3 xl:flex-row flex-col items-start mb-3" v-if="vg_selectedLocation.id">
            <!-- single thc or range -->
            <div class="w-full xl:w-1/2" :class="(form.thc_cbd_value_type=='single')?'':'hidden'">
                <div class="input-wrapper relative">
                    <Field v-slot="{field, errors, errorMessage}" name="thc" v-model.number="form.thc">
                        <label class="text-xs" :class="labelClass(field.value, errorMessage)">THC</label>
                        <div class="flex flex-row rounded bg-gray-black ">
                            <span class="mt-1 ml-2">{{form.concentration_unit}}</span>
                            <input @focus="vm_modified(true)" v-model.number="form.thc" placeholder="20.5" class="block tracking-wider border-0 focus:ring-0 bg-gray-black  w-full text-sm placeholder-theme-878686" type="number">
                        </div>
                        <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        <div v-else class="text-xs">&nbsp;</div>
                    </Field>
                </div>
            </div>
            <!-- cbd is not set or cdb is range -->
            <div class="w-full xl:w-2/3" :class="(!form.thc_cbd_value_type || form.thc_cbd_value_type=='range')?'':'hidden'">
                <div class="input-wrapper">
                    <label class="text-xs">THC</label>
                    <div class="flex">
                        <div class="relative w-full">
                            <Field v-slot="{field, errors, errorMessage}" name="thc_min" v-model.number="form.thc_min">
                                    <div class="flex flex-row rounded bg-gray-black ">
                                        <span class="mt-1 ml-1">{{form.concentration_unit}}</span>
                                        <input @focus="vm_modified(true)" v-model.number="form.thc_min" placeholder="20.5" class="px-1 block tracking-wider border-0 focus:ring-0 bg-gray-black  w-full text-sm placeholder-theme-878686" type="number">
                                    </div>
                                    <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                                    <div v-else class="text-xs">&nbsp;</div>
                                </Field>
                        </div>
                        <span class="flex items-center mx-1">
                            <svg width="5" height="3" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.61719 0.955078V2.5H0.754883V0.955078H4.61719Z" fill="white"/>
                            </svg>
                        </span>
                        <div class="relative w-full">
                            <Field v-slot="{field, errors, errorMessage}" name="thc_max" v-model.number="form.thc_max">
                                <div class="flex flex-row rounded bg-gray-black ">
                                    <span class="mt-1 ml-1">{{form.concentration_unit}}</span>
                                    <input @focus="vm_modified(true)" v-model.number="form.thc_max" placeholder="20.5" class="px-1 block tracking-wider border-0 focus:ring-0 bg-gray-black  w-full text-sm placeholder-theme-878686" type="number">
                                </div>
                                <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                                <div v-else class="text-xs">&nbsp;</div>
                            </Field>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Single CBD or range -->
            <div  class="w-full xl:w-1/2" :class="(form.thc_cbd_value_type=='single')?'':'hidden'">
                <div class="input-wrapper relative">
                    <Field v-slot="{field, errors, errorMessage}" name="cbd" v-model.number="form.cbd">
                        <label class="text-xs" :class="labelClass(field.value, errorMessage)">CBD</label>
                        <div class="flex flex-row rounded bg-gray-black ">
                            <span class="mt-1 ml-2">{{form.concentration_unit}}</span>
                            <input @focus="vm_modified(true)" v-model.number="form.cbd" placeholder="20.5" class="block tracking-wider border-0 focus:ring-0 bg-gray-black  w-full text-sm placeholder-theme-878686" type="number">
                        </div>
                        <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        <div v-else class="text-xs">&nbsp;</div>
                    </Field>
                </div>
            </div>
            <!-- thc_cbd_value_type is not set or thc_cbd_value_type is range -->
            <div class="w-full xl:w-2/3" :class="(!form.thc_cbd_value_type || form.thc_cbd_value_type=='range')?'':'hidden'">
                <div class="input-wrapper">
                    <label class="text-xs">CBD</label>
                    <div class="flex">
                        <div class="relative w-full">
                            <Field v-slot="{field, errors, errorMessage}" name="cbd_min" v-model.number="form.cbd_min">
                                <div class="flex flex-row rounded bg-gray-black ">
                                    <span class="mt-1 ml-1">{{form.concentration_unit}}</span>
                                    <input @focus="vm_modified(true)" v-model.number="form.cbd_min" placeholder="20.5" class="px-1 block tracking-wider border-0 focus:ring-0 bg-gray-black  w-full text-sm placeholder-theme-878686" type="number">
                                </div>
                                <div v-if="errors.length>0" class="text-xs" :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                                <div v-else class="text-xs">&nbsp;</div>
                            </Field>
                        </div>
                        <span class="flex items-center mx-1">
                            <svg width="5" height="3" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.61719 0.955078V2.5H0.754883V0.955078H4.61719Z" fill="white"/>
                            </svg>
                        </span>
                        <div class="relative w-full">
                            <Field v-slot="{field, errors, errorMessage}" name="cbd_max" v-model.number="form.cbd_max">
                                <div class="flex flex-row rounded bg-gray-black ">
                                    <span class="mt-1 ml-1">{{form.concentration_unit}}</span>
                                    <input @focus="vm_modified(true)" v-model.number="form.cbd_max" placeholder="20.5" class="px-1 block tracking-wider border-0 focus:ring-0 bg-gray-black  w-full text-sm placeholder-theme-878686" type="number">
                                </div>
                                <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                                <div v-else class="text-xs">&nbsp;</div>
                            </Field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full xl:w-1/3">
                <div class="input-wrapper">
                    <Field v-slot="{field, errors, errorMessage}" name="totalTerp" v-model="form.totalTerp" >
                        <label class="text-xs" :class="labelClass(field.value, errorMessage)">Terps</label>
                        <div class="flex flex-row rounded bg-gray-black ">
                            <span class="mt-1 ml-2">%</span>
                            <input @focus="vm_modified(true)" v-model="form.totalTerp" placeholder="4.0" min="0" class="block tracking-wider border-none focus:ring-0 rounded bg-gray-black w-full text-sm placeholder-theme-878686" type="number">
                        </div>
                        <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        <div v-else class="text-xs">&nbsp;</div>
                    </Field>
                </div>
            </div>
        </div>
        <div class="flex items-start mb-3">
            <div class="w-full">
                <div class="input-wrapper">
                    <Field v-slot="{field, errors, errorMessage}" name="description" v-model="form.description" >
                        <label class="text-xs" :class="labelClass(field.value, errorMessage)">Description</label>
                        <textarea @focus="vm_modified(true)" v-bind="field" id="message" rows="4" class="block rounded border-none p-2.5 w-full text-sm bg-gray-black placeholder-theme-878686" placeholder=""></textarea>
                        <div v-if="errors.length>0" class="text-xs"  :class="errorClass(field.value, errorMessage)">{{ errorMessage }}</div>
                        <div v-else class="text-xs">&nbsp;</div>
                    </Field>
                </div>
            </div>
        </div>
    </Form>
    <div class="flex justify-end flex-reverse">
        <button @click="emitter.emit('closeAddEdit')" class="border rounded px-5 py-2 text-grey border-grey">Cancel</button>
        <button @click="emitter.emit('saveEditProduct')" class="uppercase hover:bg-slate-100 px-8 py-2 bg-white rounded ml-3 text-theme-green">save</button>
    </div>
</template>
<script>
import _ from 'lodash'
import Helpers from '@/helpers';
import {mapGetters, mapMutations, mapActions} from 'vuex'
import {Form, Field} from 'vee-validate'
import SelectCheckbox from '@/components/form/SelectCheckbox.vue'
import SelectSearchBrand from '@/components/form/SelectSearchBrand'
import { ref, defineComponent } from 'vue'
import ProductApi from '@/controllers/BusinessProductApis'
import { useSnackbar } from "vue3-snackbar"
import StringHelper from '@/helpers/string'
import ObjectHelper from '@/helpers/object'
import BusinessTaxApis from "@/controllers/BusinessTaxApis";
import moment from 'moment';
import isEmpty from 'lodash/isEmpty'
import PriceMarkupSettingApis from "@/controllers/LocationMarkupPrice";
import lowerCase from 'lodash/lowerCase'
import LicenseProducer from '@/components/form/LicenseProducer'

export default defineComponent({
    props: [ 'asn', 'fulfillment', 'editItem', 'type', 'cannabisProfile', 'pricing', 'categories', 'brands', 'taxes', 'productTax'],
    components: {
        Form,
        Field,
        SelectCheckbox,
        SelectSearchBrand,
        LicenseProducer
    },
    setup() {
        const snackbar = useSnackbar();
        const form = ref({
            name: '',
            brandId: '',
            categoryId: '',
            featuredCategoryIds: [],
            strain: '',
            tax: '',
            complianceType: '',
            stock: '',
            stock_threshold: '',
            sku: '',
            concentration_unit: '',
            description: '',
            thc_cbd_value_type: '',
            thc: '',
            thc_min: '',
            thc_max: '',
            cbd: '',
            cbd_min: '',
            cbd_max: '',
            totalTerp: '',
            caseSize: '',
            licensedProducer: ''
        })
        const schema = {
            name: 'required',
            // brandId: 'required',
            categoryId: 'required',
            strain: 'required',
            complianceType: 'required',
            sku: 'required|max:100',
            licensedProducer: ''
        }
        const accessorySchema= {
            name: 'required',
            // brandId: 'required',
            categoryId: 'required',
            complianceType: 'required',
            sku: 'required|max:100',
            licensedProducer: ''
        }
        const locationSchema = {
            name: 'required',
            strain: 'required',
            // brandId: 'required',
            categoryId: 'required',
            complianceType: 'required',
            // stock: 'positiveInteger',
            // stock_threshold: 'positiveInteger',
            sku: 'required|max:100',
            concentration_unit: 'required',
            thc: 'price',
            thc_min: 'price',
            thc_max: 'price',
            cbd: 'price',
            cbd_min: 'price',
            cbd_max: 'price',
            totalTerp: 'percentage',
            licensedProducer: ''
        }
        const locationAccessorySchema = {
            name: 'required',
            // brandId: 'required',
            categoryId: 'required',
            complianceType: 'required',
            // stock: 'positiveInteger',
            // stock_threshold: 'positiveInteger',
            sku: 'required|max:100',
            thc: 'price',
            thc_min: 'price',
            thc_max: 'price',
            cbd: 'price',
            cbd_min: 'price',
            cbd_max: 'price',
            licensedProducer: ''
        }

        let complianceList = ['Dried flower','Pre rolled','Extracts ingested','Extracts inhaled','Edibles solids','Edibles non-solids','Topicals','Accessory']
        let plantTypes = ['sativa', 'indica', 'hybrid', 'blend']
        const markupPrices = ref([])
        const markupPercent = ref('')
        const regularPriceModified = ref(false)
        let taxOptions = []

        return {snackbar, form, schema, accessorySchema, locationSchema, locationAccessorySchema, complianceList, plantTypes, taxOptions, markupPrices, markupPercent, regularPriceModified}
    },
    async mounted() {
        this.form = _.pick(this.editItem, ['name', 'brandId', 'categoryId', 'strain', 'sku', 'concentration_unit', 'thc', 'thc_min', 'thc_max', 'cbd', 'cbd_min', 'cbd_max', 'description'])
        this.form.complianceType = StringHelper.capitalize(this.editItem.complianceType)
        if(this.editItem?.producerId){
            this.form.licensedProducer = this.editItem.producer.licensedProducer
        }

        // cannabis profile
        this.form.thc_cbd_value_type = this.cannabisProfile.thc_cbd_value_type
        this.form.thc = StringHelper.undefinedToEmptyString(this.cannabisProfile.thc)
        this.form.thc_min = StringHelper.undefinedToEmptyString(this.cannabisProfile.thc_min)
        this.form.thc_max = StringHelper.undefinedToEmptyString(this.cannabisProfile.thc_max)
        this.form.cbd = StringHelper.undefinedToEmptyString(this.cannabisProfile.cbd)
        this.form.cbd_min = StringHelper.undefinedToEmptyString(this.cannabisProfile.cbd_min)
        this.form.cbd_max = StringHelper.undefinedToEmptyString(this.cannabisProfile.cbd_max)
        this.form.totalTerp = StringHelper.undefinedOrNullToEmptyString(this.cannabisProfile.totalTerp)
        if(!isEmpty(this.asn)){
            this.form.thc = this.asn.THC || this.asn.THC===0?this.asn.THC:''
            this.form.thc_min = this.asn.THC_MIN || this.asn.THC_MIN===0?this.asn.THC_MIN:''
            this.form.thc_max = this.asn.THC_MAX || this.asn.THC_MAX===0?this.asn.THC_MAX:''
            this.form.cbd = this.asn.CBD || this.asn.CBD===0?this.asn.CBD:''
            this.form.cbd_min = this.asn.CBD_MIN || this.asn.CBD_MIN===0?this.asn.CBD_MIN:''
            this.form.cbd_max = this.asn.CBD_MAX || this.asn.CBD_MAX===0?this.asn.CBD_MAX:''
            this.form.totalTerp = this.asn.TotalTerps?this.asn.TotalTerps:''
        }
        // pricing
        this.form.stock_threshold = this.pricing.stock_threshold
        this.form.stock = StringHelper.undefinedToEmptyString(this.pricing.stock)

        this.emitter.on("saveEditProduct", this.submit);
        //tax
        this.form.tax = this.productTax.map(each => {return each.id})
        this.taxOptions = this.taxes.map(each => {return {id: each.id, name:each.name}})
        // featured category
        if(this.editItem.id){
            this.form.featuredCategoryIds = await this.getFeaturedCategory(this.editItem.id)
        }
        // only get markup if no pricing
        if(isEmpty(this.pricing) && !isEmpty(this.asn)){
            await this.getMarkupPrices()
        }
        this.emitter.on("reloadEditCannabis", this.reloadCannabis)
    },
    unmounted(){
        this.emitter.off("saveEditProduct", this.submit)
        this.emitter.off("reloadEditCannabis", this.reloadCannabis)
    },
    beforeDestroy() {
        this.emitter.off("saveEditProduct", this.submit)
        this.emitter.off("reloadEditCannabis", this.reloadCannabis)
    },
    watch: {
        async editItem(item){
            this.form = _.pick(item, ['name', 'brandId', 'categoryId', 'strain', 'sku', 'concentration_unit', 'thc', 'thc_min', 'thc_max', 'cbd', 'cbd_min', 'cbd_max', 'description'])
            this.form.complianceType = StringHelper.capitalize(this.editItem.complianceType)
            if(item?.producerId){
                this.form.licensedProducer = item.producer.licensedProducer
            }
            // cannabis profile
            this.form.thc_cbd_value_type = this.cannabisProfile.thc_cbd_value_type
            this.form.thc = StringHelper.undefinedToEmptyString(this.cannabisProfile.thc)
            this.form.thc_min = StringHelper.undefinedToEmptyString(this.cannabisProfile.thc_min)
            this.form.thc_max = StringHelper.undefinedToEmptyString(this.cannabisProfile.thc_max)
            this.form.cbd = StringHelper.undefinedToEmptyString(this.cannabisProfile.cbd)
            this.form.cbd_min = StringHelper.undefinedToEmptyString(this.cannabisProfile.cbd_min)
            this.form.cbd_max = StringHelper.undefinedToEmptyString(this.cannabisProfile.cbd_max)
            this.form.totalTerp = StringHelper.undefinedOrNullToEmptyString(this.cannabisProfile.totalTerp)
            if(!isEmpty(this.asn)){
                this.form.caseSize = this.asn.UOMCONVERSION
                if(this.asn.Vendor){
                    this.form.licensedProducer = this.asn.Vendor
                }else if(this.asn?.pmdProduct?.producer?.licensedProducer){
                    this.form.licensedProducer = this.asn.pmdProduct.producer.licensedProducer
                }
            }
            if(!isEmpty(this.asn)){
                this.form.thc = this.asn.THC || this.asn.THC===0?this.asn.THC:''
                this.form.thc_min = this.asn.THC_MIN || this.asn.THC_MIN===0?this.asn.THC_MIN:''
                this.form.thc_max = this.asn.THC_MAX || this.asn.THC_MAX===0?this.asn.THC_MAX:''
                this.form.cbd = this.asn.CBD || this.asn.CBD===0?this.asn.CBD:''
                this.form.cbd_min = this.asn.CBD_MIN || this.asn.CBD_MIN===0?this.asn.CBD_MIN:''
                this.form.cbd_max = this.asn.CBD_MAX || this.asn.CBD_MAX===0?this.asn.CBD_MAX:''
                this.form.totalTerp = this.asn.TotalTerps?this.asn.TotalTerps:''
            }
            // pricing
            this.form.stock_threshold = this.pricing.stock_threshold
            this.form.stock = StringHelper.undefinedToZero(this.pricing.stock)
            // tax
            // this.form.tax = this.productTax.map(each => {return each.id})
            this.taxOptions = this.taxes.map(each => {return {id: each.id, name:each.name}})
            // featured category
            this.form.featuredCategoryIds = await this.getFeaturedCategory(this.editItem.id)
        },
        cannabisProfile(){
            this.form.thc_cbd_value_type = this.cannabisProfile.thc_cbd_value_type
            this.form.thc = StringHelper.undefinedToEmptyString(this.cannabisProfile.thc)
            this.form.thc_min = StringHelper.undefinedToEmptyString(this.cannabisProfile.thc_min)
            this.form.thc_max = StringHelper.undefinedToEmptyString(this.cannabisProfile.thc_max)
            this.form.cbd = StringHelper.undefinedToEmptyString(this.cannabisProfile.cbd)
            this.form.cbd_min = StringHelper.undefinedToEmptyString(this.cannabisProfile.cbd_min)
            this.form.cbd_max = StringHelper.undefinedToEmptyString(this.cannabisProfile.cbd_max)
            this.form.totalTerp = StringHelper.undefinedOrNullToEmptyString(this.cannabisProfile.totalTerp)
            if(!isEmpty(this.asn)){
                this.form.thc = this.asn.THC || this.asn.THC===0?this.asn.THC:''
                this.form.thc_min = this.asn.THC_MIN || this.asn.THC_MIN===0?this.asn.THC_MIN:''
                this.form.thc_max = this.asn.THC_MAX || this.asn.THC_MAX===0?this.asn.THC_MAX:''
                this.form.cbd = this.asn.CBD || this.asn.CBD===0?this.asn.CBD:''
                this.form.cbd_min = this.asn.CBD_MIN || this.asn.CBD_MIN===0?this.asn.CBD_MIN:''
                this.form.cbd_max = this.asn.CBD_MAX || this.asn.CBD_MAX===0?this.asn.CBD_MAX:''
                this.form.totalTerp = this.asn.TotalTerps?this.asn.TotalTerps:''
            }
        },
        pricing(){
            this.form.stock_threshold = this.pricing.stock_threshold
            this.form.stock = StringHelper.undefinedToZero(this.pricing.stock)
        },
        productTax(){
            let self =this
            setTimeout(function(){
                self.form.tax = self.productTax.map(each => {return each.id})
            }, 700)
        },
        taxes(){
            this.taxOptions = this.taxes.map(each => {return {id: each.id, name:each.name}})
        },
        'form.categoryId'(val){
            if(val){
                this.getTaxForSelectedCategory(val)
            }
        }
    },
    computed: {
        categoryColor: function() {
            if(this.form.categoryId){
                let currentCategory = this.categories.filter(each => each.id == this.form.categoryId)
                if(currentCategory[0]?.posColour)
                    return currentCategory[0].posColour
                else
                    return 'black'
            }else{
                return 'black'
            }
        },
        ...mapGetters({
            vg_selectedBusiness: 'auth/activeBusiness',
            vg_selectedLocation: 'location/selected'
        })
    },
    methods: {
        getMarkupPercent(){
            if(this.regularPriceModified){
                this.markupPercent = ((this.form.price-this.form.cost)/(this.form.cost*0.01)).toFixed(2)
            }
        },
        async getMarkupPrices(){
            let res = await PriceMarkupSettingApis.fetchByLocationId(this.vg_selectedLocation.id)
            if(res.status==200){
                this.markupPrices = res.data.payload
            }
        },
        async reloadCannabis(){
            if(this.vg_selectedLocation.id){
                let cannabisProfile = await this.getCannabisProfile(this.editItem.id)
                if(cannabisProfile){
                    this.form.thc = StringHelper.undefinedToEmptyString(cannabisProfile.thc)
                    this.form.thc_min = StringHelper.undefinedToEmptyString(cannabisProfile.thc_min)
                    this.form.thc_max = StringHelper.undefinedToEmptyString(cannabisProfile.thc_max)
                    this.form.cbd = StringHelper.undefinedToEmptyString(cannabisProfile.cbd)
                    this.form.cbd_min = StringHelper.undefinedToEmptyString(cannabisProfile.cbd_min)
                    this.form.cbd_max = StringHelper.undefinedToEmptyString(cannabisProfile.cbd_max)
                    this.form.totalTerp = StringHelper.undefinedToEmptyString(cannabisProfile.totalTerp)
                }
            }
        },
        async getCannabisProfile(productId){
            let res = await ProductApi.fetchCannabisProfile(productId, this.vg_selectedLocation.id)
            if(res.data?.payload){
                return res.data.payload
            }
        },
        async getPricing(productId){
            let data = {locationId: this.vg_selectedLocation.id, productIds:[productId]}
            let res = await ProductApi.fetchPricingByProductId(data)
            if(res.data?.payload[0]){
                return res.data.payload[0]
            }
        },
        getSchema(type){
            if(this.vg_selectedLocation.id){
                if(type=='Accessory'){
                    return this.locationAccessorySchema
                }else{
                    return this.locationSchema
                }
            }else{
                if(type=='Accessory'){
                    return this.accessorySchema
                }else{
                    return this.schema
                }
            }
        },
        async getFeaturedCategory(productId){
            let res = await ProductApi.fetchFeaturedCategory(productId)
            if(res?.data?.payload){
                return res.data.payload.map(each => each.id)
            }
        },
        labelClass(value, error){
            if(value && error){
                return 'text-theme-red'
            }else if(error){
                return 'text-theme-orange'
            }else if(value){
                return 'text-theme-green'
            }
        },
        inputClass(value, error){
            if(value && error){
                return 'border-theme-red'
            }else if(error){
                return 'border-theme-orange'
            }else if(value){
                return 'border-theme-green'
            }
        },
        errorClass(value, error){
            if(value && error){
                return 'text-theme-red'
            }else if(error){
                return 'text-theme-orange'
            }
        },
        async getTaxForSelectedCategory(catId){
            if(this.vg_selectedLocation.id){
                let categoryTax = await BusinessTaxApis.getCategoryTaxes(catId, this.vg_selectedLocation.id)
                if(categoryTax.status==200 && categoryTax.data.payload){
                    this.form.tax = categoryTax.data.payload.map(each => each.id)
                }else{
                    this.form.tax = []
                }
            }
        },
        calculatePrice(val){
            if(!this.editItem.complianceType) return
            let currentMarkupPrice = this.markupPrices.find(each => each.complianceType.toLowerCase()===lowerCase(this.editItem.complianceType.replace(/\s/gi, '')))
            if(currentMarkupPrice && currentMarkupPrice.status && currentMarkupPrice.markupPercentage){
                // this.markupPercent = currentMarkupPrice.markupPercentage
                let value = parseFloat(val)
                this.currentMarkupPrice = parseInt(currentMarkupPrice.markupPercentage)
                let result = parseFloat(value)+(val*this.currentMarkupPrice/100)
                let decimal = (result%1).toFixed(2)
                if(decimal>0){
                    return result?+parseInt(result)+0.99:''
                }else{
                    return result?+result.toFixed(2):''
                }
            }else if(this.asn){
                return this.asn.UnitPrice
            }else{
                return 0
            }
        },
        async submit(){
            let {valid} = await this.$refs.defaultForm.validate()
            let hasBatchLot = true
            let hasPackagedOn = true
            let hasPrice = true
            // check for batch lot and package date only for asn 
            if(this.asn?.SKU && this.form.complianceType!=='Accessory'){
                if(!this.asn.BatchLot){
                    this.snackbar.add({
                        type: 'info',
                        text: "Batch lot is required",
                        duration: 3000,
                    })
                    hasBatchLot = false
                }
                if(!this.asn.PackagedOnDate){
                    this.snackbar.add({
                        type: 'info',
                        text: "Packaged date is required",
                        duration: 3000,
                    })
                    hasPackagedOn = false
                }
            }
            let price
            if(this.asn?.SKU){
                price = this.calculatePrice(this.asn.UnitPrice)
                if(price===0){
                    this.snackbar.add({
                        type: 'info',
                        text: "Price is required",
                        duration: 3000,
                    })
                    hasPrice = false
                }
            }


            if(valid && hasBatchLot && hasPackagedOn && hasPrice){
                if(this.form.complianceType=='Accessory'){
                    this.editItem.productFamily = 'retail'
                }
                let productData = {...this.editItem, ...this.form}
                if(productData.barcode===''){
                    productData.barcode = null
                }

                let cannabisProfileData = {
                    locationId: this.vg_selectedLocation.id,
                    thc_cbd_value_type: this.form.thc_cbd_value_type?this.form.thc_cbd_value_type:'range',
                    concentration_unit: this.form.concentration_unit,
                    thc: parseFloat(this.form.thc),
                    cbd: parseFloat(this.form.cbd),
                    thc_min: parseFloat(this.form.thc_min),
                    thc_max: parseFloat(this.form.thc_max),
                    cbd_min: parseFloat(this.form.cbd_min),
                    cbd_max: parseFloat(this.form.cbd_max),
                    totalTerp: parseFloat(this.form.totalTerp)
                }

                let productPricingData = {
                    locationId: this.vg_selectedLocation.id,
                    cost: this.pricing.cost?parseFloat(this.pricing.cost):0,
                    price:  this.pricing.cost?parseFloat(this.pricing.price):0,
                    sale_price: this.pricing.cost?parseFloat(this.pricing.price):0,
                    stock: parseInt(this.form.stock) || 0,
                }
                if(this.asn?.SKU && isEmpty(this.pricing)){
                    productPricingData.cost = this.asn.UnitPrice
                    productPricingData.price = price
                    productPricingData.sale_price = price
                }
                if(this.asn?.SKU){
                    if(this.editItem.variantName==this.asn.foundProduct.variantName){
                        // main product is default product
                        this.asnSaveProduct(this.editItem, productData, cannabisProfileData, productPricingData)
                    }else{
                        // selected product
                        let variant = this.editItem.variants.find(each => each.sku===this.asn.foundProduct.sku)
                        if(variant.sku){
                            this.asnSaveProduct(variant, productData, cannabisProfileData, productPricingData)
                        }
                    }
                }else{
                    this.saveProduct(productData, cannabisProfileData, productPricingData)
                }
            }
        },
        async asnSaveProduct(variant, productData, cannabisProfileData, productPricingData){
            this.vm_spinner(true)
            let batch = {
                locationId: this.vg_selectedLocation.id,
                productId: variant.id,
                badge_running: true, 
                current_nou: this.asn.Shipped_Qty?parseInt(this.asn.Shipped_Qty):null, 
                cost: this.asn.UnitPrice?parseFloat(this.asn.UnitPrice):null, 
                batch_lot: this.asn.BatchLot?this.asn.BatchLot.toString():null, 
                packaged_on: this.asn.PackagedOnDate?moment(this.asn.PackagedOnDate).toDate():null, 
                shipmentId: this.asn.ShipmentID?this.asn.ShipmentID.toString():'',
                sku: this.asn.SKU,
                stacked_barcode: this.asn.GTINBarCode,
                on_hold: false,
                thc: null,
                cbd: null,
                totalTerp: null
            }
            if(this.fulfillment) cannabisProfileData.fullfillmentTime = this.fulfillment
            if(this.asn.ConcentrationUnit) cannabisProfileData.concentration_unit = this.asn.ConcentrationUnit
            if(this.asn.ShipmentID) batch.shipmentId = this.asn.ShipmentID.toString()
            if(this.asn.THC) batch.thc = this.asn.THC
            if(this.asn.THC_MIN) batch.thcMin = this.asn.THC_MIN
            if(this.asn.THC_MAX) batch.thcMax = this.asn.THC_MAX
            if(this.asn.CBD) batch.cbd = this.asn.CBD
            if(this.asn.CBD_MIN) batch.cbdMin = this.asn.CBD_MIN
            if(this.asn.CBD_MAX) batch.cbdMax = this.asn.CBD_MAX
            if(this.asn.TotalTerps) batch.totalTerp = this.asn.TotalTerps
            let obj = {}
            if(this.form.complianceType=='Accessory'){
                obj = {
                    product: productData,
                    cannabisProfile: cannabisProfileData,
                    pricing: productPricingData,
                }
                if(variant.lotTracking){
                    obj.batch = [batch]
                }else{
                    productPricingData.productId = variant.id
                    productPricingData.stock = this.asn.Shipped_Qty
                    productPricingData.activeStock = this.asn.Shipped_Qty
                }
            }else{
                obj = {
                    product: productData,
                    cannabisProfile: cannabisProfileData,
                    pricing: productPricingData,
                }
                if(variant.lotTracking){
                    obj.batch = [batch]
                }else{
                    productPricingData.productId = variant.id
                    productPricingData.stock = this.asn.Shipped_Qty
                    productPricingData.activeStock = this.asn.Shipped_Qty
                }
            }
            let res = await ProductApi.editProductProfileCostBatch(obj)
            this.vm_spinner(false)
            this.snackbar.add({
                type: 'info',
                text: res.status == 201 ? "Product updated successfully." : Helpers.catchErrorMessage(res.response.data),
                duration: 3000,
            })
            if(res.status==201){
                this.vm_modified(false)
                this.emitter.emit("reloadProductList")
                this.emitter.emit("closeAddEdit")
                if(this.asn?.SKU){
                    // for closing product from list
                    this.emitter.emit("added", this.asn)
                }
            }
        },
        async saveProduct(productData, cannabisProfileData, productPricingData){
            this.vm_spinner(true)
            let res = await ProductApi.editProduct(this.editItem.id, ObjectHelper.emptyStringToNull(productData))
            if(this.vg_selectedLocation.id){
                productPricingData.productId = this.editItem.id
                // if(this.pricing.id){
                //     await ProductApi.updateProductPricing(this.pricing.id, productPricingData)
                // }else{
                //     await ProductApi.createProductPricing(productPricingData)
                // }
                
                // create or update
                await ProductApi.updateCannabisProfile(this.editItem.id, cannabisProfileData)

                // add and remove taxes
                let variantIds = [this.editItem.id, ...this.editItem.variants.map(each => each.id)]
                await this.addOrRemoveProductTax(variantIds, this.productTax, this.form.tax)

                // add featured category
                if(this.form.featuredCategoryIds && this.form.featuredCategoryIds.length>0){
                    let featuredData = {
                        locationId: this.vg_selectedLocation.id,
                        categoryIds: this.form.featuredCategoryIds
                    }
                    await ProductApi.setFeaturedCategory(this.editItem.id, featuredData)
                }
            }
            this.vm_spinner(false)
            this.snackbar.add({
                type: 'info',
                text: res.status == 200 ? "Product updated successfully." : Helpers.catchErrorMessage(res.response.data),
                duration: 3000,
            })
            if(res.status==200){
                this.vm_modified(false)
                this.emitter.emit("reloadProductList")
                this.emitter.emit("closeAddEdit")
                if(this.asn?.SKU){
                    // for closing product from list
                    this.emitter.emit("added", this.asn)
                }
            }
        },
        async addOrRemoveProductTax(productIds, productTax, formTax){
            // add tax
            let preUpdatedTaxes = productTax.map(each => {return each.id})
            let removed = _.difference(preUpdatedTaxes, formTax)
            let added = _.difference(formTax, preUpdatedTaxes)

            for(let i=0; i<removed.length; i++){
                await ProductApi.removeProductTax({locationId: this.vg_selectedLocation.id, productIds: productIds, taxId: removed[i]})
            }

            for(let j=0; j<added.length; j++){
                await ProductApi.addProductTax({locationId: this.vg_selectedLocation.id, productIds: productIds, taxId: added[j]})
            }
            
        },
        ...mapMutations({
            vm_spinner: 'spinner/mutateSpinner',
            vm_modified: 'modifiedForm/mutateStatus'
        })
     }
})
</script>

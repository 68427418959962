<template>
<div class="text-white flex-1 col-span-1 border-0 rounded-t-xl overflow-hidden mt-1">
    <div class="pt-3 bg-[#2B2C32] px-[16px] py-[16px] rounded-xl">
        <p class="text-xs font-normal tracking-wider text-theme-878686 text-center pb-2">Drag &amp; Drop your files below or upload it manually by pressing the “+” symbol</p>
            <div>
                <div>
                    <div class="flex flex-row border border-theme-green rounded-xl" @dragenter.prevent="dragging=true" @dragover.prevent="dragging=true"  @dragleave.prevent="dragging=false" @drop.prevent="addFile" draggable="false">
                        <div class="flex bg-theme-green/30 w-[55px] border-r border-theme-green rounded-tl-xl rounded-bl-xl">
                            <block-loading v-if="uploading" class="self-center" style="height:150px !important; width:55px !important;" />
                            <label v-else for="uploadFile" class="px-2 self-center cursor-pointer">
                                <div class="self-center input-wrapper">
                                    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.9987 37.6673V21.0007H0.332031V17.0007H16.9987V0.333984H20.9987V17.0007H37.6654V21.0007H20.9987V37.6673H16.9987Z" fill="#2BA66B"/>
                                    </svg>
                                </div>
                            </label>
                        </div>
                        <input ref="uploadFile" id="uploadFile" class="hidden" multiple type="file" @change="addFile" />
                        <div v-if="images.length>0" id="sortableMedia" class="flex flex-row flex-wrap min-h-[150px]">
                            <div class="input-wrapper" v-for="image in images" :key="image.id" :id="image.id">
                                <div class="m-1 relative">
                                    <img :src="image.id" class="w-[148px] h-[150px]" />
                                    <div class="absolute top-2 right-2 cursor-pointer" @click="deleteImage(image)">
                                        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.47578 13.8248L0.425781 12.7748L6.20078 6.9998L0.425781 1.2248L1.47578 0.174805L7.25078 5.9498L13.0258 0.174805L14.0758 1.2248L8.30078 6.9998L14.0758 12.7748L13.0258 13.8248L7.25078 8.0498L1.47578 13.8248Z" fill="#F53333"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="!uploading" class="flex flex-row flex-wrap items-center pl-2 min-h-[120px]">
                            No media found.
                        </div>
                    </div>
                </div>
            </div>
    </div>
</div>
</template>
<script>
import { defineComponent, ref } from 'vue'
import ResourceApi from '@/controllers/BusinessProductResourceApis'
import ProductApis from '@/controllers/BusinessProductApis'
import { useSnackbar } from "vue3-snackbar";
import {mapMutations} from 'vuex'
import Sortable from 'sortablejs';
import Helpers from '@/helpers';

export default defineComponent({
    props: ['editItem', 'media'],
    setup() {
        const snackbar = useSnackbar();
        const uploading = ref(false)
        const images = ref([])

        return {snackbar, uploading, images}
    },
    async mounted(){
        this.uploading = true
        this.images = this.media
        this.uploading = false
    },
    methods: {
        async addFile(e){
            let fileList = e.target.files || e.dataTransfer.files
            if(fileList?.length>0){
                let self = this
                this.uploading = true
                let cnt = 0
                for(let i=0; i<fileList.length; i++){
                    this.readFile(fileList[i], async fileData =>{
                        let presignedData = {
                            business_uuid: '123',
                            integrator_name: 'pos',
                            content_type: fileList[i].type,
                            name: fileList[i].name,
                            description: ''
                        }
                        // get presigned url
                        let presignedUrlData = await ResourceApi.getPresignedUrl(presignedData)
                        if(presignedUrlData?.data?.payload?.upload_url){
                            // upload image
                            let imageConfig = {
                                headers: {
                                    'Content-Type': fileList[i].type
                                }
                            }
                            await ResourceApi.uploadResource(presignedUrlData.data.payload.upload_url, fileList[i], imageConfig)
                            this.emitter.emit('updateMediaTerpeneAroma', {type: 'media', add: {id:presignedUrlData.data.payload.full_url}})
                            cnt++
                        }

                        if(cnt==fileList.length){
                            self.uploading = false
                        }
                    })
                }
            }
        },
        readFile(file, callback){
            let reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = e => {
                let result = reader.result
                callback(result)
            }
        },
        deleteImage(image){
            this.confirm("Are you sure to delete this image?").then(async () => {
                this.emitter.emit('updateMediaTerpeneAroma', {type: 'media', remove: {id:image.id}})
            })
        },
        ...mapMutations({
            vm_spinner: 'spinner/mutateSpinner'
        })
    }
})
</script>
